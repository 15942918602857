import React, { useState, useEffect } from 'react';
import { db } from '../../../../Firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';

const UserAuth = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchEmail, setSearchEmail] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const usersPerPage = 10;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => 
      user.email.toLowerCase().includes(searchEmail.toLowerCase())
    );
    setFilteredUsers(filtered);
    // setCurrentPage(1); // 이 줄을 제거합니다.
  }, [searchEmail, users]);

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(userList);
    } catch (error) {
      console.error('사용자 목록을 가져오는 중 오류 발생:', error);
    }
  };

  const toggleSloperStatus = async (userId, currentStatus) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        isSloper: !currentStatus
      });
      // 특정 사용자만 업데이트하고 filteredUsers도 함께 업데이트
      setUsers(prevUsers => {
        const updatedUsers = prevUsers.map(user => 
          user.id === userId ? {...user, isSloper: !currentStatus} : user
        );
        setFilteredUsers(updatedUsers.filter(user => 
          user.email.toLowerCase().includes(searchEmail.toLowerCase())
        ));
        return updatedUsers;
      });
    } catch (error) {
      console.error('Sloper 상태 변경 중 오류 발생:', error);
    }
  };

  const toggleFreeStatus = async (userId, currentStatus) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        isFree: !currentStatus
      });
      // 특정 사용자만 업데이트고 filteredUsers도 함께 업데이트
      setUsers(prevUsers => {
        const updatedUsers = prevUsers.map(user => 
          user.id === userId ? {...user, isFree: !currentStatus} : user
        );
        setFilteredUsers(updatedUsers.filter(user => 
          user.email.toLowerCase().includes(searchEmail.toLowerCase())
        ));
        return updatedUsers;
      });
    } catch (error) {
      console.error('Free 상태 변경 중 오류 발생:', error);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4">
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          placeholder="Search"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
          className="p-2 border rounded w-[250px] text-right"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">이메일</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">상태</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sloper</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Free</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentUsers.map(user => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.isSloper ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {user.isSloper ? 'Y' : 'N'}
                  </span>
                  {user.isFree && (
                    <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      Free
                    </span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={user.isSloper || false}
                        onChange={() => toggleSloperStatus(user.id, user.isSloper)}
                      />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className={`absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${user.isSloper ? 'transform translate-x-full bg-[#D00070]' : 'bg-gray-200'}`}></div>
                    </div>
                  </label>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <label className="flex items-center cursor-pointer">
                    <div className="relative">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={user.isFree || false}
                        onChange={() => toggleFreeStatus(user.id, user.isFree)}
                      />
                      <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <div className={`absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${user.isFree ? 'transform translate-x-full bg-green-500' : 'bg-gray-200'}`}></div>
                    </div>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserAuth;