// 커리큘럼 보고, 학습 시작 >> 모두 다 LMS 공부하기 메뉴 위에서 진행되도록.
// CourseData+CourseInfo+PythonCursor(동적으로 변하는 커리큘럼 컴포넌트)
// 강의 커리큘럼을 보여주기 위해 공통적으로 사용되는 컴포넌트
// 강의 커리큘럼 타이틀, 강의 커리큘럼 내용, 강의 커리큘럼 이미지로 구성됨.
// 하단에 담당 Sloper가 있으신가요? / 소속된 그룹이 있으신가요? 버튼이 있음.
//

// 마지막에 한 줄 전달 메시지도 커리큘럼마다 다 드리게 나오도록.
// 

import React from 'react';
import PythonCursor from '../../components/course/PythonCursor';
import { courseData } from '../../components/course/CourseData';

const ClassCoursePage = () => {
  return (
    <div>
      <PythonCursor courseInfo={courseData.pythonCursor} />
    </div>
  );
};

export default ClassCoursePage;