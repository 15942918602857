import React from 'react';
import CustomerTabGroup from '../components/customerCenter/CustomerTabGroup';

const Service = () => {
  return (
    <div className="container max-w-[900px] mx-auto">
      <CustomerTabGroup />
    </div>
  );
};

export default Service;
