   // src/components/AdminPage.js
   import React, { useEffect, useState } from 'react';
   import { useSelector } from 'react-redux';
   import { db } from '../../../../Firebase';
   import { collection, getDocs } from 'firebase/firestore';

   const SloperAnalytics = () => {
     const { userData, isLoading } = useSelector((state) => state.auth);
     const [userCount, setUserCount] = useState(0);
     const [withdrawnUserCount, setWithdrawnUserCount] = useState(0);
     const [lastUpdated, setLastUpdated] = useState(null);

     useEffect(() => {
       if (userData && userData.isAdmin && !isLoading) {
         fetchUserCounts();
       }
     }, [userData, isLoading]);

     const fetchUserCounts = async () => {
       try {
         const usersCollection = collection(db, 'users');
         const snapshot = await getDocs(usersCollection);
         const activeUsers = snapshot.docs.filter(doc => !doc.data().isWithdrawn);
         const withdrawnUsers = snapshot.docs.filter(doc => doc.data().isWithdrawn);
         
         setUserCount(activeUsers.length);
         setWithdrawnUserCount(withdrawnUsers.length);
         
         const now = new Date();
         const koreaTime = new Date(now.getTime() + (now.getTimezoneOffset() * 60000) + (9 * 3600000));
         setLastUpdated(koreaTime.toLocaleString('ko-KR', { hour12: false }));
       } catch (error) {
         console.error('사용자 수 가져오기 오류:', error);
       }
     };

     if (!userData || !userData.isAdmin) {
       return null;
     }

     return (
       <div className="max-w-[1160px] mx-auto py-24 px-4 sm:px-6 lg:px-8">
         <h1 className='text-2xl font-bold mt-8 sm:mt-16 mb-6'>관리자 페이지</h1>
         
         <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
           <div className="bg-white shadow rounded-lg p-6">
             <h2 className="text-xl font-semibold mb-4">현재 가입자 수</h2>
             <p className="text-3xl font-bold text-blue-600">{userCount}명</p>
             {lastUpdated && (
               <p className="text-sm text-gray-500 mt-2">
                 마지막 업데이트: {lastUpdated}
               </p>
             )}
           </div>

           <div className="bg-white shadow rounded-lg p-6">
             <h2 className="text-xl font-semibold mb-4">탈퇴 회원수</h2>
             <p className="text-3xl font-bold text-red-600">{withdrawnUserCount}명</p>
             {lastUpdated && (
               <p className="text-sm text-gray-500 mt-2">
                 마지막 업데이트: {lastUpdated}
               </p>
             )}
           </div>
           
           <div className="bg-white shadow rounded-lg p-6">
             <h2 className="text-xl font-semibold mb-4">구독자 수</h2>
             <p className="text-3xl font-bold text-green-600">-</p>
             <p className="text-sm text-gray-500 mt-2">
               데이터를 불러오는 중입니다...
             </p>
           </div>

           <div className="bg-white shadow rounded-lg p-6">
             <h2 className="text-xl font-semibold mb-4">월 예상 매출</h2>
             <p className="text-3xl font-bold text-green-600">-</p>
             <p className="text-sm text-gray-500 mt-2">
               데이터가 아직 없습니다.
             </p>
           </div>
         </div>
       </div>
     );
   };

   export default SloperAnalytics;