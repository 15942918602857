import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../context/AuthContext'; // 사용자 인증 컨텍스트
import { LearningDataService } from '../../LearningData/LearningDataService';
import { ProgressService } from '../curriculum/ProgressService';
import ProblemSolver from './ProblemSolver';
import { useParams, useNavigate } from 'react-router-dom';
import { getProblemsForChapter } from '../curriculum/CurriculumManager';
import confetti from 'canvas-confetti';
import Skeleton from '../../../../skeleton/Skeleton';
import { Link } from 'react-router-dom';

const ProblemTest = () => {
  const { moduleType, chapterId } = useParams();
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [userAnswers, setUserAnswers] = useState({});
  const [userCodes, setUserCodes] = useState({});
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // 현재 로그인한 사용자 정보 가져오기
  const [startTime, setStartTime] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isLastProblem, setIsLastProblem] = useState(false);
  const [completedProblems, setCompletedProblems] = useState({});

  // currentProblem을 계산하는 부분을 추가합니다.
  const currentProblem = filteredProblems[currentProblemIndex] || {};

  useEffect(() => {
    setStartTime(Date.now());
  }, [currentProblemIndex]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (moduleType && chapterId && user) {
          const chapterProblems = await getProblemsForChapter(moduleType, chapterId);
          if (Array.isArray(chapterProblems) && chapterProblems.length > 0) {
            setFilteredProblems(chapterProblems);
            
            // 사용자의 진행 상황을 가져옵니다.
            const progress = await ProgressService.getUserProgress(user.uid);
            
            // 완료된 문제의 수를 계산합니다.
            const completedProblemsCount = Object.keys(progress[moduleType]?.[chapterId] || {}).length;
            
            // 완료된 문제 상태를 설정합니다.
            const completed = {};
            chapterProblems.forEach((problem, index) => {
              if (index < completedProblemsCount) {
                completed[problem.problemId] = true;
              }
            });
            setCompletedProblems(completed);
            
            // 다음에 풀어야 할 문제의 인덱스를 설정합니다.
            const newIndex = Math.min(completedProblemsCount, chapterProblems.length - 1);
            
            setCurrentProblemIndex(newIndex);
            setIsLastProblem(newIndex === chapterProblems.length - 1);
            
            // 사용자의 답변을 가져옵니다.
            const answers = await LearningDataService.getUserAnswers(user.uid, moduleType, chapterId);
            setUserAnswers(answers);

            // 사용자의 코드를 가져옵니다.
            const codes = await LearningDataService.getUserCodes(user.uid, moduleType, chapterId);
            setUserCodes(codes);

            // 정답 여부를 가져옵니다.
            const correct = await LearningDataService.getCorrectAnswers(user.uid, moduleType, chapterId);
            setCorrectAnswers(correct);
          } else {
            setFilteredProblems([]);
          }
        }
      } catch (error) {
        setFilteredProblems([]);
      } finally {
        setIsLoading(false);
      }
    };
    
    if (user) {
      fetchData();
    }
  }, [moduleType, chapterId, user]);

  const saveUserAnswer = async (answer) => {
    if (user && currentProblem.problemId) {
      await LearningDataService.saveUserAnswer(
        user.uid,
        moduleType,
        chapterId,
        currentProblem.problemId,
        answer
      );
      setUserAnswers(prev => ({
        ...prev,
        [currentProblem.problemId]: answer
      }));
    }
  };

  const saveUserCode = async (code) => {
    if (user && currentProblem.problemId) {
      await LearningDataService.saveUserCode(
        user.uid,
        moduleType,
        chapterId,
        currentProblem.problemId,
        code
      );
      setUserCodes(prev => ({
        ...prev,
        [currentProblem.problemId]: code
      }));
    }
  };

  const setIsAnswerCorrect = async (isCorrect) => {
    if (!user) {
      console.error('사용자가 인증되지 않았습니다.');
      return;
    }

    setCorrectAnswers(prev => ({
      ...prev,
      [currentProblem.problemId]: isCorrect
    }));
    
    const timeTaken = Math.round((Date.now() - startTime) / 1000);

    try {
      if (currentProblem.type === 'concept') {
        await LearningDataService.saveConceptProgress(
          user.uid,
          moduleType,
          chapterId,
          currentProblem.problemId
        );
      } else {
        await LearningDataService.saveProblemAttempt(
          user.uid,
          moduleType,
          chapterId,
          currentProblem.problemId,
          isCorrect,
          timeTaken
        );
      }
      await ProgressService.updateUserProgress(
        user.uid,
        moduleType,
        chapterId,
        currentProblem.problemId
      );
    } catch (error) {
      console.error('학습 데이터 또는 진행 상황 저장 중 오류:', error);
    }

    try {
      await LearningDataService.saveCorrectAnswer(
        user.uid,
        moduleType,
        chapterId,
        currentProblem.problemId,
        isCorrect
      );
    } catch (error) {
      console.error('정답 여부 저장 중 오류:', error);
    }

    setIsNextButtonDisabled(!isCorrect);

    if (isCorrect) {
      setCompletedProblems(prev => ({...prev, [currentProblem.problemId]: true}));
    }
  };

  useEffect(() => {
    // 현재 문제에 대한 정답 여부를 확인하고 Next 버튼 상태를 설정
    const isCurrentProblemCorrect = correctAnswers[currentProblem.problemId] || completedProblems[currentProblem.problemId];
    setIsNextButtonDisabled(!isCurrentProblemCorrect);
  }, [currentProblemIndex, correctAnswers, completedProblems, currentProblem.problemId]);

  const handleNext = () => {
    if (currentProblemIndex < filteredProblems.length - 1) {
      setCurrentProblemIndex(currentProblemIndex + 1);
      setIsNextButtonDisabled(false);  // 항상 NEXT 버튼을 활성화합니다.
      setIsLastProblem(currentProblemIndex + 1 === filteredProblems.length - 1);
    } else {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
      setTimeout(() => {
        navigate(`/lms/curriculum/${moduleType}`);
      }, 1000);
    }
  };

  const handleBack = () => {
    if (currentProblemIndex > 0) {
      setCurrentProblemIndex(currentProblemIndex - 1);
      setIsNextButtonDisabled(false);  // 이전 문제로 돌아갈 때도 NEXT 버튼을 활성화합니다.
    }
  };

  if (isLoading || filteredProblems.length === 0 || !currentProblem) {
    return <Skeleton />;
  }

  const progressPercentage = Math.round(((currentProblemIndex + 1) / filteredProblems.length) * 100);

  return (
    <div className="max-w-2xl mx-auto mt-20 p-6 bg-white relative">
      <Link
        to={`/lms/curriculum/${moduleType}`}
        className="absolute -top-6 left-6 px-3 py-1 text-gray-700 rounded hover:bg-gray-300"
      >
        ◀︎ 커리큘럼으로 돌아가기
      </Link>

      <div className="mb-4 flex items-center">
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-4 flex-grow">
          <div 
            className="bg-[#D00070] h-2.5 rounded-full" 
            style={{width: `${progressPercentage}%`}}
          ></div>
        </div>
        <p className="text-lg font-semibold whitespace-nowrap">
          🎯 {progressPercentage}% ({currentProblemIndex + 1}/{filteredProblems.length})
        </p>
      </div>

      <h1 className="text-2xl font-bold mb-4 text-center">{currentProblem.title}</h1>
      
      {currentProblem.image && (
        <div className="mb-4 flex justify-center">
          <img 
            src={currentProblem.image} 
            alt="Problem Img" 
            className="max-w-[90%] h-auto rounded-md"
          />
        </div>
      )}
      
      <p className="px-[5%] text-gray-700 mb-4 whitespace-pre-line">{currentProblem.description}</p>

      <ProblemSolver 
        problem={currentProblem} 
        setIsAnswerCorrect={setIsAnswerCorrect}
        savedAnswer={userAnswers[currentProblem.problemId]}
        saveUserAnswer={saveUserAnswer}
        savedCode={userCodes[currentProblem.problemId]}
        saveUserCode={saveUserCode}
        isAnswerCorrect={correctAnswers[currentProblem.problemId]}
        key={currentProblemIndex}
        isLastProblem={isLastProblem}
      />

      <div className="mt-4 flex justify-between">
        <button
          onClick={handleBack}
          disabled={currentProblemIndex === 0}
          className={`px-4 py-2 bg-gray-300 text-gray-700 rounded ${
            currentProblemIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
          }`}
        >
          Back
        </button>
        <button
          onClick={handleNext}
          disabled={isNextButtonDisabled}
          className={`px-4 py-2 bg-green-600 text-white rounded ${
            isNextButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-300'
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ProblemTest;