import React, { useState, useEffect, useCallback, useMemo } from "react";
import MonacoEditor from "../../../../monacoEditor/MonacoEditor";
import Hint from './Hint';

function ProblemSolver({ problem, setIsAnswerCorrect, savedAnswer, saveUserAnswer, savedCode, saveUserCode, isAnswerCorrect, isLastProblem }) {
  const [userAnswer, setUserAnswer] = useState(savedAnswer || "");
  const [isCorrect, setIsCorrect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState(savedCode || problem?.initialCode || "");
  const [output, setOutput] = useState('');
  const [showHint, setShowHint] = useState(false);
  const [disabledChoices, setDisabledChoices] = useState([]);
  const [encouragementMessage, setEncouragementMessage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [isUnderstood, setIsUnderstood] = useState(false);

  const encouragementMessages = useMemo(() => [
    "💭 힌트를 사용해 보세요. 포기하지 마세요, 잘 하고 있어요! 🧗🏻‍♀️",
    "🌟 다시 한 번 도전해 보세요.",
    "💪 어려워도 괜찮아요. 천천히 생각해 보세요.",
    "🔍 문제를 다시 한 번 꼼꼼히 읽어보세요."
  ], []);

  const getRandomEncouragement = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * encouragementMessages.length);
    return encouragementMessages[randomIndex];
  }, [encouragementMessages]);

  useEffect(() => {
    setIsCorrect(null);
    setShowResult(false);
    setEncouragementMessage("");
  }, [problem]);

  useEffect(() => {
    if (isAnswerCorrect !== null) {
      setIsCorrect(isAnswerCorrect);
      setShowResult(true);
      if (!isAnswerCorrect) {
        setEncouragementMessage(getRandomEncouragement());
      } else {
        setEncouragementMessage("");
      }
    }
  }, [isAnswerCorrect, getRandomEncouragement]);

  const resetProblemState = useCallback(() => {
    setCode(problem?.initialCode || "");
    setUserAnswer("");
    setOutput('');
    setShowResult(false);
    setEncouragementMessage("");
    setDisabledChoices([]);
    setIsCorrect(null);
  }, [problem]);

  useEffect(() => {
    if (problem) {
      resetProblemState();
    }
  }, [problem, resetProblemState]);

  useEffect(() => {
    if (savedAnswer !== undefined) {
      setUserAnswer(savedAnswer);
    }
    if (savedCode !== undefined) {
      setCode(savedCode);
    }
    if (isAnswerCorrect !== undefined) {
      setIsCorrect(isAnswerCorrect);
      setShowResult(isAnswerCorrect !== null);
      if (isAnswerCorrect) {
        setEncouragementMessage("");
      }
    }
  }, [savedAnswer, savedCode, isAnswerCorrect, problem.problemId]);

  const handleInputChange = (event) => {
    const newAnswer = event.target.value;
    setUserAnswer(newAnswer);
    saveUserAnswer(newAnswer);
    setShowResult(false);
    setEncouragementMessage("");
    setIsCorrect(null);
    setIsAnswerCorrect(null);
  };

  const handleMultipleChoiceSelect = (choice) => {
    if (!disabledChoices.includes(choice)) {
      setUserAnswer(choice);
      saveUserAnswer(choice);
      setShowResult(false);
      setEncouragementMessage("");
      setIsCorrect(null);
      setIsAnswerCorrect(null);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let correct = false;
    try {
      if (problem.type === "multiple_choice") {
        correct = userAnswer === problem.correctAnswer;
        if (!correct) {
          setDisabledChoices(prev => [...prev, userAnswer]);
        }
      } else if (problem.type === "open_ended" || problem.type === "input_answer") {
        const normalizedUserAnswer = userAnswer.trim().toLowerCase();
        correct = problem.acceptableAnswers
          ? problem.acceptableAnswers.some(answer => normalizedUserAnswer === answer.toLowerCase())
          : normalizedUserAnswer === (problem.correctAnswer || problem.solution).trim().toLowerCase();
      } else if (problem.type === "code") {
        correct = output.includes("Passed all the test cases 🚀");
      }

      setIsCorrect(correct);
      setIsAnswerCorrect(correct);
      
      if (!correct) {
        setEncouragementMessage(getRandomEncouragement());
      } else {
        setEncouragementMessage("");
      }
      
      setShowResult(true);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setIsCorrect(false);
      setIsAnswerCorrect(false);
      setEncouragementMessage(getRandomEncouragement());
      setShowResult(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    saveUserCode(newCode);
  };

  const handleUnderstand = () => {
    setIsUnderstood(true);
    setIsAnswerCorrect(true);
  };

  const renderQuestionContent = () => {
    switch (problem.type) {
      case "multiple_choice":
        return (
          <div className="space-y-2">
            {problem.choices.map((choice) => (
              <div
                key={choice.id}
                className={`p-4 border rounded-md cursor-pointer transition-all ${
                  userAnswer === choice.id
                    ? "bg-blue-100 border-blue-500"
                    : disabledChoices.includes(choice.id)
                    ? "bg-gray-200 opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleMultipleChoiceSelect(choice.id)}
              >
                <div className="flex items-center">
                  <span className="flex-grow whitespace-pre-wrap">{choice.text}</span>
                </div>
              </div>
            ))}
          </div>
        );
      case "open_ended":
        return (
          <textarea
            className="w-full p-2 border rounded-md"
            rows="4"
            value={userAnswer}
            onChange={handleInputChange}
            placeholder="답안을 입력하세요"
          />
        );
      case "input_answer":
        return (
          <input
            type="text"
            className="w-full p-2 border rounded-md"
            value={userAnswer}
            onChange={handleInputChange}
            placeholder="답안을 입력하세요"
          />
        );
      case "code":
        return (
          <MonacoEditor
            code={code}
            setCode={handleCodeChange}
            output={output}
            setOutput={setOutput}
            testCases={problem.testCases}
            language="python"
          />
        );
      default:
        return <p></p>;
    }
  };

  const renderButtons = () => {
    switch (problem.type) {
      case "concept":
        return (
          <div className="flex justify-end">
            <button
              onClick={handleUnderstand}
              disabled={isUnderstood}
              className={`bg-green-500 text-white px-4 py-2 rounded-md font-bold hover:bg-green-600 transition-colors duration-200 ${
                isUnderstood ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isUnderstood ? "Understood ✅" : "Understand ⭐️"}
            </button>
          </div>
        );
      case "multiple_choice":
      case "input_answer":
      case "code":
        return (
          <>
            <div className="flex justify-between w-full">
              <button
                onClick={() => setShowHint(!showHint)}
                className="bg-white text-black px-4 py-2 rounded-md border-2 border-gray-300 font-bold hover:bg-gray-100 transition-colors duration-200"
              >
                {showHint ? "Hide Hint 🙈" : "Show Hint 💭"}
              </button>
              <button
                onClick={handleSubmit}
                disabled={isLoading || (isLastProblem && isCorrect)}
                className={`bg-green-500 text-white px-4 py-2 rounded-md font-bold hover:bg-green-600 transition-colors duration-200 ${
                  isLoading || (isLastProblem && isCorrect) ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? "⏳" : isLastProblem && isCorrect ? "Completed ✅" : "Check ✅"}
              </button>
            </div>
          </>
        );
      default:
        return (
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`bg-black text-white px-4 py-2 rounded-md font-extrabold hover:bg-gray-800 transition-colors duration-200 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isLoading ? "⏳" : "Check ✅"}
          </button>
        );
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex-grow overflow-y-auto">
        <div className="max-w-2xl mx-auto bg-white p-6">
          {renderQuestionContent()}
          <div className="mt-4">
            {renderButtons()}
          </div>
          <div className={`mt-4 transition-all duration-300 ease-in-out ${showHint ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
            <Hint hints={problem.hints} />
          </div>
          <div className={`mt-4 transition-all duration-300 ease-in-out ${showResult && isCorrect !== null ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
            <div className={`p-4 rounded-md text-center font-extrabold ${isCorrect ? 'bg-green-200' : 'bg-red-200'}`}>
              <p className={isCorrect ? 'text-green-600' : 'text-red-600'}>
                {isCorrect ? 'Correct! 🎉' : encouragementMessage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProblemSolver;
