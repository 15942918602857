import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Main from '../components/landing/container/contents/Main';
import StarterContents from '../components/landing/container/contents/StarterContents';
import InfoContents from '../components/landing/container/contents/InfoContents';
import ForDevContents from '../components/landing/container/contents/ForDevContents';
import BlockContents from '../components/landing/container/contents/BlockContents';
import DiscountContents from '../components/landing/container/contents/DiscountContents';
import BestContents from '../components/landing/container/contents/BestContents';
import ModuleContents from '../components/landing/container/contents/ModuleContents';
import InfoClassContents from '../components/landing/container/contents/InfoClassContents';
// import ClassContents from '../components/landing/container/contents/ClassContents';
// import TryContents from '../components/landing/container/contents/TryContents';

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HelmetProvider>
        <meta property="og:title" content="01CS-공학교육플랫폼" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.01cs.kr" />
        <meta property="og:image" content="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbahKlg%2FbtsJraSZd4S%2FM6QRlkAUivqNnfq2VZwgKk%2Fimg.png" />
        <meta property="og:description" content="공학기초교육을 위한 01CS 컴퓨터과학 교육 플랫폼" />
        <meta property="og:site_name" content="01CS" />
        <meta property="og:locale" content="ko_KR" />
        <meta property="og:locale:alternate" content="en_US" /> 
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:locale:alternate" content="ja_JP" />
        <meta property="og:locale:alternate" content="zh_CN" /> 
        <meta property="og:locale:alternate" content="th_TH" /> 
        <meta property="og:locale:alternate" content="vi_VN" />
      </HelmetProvider>

      <Main />
      <ModuleContents />
      <InfoClassContents />
      <BestContents />
      <StarterContents />
      <ForDevContents />
      <InfoContents />
      {/* <ClassContents /> */}
      {/* <TryContents /> */}
      <BlockContents />
      <DiscountContents />
    </div>
  );
};

export default LandingPage;