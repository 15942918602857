import React, { useEffect, useState } from 'react';

const NaverTalkBtn = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // 이미 존재하는 스크립트 확인
    if (!document.querySelector('script[src="https://partner.talk.naver.com/banners/script"]')) {
      const script = document.createElement('script');
      script.src = 'https://partner.talk.naver.com/banners/script';
      script.async = true;
      script.charset = "utf-8";
      document.body.appendChild(script);

      script.onload = createNaverTalkBanner;
    } else {
      createNaverTalkBanner();
    }

    return () => {
      // 컴포넌트 언마운트 시 배너 제거
      const banner = document.querySelector('.talk_banner_div');
      if (banner) {
        banner.remove();
      }
    };
  }, []);

  const createNaverTalkBanner = () => {
    if (!document.querySelector('.talk_banner_div')) {
      const bannerDiv = document.createElement('div');
      bannerDiv.className = 'talk_banner_div';
      bannerDiv.setAttribute('data-id', '152032');
      document.getElementById('naverTalkBanner').appendChild(bannerDiv);
      window.TalkBanner && window.TalkBanner.reloadBanner();
    }
  };

  const toggleBanner = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50 flex items-center">
        <div className="relative flex items-center">
        <button
            onClick={toggleBanner}
            className="
            bg-[#00C73C] text-white w-8 h-12
            flex items-center justify-center hover:bg-[#00C73C]/50
            transition-all duration-500 ease-in-out
            translate-y-[4px] translate-x-[-20px]
            "
        >
            {isExpanded ? '〉' : '〈'}
        </button>
        <div 
            className={`
            relative transition-all duration-500 ease-in-out
            ${isExpanded ? 'w-[160px]' : 'w-0'}
            `}
        >
            <div 
            id="naverTalkBanner" 
            className={`
                absolute -left-5 -top-5 w-[160px] transition-all duration-500 ease-in-out
                ${isExpanded ? 'opacity-100' : 'opacity-100'}
            `}
            ></div>
        </div>
        </div>
    </div>
  );
};

export default NaverTalkBtn;