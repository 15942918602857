// src/components/company/container/ending/EndingCredits.js

import React from 'react';
import CompanyCredit from './CompanyCredit'; 
import TeamCredit from './TeamCredit';
import FinalCredit from './FinalCredit';

const EndingCredits = () => {
  return (
    <div>
      {/* 회사 소개 크레딧 */}
      <CompanyCredit />

      {/* 팀 소개 크레딧 */}
      <TeamCredit />

      {/* 마지막 크레딧 */}
      <FinalCredit />
    </div>
  );
};

export default EndingCredits;