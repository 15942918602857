import React from 'react';
import { Bar } from 'react-chartjs-2';

const WeekdayStudyChart = ({ data }) => {
  const chartData = {
    labels: data.map(d => d.name),
    datasets: [
      {
        label: '1주차',
        data: data.map(d => d['1주차']),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: '2주차',
        data: data.map(d => d['2주차']),
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
      },
      {
        label: '3주차',
        data: data.map(d => d['3주차']),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
      {
        label: '4주차',
        data: data.map(d => d['4주차']),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  const options = {
    scales: {
      x: { stacked: true },
      y: { stacked: true, title: { display: true, text: '학습 시간 (시간)' } },
    },
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: '4주간 요일별 학습 시간' },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default WeekdayStudyChart;