import React, { useEffect, useRef, useState, useCallback } from 'react';

const FinalCredit = () => {
  const [opacity, setOpacity] = useState(1);
  const [secondOpacity, setSecondOpacity] = useState(0);
  const [isFixed, setIsFixed] = useState(true);
  const containerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = containerRef.current?.offsetTop || 0;
    const scrollRange = 500;
    const secondScrollRange = 500;
    const totalScrollRange = scrollRange + secondScrollRange;

    if (scrollTop > offsetTop && scrollTop < offsetTop + totalScrollRange) {
      const newOpacity = Math.max(1 - (scrollTop - offsetTop) / scrollRange, 0);
      setOpacity(newOpacity);

      const secondTextOpacity = Math.min((scrollTop - offsetTop - scrollRange) / secondScrollRange, 1);
      setSecondOpacity(secondTextOpacity);
    }

    setIsFixed(scrollTop < offsetTop + totalScrollRange);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFixed(entry.isIntersecting);
      },
      { rootMargin: '0px', threshold: 0 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="relative h-[350vh] bg-black -mt-1" ref={containerRef}>
      <div className={`sticky top-0 h-screen w-full ${isFixed ? '' : 'relative'}`}>
        <div
          className="absolute top-0 left-0 w-full text-center h-screen flex items-center justify-center transition-opacity duration-500"
          style={{ opacity }}
        >
          <p className="text-lg text-[#fff] font-bold">
            <span className="block md:inline"><span className='text-[#D00070]'>SlopeX</span>celerity의 기초공학교육으로</span> 앞으로의 삶에 도움되시길 바라는 마음입니다.
          </p>
        </div>
        <div
          className="absolute top-0 left-0 w-full text-center h-screen flex items-center justify-center transition-opacity duration-500"
          style={{ opacity: secondOpacity }}
        >
          <p className="text-lg text-[#fff] font-bold">
            <span className="block md:inline">우리는 계속해서 공학교육을 발전시키고,</span> 공학기술에 기여하는 회사로 성장하겠습니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FinalCredit;