import { useEffect } from 'react';

let openModalCount = 0;
let scrollbarWidth = 0;

// 스크롤바 너비 계산 함수
const calculateScrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  document.body.appendChild(scrollDiv);
  
  const innerDiv = document.createElement('div');
  scrollDiv.appendChild(innerDiv);
  
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  
  return scrollbarWidth;
};

export const usePreventScroll = (isOpen) => {
  useEffect(() => {
    if (scrollbarWidth === 0) {
      calculateScrollbarWidth();
    }

    if (isOpen) {
      openModalCount += 1;
      if (openModalCount === 1) {
        // 스크롤 막기
        document.body.style.overflow = 'hidden';
        // 스크롤바 너비만큼 패딩 추가하여 레이아웃 이동 방지
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      }
    } else {
      if (openModalCount > 0) {
        openModalCount -= 1;
        if (openModalCount === 0) {
          // 스크롤 복구
          document.body.style.overflow = '';
          // 패딩 제거
          document.body.style.paddingRight = '';
        }
      }
    }

    return () => {
      if (isOpen && openModalCount > 0) {
        openModalCount -= 1;
        if (openModalCount === 0) {
          document.body.style.overflow = '';
          document.body.style.paddingRight = '';
        }
      }
    };
  }, [isOpen]);
};