import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const LmsUserInfo = () => {
  const userData = useSelector((state) => state.auth.userData);

  const [showWelcome, setShowWelcome] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);

  useEffect(() => {
    // 컴포넌트 마운트 시 환영 메시지를 아래에서 부드럽게 나타나게 함
    const showWelcomeTimer = setTimeout(() => {
      setShowWelcome(true);
    }, 500); // 약간의 지연 후 애니메이션 시작

    // 3초 후에 환영 메시지를 숨기고 사용자 정보를 표시
    const showUserInfoTimer = setTimeout(() => {
      setShowWelcome(false);
      setShowUserInfo(true);
    }, 5000); // 환영 메시지가 나타난 후 3초 후에 사용자 정보 표시

    return () => {
      clearTimeout(showWelcomeTimer);
      clearTimeout(showUserInfoTimer);
    }; // 컴포넌트 언마운트 시 타이머 클리어
  }, []);

  const { email, name, isAdmin, isSloper, isFree } = userData;

  const badges = [
    { condition: isAdmin, label: 'ADMIN', colorClass: 'bg-red-500', icon: '👑' },
    { condition: isSloper, label: 'SLOPER', colorClass: 'bg-green-500', icon: '🚀' },
    { condition: isFree, label: 'FREE', colorClass: 'bg-blue-500', icon: '🎁' },
  ];

  return (
    <div className="w-[95%] md:w-[70%] py-[3%] md:py-[2%] p-3 pr-8 relative h-16 md:h-10 overflow-hidden border border-gray-200 shadow-lg rounded flex items-center mx-auto mt-24">
      {/* 환영 메시지 */}
      <div
        className={`absolute mx-4 transition-all duration-500 ease-in-out transform ${
          showWelcome
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 translate-y-full'
        }`}
      >
        <span className="text-sm text-gray-700 font-extrabold"><span className='block md:inline'>Slopex AI와 Sloper의</span> 도움을 받아 목표를 달성해보세요.</span>
      </div>

      {/* 사용자 정보 */}
      <div
        className={`absolute right-4 flex flex-col md:flex-row items-end md:items-center space-y-0 md:space-x-4 transition-all duration-500 ease-in-out transform ${
          showUserInfo
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 translate-y-full'
        }`}
      >
        <div className="flex flex-wrap justify-end space-x-2">
          {badges
            .filter((badge) => badge.condition)
            .map((badge, index) => (
              <span
                key={index}
                className={`flex items-center ${badge.colorClass} text-white text-xs px-3 py-1 rounded-full font-extrabold mt-1 md:mt-0`}
              >
                <span className="mr-1">{badge.icon}</span>
                {badge.label}
              </span>
            ))}
          <div className="flex items-center space-x-2 mt-1.5 md:mt-0">
            <span className="text-sm text-gray-700 font-extrabold">{name || email}</span>
            {/* <a href="/lms/myinfo" className="text-sm text-[#d00070] font-extrabold">
              정보 수정 〉
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LmsUserInfo;