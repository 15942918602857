import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const notices = [
  { id: 1, title: 'SlopeX 0.2.3', date: '2024-12-21', content: '파이썬 알고리즘 문제가 추가되었습니다.' },
  { id: 2, title: 'SlopeX 0.2.1', date: '2024-10-11', content: '파이썬 기초 프로그래밍 교육이 추가되었습니다.' },
  { id: 3, title: 'SlopeX 0.0.1', date: '2024-09-24', content: '사용자 인증, 기억력 테스트, 상담관련 기능이 추가되었습니다.' },
  { id: 4, title: '기억력 테스트 개발완료', date: '2024-09-20', content: '개발자의 기초역량을 테스트해볼 수 있는 기억력 테스트가 개발완료되었습니다. 아직 데이터 분석은 제공해드리지 않지만, 추후 학습자들의 데이터와 AI 학습 데이터를 수집하여 데이터 분석자료를 제공할 예정입니다.' },
  { id: 5, title: '[OPEN] Ver.Pre-Alpha 🎉', date: '2024-09-01', content: '줌 강의 및 교육자료 배포를 포함합니다.' },
];

const TabB = () => {
  const [activeNotices, setActiveNotices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleNoticeClick = (id) => {
    setActiveNotices((prevActiveNotices) =>
      prevActiveNotices.includes(id)
        ? prevActiveNotices.filter((noticeId) => noticeId !== id)
        : [...prevActiveNotices, id]
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNotices = notices.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(notices.length / itemsPerPage);

  const handlePageClick = (page) => {
    if (typeof page === 'number') {
      setCurrentPage(page);
    } else {
      setCurrentPage((prevPage) => {
        let newPage = page === 'next' ? prevPage + 1 : prevPage - 1;
        if (newPage < 1) newPage = 1;
        if (newPage > totalPages) newPage = totalPages;
        return newPage;
      });
    }
  };

  return (
    <div className="max-w-[380px] md:max-w-[800px] mx-auto mt-12 space-y-6">
      {currentNotices.map((notice) => (
        <div key={notice.id} className="border-b pb-4">
          <h3
            className={`text-sm md:text-xl font-bold cursor-pointer flex justify-between items-center ${
              activeNotices.includes(notice.id) ? 'text-[#D00070]' : 'text-[#333]'
            }`}
            onClick={() => handleNoticeClick(notice.id)}
          >
            <span className="w-[45px] md:w-[100px] tracking-[-0.1em] md:tracking-normal">{`No.${notice.id}`}</span>
            <span className="flex-1 px-2 md:px-12 text-left">{notice.title}</span>
            <span className="w-[85px] md:w-[150px] text-right tracking-[-0.1em] md:tracking-normal text-gray-600">{notice.date}</span>
          </h3>
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              activeNotices.includes(notice.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <p className="text-xs md:text-base text-gray-600 mt-4 whitespace-pre-wrap">
              {notice.content}
              {notice.id === 1 && (
                <>
                  {'\n\n'}
                  <Link to="/memory-test" className="text-[#D00070] hover:underline">
                    기억력 테스트 하러가기
                  </Link>
                </>
              )}
            </p>
          </div>
        </div>
      ))}

      <div className="flex justify-center mt-4 space-x-2">
        <button
          className="px-3 py-1 rounded bg-gray-200 text-gray-600"
          onClick={() => handlePageClick('prev')}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-3 py-1 rounded ${
              currentPage === index + 1 ? 'bg-[#D00070] text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handlePageClick(index + 1)}
          >
            {index + 1}
          </button>
        ))}

        <button
          className="px-3 py-1 rounded bg-gray-200 text-gray-600"
          onClick={() => handlePageClick('next')}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default TabB;