import React, { useState } from 'react';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';
import dis01 from '../../../../assets/tile/dis01.webp';
import dis02 from '../../../../assets/tile/dis02.webp';
import ContentsModal from '../modal/ContentsModal';

const DiscountContents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  // 타일 데이터
  const tilesData = [
    {
      title1: '그룹을 위한 교육혜택 🎉',
      title2: 'Peer Learning(동료 학습)의\n학습경험을 온라인에서도',
      description: '사용자의 학습경험을 바탕으로\n교육 콘텐츠가 제공됩니다.',
      img: dis02,
      title1Color: '#C70039',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      title1Margin: 'mb-2',
      title2Margin: 'mb-60 md:mb-80',
      status: ['dev'], // status 추가
      modalContent: {
        modalTitle: '2명부터 그룹으로',
        modalDetails: `그룹내의 학습자들은 서로의 학습 데이터를 공유할 수 있습니다.
그룹의 목표를 설정하여 함께 목표를 향해 달려갑니다.
그룹에 속해있는 학습자들이 어떻게 문제를 풀어나가는지,
어떤 생각으로 문제를 해결해나가는지 참고해보세요.
동료학습의 힘은 아주 강력합니다.`,
        modalExtraInfo: '그룹 만들고 교육 시작하기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '내일배움카드로 역량강화 🎉',
      title2: `01CS의 모든 강의는
      내일배움카드 결제가능 예정`,
      description: '사업자 등록 관련 검토중',
      img: dis01,
      title1Color: '#C70039',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      title1Margin: 'mb-2',
      title2Margin: 'mb-60 md:mb-80',
      status: ['dev'], // status 추가
      modalContent: {
        modalTitle: '배움에는 아낌없이 투자하자. 🧠',
        modalDetails: `공학교육 관련 다양한 프로젝트에 아낌없는 투자를.
        공학교육을 배우고 싶을때 망설이지 않도록.
        SlopeXcelerity는 아낌없이 공학교육을 위해 투자하겠습니다.
        후회없는 선택을 위해 끝없이 도전하고 발전하겠습니다.`,
        modalExtraInfo: '강의 구경하기 〉',
        // modalLink: '#'
      },
    },
  ];

  // 모달 열기 함수
  const openModal = (tile) => {
    setSelectedTile(tile);
    setIsModalOpen(true);
  };

  // 모달 닫기 함수
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 타일들을 렌더링
  const tiles = tilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      title1Color={tile.title1Color}
      title2Color={tile.title2Color}
      descriptionColor={tile.descriptionColor}
      img={tile.img}
      backgroundColor={tile.backgroundColor}
      width={tile.width}
      height={tile.height}
      mobileWidth={tile.mobileWidth}
      mobileHeight={tile.mobileHeight}
      title1Margin={tile.title1Margin}
      title2Margin={tile.title2Margin}
      onClick={() => openModal(tile)} // 타일 클릭 시 모달 열기
      // status={tile.status} // status prop 추가
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4 text-[#969696]">
          <span className="text-[#000]">공학교육과 관련된</span><br />
          모두를 위해
        </h2>
      </div>

      {/* 타일 컨테이너 */}
      <TileContainer tiles={tiles} containerId="discount-tile-scroll" />

      {/* 모달 컴포넌트 */}
      {selectedTile && (
        <ContentsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          modalContent={selectedTile.modalContent}
          status={selectedTile.status}
          openInNewTab={true}
        />
      )}
    </div>
  );
};

export default DiscountContents;