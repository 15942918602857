
import data_basics_001 from '../assets/problem/data_basics_001.webp';
import data_basics_002 from '../assets/problem/data_basics_002.webp';
import data_basics_003 from '../assets/problem/data_basics_003.webp';
import data_basics_010 from '../assets/problem/data_basics_010.png';
import programming_fundamentals_003 from '../assets/problem/python-programming-fundamentals_003.png';
import programming_fundamentals_006 from '../assets/problem/python-programming-fundamentals_006.png';
import programming_fundamentals_008 from '../assets/problem/python-programming-fundamentals_008.png';
import programming_fundamentals_009 from '../assets/problem/python-programming-fundamentals_009.png';
import basic_data_001 from '../assets/problem/basic-data-structure_001.png';
import basic_data_002 from '../assets/problem/basic-data-structure_002.png';
import basic_data_003 from '../assets/problem/basic-data-structure_003.png';
import basic_data_004 from '../assets/problem/basic-data-structure_004.png';
import basic_data_005 from '../assets/problem/basic-data-structure_005.png';
import basic_data_006 from '../assets/problem/basic-data-structure_006.png';
import basic_data_007 from '../assets/problem/basic-data-structure_007.png';
import operators_001 from '../assets/problem/operators_001.png';
import operators_002 from '../assets/problem/operators_002.png';
import operators_003 from '../assets/problem/operators_003.png';
import operators_004 from '../assets/problem/operators_004.png';
import operators_005 from '../assets/problem/operators_005.png';
import error_001 from '../assets/problem/error_001.png';
import error_002 from '../assets/problem/error_002.png';
import error_003 from '../assets/problem/error_003.png';
import error_004 from '../assets/problem/error_004.png';
import conditional_001 from '../assets/problem/conditional_001.png';
import conditional_002 from '../assets/problem/conditional_002.png';
import conditional_003 from '../assets/problem/conditional_003.png';
import conditional_004 from '../assets/problem/conditional_004.png';
import conditional_005 from '../assets/problem/conditional_005.png';
import conditional_006 from '../assets/problem/conditional_006.png';
import conditional_007 from '../assets/problem/conditional_007.png';
import conditional_008 from '../assets/problem/conditional_008.png';
import loops_001 from '../assets/problem/loops_001.png';
import loops_002 from '../assets/problem/loops_002.png';
import loops_003 from '../assets/problem/loops_003.png';
import loops_004 from '../assets/problem/loops_004.png';
import python_data_beginner_001 from '../assets/problem/python_data_beginner_001.png';
import python_data_beginner_002 from '../assets/problem/python_data_beginner_002.png';
import python_data_beginner_003 from '../assets/problem/python_data_beginner_003.png';
import python_data_beginner_004 from '../assets/problem/python_data_beginner_004.png';
import python_data_beginner_005 from '../assets/problem/python_data_beginner_005.png';
import python_data_beginner_006 from '../assets/problem/python_data_beginner_006.png';
import python_data_beginner_007 from '../assets/problem/python_data_beginner_007.png';
import python_data_beginner_008 from '../assets/problem/python_data_beginner_008.png';
import python_data_beginner_009 from '../assets/problem/python_data_beginner_009.png';
import python_functions_001 from '../assets/problem/python_functions_001.png';
import python_functions_002 from '../assets/problem/python_functions_002.png';
import python_functions_003 from '../assets/problem/python_functions_003.png';
import python_functions_004 from '../assets/problem/python_functions_004.png';
import python_functions_005 from '../assets/problem/python_functions_005.png';
import python_functions_006 from '../assets/problem/python_functions_006.png';
import python_functions_007 from '../assets/problem/python_functions_007.png';

export const pythonLanguageBeginner = [

//id: "data-types-variables"
//title: "데이터 종류와 가장 기본적인 저장방법"
//topics: ["Programming Fundamentals", "Data Types", "Integers",
//         "Floats", "Strings", "Booleans", "Variables"]
////////////////////////////////

  // data-types-variables_001 // Programming Fundamentals // multiple_choice - 데이터
  {
    problemId: "data-types-variables_001",
    image: data_basics_001,
    title: "데이터",
    description: "데이터는 모든 프로그램의 핵심이라고 할 수 있습니다. 간단한 계산기 프로그램도 숫자 데이터를 처리하죠.",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Programming Fundamentals",
    subTopic: "Data",
    difficulty: 1,
    difficultyDescription: "데이터 첫 시작 질문",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner"
    ],
    programmingConcepts: ["데이터 타입", "변수"],
    skillsPracticed: ["기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "데이터의 개념을 이해한다."
    ],
    commonMistakes: [
      "데이터와 물리적 객체의 혼동"
    ],
    hints: {
      beginner: [
        "데이터는 정보를 컴퓨터가 이해할 수 있는 형태로 표현한 것입니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "변수"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 데이터는 다양한 형태로 표현됩니다 (예: 숫자, 문자열 등)."]
      }
    },
    choices: [
      { id: "A", text: "컴퓨터가 이해할 수 있도록 표현된 정보나 사실" },
      { id: "B", text: "컴퓨터의 하드웨어 부품 중 하나" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. 데이터는 컴퓨터가 이해하고 처리할 수 있는 정보나 사실을 의미합니다. 하드웨어 부품은 데이터가 아니라 물리적인 구성 요소입니다.",
    examples: [
      {
        input: "age = 25",
        output: "25"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-09",
    lastUpdated: "2024-10-09",
    versionNumber: 1.0
  },

  // data-types-variables_002 // Programming Fundamentals // concept - 데이터의 중요성
  {
    problemId: "data-types-variables_002",
    type: "concept",
    title: "데이터의 중요성",
    image: null,
    description: `
      프로그래밍에서 데이터는 프로그램이 처리하는 모든 정보를 의미합니다.
      
      주요 데이터 타입은 다음과 같습니다.
        - 정수(Integer): 소수점이 없는 숫자 (예: 1, -5, 100)
        - 실수(Float): 소수점이 있는 숫자 (예: 3.14, -0.01)
        - 문자열(String): 텍스트 데이터 (예: "Hello, World!")
        - 불리언(Boolean): 참(True) 또는 거짓(False)
    `,
    subject: "Computer Science",
    topic: "Programming Fundamentals",
    subTopic: "Data Basics",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Data", "Fundamentals"],
    relatedConcepts: ["Variables", "Data Types", "Data Structures", "Algorithms"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-15",
    lastUpdated: "2024-10-15",
    versionNumber: 1.0
  },

  // data-types-variables_003 // Data Types // multiple_choice - 정수(Integer) 데이터 타입
  {
    problemId: "data-types-variables_003",
    image: null,
    title: "정수(Integer) 데이터 타입",
    description: "다음 중 Python에서 정수(integer) 데이터 타입을 올바르게 나타내는 것은 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "Integer",
    difficulty: 2,
    difficultyDescription: "기본 데이터 타입을 이해하는 초급 수준의 질문입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분류"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Integer",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입", "변수", "정수"],
    skillsPracticed: ["데이터 타입 식별", "기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python의 기본 데이터 타입 중 정수형 이해",
      "다양한 데이터 타입의 표현 방식 구분"
    ],
    commonMistakes: [
      "부동 소수점 숫자와 정수의 혼동",
      "문자열으로 표현된 숫자와 실제 정수의 혼동",
      "불리언 값과 정수의 혼동"
    ],
    hints: {
      beginner: [
        "정수는 소수점이 없는 숫자입니다.",
        "Python에서 정수는 별도의 표시 없이 그냥 숫자로 표현됩니다."
      ],
      intermediate: [
        "다른 데이터 타입과의 차이점을 생각해보세요.",
        "각 보기의 값이 어떤 데이터 타입을 나타내는지 고려해보세요."
      ],
      advanced: [
        "Python에서 type() 함수를 사용하면 데이터의 타입을 확인할 수 있습니다.",
        "정수형 외의 다른 숫자 타입에 대해서도 생각해보세요."
      ]
    },
    relatedConcepts: ["부동 소수점", "문자열", "불리언", "타입 변환"],
    learningStyle: ["시각적 학습", "논리적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 정수는 int 타입으로 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: "42" },
      { id: "B", text: "42.0" },
      { id: "C", text: "'42'" },
      { id: "D", text: "True" },
      { id: "E", text: "int(42)" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. Python에서 정수는 소수점 없이 그냥 숫자로 표현됩니다. '42'는 순수한 정수형 데이터입니다. B는 부동 소수점 숫자, C는 문자열, D는 불리언 값, E는 정수를 반환하는 함수 호출입니다.",
    examples: [
      {
        input: "type(42)",
        output: "<class 'int'>"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: "python_intro_001"
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-07",
    lastUpdated: "2024-10-07",
    versionNumber: 1.0
  },

  // data-types-variables_004 // Data Types // code - 변수 선언 및 초기화
  {
    problemId: "data-types-variables_004",
    image: data_basics_002,
    title: "데이터를 저장하는 방법",
    description: `데이터를 저장하기 위해서는 변수라고 불리는 저장공간이 필요합니다.
    프로그래밍에서 변수는 1개의 데이터를 저장하는 공간을 의미합니다.
    
    선언과 동시에 데이터를 저장하는 것을 의미합니다.

    'a'라는 변수를 선언하고, 42라는 정수로 초기화하는 방법은 다음과 같습니다.
    프로그래밍에서 초기화는 0을 만든는다는 의미가 아닙니다.

    [Code]
    a = 42

    아래에서 그대로 따라서 작성후 🚩 Run 버튼을 눌러보세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Variables",
    subTopic: "Initialization",
    difficulty: 1,
    difficultyDescription: "변수를 사용하여 데이터를 관리하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수의 개념",
      "데이터 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Variables",
      "Data Management"
    ],
    programmingConcepts: ["변수", "데이터 저장", "출력"],
    skillsPracticed: ["변수 초기화", "값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "변수의 개념 이해하기",
      "변수를 사용하여 데이터를 효율적으로 관리하는 방법 이해하기"
    ],
    commonMistakes: [
      "변수를 문자열로 초기화하는 실수",
      "변수명을 잘못 사용하는 경우"
    ],
    hints: {
      beginner: [
        "변수는 값을 저장할 수 있는 이름입니다. 변수에 값을 저장하려면 '=' 연산자를 사용하세요.",
        "print() 함수를 사용하여 변수의 값을 출력할 수 있습니다."
      ],
      intermediate: [
        "변수명은 의미 있는 이름을 사용하는 것이 좋습니다."
      ],
      advanced: [
        "다른 데이터 타입과 변수를 결합하여 더 복잡한 데이터를 관리할 수 있습니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 변수는 타입을 명시하지 않고 선언할 수 있습니다."]
      }
    },
    initialCode: `# 아래에 변수 a를 선언하고, 42라는 정수로 초기화하는 코드를 작성해보세요.


# 변수 a의 값을 출력하는 코드입니다. 정수 42가 출력되어야 합니다.
print(a)`,
    testCases: [
      {
        input: "",
        expected: "42"
      }
    ],
    solution: `def initialize_variable():
      a = 42
      print(a)
  
  initialize_variable()`,
    examples: [
      {
        input: ``,
        output: "42"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.15
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // data-types-variables_005 // Data Types // multiple_choice - 문자열(String) 데이터 타입
  {
    problemId: "data-types-variables_005",
    image: null,
    title: "문자열(String) 데이터 타입",
    description: "변수 word에 문자열(String) 데이터 타입을 올바르게 선언하고 초기화하는 코드는 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "String",
    difficulty: 1,
    difficultyDescription: "문자열 데이터 타입의 기본적인 사용법을 묻는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입", "변수", "문자열"],
    skillsPracticed: ["문자열 선언", "기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 문자열 데이터 타입 이해",
      "문자열을 변수에 할당하는 방법 학습"
    ],
    commonMistakes: [
      "따옴표 없이 문자열을 선언함",
      "문자열을 감싸는 따옴표의 혼동",
      "문자열 내에 이스케이프 문자를 잘못 사용함"
    ],
    hints: {
      beginner: [
        "문자열은 따옴표(' 또는 \")로 감싸서 선언합니다.",
        "작은 따옴표, 큰 따옴표 중 하나를 사용하면 됩니다."
      ],
    },
    relatedConcepts: ["변수", "따옴표", "이스케이프 문자"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 따옴표로 감싸서 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: `word = "123` },
      { id: "B", text: `word = Hello` },
      { id: "C", text: `word = 'Hello'` },
      { id: "D", text: `word = Hello'` },
      { id: "E", text: `word = 123` }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. Python에서 문자열은 따옴표(' 또는 \")로 감싸서 선언해야 합니다.",
    examples: [
      {
        input: `word = 'Hello'`,
        output: `Hello`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-09",
    lastUpdated: "2024-10-09",
    versionNumber: 1.0
  },

  // data-types-variables_006 // memory_management // multiple_choice - 대소문자 구분
  {
    problemId: "data-types-variables_006",
    type: "multiple_choice",
    title: "대소문자 구분",
    image: basic_data_002,
    description: `
      파이썬에서는 대소문자를 구분합니다.
      이진법으로 변환해보면 확실하게 알 수 있습니다.
      지금 당장 이진법으로 변환하는 방법까진 몰라도 괜찮습니다.

      다음 중 문자 'a'와 'A'의 차이에 대한 설명으로 올바른 것은 무엇인가요?
      `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Strings",
    subTopic: "String",
    difficulty: 1,
    difficultyDescription: "문자의 대소문자 구분에 대해 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분류"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Characters",
      "Case Sensitivity"
    ],
    programmingConcepts: ["문자열", "대소문자", "데이터 타입"],
    skillsPracticed: ["문자 비교", "문자열 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "파이썬에서 대소문자가 구분되는 이유 이해",
      "문자열 내 대소문자 구분 방법 학습"
    ],
    commonMistakes: [
      "대소문자를 구분하지 않고 문자를 비교",
      "문자열을 비교할 때 대소문자를 혼동"
    ],
    hints: {
      beginner: [
        "파이썬은 대소문자를 구분하므로 'a'와 'A'는 다른 문자로 인식됩니다.",
        "문자열 비교 시 대소문자가 일치해야 동일한 것으로 취급됩니다."
      ]
    },
    relatedConcepts: ["문자열", "문자 비교", "데이터 타입"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["파이썬에서는 대소문자를 구분합니다."]
      }
    },
    choices: [
      { id: "A", text: "'a'와 'A'는 동일한 문자이다." },
      { id: "B", text: "이진법으로 변환해보면 서로 다른 문자임을 알 수 있다." },
      { id: "C", text: "파이썬에서는 대소문자를 구분하지 않는다." }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 파이썬에서는 'a'와 'A'를 소문자와 대문자로 구분하여 다른 문자로 인식합니다.",
    examples: [
      {
        input: `
  a = 'a'
  A = 'A'
  print(a == A)
        `,
        output: "False"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // data-types-variables_007 // data-types // code - 대소문자 구분 확인하기
  {
    problemId: "data-types-variables_007",
    title: "대소문자 구분 확인하기",
    description: `파이썬에서 대문자와 소문자는 서로 다른 문자로 인식됩니다. 아래의 코드에서 변수 a와 A를 비교하여 그 차이를 확인하고, 결과가 True로 출력되는지 확인해보세요.

  **참고:** a와 A는 대소문자가 다르므로 비교 결과가 True가 되어야 합니다.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Strings",
    subTopic: "String Comparison",
    difficulty: 1,
    difficultyDescription: "대소문자 구분을 이해하고 비교하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "조건문"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Comparison",
      "Variables"
    ],
    programmingConcepts: ["문자열", "대소문자", "비교 연산"],
    skillsPracticed: ["문자 비교", "조건문 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "파이썬에서 대소문자가 어떻게 구분되는지 이해하기",
      "문자열을 비교하는 방법 학습하기",
      "비교 연산자를 사용하여 조건문 작성하기"
    ],
    commonMistakes: [
      "대소문자를 구분하지 않고 비교",
      "비교 연산자를 잘못 사용"
    ],
    hints: {
      beginner: [
        "파이썬에서는 대문자와 소문자가 다른 문자로 취급됩니다.",
        "`==` 연산자를 사용하여 두 문자를 비교하세요.",
        "비교 결과를 출력하기 위해 `print()` 함수를 사용하세요."
      ]
    },
    relatedConcepts: ["문자열", "비교 연산자", "조건문"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 대소문자는 구분됩니다."]
      }
    },
    initialCode: `# a와 A가 실제로 다른 문자인지 비교합니다.
# != 는 '같지 않다'는 뜻을 가진 비교 연산자입니다.
print('a' != 'A')
  `,
    testCases: [
      { input: "", expected: "True" }
    ],
    solution: `a = 'a'
  A = 'A'

  # a와 A가 다른지 비교하고 결과를 출력합니다.
  print(a != A)
  `,
    examples: [
      {
        input: "",
        output: "True"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // data-types-variables_008 // Data Types // multiple_choice - 불리언(Boolean) 데이터 타입
  {
    problemId: "data-types-variables_008",
    image: null,
    title: "불리언(Boolean) 데이터 타입",
    description: "불리언(Boolean) 데이터 타입을 올바르게 선언한 코드는 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "Boolean",
    difficulty: 1,
    difficultyDescription: "불리언 데이터 타입의 기본적인 사용법을 묻는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Boolean",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입", "변수", "불리언"],
    skillsPracticed: ["불리언 선언", "기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 불리언 데이터 타입 이해",
      "불리언 값을 변수에 할당하는 방법 학습"
    ],
    commonMistakes: [
      "소문자로 불리언을 선언함",
      "숫자를 불리언으로 오인함",
      "불리언 값에 따옴표 사용"
    ],
    hints: {
      beginner: [
        "불리언 값은 대문자로 시작합니다.",
        "True와 False 두 가지 값만 존재합니다."
      ]
    },
    relatedConcepts: ["조건문", "논리 연산자"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 불리언은 True와 False로 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: `is_active = true` },
      { id: "B", text: `is_active = True` },
      { id: "C", text: `is_active = "True"` },
      { id: "D", text: `is_active = 1` },
      { id: "E", text: `is_active = FALSE` }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. Python에서 불리언 값은 첫 글자가 대문자인 True와 False로 표현됩니다. A는 소문자, C는 문자열, D는 정수, E는 대문자 FALSE는 잘못된 표현입니다.",
    examples: [
      {
        input: `is_active = True`,
        output: `True`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-08",
    lastUpdated: "2024-10-08",
    versionNumber: 1.0
  },

  // data-types-variables_009 // Programming Fundamentals // concept - 낙타 표기법
  {
    problemId: "data-types-variables_009",
    image: data_basics_003,
    type: "concept",
    title: "낙타 표기법",
    description: `
      낙타 표기법(Camel Case)은 프로그래밍에서 변수, 함수 등 이름을 지을 때 사용하는 방법 중 하나예요. 여러 단어를 하나로 연결할 때, 첫 단어는 소문자로 시작하고, 다음 단어의 첫 글자를 대문자로 써서 읽기 쉽게 만드는 방식이에요. 예를 들어, myVariableName, calculateTotal 같은 이름들이 낙타 표기법이에요.
      
      1. 낙타 표기법이란?
         - 낙타 표기법(Camel Case): 첫 단어는 소문자로 시작하고, 이어지는 단어의 첫 글자를 대문자로 써요. 예를 들어, myName, totalAmount 등이 있어요.
      
      2. 왜 낙타 표기법을 사용할까요?
         - 읽기 쉬움: 이름이 길어질 때, 단어가 어디서 시작하고 끝나는지 쉽게 알 수 있어요.
         - 일관성: 팀에서 모두 같은 방식으로 이름을 짓기 때문에, 코드를 읽고 이해하기 쉬워져요.
         - 표준 준수: 많은 프로그래밍 언어와 도구들이 낙타 표기법을 권장하고 있어요.
      
      3. 낙타 표기법과 스네이크 표기법 비교하기:
         - 낙타 표기법(Camel Case): myVariableName
         - 스네이크 표기법(Snake Case): my_variable_name`,
    subject: "Computer Science",
    topic: "Programming Fundamentals",
    subTopic: "Naming Conventions",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Naming Conventions", "Camel Case", "Python"],
    examples: [
      {
        description: "낙타 표기법을 사용한 클래스와 함수 이름 (파이썬 예시)",
        code: `
  # 클래스 이름은 낙타 표기법을 사용해요.
  class DataProcessor:
      def __init__(self, data):
          self.data = data
      
      def processData(self):
          # 데이터를 처리하는 코드
          print('데이터를 처리하고 있어요.')
  
  # 함수 이름은 보통 스네이크 표기법을 사용해요.
  def calculate_total(amount, tax):
      total = amount + (amount * tax)
      return total
  
  # 클래스 사용 예시
  processor = DataProcessor([1, 2, 3])
  processor.processData()
  
  # 함수 사용 예시
  total = calculate_total(100, 0.05)
  print(f'총액: {total}')
      `
      }
    ],
    relatedConcepts: ["변수", "데이터 타입", "스네이크 표기법", "코드 읽기"],
    author: "SlopeXcelerity",
    dateCreated: "2024-04-27",
    lastUpdated: "2024-04-27",
    versionNumber: 1.0
  },

  // data-types-variables_010 // Data Types // code - 기본 데이터 타입 변수 선언 및 출력
  {
    problemId: "data-types-variables_010",
    image: null,
    title: "기본 데이터 타입 변수 선언 및 출력",
    description: `a, b, c, d 변수를 선언하여 각각 다른 데이터 타입으로 초기화하고 출력하세요.
    
    - 변수 a를 정수형으로 선언하고 정수 42로 초기화하세요.
    - 변수 b를 실수형으로 선언하고 실수 3.14로 초기화하세요.
    - 변수 c를 문자열형으로 선언하고 문자열 "Hello"로 초기화하세요.
    - 변수 d를 불리언형으로 선언하고 불리언 True로 초기화하세요.
    
    각각의 변수를 출력하세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Variables",
    subTopic: "Data Types",
    difficulty: 1,
    difficultyDescription: "기본 데이터 타입을 사용하여 변수를 선언하고 출력하는 방법을 배우는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수의 개념",
      "데이터 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Variables",
      "Data Types"
    ],
    programmingConcepts: ["변수", "데이터 타입", "출력"],
    skillsPracticed: ["변수 선언", "값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "기본 데이터 타입의 개념 이해하기",
      "다양한 데이터 타입을 사용하여 변수를 선언하고 출력하는 방법 배우기"
    ],
    commonMistakes: [
      "데이터 타입을 잘못 초기화하는 경우",
      "변수명을 일관성 없이 사용하는 경우"
    ],
    hints: {
      beginner: [
        "변수는 값을 저장할 수 있는 이름입니다. 변수에 값을 저장하려면 '=' 연산자를 사용하세요.",
        "print() 함수를 사용하여 변수의 값을 출력할 수 있습니다."
      ],
      intermediate: [
        "다양한 데이터 타입을 사용하여 변수를 선언해 보세요."
      ],
      advanced: [
        "복합적인 데이터 타입과 변수를 결합하여 더 복잡한 데이터를 관리할 수 있습니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 변수는 타입을 명시하지 않고 선언할 수 있습니다."]
      }
    },
    initialCode: "# 변수 a, b, c, d를 선언하고 각각의 데이터 타입으로 초기화해보세요.\n\n\n# 변수 a, b, c, d의 값을 출력하는 코드를 작성하세요.\nprint(a, b, c, d)",
    testCases: [
      {
        input: "",
        expected: `42 3.14 Hello True`
      }
    ],
    solution: "def initialize_variables():\n    a = 42\n    b = 3.14\n    c = \"Hello\"\n    d = True\n    print(a)\n    print(b)\n    print(c)\n    print(d)\n\ninitialize_variables()",
    examples: [
      {
        input: "",
        output: `42 3.14 Hello True`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.15
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },

  // data-types-variables_011 // Data Types // multiple_choice - 데이터 형 정확하게 구분하기
  {
    problemId: "data-types-variables_011",
    image: null,
    title: "데이터 형 정확하게 구분하기",
    description: `a = '42'라고 작성된 코드에서 변수 a에 저장된 값의 데이터 형은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "String",
    difficulty: 1,
    difficultyDescription: "기본적인 데이터 타입의 이해를 묻는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입"],
    skillsPracticed: ["데이터 타입 구분"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 문자열 데이터 타입 이해",
      "문자열과 다른 데이터 타입의 차이점 학습"
    ],
    commonMistakes: [
      "문자열과 숫자를 혼동함"
    ],
    hints: {
      beginner: [
        "문자열은 따옴표로 감싸져 있습니다.",
        "정수는 따옴표 없이 숫자만 사용합니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 따옴표로 감싸서 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: "정수 (int)" },
      { id: "B", text: "실수 (float)" },
      { id: "C", text: "문자열 (str)" },
      { id: "D", text: "불리언 (bool)" }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. Python에서 문자열은 따옴표로 감싸져 있는 데이터를 의미합니다. \"42\"는 숫자처럼 보이지만, 따옴표로 감싸져 있으므로 문자열입니다.",
    examples: [
      {
        input: `value = "42"`,
        output: `"42"`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },

  // data-types-variables_012 // Data Types // multiple_choice - 변수 a의 데이터형 변화
  {
    problemId: "data-types-variables_012",
    image: data_basics_010,
    title: "변수 a의 데이터형 변화",
    description: "위의 코드를 실행하면, 두 번째와 네 번째로 출력되는 변수 a의 데이터형은 각각 무엇일까요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "Type Casting",
    difficulty: 2,
    difficultyDescription: "변수의 데이터형 변화를 이해하는 초급에서 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수의 개념",
      "데이터 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Variables",
      "Type Casting"
    ],
    programmingConcepts: ["데이터 타입 변경", "변수", "출력"],
    skillsPracticed: ["데이터 타입 구분", "변수의 값 변경"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 변수의 데이터형 변화를 이해한다.",
      "정수와 문자열 데이터형의 차이점을 학습한다."
    ],
    commonMistakes: [
      "데이터형 변경을 이해하지 못함",
      "정수와 문자열을 구분하지 못함"
    ],
    hints: {
      beginner: [
        "Python에서 변수는 언제든지 다른 데이터형의 값을 가질 수 있습니다.",
        "문자열은 따옴표로 감싸져 있습니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입", "출력"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 변수는 타입을 명시하지 않고 선언할 수 있으며, 데이터형이 자유롭게 변경될 수 있습니다."]
      }
    },
    choices: [
      { id: "A", text: "정수 (int), 정수 (int)" },
      { id: "B", text: "정수 (int), 문자열 (str)" },
      { id: "C", text: "문자열 (str), 문자열 (str)" },
      { id: "D", text: "문자열 (str), 정수 (int)" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 첫 번째로 출력되는 a는 정수형(int)입니다. 이후에 a는 문자열 '42'로 재할당되어 문자열(str) 데이터형이 됩니다.",
    examples: [
      {
        input: "a = 42\nprint(a)\na = '42'\nprint(a)",
        output: "42\n'42'"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },

  //id: "built-in-functions",
  //title: "기본 기능 사용해보기",
  //topics: ["print", "input", "type"]
  ////////////////////////////////

  // python-programming-fundamentals_001 // functions // concept - 파이썬의 기본 기능들
  {
    problemId: "python-programming-fundamentals_001",
    type: "concept",
    title: "파이썬의 기본 기능들",
    image: null,
    description: `
      파이썬을 설치하면 다양한 기능들을 바로 사용할 수 있습니다.
      아주 기본적으로 데이터를 출력하거나, 입력받거나, 데이터를 조작하는 데 사용됩니다.

      프로그래밍에서 특정 기능을 가진 코드를 함수(function)라고 합니다.

      함수의 종류는 엄청나게 많습니다.
      취업을 목표로 하는게 아니라면 외우는걸 추천드리진 않습니다.
    `,
    subject: "Computer Science",
    topic: "functions",
    subTopic: "Basic Features",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Basic Features", "Fundamentals"],
    relatedConcepts: ["Variables", "Data Types", "Functions", "Modules"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_002 // print // multiple_choice - print() 함수
  {
    problemId: "python-programming-fundamentals_002",
    title: "print() 함수",
    description: `데이터를 화면에 출력하는 함수는 print()입니다.
  문자열을 출력하려면 괄호 안에 따옴표로 감싸서 입력합니다.
  이때 작은 따옴표, 큰 따옴표 모두 사용할 수 있습니다.
  
  다음 중 'Hello, World!'를 올바르게 출력하는 코드는 무엇일까요?
  `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "print",
    subTopic: "Print Function",
    difficulty: 1,
    difficultyDescription: "기본 문법을 소개하는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "컴퓨터에 대한 기본 이해",
      "프로그래밍 개념의 기본 이해"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Print Function",
      "Syntax",
      "First Program",
      "Hello World",
      "Output"
    ],
    programmingConcepts: ["출력", "함수", "문자열", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "문자열 리터럴"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 콘솔에 텍스트를 출력하는 방법 학습",
      "Python print 문장의 기본 문법 이해",
      "간단한 Python 프로그램의 구조 인식"
    ],
    commonMistakes: [
      "print 함수에서 괄호를 생략함",
      "잘못된 따옴표 사용",
      "print 키워드를 잘못 철자함",
      "콜론이나 들여쓰기를 잘못 사용함",
      "print 함수에서 대문자 사용"
    ],
    hints: {
      beginner: [
        "프로그래밍에서 대소문자 구분은 아주 중요합니다.",
        "Python에서는 `print()` 함수를 사용하여 화면에 텍스트를 출력합니다.",
        "출력할 텍스트는 괄호 안에 따옴표로 감싸서 입력합니다.",
        "따옴표는 작은따옴표(')나 큰따옴표(\") 모두 사용할 수 있습니다."
      ],
      intermediate: [
        "`print()` 함수는 여러 값을 콤마로 구분하여 출력할 수 있습니다.",
        "`print()` 함수의 다양한 옵션을 활용하여 출력 형식을 조정해보세요.",
        ".format() 함수나 f-strings를 사용하여 다양한 출력 형식을 지정할 수 있습니다."
      ]
    },
    relatedConcepts: ["함수", "문자열", "문법", "표준 출력", "주석"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서는 `print`가 함수이므로 괄호를 사용합니다."]
      }
    },
    choices: [
      { id: "A", text: "print 'Hello, World!'" },
      { id: "B", text: "print(Hello, World!)" },
      { id: "C", text: "PRINT('Hello, World!')" },
      { id: "D", text: "print('Hello, World!')" },
      { id: "E", text: "console.log('Hello, World!')" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. Python 3에서 'Hello, World!'를 출력하려면 `print 'Hello, World!'`와 같이 작성합니다. 이 방법은 Python 2에서 유효하지만, Python 3에서는 `print()` 함수를 사용해야 합니다. 따라서 실제로는 A번이 올바른 정답입니다.",
    examples: [
      {
        input: "print('Hello, World!')",
        output: "Hello, World!"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-06",
    lastUpdated: "2024-10-06",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_003 // print // code - Hello World!!! 출력하기
  {
    problemId: "python-programming-fundamentals_003",
    title: "Hello, World!!! 출력하기",
    description: `파이썬을 사용하여 'Hello, World!!!'를 출력하는 코드를 작성하세요.
    
                  [출력 예시]
                  Hello, World!!!`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "print",
    subTopic: "Print Function",
    difficulty: 1,
    difficultyDescription: "기본적인 출력 기능을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 출력 방법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Print Function",
      "Syntax",
      "First Program",
      "Hello World",
      "Output"
    ],
    programmingConcepts: ["출력", "함수", "문자열", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "문자열 리터럴"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 콘솔에 텍스트를 출력하는 방법 학습",
      "Python print 문장의 기본 문법 이해",
      "간단한 Python 프로그램의 구조 인식"
    ],
    commonMistakes: [
      "print 함수에서 괄호를 생략함",
      "잘못된 따옴표 사용",
      "print 키워드를 잘못 철자함",
      "print 함수에서 대문자 사용"
    ],
    hints: {
      beginner: [
        "화면에 출력하는 함수는 print()입니다.",
        "프로그래밍에서 대소문자 구분은 아주 중요합니다.",
        "모든 문자, 심지어 공백까지 출력 예시와 똑같아야 합니다.",
        "출력할 텍스트는 괄호 안에 따옴표로 감싸서 입력합니다.",
      ]
    },
    relatedConcepts: ["함수", "문자열", "문법", "표준 출력", "주석"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
      }
    },
    initialCode: `# 'Hello, World!!!'를 출력하는 코드를 작성하세요.
`,
    testCases: [
      { input: "", expected: "Hello, World!!!" }
    ],
    solution: `print("Hello, World!!!")`,
    examples: [
      {
        input: "",
        output: "Hello, World!!!"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_004 // input // multiple_choice - input() 함수
  {
    problemId: "python-programming-fundamentals_004",
    image: programming_fundamentals_003,
    title: "input() 함수와 데이터 타입",
    description: `위의 코드는 변수 a에 값을 입력받는 코드입니다.
  입력된 값은 문자열 데이터 타입으로 저장됩니다.

  다음 중 변수 a에 저장된 값의 데이터형은 무엇일까요?
  `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Input Function",
    difficulty: 1,
    difficultyDescription: "기본적인 입력 함수와 데이터 타입을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 방법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Syntax",
      "First Program",
      "Input",
      "Data Type"
    ],
    programmingConcepts: ["입력", "함수", "데이터 타입", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 입력을 받는 방법 학습",
      "input() 함수의 반환값 데이터 타입 이해"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 정수형으로 오해",
      "input() 함수에서 괄호를 생략함",
      "input 키워드를 잘못 철자함"
    ],
    hints: {
      beginner: [
        "input() 함수는 사용자의 입력을 문자열로 반환합니다.",
        "입력받은 값을 정수형으로 사용하려면 변환이 필요합니다."
      ]
    },
    relatedConcepts: ["함수", "문자열", "데이터 타입", "입력", "변환"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서 input() 함수는 문자열을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "정수형(int)" },
      { id: "B", text: "문자열(str)" },
      { id: "C", text: "실수형(float)" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. Python에서 input() 함수는 항상 문자열(string) 형태로 입력값을 반환합니다. 만약 다른 데이터 타입으로 사용하려면 int(), float() 등의 함수를 사용하여 변환해야 합니다.",
    examples: [
      {
        input: "사용자 입력 없음",
        output: "str"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.3
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_005 // input // code - 숫자 입력 받아 출력하기
  {
    problemId: "python-programming-fundamentals_005",
    title: "숫자 입력 받아 출력하기",
    description: `사용자가 텍스트를 입력하여 변수 n에 저장하고, 그 값을 출력하는 코드를 작성해보세요.
    
    [입력 예시]
    7
    
    [출력 예시]
    7
    
    **여기서는 직접 값을 입력하지 않아도 됩니다.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Input Function",
    difficulty: 1,
    difficultyDescription: "기본적인 입력 기능을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 및 출력 방법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Syntax",
      "User Input",
      "Number",
      "Output"
    ],
    programmingConcepts: ["입력", "출력", "함수", "숫자", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "숫자 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 숫자를 입력받는 방법 학습",
      "Python input 함수의 기본 문법 이해",
      "입력받은 숫자를 출력하는 방법 익히기"
    ],
    commonMistakes: [
      "input 함수에서 괄호를 생략함",
      "잘못된 따옴표 사용",
      "input 함수의 반환 값을 정수로 변환하지 않음 (문제에서는 문자열로 출력하므로)",
      "출력 형식에서 변수 사용 실수"
    ],
    hints: {
      beginner: [
        "사용자의 입력을 받기 위해서는 input() 함수를 사용합니다.",
        "입력받은 숫자를 변수에 저장한 후 출력에 사용하세요.",
        "입력받은 데이터는 기본적으로 문자열입니다."
      ]
    },
    relatedConcepts: ["함수", "숫자", "문법", "표준 입력", "주석"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의하지 않고 내장 함수로 사용합니다."]
      }
    },
    initialCode: `#변수 n을 선언하고, 텍스트를 입력받는 코드를 작성하세요.
n = 

# 입력받은 값을 출력합니다.
print()
`,
    testCases: [
      { input: "7", expected: "7" }
    ],
    solution: `n = input()
    print(n)`,
    examples: [
      {
        input: "7",
        output: "7"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_006 // functions // concept - 파이썬 내장 함수들
  {
    problemId: "python-programming-fundamentals_006",
    type: "concept",
    title: "파이썬 내장 함수들",
    image: programming_fundamentals_006,
    description: `
      print(), input() 처럼 파이썬을 설치만해도 사용할 수 있는 기능을
      내장 함수(function)라고 부릅니다.

      기본적으로 사용할 수 있는 기능도 많지만,
      추가적으로 사용할 수 있는 기능은 훨씬 더 많습니다.

      프로그래밍에서 단순히 기능을 외우는건 중요한게 아닙니다.
      어떤 프로그램을 만들지 그 목표를 분명하게 정하여,
      논리적이고 순서대로 과정을 만들어야합니다.

      끈기를 가지고 끝까지 문제를 해결해나가는것이 중요합니다.

      그러니 외우지말고, 필요할 때 생성형 AI와 구글링으로 정보를 찾아보는게 좋습니다.
      "파이썬 내장함수"라고 검색하여 공식 문서(https://docs.python.org/3/library/functions.html)를 확인하는 것이 정석입니다.
      
      **이미지는 실제 공식 문서의 3.13.0 버전의 내장함수 캡처화면입니다.
    `,
    subject: "Computer Science",
    topic: "functions",
    subTopic: "Built-in Functions",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Built-in Functions", "Fundamentals"],
    relatedConcepts: ["Variables", "Data Types", "Functions", "Modules"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_007 // input // code - 여러 데이터의 타입 확인하기
  {
    problemId: "python-programming-fundamentals_007",
    title: "여러 데이터의 타입 확인하기",
    description: `데이터의 타입을 확인하기 위해서 type() 함수를 사용할 수 있습니다.
    
    아래의 데이터를 저장하고 type() 함수를 사용하여 출력한 결과를 확인해보세요.

    - 문자열 type() 출력 ➡️ <class 'str'>
    - 정수 type() 출력  ➡️ <class 'int'>
    - 실수 type() 출력 ➡️ <class 'float'>
    - 불리언 type() 출력 ➡️ <class 'bool'>
    
    전체 코드를 꼼꼼하게 읽어보고, 🚩 Run 버튼을 클릭하여 결과를 확인해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Type Function",
    difficulty: 1,
    difficultyDescription: "여러 데이터 타입을 확인하고 type() 함수를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 및 출력 방법",
      "데이터 타입 이해"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Function",
      "Syntax",
      "User Input",
      "Data Type",
      "Output"
    ],
    programmingConcepts: ["입력", "출력", "함수", "데이터 타입", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 확인"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 다양한 데이터 타입을 입력받는 방법 학습",
      "type() 함수의 사용법 이해",
      "입력받은 데이터의 타입을 확인하는 방법 익히기"
    ],
    commonMistakes: [
      "type() 함수의 사용법을 잘못 이해함",
      "입력된 값을 제대로 출력하지 못함",
      "데이터 타입을 혼동함"
    ],
    hints: {
      beginner: [
        "`input()` 함수는 사용자의 입력을 문자열로 반환합니다.",
        "`type()` 함수를 사용하여 변수의 데이터 타입을 확인할 수 있습니다.",
        "각 데이터 타입에 맞는 값을 변수에 할당하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "정수", "실수", "불린", "데이터 타입", "변수", "출력"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의하지 않고 내장 함수로 사용합니다."]
      }
    },
    initialCode: `# 문자열을 입력받아 변수 s에 저장합니다.
s = "Hello"
# 정수를 입력받아 변수 i에 저장합니다.
i = 10
# 실수를 입력받아 변수 f에 저장합니다.
f = 3.14
# 불리언 값을 입력받아 변수 b에 저장합니다.
b = True

# 각 변수의 데이터 타입을 출력합니다.
print(type(s))
print(type(i))
print(type(f))
print(type(b))`,
    testCases: [
      {
        input: "",
        expected: `<class 'str'>
<class 'int'>
<class 'float'>
<class 'bool'>`
      }
    ],
    solution: `s = "Hello"
  i = 10
  f = 3.14
  b = True

  print(type(s))
  print(type(i))
  print(type(f))
  print(type(b))
  `,
    examples: [
      {
        input: "",
        output: "<class 'str'>\n<class 'int'>\n<class 'float'>\n<class 'bool'>"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_008 // input // multiple_choice - 문자를 정수로 변환하기
  {
    problemId: "python-programming-fundamentals_008",
    image: programming_fundamentals_008,
    title: "문자를 정수로 변환하기",
    description: `input() 함수로 입력받은 값은 모두 문자 데이터로 저장됩니다.
    위 코드처럼 문자와 숫자를 더하면 에러가 발생합니다.
    파이썬에서 문자와 숫자는 더할 수 없습니다.

    그래서 문자를 숫자로 변환해야합니다.
    이때 사용하는 함수가 int()입니다.
    
    세번째 줄에서 정수로 변환하는 코드를 입력해줘야 합니다.
    아래 보기에서 정수로 변환하는 방법으로 올바른 코드를 찾아보세요.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Input Function and Type Conversion",
    difficulty: 1,
    difficultyDescription: "input() 함수와 데이터 타입 변환을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 방법",
      "데이터 타입 변환"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Conversion",
      "Syntax",
      "First Program",
      "Input",
      "Data Type"
    ],
    programmingConcepts: ["입력", "함수", "데이터 타입", "변환", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 이해", "타입 변환"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 입력을 받는 방법 학습",
      "input() 함수의 반환값 데이터 타입 이해",
      "input 받은 값을 int() 함수를 사용하여 정수로 변환하는 방법 학습"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 정수형으로 오해",
      "input() 함수에서 괄호를 생략함",
    ],
    hints: {
      beginner: [
        "input() 함수는 사용자의 입력을 문자열로 반환합니다.",
        "입력받은 값을 정수형으로 변환하기 위해 int() 함수를 사용하세요.",
        "변환된 값을 변수에 다시 저장한 후 출력에 사용하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "데이터 타입", "입력", "변환"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서 input() 함수는 문자열을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "a = float(a)" },
      { id: "B", text: "a = int(a)" },
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. float() 함수는 실수로 변환하는 함수입니다.",
    examples: [
      {
        input: "7",
        output: "7"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.3
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_009 // int // code - int() 함수 사용해보기
  {
    problemId: "python-programming-fundamentals_009",
    image: programming_fundamentals_009,
    title: "int() 함수 사용해보기",
    description: `변수 n의 값을 정수로 변환해보세요.
    그냥 실행시켜보면, 위와 같은 오류가 발생합니다.
    오직 문자와 문자만 연결할 수 있다는 typeError입니다.
    
    이전 문제에서 배웠던 코드를 참고해서 직접 코드를 작성해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Type Function",
    difficulty: 1,
    difficultyDescription: "여러 데이터 타입을 확인하고 type() 함수를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 및 출력 방법",
      "데이터 타입 이해"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Function",
      "Syntax",
      "User Input",
      "Data Type",
      "Output"
    ],
    programmingConcepts: ["입력", "출력", "함수", "데이터 타입", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 확인"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 다양한 데이터 타입을 입력받는 방법 학습",
      "type() 함수의 사용법 이해",
      "입력받은 데이터의 타입을 확인하는 방법 익히기"
    ],
    commonMistakes: [
      "type() 함수의 사용법을 잘못 이해함",
      "입력된 값을 제대로 출력하지 못함",
      "데이터 타입을 혼동함"
    ],
    hints: {
      beginner: [
        "`input()` 함수는 사용자의 입력을 문자열로 반환합니다.",
        "`type()` 함수를 사용하여 변수의 데이터 타입을 확인할 수 있습니다.",
        "각 데이터 타입에 맞는 값을 변수에 할당하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "정수", "실수", "불린", "데이터 타입", "변수", "출력"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의하지 않고 내장 함수로 사용합니다."]
      }
    },
    initialCode: `# 변수 n에 사용자가 입력한 값을 저장합니다.
n = input()
# 변수 n에 저장된 문자를 정수로 변환하는 코드를 입력해보세요.

# 정수로 변환된 n과 정수 10을 더한 값을 출력합니다.
print(n+10)`,
    testCases: [
      {
        input: "7",
        expected: `17`
      }
    ],
    solution: `n = input()
    n = int(n)

    print(n+10)
  `,
    examples: [
      {
        input: "",
        output: "<class 'str'>\n<class 'int'>\n<class 'float'>\n<class 'bool'>"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_010 // input // multiple_choice - 이제 시작입니다
  {
    problemId: "python-programming-fundamentals_010",
    image: programming_fundamentals_008,
    title: "이제 시작입니다",
    description: `파이썬에서 아주 기본적으로 사용되는 함수에 대해서 알아봤습니다.
    이정도만 알아도 아주 기본적인 계산기 프로그램을 만들수 있죠.
    
    당장 개발자 취업을 위한게 아니라면,
    지금 당장은 코드를 외우거나 함수를 외우는건 프로그래밍을 공부하는 올바른 방법이 아닙니다.

    올바른 프로그래밍 공부 방법은 무엇일까요?
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "functions",
    subTopic: "Functios",
    difficulty: 1,
    difficultyDescription: "프로그래밍 공부 방법에 대해 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 방법",
      "데이터 타입 변환"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Conversion",
      "Syntax",
      "First Program",
      "Input",
      "Data Type"
    ],
    programmingConcepts: ["입력", "함수", "데이터 타입", "변환", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 이해", "타입 변환"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 입력을 받는 방법 학습",
      "input() 함수의 반환값 데이터 타입 이해",
      "input 받은 값을 int() 함수를 사용하여 정수로 변환하는 방법 학습"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 정수형으로 오해",
      "input() 함수에서 괄호를 생략함",
    ],
    hints: {
      beginner: [
        "input() 함수는 사용자의 입력을 문자열로 반환합니다.",
        "입력받은 값을 정수형으로 변환하기 위해 int() 함수를 사용하세요.",
        "변환된 값을 변수에 다시 저장한 후 출력에 사용하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "데이터 타입", "입력", "변환"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서 input() 함수는 문자열을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "반드시 코드를 전부 다 외워서 사용해야한다." },
      { id: "B", text: "필요한 기능을 정확하게 파악하여 구현하기 위한 방법을 생성형 AI, 구글링 등으로 찾아본다." },
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 프로그래밍 함수는 너무 많기 때문에 전부다 외우는건 비효율적입니다.",
    examples: [
      {
        input: "7",
        output: "7"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.3
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

//id: "data-storage-and-access",
//title: "데이터는 어떻게 컴퓨터에 저장될까?",
//topics: ["memory_management", "Concept of Index", "Slicing"]
////////////////////////////////

  // basic-data-structure_001 // memory_management // concept - 메모리 내 데이터 저장 방법
  {
    problemId: "basic-data-structure_001",
    type: "concept",
    title: "메모리 내 데이터 저장 방법",
    image: basic_data_001,
    description: `
      데이터는 컴퓨터의 메모리에 저장됩니다.
      그리고 메모리는 이미지처럼 시각화 할 수 있습니다.
      1칸은 1bit의 공간을 차지합니다.

      우리는 8bit의 공간에 이진수 8개를 저장합니다.
      예를 들어, 01000001을 저장하면 문자 'A'를 저장하는 것입니다.
      즉, 'A'라는 문자 하나를 데이터에 저장하려면 8bit의 공간이 필요합니다.
      
      8bit는 1byte입니다.
      우리는 1byte를 기준으로 메모리에 어떤 값이 저장되는지 떠올리면서 공부해봅시다.
    `,
    subject: "Computer Science",
    topic: "memory_management",
    subTopic: "Data Storage",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Memory", "Data Storage"],
    relatedConcepts: ["Variables", "Data Types", "Memory Allocation"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // basic-data-structure_002 // data_types // multiple_choice - 'H' 문자 인덱싱 방법
  {
    problemId: "basic-data-structure_002",
    type: "multiple_choice",
    title: "문자는 하나씩 꺼내올 수도 있다",
    image: basic_data_002,
    description: `
      변수 word를 그대로 사용하는 방법말고도,
      변수 word에 저장된 문자를 하나씩 꺼내올 수 있습니다.
      
      마치 101호, 102호... 처럼 한 층에 데이터가 저장되어 있다고 생각하면 됩니다.
      이미지처럼 위치값이 생깁니다. 101호에 들어가있는 값을 꺼내오는 방법입니다.
      이런 방법을 인덱싱(Indexing)이라고 합니다.
      
      프로그래밍 세상에서 인덱싱은 예외없이 무조건 0부터 시작합니다.
      대괄호 안에 word[0]을 입력하면 문자열 "Hello"에서 'H'를 인덱싱할 수 있습니다.
      그렇다면 문자열 "Hello"에서 'e'를 인덱싱하려면 어떻게 해야할까요?
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "String Indexing",
    difficulty: 1,
    difficultyDescription: "문자열 인덱싱을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "인덱싱"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Indexing",
      "Variables"
    ],
    programmingConcepts: ["문자열", "인덱싱", "데이터 타입"],
    skillsPracticed: ["문자 추출", "인덱싱 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열에서 인덱스를 사용하여 특정 문자를 추출하는 방법 이해하기",
      "인덱싱이 0부터 시작함을 학습하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 생각함",
      "잘못된 인덱스 사용"
    ],
    hints: {
      beginner: [
        "파이썬에서 문자열의 인덱스는 0부터 시작합니다.",
        "`s[0]`을 사용하여 첫 번째 문자를 가져올 수 있습니다.",
        "중괄호 안에 정수를 사용하여 인덱싱을 합니다."
      ]
    },
    relatedConcepts: ["문자열", "인덱스", "문자 추출"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    choices: [
      { id: "A", text: "word['0']" },
      { id: "B", text: "word[0]" },
      { id: "C", text: "word[-1]" },
      { id: "D", text: "word[1]" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. 문자열의 첫 번째 문자는 인덱스 0을 사용하여 접근합니다. 따라서 `word[1]`은 'H'를 반환합니다.",
    examples: [
      {
        input: `
  word = "Hello"
  print(word[1])
        `,
        output: "e"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // basic-data-structure_003 // data_types // code - 'o' 문자 인덱싱 확인하기
  {
    problemId: "basic-data-structure_003",
    title: "인덱싱 연습하기",
    description: `문자열 "Hello"에서 마지막 문자 'o'를 인덱싱하여 출력하는 코드를 작성하세요. `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "String Indexing",
    difficulty: 1,
    difficultyDescription: "문자열 인덱싱을 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "인덱싱"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Indexing",
      "Variables"
    ],
    programmingConcepts: ["문자열", "인덱싱", "데이터 타입"],
    skillsPracticed: ["문자 추출", "인덱싱 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열에서 인덱스를 사용하여 특정 문자를 추출하는 방법 이해하기",
      "인덱싱이 0부터 시작함을 학습하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 생각함",
      "잘못된 인덱스 사용",
      "인덱스 번호를 잘못 입력함"
    ],
    hints: {
      beginner: [
        "파이썬에서 문자열의 인덱스는 0부터 시작합니다.",
        "`word[4]`을 사용하여 다섯 번째 문자를 가져올 수 있습니다.",
        "문자열을 출력하기 위해 `print()` 함수를 사용하세요."
      ]
    },
    relatedConcepts: ["문자열", "인덱스", "문자 추출"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    initialCode: `# 문자열 "Hello"를 변수 word에 저장합니다.
word = "Hello"

# print() 함수를 사용하여 'o'를 출력하세요.
`,
    testCases: [
      { input: "", expected: "o" }
    ],
    solution: `word = "Hello"

  # 'o' 문자를 인덱싱하여 출력합니다.
  print(word[4])
  `,
    examples: [
      {
        input: "",
        output: "o"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_004 // data_types // code - 'Hello World!'에서 특정 문자 출력하기
  {
    problemId: "basic-data-structure_004",
    type: "code",
    title: "인덱싱 연습하기",
    description: `문자열 "Hello World!"에서 'H', 'W', '!' 문자를 인덱싱하여 출력하는 코드를 작성하세요.
    
  [출력 예시]
  H W !`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "String Indexing",
    difficulty: 1,
    difficultyDescription: "문자열에서 특정 문자를 인덱싱하여 출력하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "인덱싱"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Indexing",
      "Variables"
    ],
    programmingConcepts: ["문자열", "인덱싱", "데이터 타입"],
    skillsPracticed: ["문자 추출", "인덱싱 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열에서 인덱스를 사용하여 특정 문자를 추출하는 방법 이해하기",
      "인덱싱이 0부터 시작함을 학습하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 생각함",
      "잘못된 인덱스 사용",
      "인덱스 번호를 잘못 입력함"
    ],
    hints: {
      beginner: [
        "파이썬에서 문자열의 인덱스는 0부터 시작합니다.",
        "`word[0]`을 사용하여 첫 번째 문자를 가져올 수 있습니다.",
        "문자열을 출력하기 위해 `print()` 함수를 사용하세요."
      ]
    },
    relatedConcepts: ["문자열", "인덱스", "문자 추출"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    initialCode: `# 문자열 "Hello World!"를 변수 word에 저장합니다.
word = "Hello World!"

# 'H', 'W', '!'를 인덱싱하여 출력하는 코드를 작성하세요.
print(word[], word[], word[])`,
    testCases: [
      {
        input: "",
        expected: `H W !`
      }
    ],
    solution: `word = "Hello World!"

  print(word[0], word[6], word[11])`,
    examples: [
      {
        input: "",
        output: `H W !`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_005 // data_types // multiple_choice - 인덱스 개념 이해하기
  {
    problemId: "basic-data-structure_005",
    type: "multiple_choice",
    title: "인덱스 개념 이해하기",
    description: `인덱스 개념을 정확하게 이해하는 것은 프로그래밍에서 매우 중요합니다. 원하는 위치에 있는 데이터를 가져올 수 있어야 하며, 갈수록 인덱스 번호를 사용할 일이 많아집니다.

  다음 중 인덱스에 대한 설명으로 잘못된 것은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "Index Understanding",
    difficulty: 1,
    difficultyDescription: "인덱스의 기본 개념을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍의 기본 개념",
      "데이터 구조의 기초"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Structures",
      "Indexing",
      "Concepts"
    ],
    programmingConcepts: ["인덱싱", "데이터 구조"],
    skillsPracticed: ["개념 이해", "오개념 식별"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "인덱스의 기본 개념 이해하기",
      "프로그래밍에서 인덱스의 중요성 인식하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 오해함",
      "인덱스의 중요성을 과소평가함"
    ],
    hints: {
      beginner: [
        "대부분의 프로그래밍 언어에서 인덱스는 특정한 시작점을 가집니다.",
        "인덱스는 데이터의 위치를 나타내는 중요한 개념입니다."
      ]
    },
    relatedConcepts: ["배열", "리스트", "문자열 처리"],
    learningStyle: ["개념적 학습", "비판적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    choices: [
      { id: "A", text: "인덱스는 데이터의 위치를 나타낸다." },
      { id: "B", text: "인덱스를 사용하여 특정 위치의 데이터에 접근할 수 있다." },
      { id: "C", text: "위치값은 1부터 시작한다." }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. 대부분의 프로그래밍 언어에서 인덱스는 0부터 시작합니다. 1부터 시작한다는 것은 잘못된 설명입니다. A, B, D는 모두 인덱스에 대한 올바른 설명입니다.",
    examples: [],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_006 // data_types // concept - 파이썬의 슬라이싱 이해하기
  {
    problemId: "basic-data-structure_006",
    type: "concept",
    title: "슬라이싱 이해하기",
    image: basic_data_003,
    description: `
      슬라이싱(Slicing)은 범위를 지정하여 여러개의 요소를 출력하는 방법입니다.
      word[1:4:1]는 "ell"를 출력합니다.
      
      [start:end:step]
      
      - start: 슬라이싱을 시작할 인덱스 (default: 0)
      - end: 슬라이싱을 끝낼 인덱스 (default: 끝값)
      - step: 슬라이싱 간격 (default: 1)

      다양한 방법으로 슬라이싱 할 수 있습니다. 예를 들어,
      word[0:5:1]은 "hello"를 출력합니다.
      word[0:5:2]은 "hlo"를 출력합니다.
      word[0:5:-1]은 "olleh"를 출력합니다.

      그리고 줄여서 사용할 수도 있습니다. 예를 들어,
      시작값이 0이라면 word[:3:1]라고 사용해도 됩니다.
      증가값이 1이라면 word[2:4:]라고 사용해도 됩니다.
      처음부터 끝까지 출력하고 싶다면 word[:]라고 사용해도 됩니다.
      dafault는 생략 가능합니다.
      
      반복문에서 배우게 될 range()라는 함수도 슬라이싱 방법과 유사하게 사용됩니다.

      어디부터, 어디까지, 얼마나 증가하는지 값을 정하기 위해서는
      출력하고자 하는 데이터의 위치값을 정확하게 알고 있어야합니다.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Slicing",
    difficulty: 2,
    difficultyDescription: "슬라이싱의 기본 개념과 활용법을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "인덱싱 개념",
      "시퀀스 타입(문자열, 리스트 등)"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Data Structures",
      "Slicing",
      "String Manipulation"
    ],
    programmingConcepts: ["슬라이싱", "인덱싱", "시퀀스 타입"],
    skillsPracticed: ["데이터 추출", "문자열 조작"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "슬라이싱의 기본 문법 이해하기",
      "슬라이싱을 사용하여 시퀀스의 일부분을 추출하는 방법 학습",
      "음수 인덱스와 역순 슬라이싱의 개념 파악하기"
    ],
    commonMistakes: [
      "end 인덱스가 포함된다고 오해함",
      "음수 인덱스의 의미를 잘못 이해함",
      "step의 역할을 혼동함"
    ],
    relatedConcepts: ["인덱싱", "시퀀스 타입", "문자열 처리", "리스트 조작"],
    learningStyle: ["개념적 학습", "시각적 학습"],
    accessibilityFeatures: ["이미지에 대한 대체 텍스트 제공"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_008 // data_types // multiple_choice - 올바른 슬라이싱 방법 선택하기
  {
    problemId: "basic-data-structure_008",
    image: basic_data_004,
    type: "multiple_choice",
    title: "올바른 슬라이싱 방법",
    description: `"o W"를 추출하기 위한 올바른 슬라이싱 방법을 고르세요.
    공백도 하나의 문자입니다.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Slicing",
    difficulty: 2,
    difficultyDescription: "슬라이싱의 기본 방법을 이해하고 적용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "분석"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 인덱싱",
      "슬라이싱 기본 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "Data Structures",
      "Slicing",
      "String Manipulation"
    ],
    programmingConcepts: ["슬라이싱", "인덱싱", "문자열 조작"],
    skillsPracticed: ["슬라이싱 적용", "문자열 추출"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "슬라이싱의 start, end, step 개념 적용하기",
      "정확한 문자열 부분 추출 방법 이해하기",
      "공백을 포함한 문자열 슬라이싱 이해하기"
    ],
    commonMistakes: [
      "start 인덱스를 잘못 계산함",
      "end 인덱스가 포함된다고 오해함",
      "step의 의미를 잘못 이해함",
      "공백을 문자로 취급하지 않음"
    ],
    hints: {
      beginner: [
        "슬라이싱의 start 인덱스는 포함됩니다.",
        "end 인덱스는 포함되지 않습니다.",
        "step은 건너뛰는 간격을 의미합니다.",
        "공백도 하나의 문자로 취급됩니다."
      ]
    },
    relatedConcepts: ["인덱싱", "문자열 처리", "시퀀스 타입"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 슬라이싱은 [start:end:step] 형식을 사용합니다."]
      }
    },
    choices: [
      { id: "A", text: "word[4:7:1]" },
      { id: "B", text: "word[4:7:2]" },
      { id: "C", text: "word[4~7~1]" },
      { id: "D", text: "word[4:8:2]" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. word[4:7:1]는 인덱스 4부터 시작하여 7 직전까지, 1칸씩 이동하며 슬라이싱합니다. 이는 'o'(인덱스 4), ' '(인덱스 5), 'W'(인덱스 6)를 선택하여 'o W'를 정확히 추출합니다. B는 'oW'를 추출합니다. C는 'lo '를 추출합니다. D는 'o'만 추출합니다.",
    examples: [
      {
        input: "word[4:7:1]",
        output: "o W"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_009 // data_types // code - 슬라이싱 연습하기
  {
    problemId: "basic-data-structure_009",
    type: "code",
    title: "슬라이싱 연습하기",
    description: `주어진 문자열 "Python Programming"에서 다음 조건에 맞는 부분 문자열을 출력하는 코드를 작성하세요. 문자열이 어떻게 저장되어 있을지 머리속으로 시각화해보세요.

  1. "thon"을 출력하세요.
  2. "Program"을 출력하세요.

  각 결과를 print() 함수를 사용하여 출력하세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Slicing",
    difficulty: 2,
    difficultyDescription: "슬라이싱의 기본 방법을 적용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "분석"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 인덱싱",
      "슬라이싱 기본 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Data Structures",
      "Slicing",
      "String Manipulation"
    ],
    programmingConcepts: ["슬라이싱", "인덱싱", "문자열 조작"],
    skillsPracticed: ["슬라이싱 적용", "문자열 추출"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "슬라이싱을 사용하여 특정 부분 문자열 추출하기",
      "start와 end 인덱스를 정확히 계산하여 적용하기"
    ],
    commonMistakes: [
      "start와 end 인덱스 계산 오류",
      "슬라이싱 범위 설정 실수"
    ],
    hints: {
      beginner: [
        "첫 번째 문제는 'Python'에서 'thon'을 추출해야 합니다.",
        "두 번째 문제는 'Programming'에서 'Program'을 추출해야 합니다.",
        "인덱스는 0부터 시작한다는 것을 기억하세요."
      ]
    },
    relatedConcepts: ["인덱싱", "문자열 처리", "시퀀스 타입"],
    learningStyle: ["실습 학습", "문제 해결 학습"],
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 슬라이싱은 [start:end] 형식을 사용합니다."]
      }
    },
    initialCode: `word = "Python Programming"

# 1. "thon" 출력
print()

# 2. "Program" 출력
print()`,
    testCases: [
      {
        input: "",
        expected: `thon
Program`
      }
    ],
    solution: `word = "Python Programming"

  # 1. "thon"
  print(word[2:6])

  # 2. "Program"
  print(word[7:14])`,
    examples: [
      {
        input: "",
        output: `thon
  Program`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_010 // data_types // concept - 위치값과 연관된 함수
  {
    problemId: "basic-data-structure_010",
    image: basic_data_005,
    type: "concept",
    title: "위치값과 연관된 함수",
    description: `len(), index() 함수는 문자열을 다룰 때 자주 사용되는 함수입니다.

    len() 함수는 문자열의 길이를 반환합니다.
    즉, 요소의 개수를 파악할 수 있습니다.

    index() 함수는 특정 문자의 위치값을 반환합니다.
    즉, 특정 문자의 위치를 찾을 수 있습니다.

    문자열 처리 작업을 하는데 있어 위치값을 정확하게 파악하고 사용하는 것이 매우 중요합니다. 특히 len() 함수는 슬라이싱, range() 함수 등에서도 자주 사용됩니다.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "len() and index()",
    difficulty: 2,
    difficultyDescription: "len()과 index() 함수의 기본 개념을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열의 기본 개념",
      "인덱싱의 기본 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "String Functions",
      "len()",
      "index()"
    ],
    programmingConcepts: ["문자열 함수", "문자열 길이", "문자열 검색"],
    skillsPracticed: ["문자열 길이 계산", "문자열 내 위치 찾기"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "len() 함수의 사용법과 반환값 이해하기",
      "index() 메서드의 사용법과 반환값 이해하기",
      "문자열 처리에서 len()과 index()의 활용 방법 파악하기"
    ],
    commonMistakes: [
      "len() 함수의 결과와 인덱스를 혼동함",
      "index() 메서드가 찾지 못할 때 발생하는 예외를 처리하지 않음",
      "대소문자 구분을 고려하지 않고 index() 메서드를 사용함"
    ],
    relatedConcepts: ["문자열 슬라이싱", "문자열 메서드", "예외 처리"],
    learningStyle: ["개념적 학습", "예시 기반 학습"],
    accessibilityFeatures: ["텍스트 기반 설명"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_011 // data_types // multiple_choice - len() 함수 이해하기
  {
    problemId: "basic-data-structure_011",
    image: basic_data_006,
    type: "multiple_choice",
    title: "len() 함수 이해하기",
    description: `위 코드의 올바른 출력값을 고르세요. 공백과 특수문자도 문자라는 사실을 잊지마세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "len()",
    difficulty: 1,
    difficultyDescription: "len() 함수의 기본적인 사용법을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열의 기본 개념"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "String Functions",
      "len()"
    ],
    programmingConcepts: ["문자열 함수", "문자열 길이"],
    skillsPracticed: ["문자열 길이 계산"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "len() 함수의 사용법 이해하기",
      "문자열 길이 계산 방법 학습하기"
    ],
    commonMistakes: [
      "공백을 문자로 세지 않음",
      "특수문자를 문자로 세지 않음",
      "len() 함수의 결과를 인덱스와 혼동함"
    ],
    hints: {
      beginner: [
        "len() 함수는 모든 문자를 세어 길이를 반환합니다.",
        "공백도 하나의 문자로 취급됩니다.",
        "특수문자(쉼표, 느낌표 등)도 문자로 세어집니다."
      ]
    },
    relatedConcepts: ["인덱싱", "문자열 처리"],
    learningStyle: ["개념적 학습", "시각적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 len() 함수는 문자열의 길이를 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "11" },
      { id: "B", text: "12" },
      { id: "C", text: "13" },
      { id: "D", text: "14" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 'Hello World!'는 총 12개의 문자로 구성되어 있습니다. 여기에는 'H', 'e', 'l', 'l', 'o', ' ', 'W', 'o', 'r', 'l', 'd', '!'가 포함됩니다. len() 함수는 모든 문자(공백과 특수문자 포함)를 세기 때문에 결과값은 13입니다.",
    examples: [
      {
        input: "len('Hello, World!')",
        output: "12"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_012 // data_types // multiple_choice - 정수형 데이터 특성 이해하기
  {
    problemId: "basic-data-structure_012",
    image: basic_data_007,
    type: "multiple_choice",
    title: "정수형 데이터 특성 이해하기",
    description: `정수 데이터는 4byte 메모리를 차지합니다.
    문자 하나는 1byte 공간을 차지하고, 정수 하나는 4btye 공간을 차지하죠.

    (32비트 or 64비트 운영체제에 따라 사용되는 메모리 크기가 다를 수 있습니다. 일단 우리는 32비트 운영체제를 사용한다고 가정하겠습니다.)

    문자열 데이터를 변수에 저장한다면 인덱싱/슬라이싱이 가능하지만,
    정수 데이터는 인덱싱/슬라이싱이 불가능합니다.
    이미지처럼 위치값이 없기 때문입니다.

    그렇다면 정수형 데이터에 대한 설명으로 올바른 것은 무엇일까요?
  `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "memory_management",
    subTopic: "Integer",
    difficulty: 1,
    difficultyDescription: "정수형 데이터의 기본 특성을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 데이터 타입",
      "인덱싱의 기본 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Integer"
    ],
    programmingConcepts: ["데이터 타입", "정수", "인덱싱"],
    skillsPracticed: ["데이터 타입 특성 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "정수형 데이터의 기본 특성 이해하기",
      "정수형 데이터와 인덱싱의 관계 인식하기"
    ],
    commonMistakes: [
      "정수형 데이터를 인덱싱할 수 있다고 오해함",
      "정수형 데이터를 시퀀스 타입으로 잘못 이해함"
    ],
    hints: {
      beginner: [
        "정수는 단일 값을 나타내는 스칼라 타입입니다.",
        "인덱싱은 시퀀스 타입의 데이터에 적용되는 개념입니다.",
        "파이썬에서 모든 데이터 타입이 인덱싱을 지원하는 것은 아닙니다."
      ]
    },
    relatedConcepts: ["데이터 구조", "시퀀스 타입", "스칼라 타입"],
    learningStyle: ["개념적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 정수는 int 클래스의 인스턴스입니다."]
      }
    },
    choices: [
      { id: "A", text: "정수형 데이터는 인덱싱이 가능하며, 각 자릿수에 접근할 수 있다." },
      { id: "B", text: "정수형 데이터는 인덱싱이 불가능하며, 단일 값으로 취급된다." },
      { id: "C", text: "정수형 데이터는 항상 양수만 표현할 수 있다." },
      { id: "D", text: "정수형 데이터는 소수점 이하 자리를 가질 수 있다." }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 파이썬에서 정수형 데이터는 단일 값을 나타내는 스칼라 타입이므로 인덱싱이 불가능합니다. 정수는 하나의 전체 값으로 취급되며, 개별 자릿수에 직접 접근할 수 없습니다. 인덱싱은 문자열이나 리스트와 같은 시퀀스 타입의 데이터에 적용되는 개념입니다.",
    examples: [
      {
        input: "5[0]",
        output: "TypeError: 'int' object is not subscriptable"
      },
      {
        input: "isinstance(42, int)",
        output: "True"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.2
  },

  //id: "operators",
  //title: "프로그래밍 연산자",
  //topics: ["Arithmetic Operators", "Comparison Operators", "Logical Operators", "Assignment Operators"]
  ////////////////////////////////
  
  // operators_001 // Arithmetic Operators // concept - 기본 산술 연산자
  {
    problemId: "operators_001",
    type: "concept",
    title: "기본 산술 연산자",
    image: operators_001,
    description: `프로그래밍에서 사용되는 산술 연산의 종류입니다.

    이러한 연산자들은 프로그래밍에서 수학적 계산을 수행하는 데 사용됩니다. 
    연산자의 우선순위는 일반적인 수학 규칙을 따르며, 괄호를 사용하여 연산 순서를 명시적으로 지정할 수 있습니다.
    
    프로그래밍에서는 문자와 정수, 문자와 문자 등 다양한 데이터 타입도 가능한 산술 연산이 존재합니다.`,
    subject: "Computer Science",
    topic: "Arithmetic Operators",
    subTopic: "Basic Arithmetic",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Arithmetic", "Operators"],
    relatedConcepts: ["Variables", "Data Types", "Expressions"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_002 // Arithmetic Operators // multiple_choice - 불가능한 문자 연산
  {
    problemId: "operators_002",
    type: "multiple_choice",
    title: "불가능한 문자 연산",
    description: `다음 보기에서 연산이 불가능한 것은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Arithmetic Operators",
    subTopic: "String Operations",
    difficulty: 2,
    difficultyDescription: "문자열 연산의 특성을 이해하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 연산",
      "산술 연산자"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "String Operations",
      "Arithmetic Operators"
    ],
    programmingConcepts: ["문자열 연산", "산술 연산자", "타입 호환성"],
    skillsPracticed: ["연산자 이해", "문자열 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열과 다른 데이터 타입 간의 연산 가능성 이해",
      "Python에서의 문자열 연산 규칙 파악"
    ],
    commonMistakes: [
      "모든 산술 연산자가 문자열에 적용 가능하다고 오해함",
      "문자열과 숫자의 연산 규칙을 혼동함"
    ],
    hints: {
      beginner: [
        "문자와 문자는 + 연산자로 연결가능합니다.",
        "문자와 정수는 * 연산자 사용가능합니다.",
        "문자열과 숫자는 서로 다른 데이터 타입입니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 변환", "연산자 오버로딩"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 불변(immutable) 객체입니다."]
      }
    },
    choices: [
      { id: "A", text: "'Hello' + ' World'" },
      { id: "B", text: "'Python' * 3" },
      { id: "C", text: "'5' - 3" }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. 문자와 숫자는 뺄 수 없습니다.",
    examples: [
      {
        input: "'Hello' + ' World'",
        output: "'Hello World'"
      },
      {
        input: "'Python' * 3",
        output: "'PythonPythonPython'"
      },
      {
        input: "'5' - 3",
        output: "TypeError: unsupported operand type(s) for -: 'str' and 'int'"
      },
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_003 // Arithmetic Operators // multiple_choice - 가능한 문자 연산
  {
    problemId: "operators_003",
    type: "multiple_choice",
    title: "가능한 산술 연산 선택하기",
    description: `문자와 문자는 + 연산으로 연결(concatenation) 가능하고, 문자와 정수는 * 연산으로 곱셈이 가능합니다.
    
    데이터 타입에 따라 어떤 연산이 가능한지 미리 알고 있어야 합니다.
    프로그래밍에서 데이터 타입에 따라 불가능한 연산들이 존재하기 때문입니다.
    만약 이 규칙을 어긴다면, 프로그램이 오류를 발생시키거나 예상치 못한 결과를 출력할 수 있습니다.
    
    다음중 연산이 가능한 경우는 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Arithmetic Operators",
    subTopic: "String and Integer Operations",
    difficulty: 2,
    difficultyDescription: "문자열과 정수 간의 연산 특성을 이해하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 연산",
      "산술 연산자",
      "타입 변환"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "String Operations",
      "Arithmetic Operators",
      "Type Conversion"
    ],
    programmingConcepts: ["문자열 연산", "산술 연산자", "타입 호환성", "타입 변환"],
    skillsPracticed: ["연산자 이해", "문자열 처리", "타입 분석"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열과 정수 간의 연산 가능성 이해",
      "Python에서의 문자열과 정수 연산 규칙 파악",
      "암시적 및 명시적 타입 변환의 이해"
    ],
    commonMistakes: [
      "모든 산술 연산자가 문자열과 정수 사이에 적용 가능하다고 오해함",
      "문자열과 정수의 연산 규칙을 혼동함",
      "암시적 타입 변환이 항상 일어난다고 가정함"
    ],
    hints: {
      beginner: [
        "문자와 문자는 + 연산자로 연결가능합니다.",
        "문자와 정수는 * 연산자 사용가능합니다.",
        "문자열과 숫자는 서로 다른 데이터 타입입니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 변환", "연산자 오버로딩"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 불변(immutable) 객체입니다."]
      }
    },
    choices: [
      { id: "A", text: "'5' - 3" },
      { id: "B", text: "'Python' / 2" },
      { id: "C", text: "'abc' * 'def'" },
      { id: "D", text: "int('5') + 3" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. int('5') + 3은 문자열 '5'를 정수로 변환한 후 3과 더하는 유효한 연산입니다. A는 문자열과 정수의 뺄셈, B는 문자열과 정수의 나눗셈, C는 문자열과 문자열의 곱셈으로 모두 Python에서 지원되지 않는 연산입니다.",
    examples: [
      {
        input: "'5' - 3",
        output: "TypeError: unsupported operand type(s) for -: 'str' and 'int'"
      },
      {
        input: "'Python' / 2",
        output: "TypeError: unsupported operand type(s) for /: 'str' and 'int'"
      },
      {
        input: "'abc' * 'def'",
        output: "TypeError: can't multiply sequence by non-int of type 'str'"
      },
      {
        input: "int('5') + 3",
        output: "8"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_004 // Arithmetic Operators // concept - 나누기 연산자의 종류
  {
    problemId: "operators_004",
    type: "concept",
    title: "이제 나누기를 연습해볼까요?",
    image: operators_002,
    description: `나누기 연산자에는 세 가지 종류가 있습니다.

    1. 일반 나누기 (/): 실수 타입 결과 반환(소수점 이하 16자리까지 표시)
    2. 몫 나누기 (//): 나눗셈 몫만 반환
    3. 나머지 연산 (%): 나눗셈 나머지를 반환

    이러한 나누기 연산자들은 프로그래밍에서 다양한 상황에 활용됩니다. 
    짝수/홀수 판별, 시간 계산, 데이터 분할 등에 사용될 수 있습니다.`,
    subject: "Computer Science",
    topic: "Arithmetic Operators",
    subTopic: "Division Operations",
    difficulty: 2,
    difficultyDescription: "나누기 연산자의 종류와 특성을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "구별"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "기본적인 산술 연산",
      "정수와 실수의 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Arithmetic Operators",
      "Division"
    ],
    programmingConcepts: ["나누기 연산", "데이터 타입", "연산자 종류"],
    skillsPracticed: ["연산자 구별", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "세 가지 나누기 연산자의 차이점 이해",
      "각 연산자의 결과 타입 파악",
      "적절한 상황에 맞는 나누기 연산자 선택 능력 향상"
    ],
    commonMistakes: [
      "일반 나누기(/)와 몫 나누기(//)를 혼동함",
      "나머지 연산(%)의 결과를 잘못 해석함",
      "연산 결과의 데이터 타입을 고려하지 않음"
    ],
    relatedConcepts: ["데이터 타입", "타입 변환", "정수 나눗셈"],
    learningStyle: ["개념적 학습", "예시 기반 학습"],
    accessibilityFeatures: ["텍스트 기반 설명", "예시 코드 제공"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_005 // Comparison Operators // concept - 비교 연산자 이해하기
  {
    problemId: "operators_005",
    type: "concept",
    title: "두 값 비교하기",
    image: operators_003,
    description: `두 값을 비교할 수 있는 비교 연산자도 있습니다.
    비교한 결과는 불리언 타입(참, 거짓)으로 반환됩니다.
    참은 True(1), 거짓은 False(0)로 표현한다는 사실 잊지 않으셨죠?

    1. 크다 (>): 왼쪽 값이 오른쪽 값보다 크면 True, 아니면 False를 반환합니다.
    2. 작다 (<): 왼쪽 값이 오른쪽 값보다 작으면 True, 아니면 False를 반환합니다.
    3. 크거나 같다 (>=): 왼쪽 값이 오른쪽 값보다 크거나 같으면 True, 아니면 False를 반환합니다.
    4. 작거나 같다 (<=): 왼쪽 값이 오른쪽 값보다 작거나 같으면 True, 아니면 False를 반환합니다.
    5. 같음 (==): 두 값이 같으면 True, 다르면 False를 반환합니다.
    6. 다름 (!=): 두 값이 다르면 True, 같으면 False를 반환합니다.

    비교 연산자는 조건문(if, elif, else)이나 반복문(while)에서 자주 사용되며, 프로그램의 흐름을 제어하는 데 중요한 역할을 합니다.`,
    subject: "Computer Science",
    topic: "Comparison Operators",
    subTopic: "Basic Comparison",
    difficulty: 2,
    difficultyDescription: "비교 연산자의 종류와 특성을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "기본적인 산술 연산",
      "불리언(Boolean) 타입의 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Comparison Operators",
      "Boolean"
    ],
    programmingConcepts: ["비교 연산", "불리언 로직", "조건문"],
    skillsPracticed: ["연산자 구별", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "다양한 비교 연산자의 기능 이해",
      "비교 연산의 결과 타입 파악",
      "비교 연산자를 활용한 조건 표현 능력 향상"
    ],
    commonMistakes: [
      "같음(==)과 할당(=) 연산자를 혼동함",
      "비교 연산의 결과가 항상 불리언 타입임을 간과함",
      "연결된 비교 연산의 의미를 잘못 해석함"
    ],
    relatedConcepts: ["조건문", "반복문", "논리 연산자"],
    learningStyle: ["개념적 학습", "예시 기반 학습"],
    accessibilityFeatures: ["텍스트 기반 설명", "예시 코드 제공"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_006 // Comparison Operators // multiple_choice - False를 반환하는 비교 연산자
  {
    problemId: "operators_006",
    type: "multiple_choice",
    title: "False를 반환하는 경우는?",
    description: `문자와 문자, 문자와 정수에 대해서도 사용가능한 비교연산자가 있습니다.
    문자와 문자, 문자와 정수 데이터 타입은 각각의 크기를 비교하는건 불가능하겠지만, 같다/같지않다는 따져볼 수 있을겁니다.
    
    문자와 정수의 크기도 한 번 비교해보세요.
    '5' > 3이라고 비교한다면 TypeError: '>' not supported between instances of 'str' and 'int'라는 에러가 발생합니다.

    에러 메시지가 뜨는걸 두려워할 필요없습니다.
    다양한 코드를 입력해보면서 오류를 맞이해보세요.
    놀랍게도 프로그래밍은 많은 오류를 해결해본 사람이 훨씬 잘하게 될테니까요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Comparison Operators",
    subTopic: "Boolean Results",
    difficulty: 2,
    difficultyDescription: "비교 연산자의 결과를 예측하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "비교 연산자",
      "불리언 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "Comparison Operators",
      "Boolean"
    ],
    programmingConcepts: ["비교 연산", "불리언 로직"],
    skillsPracticed: ["연산자 이해", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "비교 연산자의 결과 예측 능력 향상",
      "다양한 데이터 타입에 대한 비교 연산 이해"
    ],
    commonMistakes: [
      "문자열 비교 시 대소문자를 고려하지 않음",
      "다른 데이터 타입 간의 비교 결과를 잘못 예측함"
    ],
    hints: {
      beginner: [
        "비교 연산자는 두 값을 비교합니다.",
        "결과는 항상 True 또는 False입니다.",
        "문자열 비교 시 대소문자를 구분합니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 변환", "조건문"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 비교 연산자는 불리언 값을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "'5' == '5'" },
      { id: "B", text: "3 <= 3" },
      { id: "C", text: "10 > 5" },
      { id: "D", text: "'python' == 'Python'" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. 'python' == 'Python'은 False를 반환합니다. Python에서 문자열 비교는 대소문자를 구분하기 때문입니다.",
    examples: [
      {
        input: "'5' == '5'",
        output: "True"
      },
      {
        input: "3 <= 3",
        output: "True"
      },
      {
        input: "10 > 5",
        output: "True"
      },
      {
        input: "'python' == 'Python'",
        output: "False"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_007 // Logical Operators // concept - 논리 연산자와 논리회로의 기초
  {
    problemId: "operators_007",
    type: "concept",
    title: "여러 조건을 다루는 논리 연산자",
    image: null,
    description: `지금까지는 하나의 경우에 대해서 비교했지만, 실제 프로그래밍에서는 여러 조건을 동시에 고려해야 하는 경우가 많습니다. 이때 사용되는 것이 논리 연산자입니다.
    이러한 논리 연산자들은 컴퓨터 내부의 논리회로를 기반으로 작동합니다. 논리회로는 디지털 시스템의 기본 구성 요소로, 0과 1의 이진 신호를 처리합니다.

    논리회로와 논리 연산자가 중요한 이유는 너무나도 많습니다. 예를 들면,
    1. 복잡한 의사결정: 여러 조건을 조합하여 복잡한 상황을 표현할 수 있습니다.
    2. 효율적인 코드: 여러 조건을 간결하게 표현하여 코드의 가독성과 효율성을 높입니다.
    3. 하드웨어와 소프트웨어의 연결: 논리회로는 하드웨어의 기본이며, 이를 이해하면 컴퓨터의 작동 원리를 더 깊이 이해할 수 있습니다.
    4. 알고리즘 설계: 복잡한 알고리즘을 설계할 때 논리 연산은 핵심적인 역할을 합니다.
    5. 데이터 처리: 대량의 데이터를 필터링하거나 분석할 때 논리 연산이 필수적입니다.

    프로그래밍에서 논리 연산자를 이해하고 활용하는 것은 효과적인 코드 작성의 기본이 됩니다.`,
    subject: "Computer Science",
    topic: "Logical Operators",
    subTopic: "Basic Logic Circuits",
    difficulty: 2,
    difficultyDescription: "논리 연산자와 논리회로의 기본 개념을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "기본적인 불리언 연산",
      "조건문의 기초"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Intermediate",
      "Logical Operators",
      "Logic Circuits"
    ],
    programmingConcepts: ["논리 연산", "불리언 로직", "디지털 시스템"],
    skillsPracticed: ["논리적 사고", "복합 조건 분석"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "기본적인 논리 연산자(AND, OR, NOT)의 기능 이해",
      "논리회로의 기본 개념과 중요성 인식",
      "프로그래밍에서 논리 연산자의 활용 방법 파악"
    ],
    commonMistakes: [
      "AND와 OR 연산의 결과를 혼동함",
      "복합 조건에서 연산자 우선순위를 고려하지 않음",
      "논리 연산의 결과가 항상 불리언 타입임을 간과함"
    ],
    relatedConcepts: ["조건문", "불리언 대수", "디지털 로직"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    accessibilityFeatures: ["텍스트 기반 설명", "시각적 다이어그램"],
    technologyRequirements: ["없음"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_008 // Logical Operators // multiple_choice - AND 게이트 이해하기
  {
    problemId: "operators_008",
    image: operators_004,
    type: "multiple_choice",
    title: "AND 게이트 이해하기",
    description: `AND 게이트는 논리 회로의 기본 요소 중 하나입니다. AND 게이트의 특성은 다음과 같습니다:

    - 두 개 이상의 입력을 받습니다.
    - 모든 입력이 1(True)일 때만 출력이 1(True)입니다.
    - 하나라도 0(False)이면 출력은 0(False)입니다.

    다음 중 AND 게이트의 동작을 올바르게 설명한 것은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Logical Operators",
    subTopic: "AND Gate",
    difficulty: 2,
    difficultyDescription: "AND 게이트의 기본 개념을 이해하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "불리언 대수의 기초",
      "AND 연산의 기본 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "Logical Operators",
      "Boolean Algebra",
      "AND Gate"
    ],
    programmingConcepts: ["논리 연산", "불리언 로직", "AND 게이트"],
    skillsPracticed: ["논리적 사고", "연산자 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "AND 게이트의 기본 동작 이해",
      "AND 연산의 결과 예측 능력 향상"
    ],
    commonMistakes: [
      "AND와 OR 연산의 결과를 혼동함",
      "불리언 값의 의미를 잘못 해석함"
    ],
    hints: {
      beginner: [
        "AND 연산은 모든 조건이 참일 때만 참입니다.",
        "하나라도 거짓이면 결과는 거짓입니다.",
        "False는 0, True는 1로 생각해볼 수 있습니다."
      ]
    },
    relatedConcepts: ["불리언 대수", "조건문", "디지털 로직"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 AND 연산자는 'and'로 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: "AND 게이트에서 두 입력 중 하나만 True여도 결과는 True이다." },
      { id: "B", text: "AND 게이트에서 두 입력이 모두 False일 때만 결과가 False이다." },
      { id: "C", text: "AND 게이트에서 두 입력이 모두 True일 때만 결과가 True이다." }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. AND 게이트에서는 모든 입력이 True일 때만 결과가 True입니다. A는 OR 게이트의 특성을 설명하고 있습니다. B는 잘못된 설명입니다; AND 게이트에서는 하나라도 False이면 결과가 False입니다. D 역시 잘못된 설명으로, AND 게이트의 특성과 반대되는 내용입니다.",
    examples: [
      {
        input: "True and True",
        output: "True"
      },
      {
        input: "True and False",
        output: "False"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.1
  },

  // operators_009 // Logical Operators // multiple_choice - OR 게이트 이해하기
  {
  problemId: "operators_009",
  image: operators_005,
  type: "multiple_choice",
  title: "OR 게이트 이해하기",
  description: `OR 게이트는 논리 회로의 기본 요소 중 하나입니다. OR 게이트의 특성은 다음과 같습니다:

  - 두 개 이상의 입력을 받습니다.
  - 하나 이상의 입력이 1(True)이면 출력이 1(True)입니다.
  - 모든 입력이 0(False)일 때만 출력이 0(False)입니다.

  다음 중 OR 게이트의 동작을 올바르게 설명한 것은 무엇일까요?`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Logical Operators",
  subTopic: "OR Gate",
  difficulty: 2,
  difficultyDescription: "OR 게이트의 기본 개념을 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "불리언 대수의 기초",
    "OR 연산의 기본 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Intermediate",
    "Logical Operators",
    "Boolean Algebra",
    "OR Gate"
  ],
  programmingConcepts: ["논리 연산", "불리언 로직", "OR 게이트"],
  skillsPracticed: ["논리적 사고", "연산자 이해"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "OR 게이트의 기본 동작 이해",
    "OR 연산의 결과 예측 능력 향상"
  ],
  commonMistakes: [
    "AND와 OR 연산의 결과를 혼동함",
    "불리언 값의 의미를 잘못 해석함"
  ],
  hints: {
    beginner: [
      "OR 연산은 하나 이상의 조건이 참이면 참입니다.",
      "모든 입력이 거짓일 때만 결과가 거짓입니다.",
      "False는 0, True는 1로 생각해볼 수 있습니다."
    ]
  },
  relatedConcepts: ["불리언 대수", "조건문", "디지털 로직"],
  learningStyle: ["개념적 학습", "분석적 사고"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 OR 연산자는 'or'로 표현됩니다."]
    }
  },
  choices: [
    { id: "A", text: "OR 게이트에서 두 입력이 모두 True일 때만 결과가 True이다." },
    { id: "B", text: "OR 게이트에서 두 입력 중 하나라도 True이면 결과는 True이다." },
    { id: "C", text: "OR 게이트에서 두 입력이 모두 False일 때만 결과가 True이다." },
    { id: "D", text: "OR 게이트에서 두 입력 중 하나만 False여도 결과는 False이다." }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. OR 게이트에서는 하나 이상의 입력이 True이면 결과가 True입니다. A는 AND 게이트의 특성을 설명하고 있습니다. C는 완전히 잘못된 설명입니다; OR 게이트에서 모든 입력이 False일 때 결과가 False입니다. D 역시 잘못된 설명으로, OR 게이트의 특성과 반대되는 내용입니다.",
  examples: [
    {
      input: "True or False",
      output: "True"
    },
    {
      input: "False or False",
      output: "False"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-14",
  lastUpdated: "2024-10-14",
  versionNumber: 1.0
  },

  // operators_010 // Logical Operators // code - 3 또는 5의 배수 확인하기
{
  problemId: "operators_010",
  type: "code",
  title: "3 또는 5의 배수 확인하기",
  description: `주어진 숫자가 3의 배수이거나 5의 배수일 때 True를 출력하고, 그렇지 않으면 False를 출력하는 코드입니다.
  조건 A(3의 배수)와 조건 B(5의 배수)를 논리 연산자 OR를 사용하여 표현해봅니다.

  3의 배수는 3으로 나누었을 때 나머지가 0이고, 5의 배수는 5로 나누었을 때 나머지가 0입니다.
  
  만약 두 조건이 모두 참일때만, 참을 출력하고 싶다면 and 연산자를 사용해야합니다.
  
  우선 아래 코드를 실행시켜, 결과를 확인해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Logical Operators",
  subTopic: "OR Operation",
  difficulty: 2,
  difficultyDescription: "기본적인 논리 연산을 활용하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "논리 연산자",
    "나머지 연산자"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Intermediate",
    "Logical Operators",
    "Boolean Expressions"
  ],
  programmingConcepts: ["논리 연산", "나머지 연산", "불리언 표현식"],
  skillsPracticed: ["논리 연산자 사용", "간결한 코드 작성"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "OR 연산자의 활용 방법 이해",
    "나머지 연산자를 이용한 배수 판별 방법 학습",
    "조건문 없이 논리 연산을 수행하는 능력 향상"
  ],
  commonMistakes: [
    "OR 연산자 대신 AND 연산자를 사용함",
    "나머지 연산자의 사용법을 잘못 이해함",
    "불필요하게 복잡한 표현식을 사용함"
  ],
  hints: {
    beginner: [
      "3의 배수는 3으로 나누었을 때 나머지가 0입니다.",
      "5의 배수는 5로 나누었을 때 나머지가 0입니다.",
      "OR 연산자는 '또는'의 의미로, 두 조건 중 하나만 참이어도 전체가 참입니다."
    ],
    intermediate: [
      "나머지 연산자 %를 사용하여 배수를 확인할 수 있습니다.",
      "논리 연산의 결과는 그 자체로 True 또는 False입니다."
    ]
  },
  relatedConcepts: ["불리언 대수", "나머지 연산", "단축 평가"],
  learningStyle: ["실천적 학습"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 OR 연산자는 'or'로 표현됩니다."]
    }
  },
  initialCode: `number = 15
# 아래 줄을 수정하여 number가 3의 배수이거나 5의 배수일 때 True를 출력하도록 만드세요.
print(number % 3 == 0 or number % 5 == 0)`,
  testCases: [
    { input: "15", expected: "True" }
  ],
  solution: `number = 15
print(number % 3 == 0 or number % 5 == 0)`,
  examples: [
    {
      input: "15",
      output: "True"
    },
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-14",
  lastUpdated: "2024-10-14",
  versionNumber: 1.1
},

// operators_011 // Logical Operators // code - 2와 3의 공배수 확인하기
{
  problemId: "operators_011",
  type: "code",
  title: "2와 3의 공배수 확인하기",
  description: `주어진 숫자가 2의 배수이면서 동시에 3의 배수일 때(즉, 2와 3의 공배수일 때) True를 출력하고, 그렇지 않으면 False를 출력하는 코드를 작성하세요.
  공배수는 두 조건(2의 배수이기도 하고, 3의 배수이기도 할때)이 모두 True일때를 뜻합니다. and 연산자를 사용해보세요.

  [입력 예시]
  6

  [출력 예시]
  True`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Logical Operators",
  subTopic: "AND Operation",
  difficulty: 2,
  difficultyDescription: "기본적인 논리 연산을 활용하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "논리 연산자",
    "나머지 연산자"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Intermediate",
    "Logical Operators",
    "Boolean Expressions"
  ],
  programmingConcepts: ["논리 연산", "나머지 연산", "불리언 표현식"],
  skillsPracticed: ["논리 연산자 사용", "간결한 코드 작성"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "AND 연산자의 활용 방법 이해",
    "나머지 연산자를 이용한 배수 판별 방법 학습",
    "조건문 없이 논리 연산을 수행하는 능력 향상"
  ],
  commonMistakes: [
    "AND 연산자 대신 OR 연산자를 사용함",
    "나머지 연산자의 사용법을 잘못 이해함",
    "불필요하게 복잡한 표현식을 사용함"
  ],
  hints: {
    beginner: [
      "2의 배수는 2로 나누었을 때 나머지가 0입니다.",
      "3의 배수는 3으로 나누었을 때 나머지가 0입니다.",
      "AND 연산자는 '그리고'의 의미로, 두 조건이 모두 참일 때만 전체가 참입니다."
    ],
    intermediate: [
      "나머지 연산자 %를 사용하여 배수를 확인할 수 있습니다.",
      "논리 연산의 결과는 그 자체로 True 또는 False입니다."
    ]
  },
  relatedConcepts: ["불리언 대수", "나머지 연산", "단축 평가"],
  learningStyle: ["실천적 학습"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 AND 연산자는 'and'로 표현됩니다."]
    }
  },
  initialCode: `number = 6
# 아래 줄을 수정하여 number가 2의 배수이면서 동시에 3의 배수일 때 True를 출력하도록 만드세요.
print()
`,
  testCases: [
    { input: "15", expected: "True" },
  ],
  solution: `number = 6
print(number % 2 == 0 and number % 3 == 0)

number = 8
print(number % 2 == 0 and number % 3 == 0)
`,
  examples: [
    {
      input: "6",
      output: "True"
    },
    {
      input: "8",
      output: "False"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-14",
  lastUpdated: "2024-10-14",
  versionNumber: 1.0
},

//id: "error-handling",
//title: "오류에 대하여",
//topics: ["Error Handling", "Syntax Errors", "Runtime Errors", "Logical Errors"]
////////////////////////////////
// error-handling_001 // Syntax Errors // concept - 기본적인 오류 유형 이해하기
{
  problemId: "error-handling_001",
  type: "concept",
  title: "기본적인 오류 유형 이해하기",
  image: error_001,
  description: `프로그래밍을 하다 보면 다양한 오류를 만나게 됩니다. 오류가 발생했다고 낙심할필요 없습니다.

  오히려 오류를 만난건 문제를 해결할 수 있는 좋은 기회가 될테니까요. 그 어떤 개발자도 오류를 만나지 않는다는 보장은 없습니다.

  그러니 자신감을 가지고 하나씩, 천천히 오류를 해결하다보면 분명히 내가 원하는 SW를 만드는 단계까지 올라갈 수 있을 것입니다.

  이제 하나씩 오류에 대해서 알아보겠습니다.`,
  subject: "Computer Science",
  topic: "Error Handling",
  subTopic: "Basic Error Types",
  difficulty: 1,
  tags: ["Python", "Beginner", "Error Types", "Debugging"],
  relatedConcepts: ["Debugging", "Code Review", "Error Messages"],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// error-handling_002 // Syntax Errors // multiple_choice - 구문 오류 이해하기
{
  problemId: "error-handling_002",
  image: error_002,
  type: "multiple_choice",
  title: "구문 오류 이해하기",
  description: `Hello를 출력하는 코드를 작성했습니다. 하지만 오류가 발생합니다.
  누구나 발생할 수 있는 오류이니, 자신감을 가지고 오류를 해결해봅시다.

  첫 번째 줄의 print("Hello)에서 오류가 발생했다고 합니다.
  EOL은 End of Line의 약자로, 줄의 끝을 의미합니다.
  문자열을 출력하는 코드에서 문자열이 끝나지 않았다는 뜻입니다.
  문자열을 출력하려면 양쪽에 따옴표를 붙여야 하죠.
  명백한 개발자의 실수입니다.
  
  놀랍게도 우리는 이런 오류메시지도 외울 필요가 없습니다.
  오류 메시지를 보고 무엇이 잘못됐는지 생성형 AI에게 물어보면 되기 때문이죠.
  `,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Error Handling",
  subTopic: "Syntax Errors",
  difficulty: 1,
  difficultyDescription: "구문 오류의 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "문자열 리터럴의 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Syntax Errors",
    "String Literals"
  ],
  programmingConcepts: ["구문 오류", "문자열 리터럴", "변수 사용"],
  skillsPracticed: ["오류 메시지 해석", "코드 분석"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "구문 오류의 특성 이해",
    "문자열과 변수의 차이 인식",
    "오류 메시지 해석 능력 개발"
  ],
  commonMistakes: [
    "문자열과 변수를 혼동함",
    "따옴표의 필요성을 인식하지 못함",
    "NameError의 의미를 잘못 해석함"
  ],
  hints: {
    beginner: [
      "문자열은 따옴표로 둘러싸여 있어야 합니다.",
      "따옴표 없이 사용된 단어는 변수로 해석됩니다.",
      "정의되지 않은 변수를 사용하면 NameError가 발생합니다."
    ]
  },
  relatedConcepts: ["문자열 리터럴", "변수 선언", "print 함수 사용법"],
  learningStyle: ["개념적 학습", "분석적 사고"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 문자열은 작은따옴표나 큰따옴표로 둘러싸여야 합니다."]
    }
  },
  choices: [
    { id: "A", text: "오늘 컴퓨터의 기분이 안좋아서" },
    { id: "B", text: "'Hello'는 작은 따옴표를 사용해야 하는데 큰 따옴표를 사용해서" },
    { id: "C", text: "첫 번째 줄에서 문자열 출력을 위해 따옴표를 제대로 사용하지 않아서" }
  ],
  correctAnswer: "C",
  solution: "정답은 C입니다. 이 오류는 Hello를 문자열로 인식하지 못해 발생했습니다. Python에서 문자열은 따옴표(작은따옴표 또는 큰따옴표)로 둘러싸여 있어야 합니다. 따옴표 없이 Hello를 사용하면 Python은 이를 변수 이름으로 해석하려고 시도합니다. 그러나 Hello라는 변수가 정의되지 않았기 때문에 NameError가 발생합니다. 올바른 코드는 print('Hello') 또는 print(\"Hello\")입니다.",
  examples: [
    {
      input: `print("Hello")`,
      output: "Hello"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.1
},

// error-handling_003 // Type Errors // multiple_choice - 문자와 정수 더하기 오류 이해하기
{
  problemId: "error-handling_003",
  image: error_003,
  type: "multiple_choice",
  title: "문자와 정수 더하기 오류 이해하기",
  description: `그렇다면 위의 오류는 왜 발생할까요?
  에러가 발생해도 괜찮으니, 당황하지 말고 차분히 오류 메시지를 읽어보세요.
  
  안타까운 소식일수도 있지만, 프로그래밍을 하는데 어느정도의 영어는 필요하다는 것을 알 수 있습니다. 만약 영어를 전혀 못한다면, 빠르게 오류를 파악하는게 힘들테니까요.
  
  영어를 못한다고 프로그래밍을 아예 못하는건 아니니 너무 걱정하지 않아도 됩니다. 우리는 생성형 AI를 적극적으로 사용할테니까요.
  
  그래도 개발자가 잘다루는 언어 중에 영어가 포함된다면, 연봉의 앞자리가 바뀔수 있을겁니다. 😎`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Error Handling",
  subTopic: "Type Errors",
  difficulty: 2,
  difficultyDescription: "타입 오류의 기본 개념을 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 데이터 타입",
    "문자열과 정수의 차이",
    "타입 변환의 개념"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Intermediate",
    "Type Errors",
    "Data Types"
  ],
  programmingConcepts: ["타입 오류", "문자열 연산", "타입 변환"],
  skillsPracticed: ["오류 메시지 해석", "데이터 타입 분석"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "타입 오류의 특성 이해",
    "문자열과 정수의 연산 규칙 파악",
    "오류 메시지 해석 능력 개발"
  ],
  commonMistakes: [
    "문자열과 정수를 직접 더할 수 있다고 오해함",
    "타입 변환의 필요성을 인식하지 못함",
    "TypeError의 의미를 잘못 해석함"
  ],
  hints: {
    beginner: [
      "Python에서 문자열과 정수는 서로 다른 데이터 타입입니다.",
      "서로 다른 타입의 데이터를 연산할 때는 타입 변환이 필요할 수 있습니다.",
      "문자열 '5'와 정수 5는 Python에서 다르게 취급됩니다."
    ]
  },
  relatedConcepts: ["데이터 타입", "타입 변환", "문자열 연산"],
  learningStyle: ["개념적 학습", "분석적 사고"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 문자열과 정수는 직접 더할 수 없습니다."]
    }
  },
  choices: [
    { id: "A", text: "문자열 '5'가 올바르게 정의되지 않아 발생한 오류입니다." },
    { id: "B", text: "정수 3이 문자열로 자동 변환되지 않아 발생한 오류입니다." },
    { id: "C", text: "문자열과 정수를 더할 수 없어 발생한 오류입니다." },
    { id: "D", text: "print 함수가 result 변수를 올바르게 출력하지 못해 발생한 오류입니다." }
  ],
  correctAnswer: "C",
  solution: "정답은 C입니다. 이 오류는 문자열 '5'와 정수 3을 직접 더하려고 시도해서 발생했습니다. Python에서는 서로 다른 데이터 타입인 문자열과 정수를 직접 더할 수 없습니다. 이런 경우 TypeError가 발생하며, 문자열에는 문자열만 연결(concatenate)할 수 있다는 메시지가 표시됩니다. 이 문제를 해결하려면 문자열을 정수로 변환하거나(int('5') + 3) 정수를 문자열로 변환해야('5' + str(3)) 합니다.",
  examples: [
    {
      input: `result = int("5") + 3
print(result)`,
      output: "8"
    },
    {
      input: `result = "5" + str(3)
print(result)`,
      output: "53"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// error-handling_004 // Syntax Errors // concept - 기본적인 오류 유형 이해하기
{
  problemId: "error-handling_004",
  image: error_004,
  type: "concept",
  title: "기본적인 오류 유형 이해하기",
  description: `오류의 종류는 크게 3가지 정도로 나눌 수 있습니다.

  1. 구문 오류 (Syntax Error):
     - 프로그램의 문법이 올바르지 않을 때 발생합니다.

  2. 런타임 오류 (Runtime Error):
     - 프로그램 실행 중에 발생하는 오류입니다.
     - 문법적으로는 올바르지만 프로그램 실행 중 예상치 못한 상황이 발생할 때 나타납니다.

  3. 논리 오류 (Logical Error):
     - 프로그램이 오류 없이 실행되지만 의도한 결과가 나오지 않는 경우입니다.
     - 가장 찾기 어려운 오류 유형으로, 프로그램의 논리가 잘못되었을 때 발생합니다.

  이러한 오류들을 이해하고 해결하는 능력은 프로그래밍 실력 향상에 매우 중요합니다. 오류를 만났을 때 당황하지 말고, 오류 메시지를 주의 깊게 읽고 분석하는 습관을 들이세요. 오류 해결 과정은 프로그래밍 학습의 중요한 부분입니다. 그리고 생성형 AI를 적극적으로 사용해보세요.`,
  subject: "Computer Science",
  topic: "Error Handling",
  subTopic: "Basic Error Types",
  difficulty: 1,
  tags: ["Python", "Beginner", "Error Types", "Debugging"],
  relatedConcepts: ["Debugging", "Code Review", "Error Messages"],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// id: "conditional-statements",
// title: "조건문",
// topics: ["Conditional Statements", "if Statements", "else Statements", "elif Statements", "Nested Conditionals"]
////////////////////////////////
// conditional-statements_001 // if Statements // concept - 조건문의 기본 개념
{
  problemId: "conditional-statements_001",
  type: "concept",
  title: "조건문의 기본 개념",
  image: conditional_001,
  description: `조건문은 프로그램의 흐름을 제어합니다.
  참(True)인지 거짓(False)인지에 따라 다른 코드를 실행할 수 있게 해줍니다.

  우리는 앞서 참, 거짓을 판단할 때 비교 연산자를 사용했습니다.
  조건식에서는 비교 연산자를 사용하여 참, 거짓을 판단합니다.

  조건문을 사용하면 프로그램이 상황에 따라 다르게 동작하도록 만들 수 있어, 더 유연하고 지능적인 프로그램을 작성할 수 있습니다.`,
  subject: "Computer Science",
  topic: "Conditional Statements",
  subTopic: "if Statements",
  difficulty: 1,
  tags: ["Python", "Beginner", "Conditional Statements", "Control Flow"],
  relatedConcepts: ["Boolean Logic", "Comparison Operators", "Indentation in Python"],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_002 // if Statements // multiple_choice - 간단한 조건문 이해하기
{
  problemId: "conditional-statements_002",
  image: conditional_002,
  type: "multiple_choice",
  title: "간단한 조건문 이해하기",
  description: `다음 코드를 보고 실행 결과를 예측해보세요.
  조건식이 참이라면, 조건문 다음 코드가 실행됩니다.
  만약 거짓이라면, else 문 다음 코드가 실행됩니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Conditional Statements",
  subTopic: "if Statements",
  difficulty: 1,
  difficultyDescription: "조건문의 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "비교 연산자",
    "if-else 문의 기본 구조"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Conditional Statements",
    "if-else"
  ],
  programmingConcepts: ["조건문", "비교 연산"],
  skillsPracticed: ["코드 해석", "조건 평가"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "간단한 조건문의 실행 결과 예측하기",
    "비교 연산자의 사용법 이해하기"
  ],
  commonMistakes: [
    "조건의 참/거짓을 잘못 판단함",
    "else 문의 역할을 이해하지 못함"
  ],
  hints: {
    beginner: [
      "a의 값은 10입니다.",
      "조건 a > 5는 '10이 5보다 큰가?'를 의미합니다.",
      "조건이 참일 때는 if 문 다음의 코드가 실행됩니다."
    ]
  },
  relatedConcepts: ["비교 연산자", "불리언 값", "제어 흐름"],
  learningStyle: ["개념적 학습", "시각적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 들여쓰기는 코드 블록을 구분하는 중요한 요소입니다."]
    }
  },
  choices: [
    { id: "A", text: "'조건이 참입니다.' 문장 출력" },
    { id: "B", text: "'조건이 거짓입니다.' 문장 출력" },
    { id: "C", text: "아무것도 출력되지 않음" },
    { id: "D", text: "오류 발생" }
  ],
  correctAnswer: "A",
  solution: "정답은 A입니다. a의 값이 10이고, 조건 a > 5는 참이기 때문에 if 문 다음의 코드가 실행됩니다. 따라서 '조건이 참입니다.'가 출력됩니다. else 문은 조건이 거짓일 때만 실행되므로, 이 경우에는 실행되지 않습니다.",
  examples: [
    {
      input: `a = 10
if a > 5:
    print("조건이 참입니다.")
else:
    print("조건이 거짓입니다.")`,
      output: "조건이 참입니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_003 // if Statements // multiple_choice - 문자열 비교 조건문 이해하기
{
  problemId: "conditional-statements_003",
  image: conditional_003,
  type: "multiple_choice",
  title: "문자열 비교 조건문 이해하기",
  description: `이번에는 문자와 문자를 비교한 조건문입니다. 결과를 예측해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "else Statements",
  subTopic: "String Comparison",
  difficulty: 2,
  difficultyDescription: "문자열 비교와 대소문자 구분을 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용", "분석"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "문자열 비교",
    "if-else 문의 기본 구조",
    "Python의 대소문자 구분"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Intermediate",
    "Conditional Statements",
    "String Comparison"
  ],
  programmingConcepts: ["조건문", "문자열 비교", "대소문자 구분"],
  skillsPracticed: ["코드 해석", "문자열 비교", "대소문자 구분 이해"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "문자열 비교 조건문의 실행 결과 예측하기",
    "Python에서의 대소문자 구분 이해하기"
  ],
  commonMistakes: [
    "Python의 대소문자 구분을 간과함",
    "문자열 비교 시 '==' 연산자의 정확한 동작을 이해하지 못함"
  ],
  hints: {
    beginner: [
      "Python에서 문자열 비교는 대소문자를 구분합니다.",
      "'python'과 'Python'은 다른 문자열로 취급됩니다.",
      "조건이 거짓일 때는 else 문 다음의 코드가 실행됩니다."
    ]
  },
  relatedConcepts: ["문자열 메소드", "대소문자 변환", "문자열 비교 함수"],
  learningStyle: ["개념적 학습", "분석적 사고"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 문자열 비교는 대소문자를 구분합니다."]
    }
  },
  choices: [
    { id: "A", text: "'조건이 참입니다.' 문장 출력" },
    { id: "B", text: "'조건이 거짓입니다.' 문장 출력" },
    { id: "C", text: "아무것도 출력되지 않습니다." },
    { id: "D", text: "오류가 발생합니다." }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. Python에서 문자열 비교는 대소문자를 구분합니다. 'python'과 'Python'은 다른 문자열로 취급되므로, 조건 word == 'Python'은 거짓이 됩니다. 따라서 else 문 다음의 코드가 실행되어 '대소문자가 일치하지 않습니다.'가 출력됩니다.",
  examples: [
    {
      input: `word = "python"
if word == "Python":
    print("대소문자가 정확히 일치합니다.")
else:
    print("대소문자가 일치하지 않습니다.")`,
      output: "대소문자가 일치하지 않습니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_004 // if Statements // code - 숫자 비교하기
{
  problemId: "conditional-statements_004",
  type: "code",
  title: "숫자 비교하기",
  description: `사용자로부터 하나의 정수를 입력받아, 그 숫자가 3보다 큰지 판별하는 프로그램을 작성하세요.

  - 입력받은 숫자가 3보다 크면 "크다"를 출력합니다.
  - 입력받은 숫자가 3보다 작거나 같으면 "작거나 같다"를 출력합니다.

  [입력 예시]
  5

  [출력 예시]
  크다
  
  앞서 풀었던 문제들의 코드를 떠올려보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Conditional Statements",
  subTopic: "if Statements",
  difficulty: 2,
  difficultyDescription: "조건문을 사용하여 간단한 비교를 수행하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "조건문 (if-else)",
    "사용자 입력 받기",
    "문자열을 정수로 변환하기"
  ],
  estimatedTime: "10분",
  tags: [
    "Python",
    "Beginner",
    "Conditional Statements",
    "User Input",
    "Type Conversion"
  ],
  programmingConcepts: ["조건문", "사용자 입력", "타입 변환"],
  skillsPracticed: ["조건문 작성", "사용자 입력 처리", "비교 연산"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "사용자로부터 입력을 받는 방법 이해하기",
    "문자열을 정수로 변환하는 방법 학습하기",
    "조건문을 사용하여 간단한 비교 수행하기"
  ],
  commonMistakes: [
    "입력받은 값을 정수로 변환하지 않음",
    "조건문의 구조를 잘못 작성함",
    "비교 연산자를 잘못 사용함"
  ],
  hints: {
    beginner: [
      "if-else 문을 사용하여 조건에 따라 다른 결과를 출력할 수 있습니다.",
      "비교 연산을 사용하여 조건식을 완성해보세요.",
      "들여쓰기(tab)을 사용하여 코드 블록을 구분해보세요."
    ]
  },
  relatedConcepts: ["사용자 입력", "타입 변환", "비교 연산자"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 들여쓰기는 코드 블록을 구분하는 중요한 요소입니다."]
    }
  },
  initialCode: `# 1. 사용자로부터 정수를 입력받습니다.
n = int(input())

# 2. 아래 조건식을 완성해보세요.
`,
  testCases: [
    { input: "5", expected: "크다" },
    { input: "2", expected: "작거나 같다" },
    { input: "3", expected: "작거나 같다" }
  ],
  solution: `# 1. 사용자로부터 정수를 입력받습니다.
n = int(input())

# 2. 조건문을 사용하여 3과 비교합니다.
if n > 3:
    print("크다")
else:
    print("작거나 같다")`,
  examples: [
    {
      input: "5",
      output: "크다"
    },
    {
      input: "2",
      output: "작거나 같다"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_005 // if Statements // multiple_choice - 조건문 실행 결과 예측하기
{
  problemId: "conditional-statements_005",

  type: "multiple_choice",
  image: conditional_004,
  title: "조건문 실행 결과 예측하기",
  description: `위 코드를 실행하면 어떤 결과가 출력될까요?
  
  파이썬 프로그래밍에서 들여쓰기(tab)은 코드 블록을 구분하는 중요한 요소입니다.
  다른 언어에서는 중괄호{}를 사용하여 구분하지만, 파이썬에서는 들여쓰기를 사용합니다.

  마지막 줄의 print()는 들여쓰기가 없으므로 조건문과 관계없이 항상 실행됩니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Conditional Statements",
  subTopic: "if Statements",
  difficulty: 1,
  difficultyDescription: "조건문의 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "조건문 (if-else)",
    "문자열 비교"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Conditional Statements",
    "String Comparison"
  ],
  programmingConcepts: ["조건문", "문자열 비교", "프로그램 실행 흐름"],
  skillsPracticed: ["코드 해석", "조건문 이해", "실행 결과 예측"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "조건문의 실행 흐름 이해하기",
    "문자열 비교 결과 예측하기",
    "프로그램의 전체 실행 과정 파악하기"
  ],
  commonMistakes: [
    "조건문의 실행 순서를 잘못 이해함",
    "문자열 비교 결과를 잘못 예측함",
    "프로그램의 전체 실행 흐름을 놓침"
  ],
  hints: {
    beginner: [
      "word 변수의 값이 무엇인지 확인하세요.",
      "if 문의 조건이 참인지 거짓인지 판단하세요.",
      "조건이 참일 때와 거짓일 때 어떤 코드가 실행되는지 확인하세요.",
      "마지막 print 문은 항상 실행됩니다."
    ]
  },
  relatedConcepts: ["변수 할당", "문자열", "프로그램 실행 순서"],
  learningStyle: ["개념적 학습", "시각적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 들여쓰기는 코드 블록을 구분하는 중요한 요소입니다."]
    }
  },
  choices: [
    { id: "A", text: "Y" },
    { id: "B", text: "N" },
    { id: "C", text: "Y\n프로그램 종료" },
    { id: "D", text: "N\n프로그램 종료" }
  ],
  correctAnswer: "C",
  solution: "정답은 C입니다. word 변수의 값이 'a'이므로 if 문의 조건 word == 'a'가 참이 됩니다. 따라서 'Y'가 출력됩니다. 그 후 조건문과 관계없이 마지막 줄의 print 문이 실행되어 '프로그램 종료'가 출력됩니다. 결과적으로 'Y'와 '프로그램 종료'가 순서대로 출력됩니다.",
  examples: [
    {
      input: `word = 'a'

if word == 'a':
    print("Y")
else:
    print("N")


print("프로그램 종료")`,
      output: "Y\n프로그램 종료"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_006 // if Statements // multiple_choice - 조건문과 들여쓰기 이해하기
{
  problemId: "conditional-statements_006",
  type: "multiple_choice",
  image: conditional_005,
  title: "조건문과 들여쓰기 이해하기",
  description: `다음 코드를 실행하면 어떤 결과가 출력될까요?
  조건식 판별 결과에 따라 출력되는 문장은 들여쓰기를 사용하여 구분합니다.
  참일때 문장 두개를 출력하고 싶다면, 들여쓰기를 유지하면 됩니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Conditional Statements",
  subTopic: "if Statements and Indentation",
  difficulty: 2,
  difficultyDescription: "조건문의 실행 흐름과 들여쓰기의 영향을 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Analyze",
  cognitiveProcess: ["이해", "적용", "분석"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "조건문 (if-else)",
    "들여쓰기의 중요성",
    "프로그램 실행 순서"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Intermediate",
    "Conditional Statements",
    "Indentation",
    "Control Flow"
  ],
  programmingConcepts: ["조건문", "들여쓰기", "프로그램 실행 흐름"],
  skillsPracticed: ["코드 해석", "들여쓰기 이해", "실행 결과 예측"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "조건문의 실행 흐름 이해하기",
    "들여쓰기가 코드 실행에 미치는 영향 파악하기",
    "프로그램의 전체 실행 과정 분석하기"
  ],
  commonMistakes: [
    "들여쓰기의 영향을 잘못 해석함",
    "조건문 내부와 외부의 코드 실행 순서를 혼동함",
    "프로그램의 전체 실행 흐름을 놓침"
  ],
  hints: {
    beginner: [
      "word 변수의 값이 무엇인지 확인하세요.",
      "if 문의 조건이 참인지 거짓인지 판단하세요.",
      "들여쓰기된 코드와 그렇지 않은 코드의 실행 순서를 생각해보세요."
    ],
    intermediate: [
      "조건이 참일 때 실행되는 코드 블록을 정확히 파악하세요.",
      "조건문 외부의 코드는 항상 실행된다는 점을 기억하세요."
    ]
  },
  relatedConcepts: ["변수 할당", "문자열 비교", "코드 블록"],
  learningStyle: ["개념적 학습", "분석적 사고"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 들여쓰기는 코드 블록을 구분하는 중요한 요소입니다."]
    }
  },
  choices: [
    { id: "A", text: "Y\n참\n프로그램 종료" },
    { id: "B", text: "Y\n참\n거짓\n프로그램 종료" },
    { id: "C", text: "N\n거짓\n프로그램 종료" },
    { id: "D", text: "Y\n프로그램 종료" }
  ],
  correctAnswer: "A",
  solution: "정답은 A입니다. word 변수의 값이 'a'이므로 if 문의 조건 word == 'a'가 참이 됩니다. 따라서 'Y'와 '참'이 순서대로 출력됩니다. else 블록은 실행되지 않습니다. 마지막으로, 조건문과 관계없이 '프로그램 종료'가 출력됩니다. 결과적으로 'Y', '참', '프로그램 종료'가 순서대로 출력됩니다.",
  examples: [
    {
      input: `word = 'a'

if word == 'a':
    print("Y")
    print("참")
else:
    print("N")
    print("거짓")
print("프로그램 종료")`,
      output: "Y\n참\n프로그램 종료"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_007 // if Statements // code - 문자 일치 확인하기
{
  problemId: "conditional-statements_007",
  type: "code",
  title: "문자 일치 확인하기",
  description: `사용자로부터 하나의 문자를 입력받아, 그 문자가 'a'와 정확히 일치하는지 확인하는 프로그램을 작성하세요.

  - 입력받은 문자가 'a'와 일치하면 "Y"를 출력합니다.
  - 입력받은 문자가 'a'와 일치하지 않으면 "N"을 출력합니다.

  주의: 대소문자를 구분해야 합니다. 'A'는 'a'와 일치하지 않습니다.

  [입력 예시 1]
  a

  [출력 예시 1]
  Y

  [입력 예시 2]
  b

  [출력 예시 2]
  N
  
  앞선 문제의 코드를 떠올리면서 풀어보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Conditional Statements",
  subTopic: "Character Comparison",
  difficulty: 1,
  difficultyDescription: "조건문을 사용하여 단일 문자 비교를 수행하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "조건문 (if-else)",
    "사용자 입력 받기",
    "문자 비교"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Conditional Statements",
    "Character Comparison",
    "User Input"
  ],
  programmingConcepts: ["조건문", "사용자 입력", "문자 비교"],
  skillsPracticed: ["조건문 작성", "사용자 입력 처리", "문자 비교"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "사용자로부터 입력을 받는 방법 이해하기",
    "단일 문자 비교 방법 학습하기",
    "조건문을 사용하여 문자 일치 여부 확인하기"
  ],
  commonMistakes: [
    "대소문자를 구분하지 않고 비교함",
    "조건문의 구조를 잘못 작성함",
    "문자 비교 연산자를 잘못 사용함"
  ],
  hints: {
    beginner: [
      "input() 함수를 사용하여 사용자 입력을 받을 수 있습니다.",
      "if-else 문을 사용하여 조건에 따라 다른 결과를 출력할 수 있습니다.",
      "같은 경우를 판단할때는 == 연산자를 사용합니다."
    ],
    intermediate: [
      "Python에서 문자 비교는 대소문자를 구분합니다.",
      "입력받은 문자열의 첫 번째 문자만 비교하려면 인덱싱을 사용할 수 있습니다."
    ]
  },
  relatedConcepts: ["사용자 입력", "문자열 인덱싱", "대소문자 구분"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 문자 비교는 대소문자를 구분합니다."]
    }
  },
  initialCode: `# 사용자로부터 문자를 입력받습니다.
word = input()

# 여기에 코드를 작성하세요.
`,
  testCases: [
    { input: "a", expected: "Y" },
    { input: "A", expected: "N" },
    { input: "b", expected: "N" },
    { input: "1", expected: "N" }
  ],
  solution: `# 사용자로부터 문자를 입력받습니다.
word = input()

# 조건문을 사용하여 입력받은 문자가 'a'와 정확히 일치하는지 확인합니다.
if word == 'a':
    print("Y")
else:
    print("N")`,
  examples: [
    {
      input: "a",
      output: "Y"
    },
    {
      input: "b",
      output: "N"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_008 // Nested Conditionals // concept - 중첩 조건문 이해하기
{
  problemId: "conditional-statements_008",
  type: "concept",
  title: "중첩 조건문 이해하기",
  image: conditional_006,
  description: `중첩 조건문은 조건문 안에 또 다른 조건문이 있는 구조를 말합니다. 하지만 너무 많은 중첩은 코드의 가독성을 떨어뜨릴 수 있으므로 주의해야 합니다.

  중첩 조건문은 다음과 같은 상황에서 유용합니다:
  1. 여러 조건을 순차적으로 검사해야 할 때
  2. 조건에 따라 다른 조건을 추가로 검사해야 할 때
  3. 복잡한 의사 결정 과정을 구현해야 할 때

  파이썬에서는 들여쓰기로 코드 블록을 구분하기 때문에, 올바른 들여쓰기가 매우 중요합니다.`,
  subject: "Computer Science",
  topic: "Conditional Statements",
  subTopic: "Nested Conditionals",
  difficulty: 2,
  tags: ["Python", "Intermediate", "Conditional Statements", "Control Flow"],
  relatedConcepts: ["if Statements", "else Statements", "elif Statements", "Indentation"],
  learningObjectives: [
    "중첩 조건문의 개념 이해하기",
    "중첩 조건문의 구조와 실행 흐름 파악하기",
    "중첩 조건문의 사용 사례 인식하기"
  ],
  commonMistakes: [
    "들여쓰기를 잘못하여 의도하지 않은 실행 흐름이 발생함",
    "너무 많은 중첩으로 코드의 가독성이 떨어짐",
    "중첩 조건문 대신 더 간단한 구조를 사용할 수 있는 경우를 놓침"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_009 // Nested Conditionals // concept - 성적처리 프로그램 이해하기
{
  problemId: "conditional-statements_009",
  type: "concept",
  title: "성적처리 프로그램 이해하기",
  image: conditional_007,
  description: `다음은 학생의 점수를 입력받아 성적을 출력하는 프로그램입니다. 이 프로그램은 중첩 조건문을 사용하여 구현되었습니다.

  이 프로그램의 주요 특징은 다음과 같습니다:

  1. 사용자로부터 점수를 입력받아 정수로 변환합니다.
  2. 중첩 조건문을 사용하여 점수에 따른 성적을 결정합니다.
  3. 90점 이상은 A, 80점 이상은 B, 70점 이상은 C, 60점 이상은 D, 그 미만은 F입니다.
  4. 최종적으로 결정된 성적을 출력합니다.

  이 프로그램은 중첩 조건문을 사용했지만, 가독성이 떨어질 수 있습니다.`,
  subject: "Computer Science",
  topic: "Conditional Statements",
  subTopic: "Nested Conditionals",
  difficulty: 2,
  tags: ["Python", "Intermediate", "Conditional Statements", "Grade Processing"],
  relatedConcepts: ["if Statements", "elif Statements", "else Statements", "User Input", "String Formatting"],
  learningObjectives: [
    "중첩 조건문을 사용한 복잡한 로직 구현 방법 이해하기",
    "사용자 입력을 처리하는 방법 학습하기",
    "조건에 따른 변수 할당 방식 파악하기"
  ],
  commonMistakes: [
    "조건의 순서를 잘못 배치하여 의도하지 않은 결과가 나옴",
    "중첩 조건문의 들여쓰기를 잘못하여 로직 오류가 발생함",
    "입력값의 유효성 검사를 하지 않아 예외 상황을 처리하지 못함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// conditional-statements_010 // elif Statements // concept - 성적처리 프로그램 개선하기
{
  problemId: "conditional-statements_010",
  type: "concept",
  title: "성적처리 프로그램 개선하기",
  image: conditional_008,
  description: `앞서 본 성적처리 프로그램을 elif 문을 사용하여 개선해 보았습니다. 이 개선된 프로그램의 주요 특징은 다음과 같습니다:

  1. 사용자로부터 점수를 입력받아 정수로 변환하는 부분은 동일합니다.
  2. 중첩 조건문 대신 if-elif-else 구조를 사용하여 점수에 따른 성적을 결정합니다.
  3. 점수 범위는 이전과 동일합니다: 90점 이상 A, 80점 이상 B, 70점 이상 C, 60점 이상 D, 그 미만 F.
  4. 결정된 성적을 출력하는 부분도 동일합니다.

  이 개선된 버전의 장점은 다음과 같습니다:
  - 코드의 가독성이 크게 향상되었습니다.
  - 코드의 구조가 더 명확해져 유지보수가 쉬워졌습니다.
  - 새로운 조건을 추가하거나 기존 조건을 수정하기가 더 쉬워졌습니다.

  조건문은 프로그래밍에서 아주 중요한 개념입니다.
  다양한 기초문제들을 풀어보면서 코드가 어떻게 실행되는지 파악하는 수준까지 끌어올려보세요.`,
  subject: "Computer Science",
  topic: "Conditional Statements",
  subTopic: "elif Statements",
  difficulty: 2,
  tags: ["Python", "Intermediate", "Conditional Statements", "Grade Processing", "Code Improvement"],
  relatedConcepts: ["if Statements", "elif Statements", "else Statements", "User Input", "String Formatting"],
  learningObjectives: [
    "elif 문을 사용한 조건문 구조 개선 방법 이해하기",
    "중첩 조건문과 if-elif-else 구조의 차이점 파악하기",
    "코드 가독성과 효율성 향상 방법 학습하기"
  ],
  commonMistakes: [
    "elif 문의 순서를 잘못 배치하여 의도하지 않은 결과가 나옴",
    "불필요한 조건 검사를 남겨두어 효율성이 떨어짐",
    "마지막 else 문을 누락하여 예외 상황을 처리하지 못함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-15",
  lastUpdated: "2024-10-15",
  versionNumber: 1.0
},

// id: "loops",
// title: "반복문",
// topics: ["for Loops", "while Loops", "break and continue Statements"]
////////////////////////////////
// loops_001 // Loops // concept - 반복문의 기본 개념
{
  problemId: "loops_001",
  type: "concept",
  title: "반복문의 기본 개념",
  image: null,
  description: `'Hello' 문자열을 5번 출력하는 코드를 작성해보세요.
  귀찮지만 직접 입력하면서 코드를 작성할 수 있을겁니다.
  
  이번에는 30번 작성해보세요.
  복사/붙여넣기를 사용하면 이 또한 가능할겁니다.
  
  이번에는 109283번 작성해보세요.
  대량의 반복 작업은 이제 사람이 직접하면 안됩니다.`,
  subject: "Computer Science",
  topic: "for Loops",
  subTopic: "Basic Concepts",
  difficulty: 1,
  tags: ["Python", "Beginner", "Loops", "Control Flow"],
  relatedConcepts: ["for Loops", "while Loops", "Iteration"],
  learningObjectives: [
    "반복문의 기본 개념 이해하기",
    "반복문이 왜 필요한지 알기",
    "반복문을 잘 사용하는 것의 중요성 알기"
  ],
  commonMistakes: [
    "꼭 필요하지 않을 때도 반복문을 사용함",
    "반복문이 멈추지 않고 계속 돌아가게 만듦",
    "반복문을 왜 사용하는지 모르고 그냥 사용함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.1
},

// loops_002 // for Loops // code - range() 함수로 "Hello" 출력하기
{
  problemId: "loops_002",
  type: "code",
  title: "range() 함수로 반복하기",
  image: null,
  description: `반복문의 종류는 for문과 while문이 있습니다.
  
  이번에는 for문을 사용해보겠습니다.
  'Hello' 문자열을 5번 반복해서 출력해보겠습니다.

  아래 코드를 실행시켜보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "range() function",
  difficulty: 1,
  difficultyDescription: "range() 함수를 사용한 기본적인 반복문 예제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "for 반복문의 기본 구조",
    "range() 함수의 기본 사용법"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Beginner",
    "Loops",
    "range() function"
  ],
  programmingConcepts: ["반복문", "range() 함수"],
  skillsPracticed: ["코드 실행", "결과 해석"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "range() 함수의 기본 사용법 이해하기",
    "for 반복문과 range() 함수의 조합 이해하기",
    "start, end, step 매개변수의 역할 파악하기"
  ],
  commonMistakes: [
    "range() 함수의 end 값이 포함되지 않는다는 점을 간과함",
    "step 값의 영향을 잘못 이해함"
  ],
  hints: {
    beginner: [
      "range(0, 5, 1)는 0부터 4까지의 숫자를 생성합니다.",
      "for 반복문은 range() 함수가 생성한 각 숫자에 대해 한 번씩 실행됩니다."
    ]
  },
  relatedConcepts: ["슬라이싱", "인덱싱", "시퀀스 타입"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 range() 함수는 start, stop, step 매개변수를 가집니다."]
    }
  },
  initialCode: `# "Hello"를 5번 출력하는 코드
for i in range(0, 5, 1):
    print("Hello")`,
  testCases: [
    { input: "", expected: `Hello
Hello
Hello
Hello
Hello` }
  ],
  solution: "제공된 코드가 이미 완성된 솔루션입니다.",
  examples: [
    {
      input: "",
      output: "Hello\nHello\nHello\nHello\nHello"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.3
},

// loops_003 // for Loops // code - range() 함수와 변수 i 활용하기
{
  problemId: "loops_003",
  type: "code",
  title: "범위만큼 반복 실행👻",
  image: loops_001,
  description: `앞선 코드를 조금 더 자세히 살펴보겠습니다.
  단순히 5번 반복한다는 사실보다 중요한 개념이 숨어있습니다.
  
  바로, i라는 변수가 range() 함수 안에 있는 숫자들을 활용한다는 개념입니다.
  우리 앞서 슬라이싱을 배울때 [start:end:step] 형태를 배웠었죠?
  사용법은 똑같습니다.
  
  아래 코드를 실행해보고 i의 값이 어떻게 변하는지 확인해보세요.
  i는 0, 1, 2, 3, 4를 순서대로 출력합니다.
  숫자가 5개 출력되기 때문에, 5번 반복한다는 사실을 인지해야합니다.

  추후 인덱싱을 반복문과 함께 사용하게되면 이러한 개념이 굉장히 중요해집니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "range() function",
  difficulty: 2,
  difficultyDescription: "range() 함수와 반복 변수 i의 관계를 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "for 반복문의 기본 구조",
    "range() 함수의 기본 사용법"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "range() function"
  ],
  programmingConcepts: ["반복문", "range() 함수", "변수 활용"],
  skillsPracticed: ["코드 실행", "결과 해석"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "range() 함수와 반복 변수 i의 관계 이해하기",
    "반복문 내에서 변수 i의 변화 관찰하기",
    "range() 함수의 동작 원리 파악하기"
  ],
  commonMistakes: [
    "range() 함수의 결과값을 잘못 이해함",
    "반복 변수 i의 역할을 간과함"
  ],
  hints: {
    beginner: [
      "range(5)는 0부터 4까지의 숫자를 생성합니다.",
      "각 반복에서 i는 range()가 생성한 숫자 중 하나의 값을 가집니다."
    ]
  },
  relatedConcepts: ["변수", "시퀀스 타입", "반복"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 range() 함수는 시퀀스를 생성합니다."]
    }
  },
  initialCode: `# i의 값을 출력하는 코드
for i in range(0, 5, 1):
    print(i)`,
  testCases: [
    { input: "", expected: `0
1
2
3
4` }
  ],
  solution: "제공된 코드가 이미 완성된 솔루션입니다.",
  examples: [
    {
      input: "",
      output: "0\n1\n2\n3\n4"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.1
},

// loops_004 // for Loops // multiple_choice - for 반복문과 range() 함수의 진정한 의미
{
  problemId: "loops_004",
  type: "multiple_choice",
  image: loops_001,
  title: "for 반복문과 range() 함수의 진정한 의미",
  description: `이 코드에서 가장 중요한 점은 무엇일까요?`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "range() function",
  difficulty: 2,
  difficultyDescription: "for 반복문과 range() 함수의 깊은 의미를 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "for 반복문",
    "range() 함수의 기본 사용법"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "range() function"
  ],
  programmingConcepts: ["반복문", "range() 함수", "변수 활용"],
  skillsPracticed: ["코드 해석", "개념 이해"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "for 반복문과 range() 함수의 깊은 의미 이해하기",
    "반복 변수 i의 역할 파악하기",
    "range() 함수가 생성하는 시퀀스의 의미 이해하기"
  ],
  commonMistakes: [
    "단순히 반복 횟수로만 이해함",
    "range() 함수가 생성하는 시퀀스의 의미를 간과함",
    "반복 변수 i의 활용 가능성을 인식하지 못함"
  ],
  hints: {
    beginner: [
      "range(0, 5, 1)이 생성하는 숫자들을 생각해보세요.",
      "i 변수가 각 반복에서 어떤 값을 가지는지 고려해보세요."
    ],
    intermediate: [
      "i 변수를 활용하여 할 수 있는 일들을 상상해보세요.",
      "단순한 반복 외에 이 구조가 제공하는 추가적인 기능을 생각해보세요."
    ]
  },
  relatedConcepts: ["시퀀스", "이터레이션", "변수 스코프"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 range() 함수는 시퀀스를 생성합니다."]
    }
  },
  choices: [
    { id: "A", text: "코드가 정확히 5번 반복된다" },
    { id: "B", text: "i 변수가 0, 1, 2, 3, 4의 값을 순서대로 가질 수 있다" }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. 이 코드의 가장 중요한 점은 i 변수가 0, 1, 2, 3, 4의 값을 순서대로 가질 수 있다는 것입니다. 이는 단순히 5번 반복하는 것 이상의 의미를 가집니다. i 변수를 활용하면 각 반복에서 다른 작업을 수행하거나, 인덱스로 사용하거나, 계산에 활용하는 등 다양한 작업을 할 수 있습니다. 이러한 특성은 for 반복문과 range() 함수의 강력한 기능 중 하나입니다.",
  examples: [
    {
      input: `for i in range(0, 5, 1):
    print(i)`,
      output: "0\n1\n2\n3\n4"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_005 // for Loops // multiple_choice - range() 함수 사용법
{
  problemId: "loops_005",
  type: "multiple_choice",
  image: loops_002,
  title: "range() 함수 사용법",
  description: `다음 세 가지 for 반복문은 모두 동일한 결과를 가집니다.
  
  step의 1은 default값이므로 생략 가능합니다.
  start의 0은 default값이므로 생략 가능합니다.
  
  range(start, end, step) 함수에서는 start, end, step값이 어떻게 설정되어 있는지 정확하게 알아야합니다.
  
  아래 보기중 옳은 것을 고르세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "range() function",
  difficulty: 2,
  difficultyDescription: "range() 함수의 매개변수 생략 규칙을 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "for 반복문",
    "range() 함수의 기본 사용법"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "range() function"
  ],
  programmingConcepts: ["반복문", "range() 함수", "함수 매개변수"],
  skillsPracticed: ["코드 해석", "개념 이해"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "range() 함수의 매개변수 구조 이해하기",
    "매개변수 생략 시 적용되는 기본값 알기",
    "range() 함수의 다양한 사용법 익히기"
  ],
  commonMistakes: [
    "매개변수 생략 시 적용되는 기본값을 잘못 이해함",
    "start, stop, step의 의미를 혼동함",
    "매개변수 생략 규칙을 잘못 이해함"
  ],
  hints: {
    beginner: [
      "range() 함수는 start, end, step 값을 가집니다.",
      "start의 기본값은 0이고, step의 기본값은 1입니다.",
      "경우에 따라 start, step 값을 생략할 수 있습니다.",
      "start, end, step 값에 따라 반복문의 반복 횟수가 달라집니다."
    ],
  },
  relatedConcepts: ["함수 매개변수", "기본값", "시퀀스 생성"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 range() 함수는 start, stop, step 매개변수를 가집니다."]
    }
  },
  choices: [
    { id: "A", text: "range() 함수는 무조건 start, end, step을 다 써야한다." },
    { id: "B", text: "for i in range(1, 5):는 5번 반복시키는 반복문이다." },
    { id: "C", text: "range() 함수의 start 값은 절대 생략할 수 없다." },
    { id: "D", text: "for i in range(1, 3, 1):는 2번 반복한다." }
  ],
  correctAnswer: "D",
  solution: "정답은 D입니다. range() 함수는 매개변수를 생략할 수 있으며, 생략 시 기본값이 적용됩니다. start의 기본값은 0이고, step의 기본값은 1입니다. 따라서 range(5)는 range(0, 5, 1)과 동일한 결과를 만듭니다. 이러한 특성 때문에 위의 세 가지 for 반복문이 모두 동일한 결과를 만들 수 있는 것입니다.",
  examples: [
    {
      input: "range(5)",
      output: "0, 1, 2, 3, 4"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_006 // for Loops // code - 문자열 인덱싱과 반복문
{
  problemId: "loops_006",
  type: "code",
  title: "문자열 인덱싱을 반복문으로 구현하기",
  image: loops_003,
  description: `앞서 우리는 문자열의 각 문자를 인덱싱으로 접근하는 방법을 배웠습니다. 
  이제 개발자의 눈으로 코드를 바라보는 방법을 배워보겠습니다.
  
  3번째 줄의 word[0]부터 7번째 줄의 word[4]까지 값이 1씩 증가하고 있습니다.
  start, end, step값이 존재합니다.
  이런 경우에는 반복문으로 변경할 수 있습니다.
  
  아래 코드를 그대로 실행시켜보세요.
  반복문이 실행되면서 print(word[i])는 위의 코드와 완벽하게 똑같이 출력됩니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "String Indexing",
  difficulty: 2,
  difficultyDescription: "문자열 인덱싱과 반복문을 결합하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "문자열 인덱싱",
    "for 반복문",
    "range() 함수의 사용법"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "String Indexing"
  ],
  programmingConcepts: ["반복문", "문자열 인덱싱", "range() 함수"],
  skillsPracticed: ["코드 실행", "결과 해석"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "반복문을 사용한 문자열 순회 방법 이해하기",
    "range() 함수와 len() 함수의 조합 활용하기",
    "인덱싱과 반복문의 관계 파악하기"
  ],
  commonMistakes: [
    "range() 함수의 인자를 잘못 설정함",
    "문자열 길이와 인덱스의 관계를 혼동함",
    "반복문 내에서 인덱싱을 잘못 사용함"
  ],
  hints: {
    beginner: [
      "range() 함수는 0부터 시작하여 지정된 숫자 직전까지의 숫자를 생성합니다.",
      "문자열의 길이는 len() 함수로 구할 수 있습니다."
    ],
    intermediate: [
      "range(len(word))는 0부터 word의 길이 - 1까지의 숫자를 생성합니다.",
      "이 숫자들은 word의 유효한 인덱스와 일치합니다."
    ]
  },
  relatedConcepts: ["시퀀스 타입", "이터레이션", "문자열 메소드"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 문자열은 불변(immutable) 시퀀스 타입입니다."]
    }
  },
  initialCode: `word = "Hello"

for i in range(0, 5, 1):
    print(word[i])`,
  testCases: [
    { input: "", expected: `H
e
l
l
o` }
  ],
  solution: "제공된 코드가 이미 완성된 솔루션입니다.",
  examples: [
    {
      input: "",
      output: "H\ne\nl\nl\no"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_007 // for Loops // code - 입력받은 문자열 순회하기
{
  problemId: "loops_007",
  type: "code",
  title: "반복문에서 len() 함수 사용하기",
  image: null,
  description: `만약 사용자가 매번 입력하는 문자의 길이가 달라진다면 우리는 매번 그 문자의 길이에 맞춰서 end 값을 변경해줘야 합니다.
  이건 너무 비효율적입니다. 그래서 사용자가 입력한 문자의 길이를 자동으로 구하는 방법을 사용해보려고 합니다.
  
  놀랍게도 우리가 배웠던 len() 함수를 사용하면 됩니다.
  아래 코드를 유심히 읽어보고, 그대로 실행시켜보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "String Iteration",
  difficulty: 2,
  difficultyDescription: "사용자 입력, 문자열 길이 계산, 반복문을 결합하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "사용자 입력 받기",
    "문자열 인덱싱",
    "for 반복문",
    "range() 함수와 len() 함수의 사용법"
  ],
  estimatedTime: "10분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "String Iteration",
    "User Input"
  ],
  programmingConcepts: ["반복문", "문자열 인덱싱", "사용자 입력", "함수 활용"],
  skillsPracticed: ["코드 작성", "문자열 처리", "반복문 구현"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "사용자로부터 입력받은 문자열 처리하기",
    "문자열 길이를 이용한 반복문 구현하기",
    "문자열 인덱싱과 반복문 결합하기"
  ],
  commonMistakes: [
    "range() 함수의 인자를 잘못 설정함",
    "문자열 길이와 인덱스의 관계를 혼동함",
    "반복문 내에서 인덱싱을 잘못 사용함"
  ],
  hints: {
    beginner: [
      "input() 함수를 사용하여 사용자로부터 문자열을 입력받을 수 있습니다.",
      "len() 함수는 문자열의 길이를 반환합니다.",
      "range(len(word))는 0부터 word의 길이 - 1까지의 숫자를 생성합니다."
    ],
    intermediate: [
      "f-string을 사용하면 문자열 포매팅을 쉽게 할 수 있습니다.",
      "print() 함수 내에서 여러 값을 쉼표로 구분하여 출력할 수 있습니다."
    ]
  },
  relatedConcepts: ["사용자 입력", "문자열 메소드", "문자열 포매팅"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 문자열은 불변(immutable) 시퀀스 타입입니다."]
    }
  },
  initialCode: `# 사용자로부터 문자열을 입력받습니다.
word = input()

# 입력받은 문자열 길이를 len() 함수를 사용하여 구합니다.
# start 0과, step 1은 기본값이므로 생략해보겠습니다.
for i in range(len(word)):
    print(word[i])`,
  testCases: [
    { input: "Hello", expected: `H
e
l
l
o` },
    { input: "Python", expected: `P
y
t
h
o
n` }
  ],
  solution: `# 사용자로부터 문자열을 입력받습니다.
word = input("문자열을 입력하세요: ")

# 입력받은 문자열의 각 문자를 순회하며 출력합니다.
for i in range(len(word)):
    print(f"{i}: {word[i]}")`,
  examples: [
    {
      input: "Hello",
      output: `H
e
l
l
o`
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_008 // for Loops // code - 1부터 10까지 출력하기
{
  problemId: "loops_008",
  type: "code",
  title: "1부터 10까지 출력하기",
  image: null,
  description: `1부터 10까지의 숫자를 순서대로 출력하는 프로그램을 작성해보세요.
  
  for 반복문과 range() 함수를 사용하여 이 작업을 수행할 수 있습니다.
  range() 함수의 start, end, step 값을 적절히 설정하여 1부터 10까지의 숫자를 생성하도록 해야 합니다.

  아래 코드를 완성하여 실행해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "for Loops",
  subTopic: "range() function",
  difficulty: 1,
  difficultyDescription: "for 반복문과 range() 함수의 기본 사용법을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "이해"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "for 반복문",
    "range() 함수의 기본 사용법"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Loops",
    "range() function"
  ],
  programmingConcepts: ["반복문", "range() 함수", "출력"],
  skillsPracticed: ["코드 작성", "반복문 구현", "함수 매개변수 설정"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "for 반복문 사용법 익히기",
    "range() 함수의 매개변수 이해하기",
    "순차적인 숫자 출력 구현하기"
  ],
  commonMistakes: [
    "range() 함수의 시작 값을 0으로 설정함",
    "range() 함수의 종료 값을 잘못 설정함",
    "print() 함수 사용 시 줄바꿈을 고려하지 않음"
  ],
  hints: {
    beginner: [
      "range() 함수는 (시작, 끝, 단계) 형태로 사용할 수 있습니다.",
      "range(1, 11)은 1부터 10까지의 숫자를 생성합니다.",
      "print() 함수는 기본적으로 출력 후 줄바꿈을 합니다."
    ],
    intermediate: [
      "range() 함수의 끝 값은 생성되는 숫자에 포함되지 않습니다.",
      "for 문의 변수 이름은 관례적으로 i를 많이 사용하지만, 다른 이름을 사용해도 됩니다."
    ]
  },
  relatedConcepts: ["반복문", "시퀀스 생성", "함수 매개변수"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 for 문은 in 키워드를 사용합니다."]
    }
  },
  initialCode: `# 1부터 10까지 출력하는 코드를 완성시켜보세요.
for i in range():
  print()
`,
  testCases: [
    { input: "", expected: `1
2
3
4
5
6
7
8
9
10` }
  ],
  solution: `# 1부터 10까지 출력하는 코드를 작성하세요.
for i in range(1, 11):
    print(i)`,
  examples: [
    {
      input: "",
      output: `1
2
3
4
5
6
7
8
9
10`
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_009 // while Loops // code - while문으로 1부터 10까지 출력하기
{
  problemId: "loops_009",
  type: "code",
  title: "while문으로 1부터 10까지 출력하기",
  image: null,
  description: `이전 문제에서는 for문을 사용하여 숫자를 출력했습니다.
  이번에는 while문을 사용해서 똑같은 결과를 출력해보겠습니다.
  
  시작값을 설정하고, 조건식을 판별하면서 값이 증가한다는건 앞선 코드와 동일합니다.
  코드를 실행시켜 결과를 확인해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "while Loops",
  subTopic: "Basic while loop",
  difficulty: 2,
  difficultyDescription: "while 반복문의 기본 사용법을 다루는 초중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "이해"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "while 반복문",
    "변수 사용법"
  ],
  estimatedTime: "7분",
  tags: [
    "Python",
    "Beginner",
    "Loops",
    "while loop"
  ],
  programmingConcepts: ["반복문", "변수", "조건문", "증가 연산"],
  skillsPracticed: ["코드 작성", "반복문 구현", "변수 조작"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "while 반복문 사용법 익히기",
    "반복 조건 설정하기",
    "변수를 사용하여 반복 횟수 추적하기"
  ],
  commonMistakes: [
    "무한 루프에 빠짐",
    "변수 증가를 잊음",
    "조건을 잘못 설정하여 원하는 횟수만큼 반복하지 않음"
  ],
  hints: {
    beginner: [
      "num 변수를 1로 초기화하세요.",
      "while 문의 조건은 num이 10 이하일 때까지 반복해야 합니다.",
      "반복문 내에서 num을 출력한 후, num을 1 증가시키세요."
    ],
    intermediate: [
      "while num <= 10: 형태로 조건을 설정할 수 있습니다.",
      "num += 1을 사용하여 num을 증가시킬 수 있습니다."
    ]
  },
  relatedConcepts: ["for 반복문", "조건문", "변수 스코프"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 while 문은 조건이 참인 동안 계속 실행됩니다."]
    }
  },
  initialCode: `# 1부터 10까지 출력하는 코드를 while문으로 구현해봤습니다.

num = 1 # 시작값 설정
while num <= 10: # 조건식 설정
    print(num)
    num = num + 1 # 증감식 설정`,
  testCases: [
    { input: "", expected: `1
2
3
4
5
6
7
8
9
10` }
  ],
  solution: `# 1부터 10까지 출력하는 코드를 while문을 사용하여 작성하세요.
num = 1
while num <= 10:
    print(num)
    num = num + 1`,
  examples: [
    {
      input: "",
      output: `1
2
3
4
5
6
7
8
9
10`
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_010 // while Loops // concept - 무한 루프 이해하기
{
  problemId: "loops_010",
  type: "concept",
  title: "무한 루프 이해하기",
  image: loops_004,
  description: `while문의 조건식을 True로 설정하면, 무한 루프가 됩니다.
  조건식이 항상 참이라는 의미를 가지기 때문입니다.
  위 코드는 무한대로 "Hello"를 출력합니다.
  
  하지만 위 코드처럼 무한루프를 실행하면, 더 이상 코드를 제어할 수 없습니다.
  
  프로그래밍을 할때에는 코드의 흐름을 제어하기 위한 다양한 방법이 존재합니다.
  대표적으로 break, continue 등을 사용할 수 있습니다.
  다음 문제에서 break, continue 사용법을 확인해보세요.`,
  subject: "Computer Science",
  topic: "while Loops",
  subTopic: "Infinite Loops",
  difficulty: 2,
  tags: ["Python", "Intermediate", "Loops", "Infinite Loop", "Debugging"],
  relatedConcepts: ["while Loops", "Loop Control", "Debugging Techniques"],
  learningObjectives: [
    "무한 루프의 개념 이해하기",
    "무한 루프의 발생 원인 파악하기",
    "무한 루프 방지 방법 학습하기"
  ],
  commonMistakes: [
    "루프의 종료 조건을 설정하지 않음",
    "루프 내에서 조건을 변경하는 코드를 누락함",
    "무한 루프의 의도적 사용과 오류를 구분하지 못함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_011 // while Loops // code - break 문으로 무한 루프 종료하기
{
  problemId: "loops_011",
  type: "code",
  title: "코드 흐름 제어하기-break",
  image: null,
  description: `break가 실행되면 반복문을 곧바로 탈출합니다.
  
  사용자로부터 계속해서 문자열을 입력받다가 'exit'가 입력되면 프로그램을 종료합니다.

  - while True를 사용하여 무한 루프를 만듭니다.
  - 사용자로부터 input() 함수를 사용하여 문자열을 입력받습니다.
  - 입력받은 문자열이 'exit'이면 break 문을 사용하여 루프를 종료합니다.
  - 'exit'가 아니면 입력받은 문자열을 그대로 출력합니다.

  아래 코드를 꼼꼼하게 살펴보고, 그대로 실행하여 결과를 확인해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "while Loops",
  subTopic: "break statement",
  difficulty: 2,
  difficultyDescription: "무한 루프와 break 문을 이해하고 활용하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "while 반복문",
    "break 문",
    "조건문"
  ],
  estimatedTime: "10분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "break statement",
    "User Input"
  ],
  programmingConcepts: ["무한 루프", "break 문", "사용자 입력", "조건문"],
  skillsPracticed: ["무한 루프 구현", "사용자 입력 처리", "break 문 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "무한 루프의 개념 이해하기",
    "break 문을 사용하여 루프 종료하기",
    "사용자 입력을 기반으로 프로그램 흐름 제어하기"
  ],
  commonMistakes: [
    "무한 루프에서 빠져나오는 조건을 설정하지 않음",
    "break 문의 위치를 잘못 설정함",
    "사용자 입력을 적절히 처리하지 않음"
  ],
  hints: {
    beginner: [
      "while True를 사용하여 무한 루프를 만들 수 있습니다.",
      "input() 함수를 사용하여 사용자로부터 입력을 받을 수 있습니다.",
      "if 문을 사용하여 입력값이 'exit'인지 확인할 수 있습니다."
    ],
    intermediate: [
      "break 문은 가장 가까운 루프를 즉시 종료합니다.",
      "루프 종료 후 추가 메시지를 출력하려면 루프 밖에 코드를 작성하세요."
    ]
  },
  relatedConcepts: ["while 루프", "조건문", "사용자 입력 처리"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 break 문은 현재 루프를 즉시 종료합니다."]
    }
  },
  initialCode: `# 무한 루프를 생성합니다.
while True:
    # 사용자로부터 입력을 받습니다.
    word = input()
    
    # 입력이 'exit'이면 루프를 종료합니다.
    if word == 'exit':
        break
    
    # 'exit'가 아니면 입력받은 문자열을 출력합니다.
    print(word)

# 루프가 종료된 후 메시지를 출력합니다.
print("프로그램을 종료합니다.")`,
  testCases: [
    { 
      input: "Hello\nPython\nexit", 
      expected: `Hello
Python
프로그램을 종료합니다.`
    },
    { 
      input: "Test\nexit", 
      expected: `Test
프로그램을 종료합니다.`
    }
  ],
  solution: "제공된 코드가 이미 완성된 솔루션입니다.",
  examples: [
    {
      input: "Hello\nPython\nexit",
      output: `Hello
Python
프로그램을 종료합니다.`
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.0
},

// loops_012 // while Loops // code - continue 문으로 특정 조건 건너뛰기
{
  problemId: "loops_012",
  type: "code",
  title: "코드 흐름 제어하기-continue",
  image: null,
  description: `continue 문은 현재 반복을 즉시 종료하고 다음 반복으로 넘어갑니다.
  
  1부터 10까지의 숫자 중 짝수만 출력하는 프로그램을 작성해보았습니다.

  - while 문을 사용하여 1부터 10까지 반복합니다.
  - 현재 숫자가 홀수인 경우 continue를 사용하여 다음 반복으로 넘어갑니다.
  - 짝수인 경우에만 해당 숫자를 출력합니다.

  아래 코드를 꼼꼼하게 살펴보고, 그대로 실행하여 결과를 확인해보세요.
  이번 코드는 난이도를 조금 올렸습니다. 어떻게 실행되는지 최대한 자세히 살펴보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "while Loops",
  subTopic: "continue statement",
  difficulty: 2,
  difficultyDescription: "while 루프와 continue 문을 이해하고 활용하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "while 반복문",
    "continue 문",
    "조건문",
    "나머지 연산자(%)"
  ],
  estimatedTime: "10분",
  tags: [
    "Python",
    "Intermediate",
    "Loops",
    "continue statement",
    "Conditional Logic"
  ],
  programmingConcepts: ["while 루프", "continue 문", "조건문", "나머지 연산"],
  skillsPracticed: ["조건에 따른 코드 실행 제어", "continue 문 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "continue 문의 개념 이해하기",
    "continue 문을 사용하여 특정 조건에서 코드 실행 건너뛰기",
    "while 루프와 조건문을 조합하여 문제 해결하기"
  ],
  commonMistakes: [
    "continue 문의 위치를 잘못 설정함",
    "조건문을 잘못 작성하여 원하는 결과를 얻지 못함",
    "무한 루프에 빠짐"
  ],
  hints: {
    beginner: [
      "while 문을 사용하여 1부터 10까지 반복합니다.",
      "숫자가 홀수인지 확인하기 위해 % 연산자를 사용합니다.",
      "홀수일 때 continue를 사용하여 다음 반복으로 넘어갑니다."
    ],
    intermediate: [
      "continue 문 이후의 코드는 실행되지 않습니다.",
      "루프 변수를 증가시키는 위치에 주의하세요."
    ]
  },
  relatedConcepts: ["while 루프", "조건문", "나머지 연산"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 continue 문은 현재 반복을 즉시 종료하고 다음 반복으로 넘어갑니다."]
    }
  },
  initialCode: `# 1부터 10까지의 짝수만 출력하는 코드입니다.
num = 1
while num <= 10:
    if num % 2 != 0:  # 홀수인 경우
        num += 1
        continue
    print(num)  # 짝수인 경우 출력
    num += 1  # num을 1 증가시킵니다.`,
  testCases: [
    { input: "", expected: `2
4
6
8
10` }
  ],
  solution: "제공된 코드가 이미 완성된 솔루션입니다.",
  examples: [
    {
      input: "",
      output: `2
4
6
8
10`
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-16",
  lastUpdated: "2024-10-16",
  versionNumber: 1.1
},

//id: "python-data-structures-beginner",
//title: "데이터를 저장하는 또 다른 방법",
//topics: ["List Operations", "Tuple Characteristics", "Dictionary Usage", "split"]
////////////////////////////////

// python-data-structures-beginner_001 // List Operations // concept - 리스트의 필요성과 기본 개념
{
  problemId: "python-data-structures-beginner_001",
  type: "concept",
  title: "리스트의 필요성과 기본 개념",
  image: python_data_beginner_001,
  description: `자, 이런 경우 우리는 몇 개의 변수를 만들어야 할까요? 90개의 변수를 관리하는 것은 비효율적이고 오류가 발생하기 쉽습니다.
  
  이런 경우, 리스트(List)를 사용하면 데이터를 훨씬 효율적으로 저장하고 관리할 수 있습니다. 리스트(List)는 여러 개의 데이터를 순서대로 저장할 수 있는 자료구조입니다.

  math_scores = [90, 85, ..., 89]     # 30개의 수학 점수
  english_scores = [85, 78, ..., 91]  # 30개의 영어 점수
  science_scores = [88, 92, ..., 94]  # 30개의 과학 점수

  이제 본격적으로 리스트에 대해서 알아보겠습니다.`,
  subject: "Computer Science",
  topic: "List Operations",
  subTopic: "Basic Concepts",
  difficulty: 1,
  tags: ["Python", "Beginner", "Data Structures", "List"],
  relatedConcepts: ["Indexing", "Slicing", "Mutability"],
  learningObjectives: [
    "리스트의 필요성 이해하기",
    "리스트의 기본 개념 파악하기",
    "리스트의 주요 특징 알기",
    "리스트 생성 방법 익히기"
  ],
  commonMistakes: [
    "리스트와 개별 변수의 사용 상황을 구분하지 못함",
    "리스트의 인덱싱이 0부터 시작한다는 점을 잊음",
    "리스트의 변경 가능성(mutability)을 간과함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.1
},

// python-data-structures-beginner_002 // List Operations // code - 리스트 생성하기
{
  problemId: "python-data-structures-beginner_002",
  type: "code",
  title: "리스트 생성하기",
  image: python_data_beginner_002,
  description: `리스트는 파이썬에서 가장 많이 사용되는 데이터 구조 중 하나입니다. 리스트에는 다양한 데이터 형을 포함할 수 있습니다.

  그리고 우리가 배웠던 인덱싱, 슬라이싱을 그대로 사용할 수 있습니다. 우리가 지금까지 인덱싱, 슬라이싱을 공부했던 이유입니다.

  아래 코드는 여러 가지 데이터 타입을 포함한 리스트를 생성하는 예제입니다. 이 리스트에는 정수, 문자열, 실수, 불리언 값이 포함되어 있습니다.

  코드를 실행하여 리스트가 어떻게 생성되고 출력되는지 확인해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "List Creation",
  difficulty: 1,
  difficultyDescription: "리스트 생성의 기본을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "변수 개념",
    "기본 데이터 타입"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List"
  ],
  programmingConcepts: ["리스트", "변수 할당", "데이터 타입"],
  skillsPracticed: ["리스트 생성", "print() 함수 사용", "type() 함수 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "다양한 데이터 타입을 포함한 리스트를 생성할 수 있다.",
    "리스트의 각 요소에 접근하는 방법을 이해한다.",
    "type() 함수를 사용하여 데이터 타입을 확인할 수 있다."
  ],
  commonMistakes: [
    "대괄호 []와 소괄호 ()를 혼동함",
    "리스트 요소 사이의 쉼표를 빼먹음",
    "리스트 인덱싱이 0부터 시작한다는 점을 잊음"
  ],
  hints: {
    beginner: [
      "리스트는 대괄호 []를 사용하여 생성합니다.",
      "리스트의 요소들은 쉼표로 구분합니다.",
      "리스트의 인덱싱은 0부터 시작합니다."
    ]
  },
  relatedConcepts: ["튜플", "문자열", "데이터 타입"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 리스트는 대괄호 []를 사용하여 생성합니다."]
    }
  },
  initialCode: `# 다양한 데이터 타입을 포함한 리스트
mixed_list = [42, "Hello", 3.14, True]

# 리스트 출력
# 혹시 0부터 3까지 1씩 증가하는 숫자가 보이시나요?
# 반복문으로 변경할 수 있습니다.
# 아래 코드를 지우고 반복문으로 도전해보셔도 됩니다.
print(mixed_list[0])
print(mixed_list[1])
print(mixed_list[2])
print(mixed_list[3])
`,
  testCases: [
    { input: "", expected: `42
Hello
3.14
True` }
  ],
  solution: "제공된 코드가 이미 완성된 솔루션입니다.",
  examples: [
    {
      input: "",
      output: `42
"Hello"
3.14
True`
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.1
},

// python-data-structures-beginner_003 // List Operations // multiple_choice - 리스트의 기본 개념
{
  problemId: "python-data-structures-beginner_003",
  type: "multiple_choice",
  title: "리스트의 기본 개념 이해하기",
  image: null,
  description: `파이썬의 리스트(List)에 대한 설명 중 옳지 않은 것은 무엇일까요?`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "Basic Concepts",
  difficulty: 1,
  difficultyDescription: "리스트의 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "변수 개념",
    "기본 데이터 타입"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List"
  ],
  programmingConcepts: ["리스트", "데이터 타입", "인덱싱"],
  skillsPracticed: ["개념 이해", "분석적 사고"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "리스트의 기본 특성을 이해한다.",
    "리스트와 다른 데이터 타입의 차이점을 구별할 수 있다.",
    "리스트의 주요 기능을 파악한다."
  ],
  commonMistakes: [
    "리스트와 문자열의 특성을 혼동함",
    "리스트의 가변성(mutability)을 이해하지 못함",
    "리스트 인덱싱의 특성을 잘못 이해함"
  ],
  hints: {
    beginner: [
      "리스트는 여러 개의 데이터를 순서대로 저장할 수 있습니다.",
      "리스트는 대괄호 []를 사용하여 생성합니다.",
      "리스트의 요소는 다양한 데이터 타입을 가질 수 있습니다."
    ],
    intermediate: [
      "리스트는 가변(mutable) 데이터 타입입니다.",
      "리스트의 인덱싱은 0부터 시작합니다."
    ]
  },
  relatedConcepts: ["튜플", "문자열", "시퀀스 타입"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python의 리스트는 대괄호 []를 사용하여 생성합니다."]
    }
  },
  choices: [
    { id: "A", text: "리스트는 여러 개의 데이터를 순서대로 저장할 수 있다." },
    { id: "B", text: "리스트의 요소들은 모두 같은 데이터 타입이어야 한다." },
    { id: "C", text: "리스트는 대괄호 []를 사용하여 생성한다." },
    { id: "D", text: "리스트의 요소는 추가, 삭제, 수정이 가능하다." }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. 리스트의 요소들은 반드시 같은 데이터 타입일 필요가 없습니다. 리스트는 다양한 데이터 타입(정수, 문자열, 불리언 등)을 함께 저장할 수 있습니다. 예를 들어, [1, 'Hello', 3.14, True]와 같은 리스트가 가능합니다. 나머지 설명들은 모두 리스트의 올바른 특성을 설명하고 있습니다.",
  examples: [
    {
      input: "my_list = [1, 'Hello', 3.14, True]",
      output: "이는 유효한 Python 리스트입니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_004 // List Operations // multiple_choice - append() 함수 이해하기
{
  problemId: "python-data-structures-beginner_004",
  type: "multiple_choice",
  title: "append() 함수 이해하기",
  image: python_data_beginner_003,
  description: `파이썬의 리스트는 조작 가능한 데이터 구조입니다. 이는 리스트의 요소를 추가, 삭제, 수정할 수 있다는 의미입니다. 
  
  리스트에 새로운 요소를 추가하는 방법 중 하나는 append() 함수를 사용하는 것입니다. append()를 사용하면 리스트의 끝에 새로운 요소를 추가합니다.

  위 코드를 실행한 후의 my_list의 데이터는 어떻게 될까요?`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "append() Function",
  difficulty: 1,
  difficultyDescription: "리스트 조작의 기본을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "리스트의 기본 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List",
    "append()"
  ],
  programmingConcepts: ["리스트", "메소드", "가변성"],
  skillsPracticed: ["리스트 조작", "메소드 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "리스트의 가변성을 이해한다.",
    "append() 함수의 기본적인 동작 방식을 이해한다."
  ],
  commonMistakes: [
    "append() 후 리스트의 길이 변화를 잘못 예측함",
    "append()가 원래 리스트를 변경하지 않는다고 오해함"
  ],
  hints: {
    beginner: [
      "append() 함수는 리스트의 끝에 괄호 안의 데이터 요소를 리스트에 추가합니다.",
      "append()는 리스트의 길이를 1만큼 증가시킵니다."
    ]
  },
  relatedConcepts: ["리스트 인덱싱", "리스트 슬라이싱"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["append() 메소드는 리스트 객체에 대해 호출됩니다."]
    }
  },
  choices: [
    { id: "A", text: "[7, 2, 5]" },
    { id: "B", text: "[7, 2, 5, 3]" },
    { id: "C", text: "[7, 2, 5, 9]" },
    { id: "D", text: "[7, 2, 5, 3, 4]" }
  ],
  correctAnswer: "C",
  solution: "정답은 C입니다. append() 함수는 리스트의 끝에 새로운 요소를 추가합니다. 따라서 [7, 2, 5]이라는 원래 리스트에 9를 append()하면 [7, 2, 5, 9]가 됩니다. append() 함수는 리스트를 직접 수정하므로, 새로운 리스트를 만들지 않고 기존 리스트에 요소를 추가합니다.",
  examples: [
    {
      input: `my_list = [7, 2, 5]
my_list.append(9)
print(my_list)`,
      output: "[7, 2, 5, 9]"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.1
},

// python-data-structures-beginner_005 // List Operations // code - append() 함수 사용하기
{
  problemId: "python-data-structures-beginner_005",
  type: "code",
  title: "append() 함수로 리스트에 요소 추가하기",
  image: null,
  description: `주어진 리스트 fruits에 'orange'를 추가하는 코드를 작성해보세요. append() 함수를 사용하여 리스트를 수정한 후, 수정된 리스트를 출력하세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "append() Function",
  difficulty: 1,
  difficultyDescription: "append() 함수의 기본 사용법을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "이해"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "리스트의 기본 개념"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List",
    "append()"
  ],
  programmingConcepts: ["리스트", "메소드", "가변성"],
  skillsPracticed: ["리스트 조작", "메소드 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "append() 함수의 기본 사용법을 익힌다.",
    "리스트의 가변성을 이해한다.",
    "리스트 조작 후 결과를 출력하는 방법을 학습한다."
  ],
  commonMistakes: [
    "append() 함수의 괄호를 빼먹음",
    "append() 후 리스트를 출력하지 않음",
    "append()의 결과를 새로운 변수에 할당하려고 함"
  ],
  hints: {
    beginner: [
      "append() 함수는 리스트 객체에 대해 호출합니다.",
      "append() 함수의 인자로 추가할 요소를 넣습니다.",
      "수정된 리스트를 확인하려면 print() 함수를 사용하세요."
    ]
  },
  relatedConcepts: ["리스트 인덱싱", "리스트 슬라이싱"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["append() 메소드는 리스트 객체에 대해 호출됩니다."]
    }
  },
  initialCode: `fruits = ['apple', 'banana', 'cherry']

# 여기에 'orange'를 추가하는 코드를 작성해보세요.

# 수정된 리스트를 출력하세요.
print(fruits)`,
  testCases: [
    { input: "", expected: "['apple', 'banana', 'cherry', 'orange']" }
  ],
  solution: `fruits = ['apple', 'banana', 'cherry']

# 'orange'를 추가하는 코드
fruits.append('orange')

# 수정된 리스트를 출력
print(fruits)`,
  examples: [
    {
      input: "",
      output: "['apple', 'banana', 'cherry', 'orange']"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.2,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_006 // List Operations // multiple_choice - remove() 함수 이해하기
{
  problemId: "python-data-structures-beginner_006",
  type: "multiple_choice",
  title: "remove() 함수로 리스트에서 요소 삭제하기",
  image: python_data_beginner_004,
  description: `파이썬의 리스트에서 특정 요소를 삭제하는 방법 중 하나는 remove() 함수를 사용하는 것입니다. 
  remove() 함수는 리스트에서 지정된 값과 일치하는 첫 번째 요소를 찾아 삭제합니다. 중복되는 요소가 있다면, 인덱스 값이 더 낮은 요소가 삭제됩니다.
  
  다음 코드를 실행한 후의 리스트 fruits의 내용은 무엇일까요?`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "remove() Function",
  difficulty: 1,
  difficultyDescription: "remove() 함수의 기본 사용법을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "리스트의 기본 개념"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List",
    "remove()"
  ],
  programmingConcepts: ["리스트", "메소드", "요소 삭제"],
  skillsPracticed: ["리스트 조작", "메소드 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "remove() 함수의 기본 동작 방식을 이해한다.",
    "리스트에서 요소를 삭제하는 방법을 학습한다.",
    "remove() 함수가 첫 번째 일치하는 요소만 삭제함을 이해한다."
  ],
  commonMistakes: [
    "remove() 함수가 모든 일치하는 요소를 삭제한다고 오해함",
    "remove() 함수의 결과를 새로운 변수에 할당하려고 함",
    "remove() 함수가 인덱스를 사용한다고 오해함"
  ],
  hints: {
    beginner: [
      "remove() 함수는 지정된 값과 일치하는 첫 번째 요소만 삭제합니다.",
      "remove() 함수는 리스트를 직접 수정합니다.",
      "remove() 함수는 값을 기준으로 삭제하며, 인덱스를 사용하지 않습니다."
    ]
  },
  relatedConcepts: ["pop()", "del 키워드"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["remove() 메소드는 리스트 객체에 대해 호출됩니다."]
    }
  },
  choices: [
    { id: "A", text: "['apple', 'cherry', 'banana', 'date']" },
    { id: "B", text: "['apple', 'cherry', 'date']" },
    { id: "C", text: "['apple', 'banana', 'cherry', 'date']" },
    { id: "D", text: "['banana', 'apple', 'cherry', 'banana', 'date']" }
  ],
  correctAnswer: "A",
  solution: "정답은 A입니다. remove() 함수는 리스트에서 지정된 값과 일치하는 첫 번째 요소만을 삭제합니다. 이 경우, 'banana'가 리스트에 두 번 등장하지만, remove('banana')는 첫 번째 'banana'만 삭제합니다. 따라서 결과 리스트는 ['apple', 'cherry', 'banana', 'date']가 됩니다.",
  examples: [
    {
      input: `fruits = ['apple', 'banana', 'cherry', 'banana', 'date']
fruits.remove('banana')
print(fruits)`,
      output: "['apple', 'cherry', 'banana', 'date']"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_007 // List Operations // code - remove() 함수 사용하기
{
  problemId: "python-data-structures-beginner_007",
  type: "code",
  title: "remove() 함수로 리스트에서 요소 삭제하기",
  image: null,
  description: `파이썬의 리스트에서 특정 요소를 삭제하는 방법 중 하나는 remove() 함수를 사용하는 것입니다. 
  remove() 함수는 리스트에서 지정된 값과 일치하는 첫 번째 요소를 찾아 삭제합니다.

  주어진 리스트 fruits에서 'banana'를 삭제하는 코드를 작성해보세요. remove() 함수를 사용하여 리스트를 수정한 후, 수정된 리스트를 출력하세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "remove() Function",
  difficulty: 1,
  difficultyDescription: "remove() 함수의 기본 사용법을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "이해"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "리스트의 기본 개념"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List",
    "remove()"
  ],
  programmingConcepts: ["리스트", "메소드", "요소 삭제"],
  skillsPracticed: ["리스트 조작", "메소드 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "remove() 함수의 기본 사용법을 익힌다.",
    "리스트에서 요소를 삭제하는 방법을 학습한다.",
    "리스트 조작 후 결과를 출력하는 방법을 익힌다."
  ],
  commonMistakes: [
    "remove() 함수의 괄호를 빼먹음",
    "remove() 후 리스트를 출력하지 않음",
    "remove()의 결과를 새로운 변수에 할당하려고 함"
  ],
  hints: {
    beginner: [
      "remove() 함수는 리스트 객체에 대해 호출합니다.",
      "remove() 함수의 인자로 삭제할 요소의 값을 넣습니다.",
      "수정된 리스트를 확인하려면 print() 함수를 사용하세요."
    ]
  },
  relatedConcepts: ["리스트 인덱싱", "pop() 메소드"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["remove() 메소드는 리스트 객체에 대해 호출됩니다."]
    }
  },
  initialCode: `fruits = ['apple', 'banana', 'cherry', 'date']

# 여기에 'banana'를 삭제하는 코드를 작성하세요.

# 수정된 리스트를 출력하세요.
print(fruits)`,
  testCases: [
    { input: "", expected: "['apple', 'cherry', 'date']" }
  ],
  solution: `fruits = ['apple', 'banana', 'cherry', 'date']

# 'banana'를 삭제하는 코드
fruits.remove('banana')

# 수정된 리스트를 출력
print(fruits)`,
  examples: [
    {
      input: "",
      output: "['apple', 'cherry', 'date']"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.2,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_008 // List Operations // multiple_choice - 리스트 요소 수정하기
{
  problemId: "python-data-structures-beginner_008",
  type: "multiple_choice",
  title: "리스트 요소 수정하기",
  image: python_data_beginner_005,
  description: `인덱스 값을 정확하게 알고 있는건 너무 중요합니다. 위 코드처럼 인덱스 값을 사용해서 값을 다시 할당할 수 있습니다.
  여기서 할당한다는 뜻은 새로운 값으로 다시 저장하는 것을 의미합니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "List Operations",
  subTopic: "Modifying List Elements",
  difficulty: 1,
  difficultyDescription: "리스트 요소 수정의 기본을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["개념적 지식", "절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "리스트의 기본 개념",
    "리스트 인덱싱"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Data Structures",
    "List",
    "Modifying Elements"
  ],
  programmingConcepts: ["리스트", "인덱싱", "가변성"],
  skillsPracticed: ["리스트 조작", "인덱스 사용"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "리스트의 가변성을 이해한다.",
    "인덱스를 사용하여 리스트 요소를 수정하는 방법을 학습한다."
  ],
  commonMistakes: [
    "리스트 인덱싱이 0부터 시작한다는 점을 잊음",
    "수정된 요소의 위치를 잘못 파악함",
    "문자열과 리스트의 가변성 차이를 혼동함"
  ],
  hints: {
    beginner: [
      "리스트의 인덱싱은 0부터 시작합니다.",
      "fruits[1]은 리스트의 두 번째 요소를 가리킵니다.",
      "리스트 요소 수정은 해당 인덱스에 새 값을 할당하는 것으로 이루어집니다."
    ]
  },
  relatedConcepts: ["리스트 슬라이싱", "문자열 불변성"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["리스트 요소는 인덱스를 사용하여 직접 수정할 수 있습니다."]
    }
  },
  choices: [
    { id: "A", text: "['apple', 'banana', 'cherry', 'date']" },
    { id: "B", text: "['apple', 'blueberry', 'cherry', 'date']" },
    { id: "C", text: "['blueberry', 'banana', 'cherry', 'date']" },
    { id: "D", text: "['apple', 'banana', 'blueberry', 'date']" }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. fruits[1] = 'blueberry'는 리스트의 두 번째 요소(인덱스 1)를 'blueberry'로 변경합니다. 리스트의 인덱싱은 0부터 시작하므로, 인덱스 1은 두 번째 요소인 'banana'를 가리킵니다. 따라서 'banana'가 'blueberry'로 대체되어 최종 리스트는 ['apple', 'blueberry', 'cherry', 'date']가 됩니다.",
  examples: [
    {
      input: `fruits = ['apple', 'banana', 'cherry', 'date']
fruits[1] = 'blueberry'
print(fruits)`,
      output: "['apple', 'blueberry', 'cherry', 'date']"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_009 // List Operations // concept - split() 함수 이해하기
{
  problemId: "python-data-structures-beginner_009",
  type: "concept",
  title: "split() 함수 이해하기",
  image: python_data_beginner_006,
  description: `리스트를 이해했다면, 이제 알고리즘 문제를 풀 때 자주 등장하는 split() 함수에 대해 알아보겠습니다.

split() 함수는 문자열을 특정 문자(delimiter)를 기준으로 나누어 리스트로 만드는 기능을 합니다.

split() 함수안에 아무것도 입력하지 않는다면, 공백을 기준으로 문자열을 나누어 리스트로 만듭니다.
`,
  subject: "Computer Science",
  topic: "split",
  subTopic: "String Manipulation",
  difficulty: 1,
  tags: ["Python", "Beginner", "String", "split()", "Algorithm"],
  relatedConcepts: ["문자열 메소드", "리스트", "입력 처리"],
  learningObjectives: [
    "split() 함수의 기본 개념 이해하기",
    "split() 함수의 주요 특징 파악하기",
    "알고리즘 문제에서 split() 함수의 활용법 익히기"
  ],
  commonMistakes: [
    "split() 함수의 결과가 리스트라는 점을 간과함",
    "기본 구분자가 공백이라는 점을 잊음",
    "split() 후 데이터 타입 변환의 필요성을 인지하지 못함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_010 // List Operations // concept - split() 함수 이해하기
{
  problemId: "python-data-structures-beginner_010",
  type: "concept",
  title: "split() 함수 이해하기",
  image: python_data_beginner_007,
  description: `만약 split() 함수안에 특정 문자를 입력한다면, 특정 문자를 기준으로 문자열을 나누어 리스트로 만듭니다.

  예를 들어, split('-')를 입력한다면, 문자열을 '-'를 기준으로 나누어 리스트로 만듭니다.`,
  subject: "Computer Science",
  topic: "split",
  subTopic: "String Manipulation",
  difficulty: 1,
  tags: ["Python", "Beginner", "String", "split()", "Algorithm"],
  relatedConcepts: ["문자열 메소드", "리스트", "입력 처리"],
  learningObjectives: [
    "split() 함수의 기본 개념 이해하기",
    "split() 함수의 주요 특징 파악하기",
    "알고리즘 문제에서 split() 함수의 활용법 익히기"
  ],
  commonMistakes: [
    "split() 함수의 결과가 리스트라는 점을 간과함",
    "기본 구분자가 공백이라는 점을 잊음",
    "split() 후 데이터 타입 변환의 필요성을 인지하지 못함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_011 // List Operations // concept - split() 함수 이해하기
{
  problemId: "python_arithmetic_011",
  title: "두 숫자 더하기",
  description: `이번에는 조금은 도전적인 문제를 풀어보겠습니다.
  알고리즘 문제를 풀 때 자주 등장하는 split() 함수에 대해 알아보겠습니다.
  두 개의 정수를 공백을 두고 입력받아 그 합을 출력하는 코드를 작성해보세요.

  1. 두 개의 정수를 공백을 두고 입력받습니다.
  2. 공백을 기준으로 문자열을 나누어 리스트로 만듭니다.
  3. 리스트의 요소를 정수로 변환합니다.
  4. 리스트의 요소를 모두 더한 값을 출력합니다. 이때 리스트의 모든 요소가 정수라면 sum()이라는 함수를 사용할 수 있습니다.

  [입력 예시 - TestCase 1]
  3 5

  [출력 예시]
  8
  
  2번 과정에 해당하는 코드를 작성해보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "split",
  subTopic: "Addition",
  difficulty: 1,
  difficultyDescription: "기본적인 산술 연산을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "이해"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "함수 정의",
    "기본 산술 연산"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Arithmetic",
    "Functions"
  ],
  programmingConcepts: ["함수", "산술 연산", "반환 값"],
  skillsPracticed: ["함수 작성", "덧셈 연산"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "정수 입력받기",
    "기본적인 산술 연산 수행 방법 이해"
  ],
  commonMistakes: [
    "함수에서 결과를 출력하지 않고 반환해야 함",
    "입력값을 정수로 변환하지 않음"
  ],
  hints: {
    beginner: [
      "input()은 모든 입력을 문자열로 받기 때문에 정수로 변환해야 합니다.",
      "변수 하나씩 int() 함수를 사용해서 변환해보세요."
    ],
    intermediate: [
      "map() 함수를 사용하여 한 번에 여러 변수를 변환할 수 있습니다."
    ],
    advanced: [
      "a, b 변수대신 list 형태로 입력 받아보세요."
    ]
  },
  relatedConcepts: ["함수", "변수", "반환 값"],
  learningStyle: ["실천적 학습"],
  type: "code",
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
    }
  },
  initialCode: `#1. 두 개의 정수를 공백을 두고 입력받습니다.
a = input()
#2. 공백을 기준으로 문자열을 나누어 리스트로 만드는 코드를 작성해보세요.

#3. a를 정수로 변환하는 코드입니다.
a[0] = int(a[0])
a[1] = int(a[1])
#4. a리스트의 모든 요소를 더한 값을 출력합니다.
print(sum(a))`,
  testCases: [
    { input: "3 5", expected: "8" },
    { input: "-2 7", expected: "5" },
  ],
  solution: ``,
  examples: [
    {
      input: `3 5`,
      output: "8"
    }
  ],
  image: null,
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.2,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-10",
  lastUpdated: "2024-10-10",
  versionNumber: 1.0
},

// python-data-structures-beginner_012 // List Operations // concept - 튜플의 기본 개념 이해하기
{
  problemId: "python-data-structures-beginner_012",
  type: "concept",
  title: "튜플의 기본 개념 이해하기",
  image: python_data_beginner_008,
  description: `튜플(Tuple)은 또 다른 데이터 저장 방법입니다.
  리스트와 가장 큰 차이점이 있다면, 추가/삭제/수정이 불가능하다는 점입니다.
  그리고 소괄호를 사용합니다.

  '아, 이렇게 저장하는 방법도 있구나!' 정도만 알아두셔도 괜찮습니다.
`,
  subject: "Computer Science",
  topic: "Tuple Characteristics",
  subTopic: "Basic Concepts",
  difficulty: 1,
  tags: ["Python", "Beginner", "Data Structures", "Tuple"],
  relatedConcepts: ["리스트", "불변성", "시퀀스 타입"],
  learningObjectives: [
    "튜플의 기본 개념 이해하기",
    "튜플과 리스트의 차이점 파악하기",
    "튜플의 주요 특징과 사용 사례 알기"
  ],
  commonMistakes: [
    "튜플을 리스트와 동일하게 취급하여 수정을 시도함",
    "튜플의 불변성을 간과하고 요소 변경을 시도함",
    "튜플과 리스트의 성능 차이를 이해하지 못함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// python-data-structures-beginner_013 // List Operations // concept - 딕셔너리의 기본 개념 이해하기
{
  problemId: "python-data-structures-beginner_013",
  type: "concept",
  title: "딕셔너리의 기본 개념 이해하기",
  image: python_data_beginner_009,
  description: `딕셔너리(Dictionary)는 파이썬의 강력한 데이터 구조 중 하나입니다.
  키(key)와 값(value)의 쌍으로 데이터를 저장합니다. 그리고 중괄호 {}를 사용하여 생성합니다.
  키 값이 유일해야 한다던지, 추가/삭제/수정이 가능하다는 점만 알아두셔도 괜찮습니다.

딕셔너리의 데이터 구조를 이해하는건 너무 중요하지만, 입문 단계에서는 잠시 넘어가도록 하겠습니다.
`,
  subject: "Computer Science",
  topic: "Dictionary Usage",
  subTopic: "Basic Concepts",
  difficulty: 1,
  tags: ["Python", "Beginner", "Data Structures", "Dictionary"],
  relatedConcepts: ["해시 테이블", "키-값 쌍", "매핑 타입"],
  learningObjectives: [
    "딕셔너리의 기본 개념 이해하기",
    "딕셔너리의 주요 특징 파악하기",
    "딕셔너리의 생성과 기본 사용법 익히기"
  ],
  commonMistakes: [
    "키-값 쌍의 구분을 혼동함 (콜론 대신 등호 사용 등)",
    "존재하지 않는 키에 접근하여 KeyError 발생",
    "딕셔너리와 리스트의 사용 상황을 혼동함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-17",
  lastUpdated: "2024-10-17",
  versionNumber: 1.0
},

// id: "functions",
// title: "함수"
// topics: ["Function Definition", "Parameters", "Return Values", "Scope"]
////////////////////////////////

// functions_001 // Function Definition // concept - 함수의 기본 개념
{
  problemId: "functions_001",
  type: "concept",
  title: "함수의 기본 개념",
  image: python_functions_001,
  description: `프로그래밍 세상에서는 개발자가 직접 함수를 만들 수 있습니다. 함수를 사용하면 코드를 재사용하고 프로그램을 더 체계적으로 구성할 수 있습니다.

  자판기를 떠올려보면 쉽게 이해할 수 있습니다.

  1. 개발자가 직접 버튼을 만듭니다. (함수 정의)
  2. 버튼을 누리면 기능이 작동합니다. (함수 기능구현)
  3. 기능이 작동한 결과를 얻습니다. (함수 반환값)`,
  subject: "Computer Science",
  topic: "Function Definition",
  subTopic: "Basic Concepts",
  difficulty: 1,
  tags: ["Python", "Beginner", "Functions", "Code Organization"],
  relatedConcepts: ["변수", "스코프", "반환값"],
  learningObjectives: [
    "함수의 기본 개념 이해하기",
    "함수 정의 방법 익히기",
    "함수 사용의 이점 파악하기"
  ],
  commonMistakes: [
    "함수 이름을 예약어로 사용함",
    "함수 정의와 호출을 혼동함",
    "들여쓰기를 올바르게 하지 않음"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_002 // Function Definition // multiple_choice - 함수의 기본 개념
{
  problemId: "functions_002",
  type: "multiple_choice",
  title: "함수의 기본 개념 이해하기",
  image: python_functions_002,
  description: `파이썬 자판기가 있다고 가정해보겠습니다. 버튼을 눌리면, 기능을 실행합니다.
  print() 버튼을 눌리면, 텍스트를 출력하는 기능을 실행합니다.
  input() 버튼을 눌리면, 텍스트를 입력하는 기능을 실행합니다.
  
  버튼을 눌리는 행동을 '함수를 호출한다'고 합니다.
  print() 함수를 호출하면, 텍스트를 출력하는 기능을 실행합니다.
  input() 함수를 호출하면, 텍스트를 입력하는 기능을 실행합니다.

  프로그래밍 세상에서는 개발자가 직접 버튼을 만들 수 있습니다.
  다음 중 함수에 대한 설명으로 잘못된 것은 무엇일까요?
  `,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Basic Concepts",
  difficulty: 1,
  difficultyDescription: "함수의 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "변수의 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Concepts"
  ],
  programmingConcepts: ["함수", "코드 재사용", "모듈화"],
  skillsPracticed: ["개념 이해", "분석적 사고"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "함수의 기본 개념을 이해한다.",
    "함수의 주요 특징과 장점을 파악한다."
  ],
  commonMistakes: [
    "함수의 재사용성을 간과함",
    "함수와 변수의 개념을 혼동함",
    "함수의 입력과 출력 개념을 잘못 이해함"
  ],
  hints: {
    beginner: [
      "함수는 개발자가 새롭게 만들 수 있습니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "매개변수"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
    }
  },
  choices: [
    { id: "A", text: "함수는 코드의 재사용성을 높입니다." },
    { id: "B", text: "함수는 프로그램을 더 체계적으로 구성하는 데 도움을 줍니다." },
    { id: "C", text: "함수는 새롭게 만들 수 없고, 정해진 것만 사용할 수 있다." },
    { id: "D", text: "함수는 특정 작업을 수행하는 코드의 묶음입니다." }
  ],
  correctAnswer: "C",
  solution: "정답은 C입니다. 함수는 개발자가 새롭게 만들 수 있습니다.",
  examples: [
    {
      input: "",
      output: "함수는 결과값을 개발자가 새롭게 만들 수 있습니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.1
},

// functions_003 // Function Definition // multiple_choice - 함수 선언 기초
{
  problemId: "functions_003",
  type: "multiple_choice",
  title: "함수 선언 기초",
  image: python_functions_003,
  description: `함수를 만드는 방법을 설명합니다.

  함수를 생성하기 위해서 정의하다는 뜻을 가진 def 키워드를 사용합니다.
  이미지처럼 def 함수명(): 형태로 함수를 선언하고, 들여쓰기 후 기능을 구현합니다.

  그렇다면 greet라는 함수명을 가지고, 'Hello' 문자열을 출력하는 기능을 가진 코드는 어떻게 구현해야 할까요?

  아래에서 같은 구조를 가진 코드를 찾아보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Basic Syntax",
  difficulty: 1,
  difficultyDescription: "함수 선언의 기본 문법을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "함수의 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Syntax"
  ],
  programmingConcepts: ["함수 선언", "키워드", "들여쓰기"],
  skillsPracticed: ["함수 선언 문법 이해"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "함수 선언의 기본 문법을 이해한다.",
    "올바른 함수 선언과 잘못된 선언을 구별할 수 있다."
  ],
  commonMistakes: [
    "def 키워드를 빼먹음",
    "콜론(:)을 빼먹음",
    "함수 본문의 들여쓰기를 잘못함"
  ],
  hints: {
    beginner: [
      "함수는 'def' 키워드로 시작합니다.",
      "함수 이름 뒤에는 괄호()가 필요합니다.",
      "함수 선언 끝에는 콜론(:)이 필요합니다.",
      "함수 본문은 들여쓰기가 되어야 합니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "매개변수"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
    }
  },
  choices: [
    { id: "A", text: "function greet():\n    print('Hello')" },
    { id: "B", text: "def greet():\n    print('Hello')" },
    { id: "C", text: "def greet()\n    print('Hello')" },
    { id: "D", text: "define greet():\n    print('Hello')" }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. 파이썬에서 함수를 선언할 때는 'def' 키워드를 사용하고, 함수 이름 뒤에 괄호()를 붙이며, 선언 끝에 콜론(:)을 사용합니다. 그리고 함수의 본문은 들여쓰기를 해야 합니다. B 옵션이 이 모든 조건을 만족하는 올바른 함수 선언 방식입니다.",
  examples: [
    {
      input: "",
      output: "def greet():\n    print('Hello')"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_004 // Function Definition // code - 간단한 함수 생성 및 호출
{
  problemId: "functions_004",
  type: "code",
  title: "간단한 함수 생성 및 호출",
  image: null,
  description: `'Hello, World!'를 출력하는 함수를 만들고 호출해보세요.

1. 'greet'라는 이름의 함수를 정의하세요.
2. 함수 내부에서 'Hello, World!'를 출력하세요.
3. 정의한 함수를 호출하세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Basic Function Creation and Calling",
  difficulty: 1,
  difficultyDescription: "함수 생성과 호출의 기본을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "함수의 개념"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Function Creation",
    "Function Calling"
  ],
  programmingConcepts: ["함수 정의", "함수 호출", "print() 함수"],
  skillsPracticed: ["함수 작성", "함수 호출"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "함수를 정의하는 방법을 익힌다.",
    "함수를 호출하는 방법을 익힌다.",
    "간단한 함수를 작성하고 실행할 수 있다."
  ],
  commonMistakes: [
    "함수 정의와 호출을 혼동함",
    "함수 이름 뒤의 괄호()를 빼먹음",
    "함수 내용의 들여쓰기를 잘못함"
  ],
  hints: {
    beginner: [
      "함수는 'def' 키워드로 시작합니다.",
      "함수 내용은 들여쓰기를 해야 합니다.",
      "함수를 호출할 때는 함수 이름 뒤에 괄호()를 붙입니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "매개변수"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
    }
  },
  initialCode: `# 여기에 greet 함수를 정의하세요.
# (자판기 버튼을 생성합니다.)

# greet 함수를 호출합니다.
# (생성된 자판기 버튼을 눌립니다.)
greet()
`,
  testCases: [
    { input: "", expected: "Hello, World!" }
  ],
  solution: `def greet():
    print("Hello, World!")

greet()`,
  examples: [
    {
      input: "",
      output: "Hello, World!"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.2,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_005 // Function Definition // multiple_choice - 함수의 기본 개념 이해하기
{
  problemId: "functions_005",
  type: "multiple_choice",
  title: "함수의 기본 개념 이해하기",
  image: python_functions_004,
  description: `자판기 버튼은 원할때마다 계속 눌릴 수 있습니다.
  함수도 마찬가지입니다. 원할때마다 또 호출할 수 있습니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Basic Concepts",
  difficulty: 1,
  difficultyDescription: "함수의 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "변수의 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Concepts"
  ],
  programmingConcepts: ["함수", "코드 재사용", "모듈화"],
  skillsPracticed: ["개념 이해", "분석적 사고"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "함수의 기본 개념을 이해한다.",
    "함수의 주요 특징과 장점을 파악한다."
  ],
  commonMistakes: [
    "함수의 재사용성을 간과함",
    "함수와 변수의 개념을 혼동함",
    "함수의 입력과 출력 개념을 잘못 이해함"
  ],
  hints: {
    beginner: [
      "함수는 특정 작업을 수행하는 코드의 묶음입니다.",
      "함수는 코드의 재사용성을 높이는 데 도움이 됩니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "매개변수"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
    }
  },
  choices: [
    { id: "A", text: "함수는 이미 만들어진것만 사용가능합니다." },
    { id: "B", text: "함수는 한 번만 사용할 수 있는 일회용입니다." },
    { id: "C", text: "함수는 특정 작업을 수행하기 위해 재사용 가능합니다." }
  ],
  correctAnswer: "C",
  solution: "정답은 C입니다. 함수는 특정 작업을 수행하는 재사용 가능한 코드 블록입니다. 함수를 사용하면 코드의 재사용성을 높이고, 프로그램을 더 체계적으로 구성할 수 있습니다. 함수는 여러 번 호출될 수 있으며, 이는 코드의 중복을 줄이고 유지보수를 용이하게 합니다.",
  examples: [
    {
      input: "",
      output: "함수는 특정 작업을 수행하는 재사용 가능한 코드 블록입니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_006 // Parameters and Return Values // multiple_choice - 함수의 입력과 출력 이해하기
{
  problemId: "functions_006",
  type: "multiple_choice",
  title: "함수의 입력과 출력 이해하기",
  image: python_functions_005,
  description: `앞선 자판기에서는 돈을 넣거나, 무언가를 돌려받는 곳이 없었습니다.
  이제 파이썬 자판기를 업그레이드 시켜서 돈을 넣거나, 무언가를 돌려받는 곳이 생겼다고 생각해봅시다.

  '돈 넣는 곳'은 데이터를 전달하는 매개변수라고 하며,
  '돌려받는 곳'은 반환값을 의미합니다.
  
  파이썬 자판기에서는 데이터를 전달하지 않거나, 반환값이 없어도 괜찮습니다.
  그리고 데이터를 전달하거나, 반환값을 사용할 수도 있습니다.
  이건 프로그램을 개발하는 개발자의 선택입니다.
  
  사실 반환값이 없다는건 우리 눈에 보이진 않지만,
  자동으로 'None'이라는 값을 반환하고 있습니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Parameters and Return Values",
  difficulty: 1,
  difficultyDescription: "함수의 입력과 출력 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "함수의 기본 개념"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Parameters",
    "Return Values"
  ],
  programmingConcepts: ["함수", "매개변수", "반환값"],
  skillsPracticed: ["개념 이해", "유추적 사고"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "함수의 입력(매개변수)과 출력(반환값) 개념을 이해한다.",
    "실생활의 예시를 통해 프로그래밍 개념을 이해한다."
  ],
  commonMistakes: [
    "매개변수와 반환값의 개념을 혼동함",
    "함수의 입력과 출력의 역할을 잘못 이해함"
  ],
  hints: {
    beginner: [
      "자판기에 돈을 넣는 것은 함수에 어떤 것을 제공하는 것과 비슷합니다.",
      "자판기에서 음료수가 나오는 것은 함수가 어떤 것을 제공하는 것과 비슷합니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "함수 호출"],
  learningStyle: ["유추적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수의 매개변수는 괄호 안에 정의되고, return 키워드로 값을 반환합니다."]
    }
  },
  choices: [
    { id: "A", text: "'돈 넣는 곳'은 함수의 이름\n'돌려받는 곳'은 함수의 본문" },
    { id: "B", text: "'돈 넣는 곳'은 데이터를 전달받는 함수의 매개변수\n'돌려받는 곳'은 함수의 반환값" },
    { id: "C", text: "'돈 넣는 곳'은 함수의 반환값\n'돌려받는 곳'은 함수의 매개변수" },
    { id: "D", text: "'돈 넣는 곳'은 함수의 호출\n'돌려받는 곳'은 함수의 정의" }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. 자판기 비유에서 '돈을 넣는 곳'은 함수의 매개변수(parameter)에 해당합니다. 함수에 입력을 제공하는 역할을 합니다. '음료수가 나오는 곳'은 함수의 반환값(return value)에 해당합니다. 함수가 처리한 결과를 제공하는 역할을 합니다. 이 비유는 함수의 입력(매개변수)과 출력(반환값)의 개념을 이해하는 데 도움이 됩니다.",
  examples: [
    {
      input: "",
      output: "함수의 매개변수는 입력을, 반환값은 출력을 나타냅니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_007 // Parameters and Return Values // code - 두 수를 더하는 함수 만들기
{
  problemId: "functions_007",
  type: "code",
  title: "데이터를 전달하고, 반환값이 있는 함수 만들기",
  image: python_functions_006,
  description: `자판기에 버튼이 있어야 버튼을 눌릴 수 있습니다.
  그 버튼을 눌러야 물건을 받을 수 있죠.

  함수를 먼저 만들어야 함수를 호출할 수 있습니다.
  함수를 호출하면서, 실행시키기 위한 데이터도 함께 전달할 수 있죠.
  그리고 결과를 반환받습니다.
  
  코드를 실제로 실행시켜보세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Parameters and Return Values",
  difficulty: 1,
  difficultyDescription: "함수의 매개변수와 반환값을 다루는 초급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["이해", "적용"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "함수의 기본 개념",
    "산술 연산"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Parameters",
    "Return Values",
    "Arithmetic"
  ],
  programmingConcepts: ["함수 정의", "매개변수", "반환값", "산술 연산"],
  skillsPracticed: ["함수 작성", "매개변수 사용", "값 반환"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "매개변수를 가진 함수를 정의할 수 있다.",
    "함수에서 값을 반환하는 방법을 익힌다.",
    "간단한 산술 연산을 함수로 구현할 수 있다."
  ],
  commonMistakes: [
    "return 문을 사용하지 않고 결과를 출력만 함",
    "매개변수의 이름을 잘못 사용함",
    "함수 내부에서 산술 연산을 하지 않고 그대로 반환함"
  ],
  hints: {
    beginner: [
      "함수는 'def' 키워드로 시작합니다.",
      "매개변수는 함수 이름 뒤의 괄호 안에 정의합니다.",
      "return 키워드를 사용하여 결과를 반환합니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "함수 호출"],
  learningStyle: ["실습 중심"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수는 def 키워드로 정의하고, return 키워드로 값을 반환합니다."]
    }
  },
  initialCode: `# 여기에 add 함수를 정의합니다.
# 데이터를 전달받기 위한 매개변수 x, y를 정의합니다.
def add(x, y):
    return x + y
# 함수를 호출하고, result 변수에 반환값을 저장합니다.
result = add(5, 3)
print(result)`,
  testCases: [
    { input: "add(5, 3)", expected: "8" }
  ],
  solution: `def add(a, b):
    return a + b

result = add(5, 3)
print(result)`,
  examples: [
    {
      input: "add(5, 3)",
      output: "8"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.1
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_008 // Parameters and Return Values // multiple_choice - 함수의 매개변수와 반환값 이해하기
{
  problemId: "functions_008",
  type: "multiple_choice",
  title: "함수의 매개변수와 반환값 이해하기",
  image: null,
  description: `함수의 매개변수와 반환값에 대한 설명 중 옳지 않은 것을 고르세요.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Function Definition",
  subTopic: "Parameters and Return Values",
  difficulty: 1,
  difficultyDescription: "함수의 매개변수와 반환값에 대한 기본 개념을 이해하는 초급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "함수의 기본 개념"
  ],
  estimatedTime: "3분",
  tags: [
    "Python",
    "Beginner",
    "Functions",
    "Parameters",
    "Return Values"
  ],
  programmingConcepts: ["함수", "매개변수", "반환값"],
  skillsPracticed: ["개념 이해", "분석적 사고"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "함수의 매개변수와 반환값의 개념을 정확히 이해한다.",
    "함수의 입력과 출력 메커니즘을 파악한다."
  ],
  commonMistakes: [
    "매개변수와 반환값의 개념을 혼동함",
    "함수가 반드시 반환값을 가져야 한다고 오해함",
    "매개변수의 개수에 대해 잘못된 이해를 가짐"
  ],
  hints: {
    beginner: [
      "매개변수는 함수에 전달되는 입력값입니다.",
      "반환값은 함수가 처리를 마치고 돌려주는 결과입니다.",
      "함수는 매개변수나 반환값이 없을 수도 있습니다."
    ]
  },
  relatedConcepts: ["변수", "스코프", "함수 호출"],
  learningStyle: ["개념적 학습"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 함수의 매개변수는 괄호 안에 정의되고, return 키워드로 값을 반환합니다."]
    }
  },
  choices: [
    { id: "A", text: "매개변수는 함수에 전달되는 데이터다." },
    { id: "B", text: "함수는 여러 개의 매개변수를 가질 수 있다." },
    { id: "C", text: "반환값은 함수가 실행을 마치고 돌려주는 결과이다." },
    { id: "D", text: "모든 함수는 반드시 하나 이상의 매개변수를 가져야 한다." },
    { id: "E", text: "함수는 반환값이 없을 수도 있다." }
  ],
  correctAnswer: "D",
  solution: "정답은 D입니다. 함수는 반드시 매개변수를 가질 필요가 없습니다. 매개변수가 없는 함수도 존재할 수 있습니다. 나머지 설명들은 모두 함수의 매개변수와 반환값에 대한 올바른 설명입니다. A, B, C, E는 모두 옳은 설명이며, 함수의 기본적인 특성을 잘 나타내고 있습니다.",
  examples: [
    {
      input: "",
      output: "함수는 매개변수가 없을 수도 있고, 여러 개일 수도 있으며, 반환값이 있을 수도 없을 수도 있습니다."
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.4,
    guessingParameter: 0.2
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
},

// functions_009 // Parameters // concept - 함수 매개변수 이해하기
{
  problemId: "functions_009",
  type: "concept",
  title: "함수 매개변수 이해하기",
  image: python_functions_007,
  description: `이미지처럼 코드를 실행시키면 에러가 발생합니다.
  그 이유는 전달하는 데이터의 개수와, 전달받는 매개변수의 개수가 일치하지 않기 때문입니다.
  
  2개의 데이터를 전달하고 싶다면, 2개의 매개변수가 필요합니다.
  
  코드를 작성할때, 매개변수의 개수와 데이터의 개수가 일치하는지 확인해야합니다.`,
  subject: "Computer Science",
  topic: "Parameters",
  subTopic: "Parameter Count",
  difficulty: 1,
  tags: ["Python", "Beginner", "Functions", "Parameters", "Error Handling"],
  relatedConcepts: ["함수 정의", "함수 호출", "에러 처리"],
  learningObjectives: [
    "함수 매개변수와 인자의 개수 일치 필요성 이해하기",
    "매개변수 개수 불일치로 인한 에러 상황 인식하기",
    "올바른 함수 호출 방법 익히기"
  ],
  commonMistakes: [
    "함수 호출 시 필요한 인자 수를 잘못 파악함",
    "매개변수와 인자의 개념을 혼동함",
    "함수 정의와 호출의 관계를 잘못 이해함"
  ],
  author: "SlopeXcelerity",
  dateCreated: "2024-10-18",
  lastUpdated: "2024-10-18",
  versionNumber: 1.0
}
];