import React from 'react';
import LmsHomeMain from '../../components/lms/container/home/LmsHomeMain';

const LmsHome = () => {
  return (
    <div className="min-h-screen bg-white">
      <LmsHomeMain />
    </div>
  );
};

export default LmsHome;