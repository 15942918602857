// src/components/Hint.jsx
import React from "react";

function Hint({ hints }) {
  if (!hints || typeof hints !== 'object') {
    return null;
  }

  return (
    <div className="mt-4 p-4 bg-gray-100 rounded-md">
      <h2 className="text-lg font-semibold mb-2">힌트</h2>
      {Object.entries(hints).map(([level, hintList]) => (
        <div key={level}>
          <h3 className="font-semibold mt-2">{level}</h3>
          <ul className="list-disc list-inside">
            {hintList.map((hint, index) => (
              <li key={index}>{hint}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Hint;