import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import GameBoard from './GameBoard';
import ScoreDisplay from './ScoreDisplay';
import MemoryTestIntro from './MemoryTestIntro';
import MemoryTestResult from './MemoryTestResult';
import Timer from './MemoryTimer';

const MemoryTest = () => {
  const [gameState, setGameState] = useState('intro');
  const [round, setRound] = useState(1);
  const [score, setScore] = useState(0);
  const [roundScores, setRoundScores] = useState([]);
  const [gridSize, setGridSize] = useState(2);
  const [sequence, setSequence] = useState([]);
  const [userSequence, setUserSequence] = useState([]);
  const [isSequencePlaying, setIsSequencePlaying] = useState(false);
  const [isRoundChanging, setIsRoundChanging] = useState(false);
  const [wrongTile, setWrongTile] = useState(null);
  const [activeTile, setActiveTile] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180); // 3분 = 180초
  const user = useSelector((state) => state.auth.user);

  const updateGameSettings = useCallback(() => {
    setGridSize(Math.min(6, Math.floor(round / 4) + 2));
  }, [round]);

  const generateSequence = useCallback(() => {
    const newSequence = [];
    const availableTiles = Array.from({ length: gridSize * gridSize }, (_, i) => i);
    for (let i = 0; i < round; i++) {
      const randomIndex = Math.floor(Math.random() * availableTiles.length);
      newSequence.push(availableTiles[randomIndex]);
      availableTiles.splice(randomIndex, 1);
    }
    setSequence(newSequence);
  }, [round, gridSize]);

  const playSequence = useCallback(async () => {
    setIsSequencePlaying(true);
    await new Promise(resolve => setTimeout(resolve, 1000)); // 1초 대기
    for (let i = 0; i < sequence.length; i++) {
      setActiveTile(sequence[i]);
      await new Promise(resolve => setTimeout(resolve, 500));
      setActiveTile(null);
      await new Promise(resolve => setTimeout(resolve, 200));
    }
    setIsSequencePlaying(false);
  }, [sequence]);

  const moveToNextRound = useCallback(() => {
    setRound(prevRound => prevRound + 1);
    setIsRoundChanging(true);
  }, []);

  const handleTileClick = useCallback((tileIndex) => {
    if (isSequencePlaying || isRoundChanging || wrongTile !== null || isCorrect) return;
    
    const newUserSequence = [...userSequence, tileIndex];
    setUserSequence(newUserSequence);

    if (newUserSequence[newUserSequence.length - 1] !== sequence[newUserSequence.length - 1]) {
      setWrongTile(tileIndex);
      setTimeout(() => {
        setUserSequence([]);
        setWrongTile(null);
        moveToNextRound();
      }, 1000);
    } else if (newUserSequence.length === sequence.length) {
      setIsCorrect(true);
      setRoundScores(prev => [...prev, round * 10]);
      setScore(prevScore => prevScore + round * 10);
      setTimeout(() => {
        setUserSequence([]);
        setIsCorrect(false);
        moveToNextRound();
      }, 1000);
    }
  }, [isSequencePlaying, userSequence, sequence, round, isRoundChanging, wrongTile, isCorrect, moveToNextRound]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isRoundChanging) {
      const timer = setTimeout(() => {
        setIsRoundChanging(false);
        updateGameSettings();
        generateSequence();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isRoundChanging, updateGameSettings, generateSequence]);

  useEffect(() => {
    if (sequence.length > 0 && !isRoundChanging) {
      playSequence();
    }
  }, [sequence, isRoundChanging, playSequence]);

  useEffect(() => {
    let timer;
    if (gameState === 'game' && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            endGame();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameState, timeLeft]);

  const startGame = () => {
    setGameState('game');
    setTimeLeft(180);
    setRound(1);
    setScore(0);
    setRoundScores([]);
    updateGameSettings();
    generateSequence();
    window.scrollTo(0, 0);
  };

  const endGame = () => {
    setGameState('result');
  };

  const resetGame = () => {
    setRound(1);
    setScore(0);
    setRoundScores([]);
    setGridSize(2);
    setSequence([]);
    setUserSequence([]);
    setIsSequencePlaying(false);
    setIsRoundChanging(false);
    setWrongTile(null);
    setIsCorrect(false);
    setGameState('intro');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-1 -mt-24 md:-mt-16">
      <div className="w-full max-w-[812px] min-w-[273px] bg-white rounded-lg">
        {/* <h1 className="text-3xl font-bold mb-6 text-center text-[#D00070]">기억력 테스트</h1> */}
        
        {gameState === 'intro' && <MemoryTestIntro onStart={startGame} />}
        {gameState === 'game' && (
          <>
            <Timer timeLeft={timeLeft} />
            <ScoreDisplay round={round} score={score} roundScores={roundScores} />
            <div className="w-full" style={{ maxWidth: '560px', margin: '0 auto' }}>
              <GameBoard
                gridSize={gridSize}
                activeTile={activeTile}
                userSequence={userSequence}
                isSequencePlaying={isSequencePlaying}
                isRoundChanging={isRoundChanging}
                wrongTile={wrongTile}
                isCorrect={isCorrect}
                onTileClick={handleTileClick}
              />
            </div>
          </>
        )}
        {gameState === 'result' && (
          <MemoryTestResult
            score={score}
            round={round - 1}
            user={user}
            onRestart={resetGame}
          />
        )}
      </div>
    </div>
  );
};

export default MemoryTest;