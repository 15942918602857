import React from 'react';
import { Line } from 'react-chartjs-2';

const WeeklyStudyTimeChart = ({ data }) => {
  const chartData = {
    labels: data.map(({ name }) => name),
    datasets: [
      {
        label: '학습 시간 (분)',
        data: data.map(({ 시간 }) => 시간),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  return <Line data={chartData} />;
};

export default WeeklyStudyTimeChart;