import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createGroup } from '../../../../features/groupSlice';

const GroupCreationForm = () => {
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createGroup({ name: groupName, description: groupDescription }));
    setGroupName('');
    setGroupDescription('');
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 p-4 bg-gray-100 rounded">
      <h2 className="text-xl font-semibold mb-4">새 그룹 생성</h2>
      <div className="mb-4">
        <label htmlFor="groupName" className="block mb-2">그룹 이름</label>
        <input
          type="text"
          id="groupName"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="groupDescription" className="block mb-2">그룹 설명</label>
        <textarea
          id="groupDescription"
          value={groupDescription}
          onChange={(e) => setGroupDescription(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          rows="4"
          required
        />
      </div>
      <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        그룹 생성 및 결제
      </button>
    </form>
  );
};

export default GroupCreationForm;