import React, { useState, useEffect, useCallback } from 'react';
import { allTilesData } from '../../../../data/AllTileData';

// '전체', 'course', 'module'
const mainCategories = ['전체', 'module'];
// 'new', 'dev', 'test', 'closed', 'best', 'ing'
const subCategories = ['dev'];

const FilterClass = ({ onFilterChange }) => {
  const [allTiles, setAllTiles] = useState([]);
  const [activeMainCategory, setActiveMainCategory] = useState('전체');
  const [activeSubCategories, setActiveSubCategories] = useState([]);
  const [prevFilteredTiles, setPrevFilteredTiles] = useState([]);

  // 초기 데이터 로딩
  useEffect(() => {
    const tiles = [
      // ...allTilesData.bestTiles,
      ...allTilesData.moduleTiles,
      // ...allTilesData.starterTiles,
      // ...allTilesData.forDevTiles,
      // ...allTilesData.blockTiles
    ];
    setAllTiles(tiles);
  }, []);

  // 필터링 함수
  const filterTiles = useCallback(() => {
    let filtered = allTiles;

    if (activeMainCategory !== '전체') {
      filtered = filtered.filter(tile => {
        if (activeMainCategory === 'course') {
          return tile.status.includes('course');
        } else if (activeMainCategory === 'module') {
          return tile.status.includes('module');
        }
        return true;
      });
    }

    if (activeSubCategories.length > 0) {
      filtered = filtered.filter(tile => 
        activeSubCategories.some(category => tile.status.includes(category))
      );
    }

    return filtered;
  }, [allTiles, activeMainCategory, activeSubCategories]);

  // 필터링 결과를 부모에 전달 (변경된 경우에만)
  useEffect(() => {
    const filtered = filterTiles();

    // 이전 필터링 결과와 비교
    const isEqual = JSON.stringify(filtered) === JSON.stringify(prevFilteredTiles);
    if (!isEqual) {
      onFilterChange(filtered);
      setPrevFilteredTiles(filtered);
    }
  }, [filterTiles, onFilterChange, prevFilteredTiles]);

  const handleMainCategoryClick = (category) => {
    setActiveMainCategory(category);
    scrollToTop();
  };

  const handleSubCategoryClick = (category) => {
    setActiveSubCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 400, behavior: 'smooth' });
  };

  return (
    <div className="w-[95%] md:w-[70%] mx-auto mb-6 space-y-4">
      <h1 className="text-xl md:text-2xl font-bold my-2 md:my-4">&nbsp;직접 강의 찾기</h1>
      
      <div className="mb-4">
        <h2 className="text-lg font-semibold mb-2">강의 유형 (단일 선택)</h2>
        <p className="text-sm text-gray-600 mb-2">강의의 전체적인 구조를 선택합니다.</p>
        <div className="flex gap-2">
          {mainCategories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 border border-black ${
                activeMainCategory === category
                  ? 'bg-[#d00070] text-white font-bold'
                  : 'bg-white text-black'
              }`}
              onClick={() => handleMainCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      <div>
        <h2 className="text-lg font-semibold mb-2">강의 특성 (다중 선택 가능)</h2>
        <p className="text-sm text-gray-600 mb-2">강의의 세부 특성을 선택합니다. 여러 개를 동시에 선택할 수 있습니다.</p>
        <div className="flex flex-wrap gap-2">
          {subCategories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 border border-black ${
                activeSubCategories.includes(category)
                  ? 'bg-[#d00070] text-white font-bold'
                  : 'bg-white text-black'
              }`}
              onClick={() => handleSubCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterClass;