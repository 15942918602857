import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'; // For reading query strings
import TabA from './TabA';
import TabB from './TabB';
import TabC from './TabC';
import TabD from './TabD';

const tabs = [
  { id: 1, label: '자주 묻는 질문' },
  { id: 2, label: '공지사항' },
  { id: 3, label: '이용 가이드' },
  { id: 4, label: 'CONTACT US' },
];  

const tabTitles = {
  1: '🙋🏻‍♂️ 자주 묻는 질문을 확인해주세요.',
  2: '📣 공지사항을 확인해주세요.',
  3: '📖 이용 가이드를 확인해주세요.',
  4: '❓ CONTACT US',
};

const CustomerTabGroup = () => {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(Number(tab));
    }
  }, [searchParams]);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div className="flex flex-col justify-center items-center py-24 md:py-36">
      <p className="max-w-[900px] w-full text-right text-sm text-[#70C64A] font-extrabold mb-2">
        오른쪽 하단의 1:1 실시간 문의로 문제를 빠르게 해결해보세요.
      </p>
      <div className="max-w-[900px] w-full bg-[#D00070] text-white text-center py-[calc(2px+20px)] px-[calc(2px-10px)] mb-4">
        <h1 className="text-xl">{tabTitles[activeTab]}</h1>
      </div>
      <div className="max-w-[900px] w-full tabs flex justify-between items-center border-b">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`text-sm md:text-2xl py-2 md:py-4 px-3 md:px-6 focus:outline-none ${
              activeTab === tab.id ? 'border-b-4 border-black font-bold' : 'text-[#D00070] opacity-40'
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="max-w-[900px] w-full mt-4 px-[3%]">
        {/* Render the appropriate tab content */}
        {activeTab === 1 && <TabA />}
        {activeTab === 2 && <TabB />}
        {activeTab === 3 && <TabC />}
        {activeTab === 4 && <TabD />}
      </div>
    </div>
  );
};

export default CustomerTabGroup;