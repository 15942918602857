import React from 'react';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';
import { allTilesData } from '../../../../data/AllTileData';

const BlockContents = () => {
  // allTilesData에서 blockTiles 데이터를 사용
  const tiles = allTilesData.blockTiles.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      title1Color={tile.title1Color}
      title2Color={tile.title2Color}
      descriptionColor={tile.descriptionColor}
      img={tile.img}
      width={tile.width}
      height={tile.height}
      mobileWidth={tile.mobileWidth}
      mobileHeight={tile.mobileHeight}
      title1Margin={tile.title1Margin}
      title2Margin={tile.title2Margin}
      status={tile.status}
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4 text-[#000]">
          <span className="text-[#969696]">역량 확인을 위한</span>
          <br />
          블록코딩 테스트
        </h2>
      </div>

      <TileContainer tiles={tiles} containerId="block-contents-scroll" />
    </div>
  );
};

export default BlockContents;