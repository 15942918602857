import React from 'react';
import { useSelector } from 'react-redux';
import LmsActiveClass from '../../components/lms/container/study/LmsActiveClass';
import LmsCurriculumClass from '../../components/lms/container/study/LmsCurriculumClass';
import LmsCompleteClass from '../../components/lms/container/study/LmsCompleteClass';
import LmsAnalytics from '../../components/lms/container/study/LmsAnalytics';
import LmsAnalyticsFoundation from '../../components/lms/container/study/LmsAnalyticsFoundation';

const LmsStudyPage = () => {
  const userData = useSelector((state) => state.auth.user);

  if (!userData) {
    return null; // 사용자 데이터가 없으면 아무것도 렌더링하지 않음
  }

  return (
    <div className="max-w-[1160px] mx-auto bg-white p-4 pt-12 md:pt-20">
      <div className="flex flex-col items-center">
        <div className="w-full flex flex-col items-start">
          {/* <p>이메일: {userData.email}</p> */}
          <LmsAnalytics />
          <LmsActiveClass />
          <LmsCurriculumClass />
          <LmsCompleteClass />
          <LmsAnalyticsFoundation />
        </div>
      </div>
    </div>
  );
};

export default LmsStudyPage;