import React from 'react';
import CareersTile from './CareersTile';

const CareersMain = () => {
  return (
    <div>
      <div className="max-w-4xl mx-auto px-4 pt-36">
            <h2 className="text-5xl font-extrabold mb-12 text-gray-800"><span className="text-[#D00070]">SlopeX</span>celerity</h2>
            <p className="text-lg font-normal text-gray-600 leading-relaxed">
                SlopeXcelerity는 우리의 삶에 필요한 기초공학교육과 관련된 다양한 프로젝트를 시도하는 기업입니다.<br/>
                SlopeX AI Agent를 개발을 중점으로, 인공지능 시대에 필요한 기초공학교육을 제공하고 있습니다.<br/><br/>
            </p>
            <p className="text-lg font-normal text-gray-600 leading-relaxed">
                우리의 AI Agent는 학습자의 수준에 맞추어 학습자가 원하는 목표를 자유자재로 설정할 수 있고, 형식적인 교육 방식을 탈피할 것입니다.<br/><br/>

            </p>
            <p className="text-lg font-normal text-gray-600 leading-relaxed">
                SlopeXcelerity와 함께 기초공학교육과 관련된 다양한 프로젝트를 함께하며 성장할 팀원을 찾고 있습니다.
            </p>
            <br/><br/>
        </div>
        <div className="max-w-full mx-auto ">
            <CareersTile />
        </div>
    </div>
  );
};

export default CareersMain;
