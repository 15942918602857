import React from 'react';
import { privacyPolicy } from './Agreements';

const PrivacyPolicy = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center overflow-x-hidden overflow-y-auto" onClick={onClose}>
      <div className="bg-white p-8 rounded-lg max-w-2xl w-full my-6 mx-auto relative" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold mb-4 text-black pr-8">개인정보 처리방침</h2>
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" aria-label="닫기">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <div className="mb-4 text-black overflow-y-auto max-h-[60vh]">
          <pre className="whitespace-pre-wrap">{privacyPolicy}</pre>
        </div>
        <button onClick={onClose} className="bg-[#D00070] text-white px-4 py-2 rounded hover:bg-[#B0005F]">
          닫기
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;