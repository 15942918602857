import React, { useState } from 'react';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';
import ContentsModal from '../modal/ContentsModal';
import bestBack02 from '../../../../assets/tile/bestBack02.gif';
import bestBack03 from '../../../../assets/tile/bestBack03.png';
import bestBack09 from '../../../../assets/tile/bestBack09.webp';

// tilesData를 컴포넌트 외부로 이동하고 내보냅니다
export const forDevTilesData = [
  {
    title1: '개발자를 꿈꾸는 학습자를 위한 ⭐️',
    title2: '파이썬 + 자료구조 기초과정',
    description: '컴퓨터과학 전공수업 A+ 수준까지',
    img: bestBack02,
    title1Color: '#fff',
    title2Color: '#fff',
    descriptionColor: '#fff',
    width: 'md:w-[400px]',
    height: 'md:h-[500px]',
    mobileWidth: 'w-[310px]',
    mobileHeight: 'h-[450px]',
    title1Margin: 'mb-0',
    title2Margin: 'mb-[330px] md:mb-[375px]',
    status: ['closed', 'ing'],
    modalContent: {
      modalTitle: '코드를 시각화하여 프로그래밍 이해하기 🧠',
      modalDetails: `단순히 문법만 배우는 공부로는 인공지능 개발자로 성장하기 어렵습니다.
                      메모리를 시각화하여 내가 작성하는 코드가 어떻게 실행되는지 바닥부터 차근차근 설명해드립니다.
                      양질의 개발자로 성장하기 위한 발판을 단단하게 다져드립니다.

                      지난 9년간 쌓아온 강의경력을 바탕으로 구성된 01CS의 파이썬 교육은
                      유튜브, 책을 보면서 공부할때는 들을 수 없는 IT기술동향과 개발자에 대한 이야기도 함께 들려드립니다.
                      
                      공학계열에 관심있는 비전공자, 청소년도 들을 수 있는 수준으로
                      대학교 전공수업 A+ 수준을 목표로 교육이 진행됩니다.
                      매주 알고리즘 과제도 있습니다. 😎`,
      modalExtraInfo: '강의 신청하기 〉',
      // modalLink: '/courses/python-basics'
    },
  },
  {
    title1: 'OpenAI API 정복하기 ⛰️',
    title2: 'AI 서비스 개발 따라하기',
    description: 'API 사용법과 챗봇 개발 프로젝트',
    img: bestBack03,
    title1Color: '#00069A',
    title2Color: '#00069A',
    descriptionColor: '#000',
    width: 'md:w-[400px]',
    height: 'md:h-[500px]',
    mobileWidth: 'w-[310px]',
    mobileHeight: 'h-[450px]',
    title1Margin: 'mb-0',
    title2Margin: 'mb-[330px] md:mb-[375px]',
    status: ['closed', 'ing'],
    modalContent: {
      modalTitle: '프로젝트 위주의 교육👾 서비스 개발까지🚀',
      modalDetails: `해당 강의는 파이썬 + 자료구조 기초과정을
                      수료한 학습자만 신청할 수 있습니다.
                      
                      가장 많이 알려진 GPT모델을 사용하여,
                      다양한 프로젝트를 진행해보는 강의입니다.
                      
                      OpenAI API를 사용하여
                      TTS, Finetune, Embedding, Chatbot 등
                      유튜브를 보고 따라하기 힘든 부분까지 알려드립니다.

                      코치가 함께 에러를 해결해주고,
                      원하는 서비스를 개발할 수 있도록 돕습니다.
                      `,
      modalExtraInfo: '강의 신청하기 〉',
      // modalLink: '/courses/ai-service-dev'
    },
  },
  {
    title1: '기획, GPT, Figma 기초 👾',
    title2: '웹 개발 따라하기',
    description: '바닥부터 시작하는 누구나 가능한 웹 개발',
    img: bestBack09,
    title1Color: '#027400',
    title2Color: '#027400',
    descriptionColor: '#027400',
    width: 'md:w-[400px]',
    height: 'md:h-[500px]',
    mobileWidth: 'w-[310px]',
    mobileHeight: 'h-[450px]',
    title1Margin: 'mb-0',
    title2Margin: 'mb-[330px] md:mb-[375px]',
    status: ['closed', 'ing'],
    modalContent: {
      modalTitle: 'AI와 함께하는 웹 개발 입문 🌐',
      modalDetails: `소프트웨어의 기본 구조를 이해하고 AI 도구를 활용하여 
                     웹/앱 개발의 기초를 배웁니다. 
                     기획부터 디자인, 개발까지 전반적인 과정을 경험해볼 수 있습니다.

                     주요 학습 내용:
                     - 소프트웨어 구조의 이해
                     - 생성형 AI를 활용한 코드 생성 및 문제 해결
                     - Figma를 이용한 기본적인 UI/UX 디자인
                     - CURSOR 개발환경
                     - Firebase 서버/DB 사용
                     - HTML, CSS, JavaScript 기초
                     - React, TypeScript, Tailwind CSS 등 프레임워크 활용
                     - 간단한 웹 애플리케이션 제작 프로젝트

                     이 과정을 통해 AI 도구를 효과적으로 활용하여 
                     웹 개발의 기초를 쌓을 수 있습니다.`,
      modalExtraInfo: '강의 신청하기 〉',
      // modalLink: '/courses/ai-web-dev-basics'
    },
  },
];

const ForDevContents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  const openModal = (tile) => {
    setSelectedTile(tile);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 타일들을 렌더링
  const tiles = forDevTilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      title1Color={tile.title1Color}
      title2Color={tile.title2Color}
      descriptionColor={tile.descriptionColor}
      img={tile.img}
      backgroundColor={tile.backgroundColor}
      width={tile.width} // 데스크탑 너비 적용
      height={tile.height} // 데스크탑 높이 적용
      mobileWidth={tile.mobileWidth} // 모바일 너비 적용
      mobileHeight={tile.mobileHeight} // 모바일 높이 적용
      title1Margin={tile.title1Margin} // title1과 title2 간의 간격
      title2Margin={tile.title2Margin} // title2와 description 간의 간격
      status={tile.status} // status prop 추가
      onClick={() => openModal(tile)}
    />
  ));

  return (
    <div className="relative w-full py-8 mt-6">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4 text-[#969696]">
          바닥부터 시작하는<br />
          <span className="text-[#000]">프로그래밍 학습</span>
        </h2>
      </div>
      <TileContainer tiles={tiles} containerId="fordev-tile-scroll" />
      {selectedTile && (
        <ContentsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          imgSrc={selectedTile.img}
          title1={selectedTile.title1}
          title2={selectedTile.title2}
          description={selectedTile.description}
          modalContent={selectedTile.modalContent}
          status={selectedTile.status}
        />
      )}
    </div>
  );
};

export default ForDevContents;