import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import ProblemSolver from '../lms/container/class/problem/ProblemSolver';
import { AuthContext } from '../../context/AuthContext';
import { LearningDataService } from '../lms/container/LearningData/LearningDataService';
import Hint from '../lms/container/class/problem/Hint';
import { algorithmCategories } from '../../data/pythonAlgorithmProblems';
import Skeleton from '../skeleton/Skeleton';

const PythonAlgorithm = ({ problems, categoryId }) => {
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [userCodes, setUserCodes] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [attempts, setAttempts] = useState({});
  const [lastAttemptDates, setLastAttemptDates] = useState({});
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showHint, setShowHint] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const problemTitleRef = useRef(null);

  const moduleType = 'python-algorithm';
  const chapterId = 'python-algorithm-chapter'; // 가상의 챕터 ID

  useEffect(() => {
    if (user && problems.length > 0) {
      loadUserData();
    } // eslint-disable-next-line
  }, [user, problems]);

  useEffect(() => {
    // 카테고리가 변경될 때마다 selectedProblem을 null로 초기화
    setSelectedProblem(null);
  }, [categoryId]);

  const scrollToProblemTitle = useCallback(() => {
    if (problemTitleRef.current) {
      setTimeout(() => {
        const yOffset = -40; // 40px 위로 추가 스크롤
        const y = problemTitleRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (selectedProblem) {
      scrollToProblemTitle();
    }
  }, [selectedProblem, scrollToProblemTitle]);

  useEffect(() => {
    // 1.5초 동안 Skeleton 컴포넌트 표시
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  // eslint-disable-next-line
  const loadUserData = async () => {
    if (!user || problems.length === 0) {
      console.error("Missing required data: user or problems");
      return;
    }

    setIsLoading(true);
    try {
      const answers = await LearningDataService.getUserAnswers(user.uid, moduleType, chapterId);
      const codes = await LearningDataService.getUserCodes(user.uid, moduleType, chapterId);
      const correct = await LearningDataService.getCorrectAnswers(user.uid, moduleType, chapterId);
      const attemptCounts = await LearningDataService.getAttempts(user.uid, moduleType, chapterId);
      const lastAttempts = await LearningDataService.getLastAttemptDates(user.uid, moduleType, chapterId);
      setUserAnswers(answers);
      setUserCodes(codes);
      setCorrectAnswers(correct);
      setAttempts(attemptCounts);
      setLastAttemptDates(lastAttempts);
    } catch (error) {
      console.error("Error loading user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveUserAnswer = async (answer) => {
    if (selectedProblem && user) {
      try {
        await LearningDataService.saveUserAnswer(user.uid, moduleType, chapterId, selectedProblem.problemId, answer);
        setUserAnswers(prev => ({
          ...prev,
          [selectedProblem.problemId]: answer
        }));
      } catch (error) {
        console.error("Error saving user answer:", error);
      }
    }
  };

  const saveUserCode = async (code) => {
    if (selectedProblem && user) {
      try {
        await LearningDataService.saveUserCode(user.uid, moduleType, chapterId, selectedProblem.problemId, code);
        setUserCodes(prev => ({
          ...prev,
          [selectedProblem.problemId]: code
        }));
      } catch (error) {
        console.error("Error saving user code:", error);
      }
    }
  };

  const setIsAnswerCorrect = async (isCorrect) => {
    if (selectedProblem && user) {
      try {
        const newAttemptCount = (attempts[selectedProblem.problemId] || 0) + 1;
        const currentDate = new Date();
        await LearningDataService.saveCorrectAnswer(user.uid, moduleType, chapterId, selectedProblem.problemId, isCorrect);
        await LearningDataService.saveAttempt(user.uid, moduleType, chapterId, selectedProblem.problemId, newAttemptCount, currentDate);
        setCorrectAnswers(prev => ({...prev, [selectedProblem.problemId]: isCorrect}));
        setAttempts(prev => ({...prev, [selectedProblem.problemId]: newAttemptCount}));
        setLastAttemptDates(prev => ({...prev, [selectedProblem.problemId]: currentDate}));
      } catch (error) {
        console.error("Error saving answer data:", error);
      }
    }
  };

  const renderProblemTile = (problem, index) => {
    const isSolved = correctAnswers[problem.problemId] === true;
    const attemptCount = attempts[problem.problemId] || 0;
    let lastAttempt = '풀이 기록없음';
    
    if (lastAttemptDates[problem.problemId]) {
      if (typeof lastAttemptDates[problem.problemId] === 'object' && lastAttemptDates[problem.problemId].toDate) {
        lastAttempt = lastAttemptDates[problem.problemId].toDate().toLocaleString();
      } else if (lastAttemptDates[problem.problemId] instanceof Date) {
        lastAttempt = lastAttemptDates[problem.problemId].toLocaleString();
      } else {
        lastAttempt = new Date(lastAttemptDates[problem.problemId]).toLocaleString();
      }
    }

    // 난이도를 Level로 표시하는 함수
    const renderDifficultyLevel = (difficulty) => {
      return (
        <div className="flex items-center text-sm text-gray-600">
          <span className="mr-1">Level:</span>
          <span className="font-bold">{difficulty}</span>
        </div>
      );
    };

    return (
      <div 
        key={problem.problemId}
        className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
        onClick={() => setSelectedProblem(problem)}
      >
        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium text-gray-500">#{index + 1}</span>
            <span className={`text-sm font-extrabold text-white px-2 py-1 rounded ${isSolved ? 'bg-green-500' : 'bg-red-500'}`}>
              {isSolved ? 'Y' : 'N'}
            </span>
          </div>
          {renderDifficultyLevel(problem.difficulty)}
          <h3 className="text-lg font-semibold text-gray-800 mt-2 mb-2">{problem.title}</h3>
          <div className="mt-4 text-xs text-gray-500 flex flex-col">
            <span>{attemptCount}회 시도</span>
            <span>{lastAttempt}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderProblemDetails = () => {
    if (selectedProblem.type === 'multiple_choice') {
      return (
        <div className="mb-8">
          <h2 ref={problemTitleRef} className="text-2xl font-bold mb-4">{selectedProblem.title}</h2>
          <p className="text-gray-700 mb-4 whitespace-pre-wrap">{selectedProblem.description}</p>
          {selectedProblem.image && (
            <img src={selectedProblem.image} alt="문제 이미지" className="mb-4 max-w-full h-auto" />
          )}
          <div className="space-y-2 mt-4">
            {selectedProblem.choices.map((choice) => (
              <div
                key={choice.id}
                className={`p-4 border rounded-md cursor-pointer transition-all ${
                  userAnswers[selectedProblem.problemId] === choice.id
                    ? "bg-blue-100 border-blue-500"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleAnswerChange(choice.id)}
              >
                <div className="flex items-center">
                  <span className="flex-grow whitespace-pre-wrap">{choice.text}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-between">
            <button
              onClick={() => setShowHint(!showHint)}
              className="bg-white text-black px-4 py-2 rounded-md border-2 border-gray-300 font-bold hover:bg-gray-100 transition-colors duration-200"
            >
              {showHint ? "Hide Hint 🙈" : "Show Hint 💭"}
            </button>
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-white px-4 py-2 rounded-md font-bold hover:bg-green-600 transition-colors duration-200"
            >
              Check ✅
            </button>
          </div>
          {showHint && (
            <div className="mt-4 p-4 rounded-md">
              <Hint hints={selectedProblem.hints} />
            </div>
          )}
          {showResult && (
            <div className={`mt-4 p-4 rounded-md text-center font-extrabold ${isCorrect ? 'bg-green-200' : 'bg-red-200'}`}>
              <p className={isCorrect ? 'text-green-600' : 'text-red-600'}>
                {isCorrect ? 'Correct! 🎉' : 'Try again. You can do it! 💪'}
              </p>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="mb-8 bg-white shadow-lg rounded-lg overflow-hidden">
          <div ref={problemTitleRef} className="bg-blue-600 text-white p-6">
            <h2 className="text-3xl font-bold">{selectedProblem.title}</h2>
          </div>
          <div className="p-6">
            <p className="text-gray-700 mb-6 text-lg leading-relaxed whitespace-pre-wrap">{selectedProblem.description}</p>
            {selectedProblem.image && (
              <img src={selectedProblem.image} alt="문제 이미지" className="mb-6 rounded-lg shadow-md max-w-full h-auto" />
            )}
            <div className="bg-gray-50 p-6 rounded-lg mb-6 shadow-inner">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-blue-600">입력 설명</h3>
                  <p className="text-gray-700">{selectedProblem.input.description}</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-blue-600">출력 설명</h3>
                  <p className="text-gray-700">{selectedProblem.output.description}</p>
                </div>
              </div>
            </div>
            {selectedProblem.examples && selectedProblem.examples.length > 0 && (
              <div className="space-y-6">
                {selectedProblem.examples.map((example, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg shadow-inner">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <h4 className="text-lg font-medium text-blue-600 mb-2">입력 예시:</h4>
                        <pre className="bg-white p-4 rounded-md shadow-sm overflow-x-auto">{example.input || ''}</pre>
                      </div>
                      <div>
                        <h4 className="text-lg font-medium text-blue-600 mb-2">출력 예시:</h4>
                        <pre className="bg-white p-4 rounded-md shadow-sm overflow-x-auto">{example.output}</pre>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  const handleAnswerChange = (choiceId) => {
    setUserAnswers(prev => ({
      ...prev,
      [selectedProblem.problemId]: choiceId
    }));
    setShowResult(false);
  };

  const handleSubmit = async () => {
    const isCorrect = userAnswers[selectedProblem.problemId] === selectedProblem.correctAnswer;
    setIsCorrect(isCorrect);
    setShowResult(true);
    await setIsAnswerCorrect(isCorrect);
    // 여기에 정답 제출 후의 로직을 추가할 수 있습니다.
  };

  // 카테고리에 해당하는 문제들의 풀이 현황을 계산하는 함수
  const calculateProgress = () => {
    const categoryProblems = problems.filter(problem => problem.category === getCategoryTitle());
    const totalProblems = categoryProblems.length;
    const solvedProblems = categoryProblems.filter(problem => correctAnswers[problem.problemId]).length;
    return { totalProblems, solvedProblems };
  };

  const renderProgressTile = () => {
    const { solvedProblems, totalProblems } = calculateProgress();
    const progressPercentage = (solvedProblems / totalProblems) * 100;

    return (
      <div 
        className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
      >
        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium text-gray-500">풀이 현황</span>
            <span className="text-sm font-extrabold text-white px-2 py-1 rounded bg-[#d00070]">
              {solvedProblems}/{totalProblems}
            </span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <span className="mr-1">진행률:</span>
            <span className="font-bold">{progressPercentage.toFixed(1)}%</span>
          </div>
          <div className="mt-4 w-full bg-gray-200 rounded-full h-2.5">
            <div 
              className="bg-[#d00070]/70 h-2.5 rounded-full" 
              style={{width: `${progressPercentage}%`}}
            ></div>
          </div>
        </div>
      </div>
    );
  };

  // 카테고리 제목을 가져오는 함수
  const getCategoryTitle = () => {
    const category = algorithmCategories.find(cat => cat.id === categoryId);
    return category ? category.title : 'Python Algorithm Problems';
  };

  // 현재 카테고리에 해당하는 문제들만 필터링하는 함수
  const filterProblemsByCategory = () => {
    return problems.filter(problem => problem.category === getCategoryTitle());
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-10 text-center">{getCategoryTitle()}</h1>
      
      {!selectedProblem ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {renderProgressTile()}
          {filterProblemsByCategory().map((problem, index) => renderProblemTile(problem, index))}
        </div>
      ) : (
        <div className="max-w-3xl mx-auto">
          <div ref={problemTitleRef}></div> {/* 스크롤 타겟 요소 */}
          {renderProblemDetails()}
          {selectedProblem.type === 'code' && (
            <ProblemSolver 
              problem={selectedProblem}
              setIsAnswerCorrect={setIsAnswerCorrect}
              savedAnswer={userAnswers[selectedProblem.problemId]}
              saveUserAnswer={saveUserAnswer}
              savedCode={userCodes[selectedProblem.problemId]}
              saveUserCode={saveUserCode}
              isAnswerCorrect={correctAnswers[selectedProblem.problemId]}
              isLastProblem={false}
            />
          )}
          <div className="flex justify-center">
            <button 
              onClick={() => setSelectedProblem(null)}
              className="mb-6 px-6 py-3 bg-white text-black font-semibold rounded-full shadow-md border-2 border-black hover:bg-gray-100 transition duration-300 ease-in-out flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              문제 목록으로 돌아가기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PythonAlgorithm;
