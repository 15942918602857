import React from 'react';

const UserInfoForm = ({
  formData,
  isEditing,
  borderPulse,
  statusMessage,
  programmingOptions,
  learningSpeedOptions,
  learningChallenges,
  collaborationPreferences,
  progressTrackingOptions,
  preferredLearningTimes,
  updateFormData,
  handleLearningChallengeClick,
  handlePreferredTimeClick,
}) => {
  return (
    <div className={`p-4 md:p-6 rounded-lg ${borderPulse ? 'border-[#D00070]' : 'border-gray-200'} transition-colors duration-300`}>
      {statusMessage && (
        <div className="mb-4 p-3 bg-green-100 text-green-700 rounded text-sm md:text-base">
          {statusMessage}
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
        {/* 이름 */}
        <div className="mb-0 md:mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            닉네임
          </label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={(e) => updateFormData('name', e.target.value)}
            disabled={!isEditing}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm md:text-base"
          />
        </div>

        {/* 이메일 */}
        <div className="mb-4 md:mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            이메일
          </label>
          <input
            type="email"
            id="email"
            value={formData.email}
            disabled={true}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm md:text-base bg-gray-100"
          />
        </div>
      </div>

      {/* 전화번호 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
          전화번호
        </label>
        <input
          type="tel"
          id="phoneNumber"
          value={formData.phoneNumber}
          onChange={(e) => updateFormData('phoneNumber', e.target.value)}
          disabled={!isEditing}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm md:text-base"
        />
      </div>

      {/* 프로그래밍 경험 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          프로그래밍 학습경험
        </label>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
          {programmingOptions.map((option, index) => (
            <button
              key={index}
              className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
                formData.programmingExperience === option
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              } ${!isEditing && 'cursor-not-allowed'}`}
              onClick={() => updateFormData('programmingExperience', option)}
              disabled={!isEditing}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* 선호하는 학습 속도 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          선호하는 학습 속도
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
          {learningSpeedOptions.map((option, index) => (
            <button
              key={index}
              className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
                formData.learningSpeed === option
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              } ${!isEditing && 'cursor-not-allowed'}`}
              onClick={() => updateFormData('learningSpeed', option)}
              disabled={!isEditing}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* 학습 챌린지 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          학습 챌린지(복수 선택가능)
        </label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
          {learningChallenges.map((challenge, index) => (
            <label
              key={index}
              className={`flex items-center p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors bg-gray-100 hover:bg-gray-200 cursor-pointer ${!isEditing && 'cursor-not-allowed'}`}
            >
              <input
                type="checkbox"
                className="hidden"
                checked={(formData.learningChallenges || []).includes(challenge)}
                onChange={() => handleLearningChallengeClick(challenge)}
                disabled={!isEditing}
              />
              <span className={`w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 inline-block border-2 rounded-md ${
                (formData.learningChallenges || []).includes(challenge)
                  ? 'bg-[#D00070] border-[#D00070]'
                  : 'border-gray-400'
              }`}>
                {(formData.learningChallenges || []).includes(challenge) && (
                  <svg className="w-3 h-3 md:w-4 md:h-4 text-white fill-current" viewBox="0 0 20 20">
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                  </svg>
                )}
              </span>
              <span className={`${(formData.learningChallenges || []).includes(challenge) ? 'text-[#D00070] font-semibold' : 'text-gray-700'}`}>
                {challenge}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* 협업 선호도 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          협업 선호도
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
          {collaborationPreferences.map((preference, index) => (
            <button
              key={index}
              className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
                formData.collaborationPreference === preference
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              } ${!isEditing && 'cursor-not-allowed'}`}
              onClick={() => updateFormData('collaborationPreference', preference)}
              disabled={!isEditing}
            >
              {preference}
            </button>
          ))}
        </div>
      </div>

      {/* 진도 추적 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          진도 추적
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
          {progressTrackingOptions.map((option, index) => (
            <button
              key={index}
              className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
                formData.progressTracking === option
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              } ${!isEditing && 'cursor-not-allowed'}`}
              onClick={() => updateFormData('progressTracking', option)}
              disabled={!isEditing}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* 선호하는 학습시간대 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          선호하는 학습시간대(최대 2개 선택)
        </label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
          {preferredLearningTimes.map((time, index) => (
            <button
              key={index}
              className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
                (formData.preferredLearningTimes || []).includes(time)
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              } ${!isEditing && 'cursor-not-allowed'}`}
              onClick={() => handlePreferredTimeClick(time)}
              disabled={!isEditing}
            >
              {time}
            </button>
          ))}
        </div>
      </div>

      {/* 보충 학습 추천 */}
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          보충 학습 추천
        </label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
          <button
            className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
              formData.supplementaryLearning === true
                ? 'bg-[#D00070] text-white font-extrabold'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            } ${!isEditing && 'cursor-not-allowed'}`}
            onClick={() => updateFormData('supplementaryLearning', true)}
            disabled={!isEditing}
          >
            추천 받기
          </button>
          <button
            className={`p-2 md:p-3 rounded-lg text-xs md:text-sm transition-colors ${
              formData.supplementaryLearning === false
                ? 'bg-[#D00070] text-white font-extrabold'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            } ${!isEditing && 'cursor-not-allowed'}`}
            onClick={() => updateFormData('supplementaryLearning', false)}
            disabled={!isEditing}
          >
            추천 받지 않기
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoForm;