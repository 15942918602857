import React from 'react';
import { PolarArea } from 'react-chartjs-2';

const StudyPatternChart = ({ data }) => {
  const chartData = {
    labels: ['아침', '오후', '저녁', '밤'],
    datasets: [
      {
        label: '학습 집중도',
        data: data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
        ],
      },
    ],
  };

  return <PolarArea data={chartData} />;
};

export default StudyPatternChart;