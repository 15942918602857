import React, { useState } from 'react';
import { useSelector } from 'react-redux'; // Redux에서 유저 데이터 가져오기
import { menuItems } from './menuItems';

const LmsTopNavMobile = ({ onNavigate }) => { // onNavigate prop 추가
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('');

  // Redux에서 user 데이터 가져오기
  const user = useSelector((state) => state.auth.userData);
  const isSloper = user?.isSloper;

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const openSubmenu = (menu) => {
    setIsAnimating(true);
    setAnimationDirection('forward');
    setTimeout(() => {
      setCurrentMenu(menu);
      setIsAnimating(false);
    }, 300);
  };

  const goBack = () => {
    setIsAnimating(true);
    setAnimationDirection('backward');
    setTimeout(() => {
      setCurrentMenu(null);
      setIsAnimating(false);
    }, 300);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    onNavigate(path); // onNavigate 사용
    setIsMenuOpen(false);
  };

  // sloper 권한이 필요한 메뉴 필터링
  const filteredMenuItems = menuItems.map(item => {
    if (item.content && item.content.subMenu) {
      return {
        ...item,
        content: {
          ...item.content,
          subMenu: item.content.subMenu.filter(subMenu => 
            !subMenu.requiresSloper || (subMenu.requiresSloper && isSloper)
          )
        }
      };
    }
    return item;
  });

  return (
    <>
      <nav className="bg-white fixed top-0 left-0 right-0 z-50">
        <div className="flex justify-between items-center h-14 pl-4 pr-2">
          <div className="w-26 h-6">
            {!isMenuOpen && (
              <a
                href="/lms/home"
                className="font-bold text-[15px]"
                onClick={(e) => handleClick(e, '/lms/home')}
              >
                <span className="text-[#D00070]">SlopeX AI</span>
              </a>
            )}
            {isMenuOpen && currentMenu && (
              <button onClick={goBack} className="text-2xl">
                〈
              </button>
            )}
          </div>
          <button onClick={toggleMenu} className="w-6 h-6 flex flex-col justify-center items-center">
            {isMenuOpen ? (
              <svg viewBox="0 0 24 24" className="w-6 h-6 stroke-current">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" className="w-6 h-6 stroke-current">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </nav>
      <div
        className={`fixed inset-0 bg-white pl-6 z-40 transition-transform duration-300 ease-in-out ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{ top: '56px', height: 'calc(100% - 56px)' }}
      >
        <div className="h-full overflow-y-auto">
          {currentMenu ? (
            <div className={`p-6 transition-all duration-300 ${
              isAnimating && animationDirection === 'backward' ? 'opacity-0 translate-y-full' : 'opacity-100 translate-y-0'
            }`}>
              <ul className="space-y-6">
                {currentMenu.content.mainMenu.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.link}
                      className="text-2xl font-extrabold"
                      onClick={(e) => {
                        handleClick(e, item.link);
                        toggleMenu();
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="mt-20">
                {currentMenu.content.subMenu.map((subMenu, index) => (
                  <div key={index} className="mb-16">
                    <h3 className="text-md text-gray-600 mb-4">{subMenu.title}</h3>
                    <ul className="space-y-3">
                      {subMenu.items.map((item) => (
                        <li key={item.name}>
                          {item.target === '_blank' ? (
                            <a 
                              href={item.link} 
                              className="text-md font-bold text-black"
                            >
                              {item.name}
                            </a>
                          ) : (
                            <a
                              href={item.link}
                              className="text-md font-bold text-black"
                              onClick={(e) => {
                                handleClick(e, item.link);
                                toggleMenu();
                              }}
                            >
                              {item.name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <ul className={`p-6 space-y-6 transition-all duration-300 ${
              isAnimating && animationDirection === 'forward' ? 'opacity-0 -translate-y-full' : 'opacity-100 translate-y-0'
            }`}>
              {filteredMenuItems.map((item, index) => (
                <li key={item.name} className="transition-all duration-300" style={{
                  transitionDelay: `${index * 50}ms`,
                  opacity: isAnimating ? 0 : 1,
                  transform: isAnimating ? 'translateX(-20px)' : 'translateX(0)'
                }}>
                  <button 
                    onClick={() => openSubmenu(item)} 
                    className="text-2xl font-extrabold w-full text-left"
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default LmsTopNavMobile;