import React from 'react';

const ZoomPage = () => {
  // const meetingID = '326 697 2978';
  // const password = 'NiM9CA';
  const meetingID = '*** *** ****';
  const password = '******';
  // const link = 'https://us06web.zoom.us/j/3266972978?pwd=UGZoeCtJQXpsVXMwb04wUmFoaGZGUT09';

  // const handleJoinMeeting = () => {
  //   window.open(link, '_blank');
  // };

  return (
    <div className="container mx-auto p-8 bg-white rounded-lg py-36">
      <h1 className="text-3xl font-extrabold mb-6 text-center text-blue-600">Zoom 강의실</h1>
      <div className="mb-6">
        <p className="text-lg font-medium text-gray-800 mb-2">수업 날짜: <span className="font-bold text-blue-600">2024년 12월 30일까지</span></p>
        <p className="text-lg font-medium text-gray-800 mb-2">강의 시간: <span className="font-bold text-blue-600">매일 17시 ~ 23시</span></p>
        <p className="text-lg font-medium text-gray-800 mb-2 mt-6">현재는 직접 줌 링크를 가지고 있는 학습자만 입장가능합니다.</p>
        <p className="text-lg font-medium text-gray-800 mb-2">만약 입장이 안될 경우, 관리자에게 문의해주세요.</p>
      </div>
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <p className="text-lg font-medium text-gray-800 mb-2">회의 ID: <span className="font-bold text-blue-600">{meetingID}</span></p>
        <p className="text-lg font-medium text-gray-800 mb-2">비밀번호: <span className="font-bold text-blue-600">{password}</span></p>
      </div>
      {/* <div className="text-center">
        <button
          onClick={handleJoinMeeting}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-5 px-8 rounded-full focus:outline-none focus:shadow-outline transition duration-300 text-lg"
        >
          강의실 입장하기
        </button>
      </div> */}
    </div>
  );
};

export default ZoomPage;

