import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { usePreventScroll } from '../../../../hook/usePreventScroll';
import { statusStyles } from '../contents/tileComponents/Tile'; // statusStyles import 추가
import { Link } from 'react-router-dom'; // Link 추가

const ContentsModal = ({ isOpen, onClose, modalContent, status, openInNewTab }) => {
  usePreventScroll(isOpen);

  if (!isOpen) return null;

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  // getCurriculumLink 함수 수정
  const getCurriculumLink = () => {
    if (modalContent && modalContent.moduleType) {
      return `/lms/curriculum/${modalContent.moduleType}`;
    }
    return modalContent.modalLink;
  };

  return (
    <div 
      className="fixed inset-0 z-[1000] overflow-y-auto bg-[#fff]/60 bg-opacity-70 backdrop-blur-sm"
      aria-labelledby="modal-title" 
      role="dialog" 
      aria-modal="true"
      onClick={onClose}
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <div 
          className="bg-white rounded-lg overflow-hidden shadow-xl w-full max-w-[370px] sm:max-w-[600px] pt-6 flex flex-col max-h-[80vh] relative"
          onClick={handleContentClick}
        >
          <button
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl"
            onClick={onClose}
          >
            <FaTimes />
          </button>

          <div className="p-6 flex-shrink-0">
            {/* Status 표시 */}
            <div className="flex justify-center gap-2 mb-4">
              {Array.isArray(status) && status.map((stat, index) => {
                const currentStatus = statusStyles[stat];
                if (currentStatus) {
                  return (
                    <span key={index} className={`${currentStatus.bgColor} ${currentStatus.textColor} text-xs font-bold px-2 py-1 rounded`}>
                      {currentStatus.text}
                    </span>
                  );
                }
                return null;
              })}
            </div>

            {modalContent && (
              <h4 className="text-lg font-bold text-center mt-4 -mb-6 whitespace-pre-line">{modalContent.modalTitle}</h4>
            )}
          </div>

          {modalContent && (
            <div className="p-6 overflow-y-auto flex-grow">
              <p className="text-gray-600 text-center whitespace-pre-line">{modalContent.modalDetails}</p>
            </div>
          )}

          {modalContent && modalContent.modalExtraInfo && (
            <div className="p-6 border-t border-gray-200 flex-shrink-0">
              {modalContent.moduleType ? (
                <Link
                  to={getCurriculumLink()}
                  target={openInNewTab ? "_blank" : "_self"}
                  rel={openInNewTab ? "noopener noreferrer" : ""}
                  className="text-[#D00070] font-bold hover:underline text-center block"
                >
                  {modalContent.modalExtraInfo}
                </Link>
              ) : (
                <a
                  href={modalContent.modalLink}
                  target={openInNewTab ? "_blank" : "_self"}
                  rel={openInNewTab ? "noopener noreferrer" : ""}
                  className="text-[#D00070] font-bold hover:underline text-center block"
                >
                  {modalContent.modalExtraInfo}
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentsModal;