import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth, db } from '../../../Firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { setUserData } from '../../../features/authSlice';
import UserInfoForm from './UserInfoForm';
import MoreOptions from './MoreOptions';
import { onAuthStateChanged } from 'firebase/auth';
import LmsAnalyticsFoundation from '../container/study/LmsAnalyticsFoundation';

const MyInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    programmingExperience: '',
    learningSpeed: '',
    learningChallenges: [],
    collaborationPreference: '',
    progressTracking: '',
    preferredLearningTimes: [],
    supplementaryLearning: null
  });
  const [isEditing, setIsEditing] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [borderPulse, setBorderPulse] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [editingMessage, setEditingMessage] = useState('');
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

  const programmingOptions = ['없음', '블록코딩', '텍스트코딩'];
  const learningSpeedOptions = ['빠르게: 개념 위주의 학습', '보통: 밸런스있는 학습', '천천히: 깊이 있는 학습'];
  const learningChallenges = ['컴퓨팅 사고력 향상', '알고리즘 테스트', '수리력 강화', '개발자 도전', 'Web/App 개발', '생성형AI 활용', '인공지능 개발', '수학역량 강화'];
  const collaborationPreferences = ['개인 학습 선호', '협업 학습 선호'];
  const progressTrackingOptions = ['매일(정해진 시간에 루틴으로)', '주간(일주일 학습시간을 정하고 주도적으로)'];
  const preferredLearningTimes = ['평일 오전', '평일 오후', '주말 오전', '주말 오후'];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFormData({
              name: userData.name || '',
              email: currentUser.email || '',
              phoneNumber: userData.phoneNumber || '',
              programmingExperience: userData.programmingExperience || '',
              learningSpeed: userData.learningSpeed || '',
              learningChallenges: userData.learningChallenges || [],
              collaborationPreference: userData.collaborationPreference || '',
              progressTracking: userData.progressTracking || '',
              preferredLearningTimes: userData.preferredLearningTimes || [],
              supplementaryLearning: userData.supplementaryLearning
            });
            dispatch(setUserData(userData));
          }
        } catch (error) {
          console.error('사용자 데이터 가져오기 오류:', error);
          setStatusMessage('사용자 데이터를 가져오는 중 오류가 발생했습니다.');
          setTimeout(() => {
            setStatusMessage('');
          }, 3000);
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [dispatch, navigate]);

  useEffect(() => {
    let intervalId;
    if (isEditing) {
      intervalId = setInterval(() => {
        setBorderPulse(prev => !prev);
      }, 3000);
    }
    return () => clearInterval(intervalId);
  }, [isEditing]);

  const handleEdit = () => {
    setIsEditing(true);
    setSaveStatus('');
    setEditingMessage('개인정보를 수정중입니다.');
  };

  const handleSave = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), formData);
        setIsEditing(false);
        setEditingMessage('');
        setStatusMessage('정보가 성공적으로 업데이트되었습니다.');
        setTimeout(() => {
          setStatusMessage('');
        }, 3000);
        
        // Redux 스토어 업데이트
        dispatch(setUserData({
          ...formData, 
          isSloper: userData.isSloper, 
          isAdmin: userData.isAdmin, 
          isFree: userData.isFree
        }));
      } catch (error) {
        console.error('Error updating user info:', error);
        setStatusMessage('정보 업데이트 중 오류가 발생했습니다.');
        setTimeout(() => {
          setStatusMessage('');
        }, 3000);
      }
    }
  };

  const updateFormData = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleLearningChallengeClick = (challenge) => {
    const currentSelections = formData.learningChallenges;
    if (currentSelections.includes(challenge)) {
      updateFormData('learningChallenges', currentSelections.filter(item => item !== challenge));
    } else {
      updateFormData('learningChallenges', [...currentSelections, challenge]);
    }
  };

  const handlePreferredTimeClick = (time) => {
    const currentSelections = formData.preferredLearningTimes;
    if (currentSelections.includes(time)) {
      updateFormData('preferredLearningTimes', currentSelections.filter(item => item !== time));
    } else if (currentSelections.length < 2) {
      updateFormData('preferredLearningTimes', [...currentSelections, time]);
    }
  };

  const handleAccountDeleted = () => {
    navigate('/goodbye');
  };

  const handleCloseModal = (e) => {
    if (e.target === e.currentTarget) {
      setShowAnalyticsModal(false);
    }
  };

  return (
    <div>
      <div className="container mx-auto p-4 max-w-[900px] mt-4 md:mt-8 relative">
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 rounded">
          <div className="flex justify-between items-center">
            <h2 className="font-bold mb-2">&nbsp;Sloper 메시지</h2>
            <button
              onClick={() => setShowAnalyticsModal(true)}
              className="text-blue-600 hover:text-blue-800"
            >
              [ 자세히 보기 ]
            </button>
          </div>
          <p>
            &nbsp;학습 경험을 공유해주시면, 효율적인 학습과 목표까지 도달하기 위한 다양한 서비스를 제공해드리겠습니다.
            <br/>&nbsp;학습 경험 데이터는 적응형 학습 기술구현과 서비스 개선을 위해 활용되며, 절대 다른 용도로 이용하지 않습니다.
          </p>
        </div>
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={() => navigate(-1)}
            className="bg-[#fff] hover:bg-[#d00070]/10 text-black font-bold py-2 px-4 rounded"
          >
            ◀︎ 뒤로
          </button>
          <button
            onClick={isEditing ? handleSave : handleEdit}
            className="bg-[#fff] hover:bg-[#d00070]/10 text-black font-bold py-2 px-4 rounded"
          >
            {isEditing ? '저장하기 🚀' : '수정하기 🎯'}
          </button>
        </div>
        <h1 className="text-2xl font-bold mt-4 text-center">내 정보 관리하기</h1>
        <UserInfoForm
          formData={formData}
          isEditing={isEditing}
          borderPulse={borderPulse}
          statusMessage={isEditing ? editingMessage : statusMessage}
          programmingOptions={programmingOptions}
          learningSpeedOptions={learningSpeedOptions}
          learningChallenges={learningChallenges}
          collaborationPreferences={collaborationPreferences}
          progressTrackingOptions={progressTrackingOptions}
          preferredLearningTimes={preferredLearningTimes}
          updateFormData={updateFormData}
          handleLearningChallengeClick={handleLearningChallengeClick}
          handlePreferredTimeClick={handlePreferredTimeClick}
        />
        {saveStatus && (
          <div className={`mb-4 p-3 rounded ${saveStatus.includes('성공') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {saveStatus}
          </div>
        )}
        <MoreOptions
          showMoreOptions={showMoreOptions}
          setShowMoreOptions={setShowMoreOptions}
          onAccountDeleted={handleAccountDeleted}
        />
        {showAnalyticsModal && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
            onClick={handleCloseModal}
          >
            <div className="bg-white rounded-lg w-full max-w-2xl h-[90vh] flex flex-col shadow-2xl">
              <div className="flex-grow overflow-y-auto px-6 pb-4">
                <LmsAnalyticsFoundation initialIsOpen={true} />
              </div>
              <div className="p-4 sticky bottom-0 bg-white z-10">
                <button
                  onClick={() => setShowAnalyticsModal(false)}
                  className="w-full bg-[#D00070] hover:bg-[#D00070]/90 text-white font-bold py-2 px-4 rounded"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyInfo;