import pythonAlgorithm_009 from '../assets/pyAlgorithm/pythonAlgorithm_009.png';
import pythonAlgorithm_011 from '../assets/pyAlgorithm/pythonAlgorithm_011.png';
import pythonAlgorithm_018 from '../assets/pyAlgorithm/pythonAlgorithm_018.png';
import pythonAlgorithm_019 from '../assets/pyAlgorithm/pythonAlgorithm_019.png';
import pythonAlgorithm_022 from '../assets/pyAlgorithm/pythonAlgorithm_022.png';
import pythonAlgorithm_024 from '../assets/pyAlgorithm/pythonAlgorithm_024.png';
import pythonAlgorithm_025 from '../assets/pyAlgorithm/pythonAlgorithm_025.png';
import pythonAlgorithm_026 from '../assets/pyAlgorithm/pythonAlgorithm_026.png';
import pythonAlgorithm_028 from '../assets/pyAlgorithm/pythonAlgorithm_028.png';
import pythonAlgorithm_033 from '../assets/pyAlgorithm/pythonAlgorithm_033.png';
import pythonAlgorithm_051 from '../assets/pyAlgorithm/pythonAlgorithm_051.png';
import pythonAlgorithm_054 from '../assets/pyAlgorithm/pythonAlgorithm_054.png'; 

import sorting_001 from '../assets/pyAlgorithm/sorting_001.png';

// 카테고리 목록 수정
export const algorithmCategories = [
  { id: 1, title: 'Beginner Python Algorithm Problems', image: '/images/basic-algorithm.svg', backgroundColor: 'bg-red-200' },
  { id: 2, title: 'Mathematical Algorithms', image: '/images/math-algorithm.svg', backgroundColor: 'bg-blue-200' },
  { id: 3, title: 'Greedy Algorithms', image: '/images/math-algorithm.svg', backgroundColor: 'bg-orange-200' },
  { id: 4, title: 'Sorting Algorithms', image: '/images/sort-algorithm.svg', backgroundColor: 'bg-green-200' },
  { id: 5, title: 'Search Algorithms', image: '/images/search-algorithm.svg', backgroundColor: 'bg-yellow-200' },
  { id: 6, title: 'Graph Algorithms', image: '/images/graph-algorithm.svg', backgroundColor: 'bg-purple-200' },
  { id: 7, title: 'Dynamic Programming', image: '/images/dynamic-programming.svg', backgroundColor: 'bg-pink-200' },
];

export const pyAlgorithm = [
  ////////////////////////////////////////////////////////////////start
  // id: 1, title: 'Beginner Python Algorithm Problems'
  ////////////////////////////////////////////////////////////////
  
  // data-types-pythonAlgorithm_001 // Basic Output // code // 문자열 출력하기
  {
    problemId: "data-types-pythonAlgorithm_001",
    image: null,
    title: "문자열 출력하기",
    description: `"Hello, world!!!"를 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Output",
    subTopic: "Print Function",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열을 출력하는 가장 본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "적용"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "print() 함수의 사용법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Output",
      "Print Function"
    ],
    programmingConcepts: ["출력", "문자열"],
    skillsPracticed: ["print() 함수 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "print() 함수를 사용하여 문자열을 출력하는 방법 이해하기"
    ],
    commonMistakes: [
      "따옴표를 빼먹는 실수",
      "print 함수의 괄호를 빼먹는 실수"
    ],
    hints: {
      beginner: [
        "print() 함수 안에 출력하고 싶은 내용을 작성하세요.",
        "문자열은 따옴표로 감싸야 합니다."
      ],
      intermediate: [
        "큰따옴표나 작은따옴표 모두 사용 가능합니다."
      ],
      advanced: [
        "여러 줄의 문자열을 출력하고 싶다면 삼중 따옴표를 사용할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자", "출력", "함수"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 print() 함수는 기본적으로 줄바꿈을 포함합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "Hello, world!!!"
      }
    ],
    solution: `def print_hello_world():
      print("Hello, world!!!")
  
  print_hello_world()`,
    examples: [
      {
        input: ``,
        output: "Hello, world!!!"
      }
    ],
    // 새로 추가된 입력과 출력 항목
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "화면에 'Hello, world!!!'를 출력합니다.",
      type: "string",
      value: "Hello, world!!!"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-19",
    lastUpdated: "2024-10-19",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_002 // Basic Output // code // 작은 따옴표와 큰 따옴표 출력하기
  {
    problemId: "data-types-pythonAlgorithm_002",
    image: null,
    title: "따옴표 출력하기",
    description: `작은 따옴표, 큰 따옴표가 문자열에 포함되어 있습니다.
    'SlopeX!" 문자열을 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Output",
    subTopic: "String Manipulation",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 내에서 특수 문자와 큰따옴표를 동시에 사용하는 방법을 다루는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "print() 함수의 사용법",
      "문자열 이스케이프 문자의 개념"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Output",
      "String Manipulation",
      "Escape Characters"
    ],
    programmingConcepts: ["출력", "문자열", "이스케이프 문자"],
    skillsPracticed: ["print() 함수 사용", "문자열 내 특수 문자 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 내에서 특수 문자와 큰따옴표를 동시에 사용하는 방법 이해하기",
      "이스케이프 문자의 개념과 사용법 익히기"
    ],
    commonMistakes: [
      "이스케이프 문자를 사용하지 않고 큰따옴표를 그대로 사용하는 실수",
      "잘못된 이스케이프 문자 사용",
      "문자열의 시작과 끝을 나타내는 따옴표 선택 실수"
    ],
    hints: {
      beginner: [
        "문자열을 작은따옴표로 감싸면 내부의 느낌표는 그대로 사용할 수 있습니다.",
        "문자열 내부의 큰따옴표 앞에는 특별한 문자를 붙여야 합니다."
      ],
      intermediate: [
        "백슬래시(\\)를 이용해 특수 문자를 이스케이프할 수 있습니다."
      ],
      advanced: [
        "raw 문자열(r'문자열')을 사용하면 백슬래시를 일반 문자로 취급할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자", "출력", "이스케이프 문자", "특수 문자 처리"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 \\는 이스케이프 문자로 사용됩니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: '\'SlopeX!"'
      }
    ],
    solution: `def print_special_string():
    print('SlopeX!"')

print_special_string()`,
    examples: [
      {
        input: ``,
        output: '\'SlopeX!"'
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      // eslint-disable-next-line no-useless-escape
      description: "화면에 \'SlopeX!\" 출력",
      type: "string",
      value: 'SlopeX!"'
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_003 // Basic Output // code // 파일 경로 출력하기
  {
    problemId: "data-types-pythonAlgorithm_003",
    image: null,
    title: "파일 경로 출력하기",
    description: `아래 파일 경로를 출력하세요. 경로는 다음과 같습니다.

C:\\User\\Desktop\\slope`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Output",
    subTopic: "String Manipulation",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "윈도우 파일 경로의 백슬래시를 올바르게 처리하는 방법을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "print() 함수의 사용법",
      "문자열 이스케이프 문자의 개념"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Output",
      "String Manipulation",
      "Escape Characters",
      "File Path"
    ],
    programmingConcepts: ["출력", "문자열", "이스케이프 문자", "파일 경로"],
    skillsPracticed: ["print() 함수 사용", "문자열 내 특수 문자 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "윈도우 파일 경로의 백슬래시를 올바르게 처리하는 방법 이해하기",
      "이스케이프 문자의 개념과 사용법 익히기"
    ],
    commonMistakes: [
      "백슬래시를 단일로 사용해서 이스케이프 처리가 되지 않는 실수",
      "잘못된 이스케이프 문자 사용",
      "경로 구분자를 슬래시(/)로 잘못 사용하는 실수"
    ],
    hints: {
      beginner: [
        "백슬래시 문자를 출력하려면 백슬래시를 두 개 사용해야 합니다.",
        "백슬래시를 문자 그대로 출력하려면 특별한 처리가 필요합니다."
      ]
    },
    relatedConcepts: ["문자열", "출력", "이스케이프 문자", "파일 경로"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 \\는 이스케이프 문자로 사용됩니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "C:\\User\\Desktop\\slope"
      }
    ],
    solution: `def print_file_path():
    print("C:\\\\User\\\\Desktop\\\\slope")

print_file_path()`,
    examples: [
      {
        input: ``,
        output: "C:\\User\\Desktop\\slope"
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "화면에 'C:\\User\\Desktop\\slope' 출력",
      type: "string",
      value: "C:\\User\\Desktop\\slope"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_004 // Basic Output // code // 문자열 곱하기 연산
  {
    problemId: "data-types-pythonAlgorithm_004",
    image: null,
    title: "문자열 곱하기 연산",
    description: `slopeX 문자열을 3번 출력하세요. 문자열 곱하기 연산을 사용하여 해결하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Output",
    subTopic: "String Multiplication",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 곱하기 연산을 이해하고 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "print() 함수의 사용법",
      "문자열 연산의 기본 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Output",
      "String Manipulation",
      "String Multiplication"
    ],
    programmingConcepts: ["출력", "문자열", "문자열 곱하기 연산"],
    skillsPracticed: ["print() 함수 사용", "문자열 곱하기 연산 적용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 곱하기 연산의 개념 이해하기",
      "문자열 곱하기를 사용하여 반복된 출력 구현하기"
    ],
    commonMistakes: [
      "문자열 곱하기 대신 반복문을 사용하는 실수",
      "문자열과 숫자의 곱하기 순서를 잘못 적용하는 실수"
    ],
    hints: {
      beginner: [
        "Python에서는 문자열에 정수를 곱할 수 있습니다.",
        "문자열 * 숫자 형태로 연산을 수행할 수 있습니다."
      ],
      intermediate: [
        "곱하기 연산의 결과를 print() 함수 안에 직접 넣을 수 있습니다."
      ],
      advanced: [
        "문자열 포매팅을 사용하여 더 복잡한 패턴을 만들 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "출력", "문자열 연산", "반복"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열 * 정수는 문자열을 정수 만큼 반복합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "slopeXslopeXslopeX"
      }
    ],
    solution: `def print_repeated_string():
    print("slopeX" * 3)

print_repeated_string()`,
    examples: [
      {
        input: ``,
        output: "slopeXslopeXslopeX"
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "화면에 'slopeX'를 3번 연속 출력합니다.",
      type: "string",
      value: "slopeXslopeXslopeX"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_005 // Basic Output // code // 정수와 문자열 함께 출력
  {
    problemId: "data-types-pythonAlgorithm_005",
    image: null,
    title: "정수와 문자열 출력",
    description: `word 변수에 "SlopeX", number 변수에 정수 7을 할당하여, "SlopeX 7"이라는 결과를 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Output",
    subTopic: "String Formatting",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열과 정수를 함께 출력하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수 할당",
      "print() 함수의 사용법",
      "문자열 포매팅의 기본 개념"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Output",
      "String Formatting",
      "Variable Assignment"
    ],
    programmingConcepts: ["출력", "문자열", "변수", "문자열 포매팅"],
    skillsPracticed: ["print() 함수 사용", "변수 할당", "문자열과 정수 함께 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "변수에 값을 할당하는 방법 이해하기",
      "문자열과 정수를 함께 출력하는 방법 익히기",
      "기본적인 문자열 포매팅 기법 학습하기"
    ],
    commonMistakes: [
      "변수 이름을 잘못 사용하는 실수",
      "문자열과 정수를 결합할 때 타입 에러를 발생시키는 실수",
      "출력 형식을 정확히 지키지 않는 실수"
    ],
    hints: {
      beginner: [
        "변수에 값을 할당할 때는 '=' 연산자를 사용합니다.",
        "문자열과 정수를 함께 출력할 때는 쉼표(,)를 사용하거나 문자열로 변환해야 합니다."
      ],
      intermediate: [
        "f-string을 사용하면 변수 값을 문자열 안에 쉽게 삽입할 수 있습니다.",
        ".format() 함수를 사용하여 문자열을 포매팅할 수 있습니다."
      ],
      advanced: [
        "% 연산자를 사용한 오래된 스타일의 문자열 포매팅 방식도 있습니다."
      ]
    },
    relatedConcepts: ["변수", "문자열", "출력", "문자열 포매팅"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python 3.6+ 버전에서는 f-string을 사용할 수 있습니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "SlopeX 7"
      }
    ],
    solution: `word = "SlopeX"
number = 7
print(f"{word} {number}")`,
    examples: [
      {
        input: ``,
        output: "SlopeX 7"
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "화면에 'SlopeX 7'을 출력합니다.",
      type: "string",
      value: "SlopeX 7"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_006 // Basic Output // code // 숫자 연산하기
  {
    problemId: "data-types-pythonAlgorithm_006",
    image: null,
    title: "숫자 연산하기",
    description: `두 숫자 5와 3을 사용하여 다음 연산의 결과를 순서대로 출력하세요:
    1. 덧셈 (+)
    2. 뺄셈 (-)
    3. 곱셈 (*)
    4. 거듭제곱 (**)
    5. 나눗셈 (/)
    6. 몫 (//)
    7. 나머지 (%)
    각 결과는 새로운 줄에 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Output",
    subTopic: "Arithmetic Operations",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "기본적인 숫자 연산과 결과 출력을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "기본적인 산술 연산자",
      "print() 함수의 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Arithmetic Operations",
      "Output"
    ],
    programmingConcepts: ["출력", "산술 연산", "연산자"],
    skillsPracticed: ["print() 함수 사용", "기본 산술 연산 적용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python의 기본 산술 연산자 이해하기",
      "연산 결과를 올바르게 출력하는 방법 익히기"
    ],
    commonMistakes: [
      "연산자 우선순위를 잘못 이해하는 실수",
      "나눗셈과 몫 연산의 차이를 혼동하는 실수",
      "결과를 새로운 줄에 출력하지 않는 실수"
    ],
    hints: {
      beginner: [
        "각 연산의 결과를 별도의 print() 문으로 출력하세요.",
        "나눗셈 결과는 소수점이 포함될 수 있습니다."
      ],
      intermediate: [
        "f-string을 사용하여 연산과 결과를 함께 출력할 수 있습니다.",
        "round() 함수를 사용하여 소수점 자릿수를 제한할 수 있습니다."
      ],
      advanced: [
        "리스트와 반복문을 사용하여 코드를 더 간결하게 만들 수 있습니다."
      ]
    },
    relatedConcepts: ["산술 연산", "출력", "연산자 우선순위"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 '//'는 몫을 구하는 연산자입니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "8\n2\n15\n125\n1.6666666666666667\n1\n2"
      }
    ],
    solution: `a = 5
b = 3
print(a + b)
print(a - b)
print(a * b)
print(a ** b)
print(a / b)
print(a // b)
print(a % b)`,
    examples: [
      {
        input: ``,
        output: `8
2
15
125
1.6666666666666667
1
2`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "7개의 연산 결과를 각각 새로운 줄에 출력합니다.",
      type: "string",
      value: `8
2
15
125
1.6666666666666667
1
2`
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_007 // Basic Input/Output // code // 문자열 입력과 출력
  {
    problemId: "data-types-pythonAlgorithm_007",
    image: null,
    title: "문자열 입력과 출력",
    description: `사용자로부터 하나의 문자열을 입력받아 그대로 출력하는 프로그램을 작성하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Input/Output",
    subTopic: "User Input",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "사용자 입력을 받고 출력하는 가장 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "input() 함수의 사용법",
      "print() 함수의 사용법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Input",
      "Output",
      "User Interaction"
    ],
    programmingConcepts: ["입력", "출력", "변수"],
    skillsPracticed: ["input() 함수 사용", "print() 함수 사용", "변수 할당"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받는 방법 이해하기",
      "입력받은 데이터를 변수에 저장하고 출력하는 방법 익히기"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 변수에 저장하지 않는 실수",
      "입력받은 값을 출력하지 않고 다른 내용을 출력하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 사용하여 사용자 입력을 받을 수 있습니다.",
        "입력받은 값을 변수에 저장하세요."
      ],
      intermediate: [
        "저장된 변수를 print() 함수를 사용하여 출력하세요."
      ],
      advanced: [
        "input() 함수의 인자로 프롬프트 메시지를 추가할 수 있습니다."
      ]
    },
    relatedConcepts: ["사용자 입력", "변수", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 input() 함수는 항상 문자열을 반환합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "A",
        expected: "A"
      },
      {
        input: "7",
        expected: "7"
      },
      {
        input: "@",
        expected: "@"
      }
    ],
    solution: `char = input()
  print(char)`,
    examples: [
      {
        input: `X`,
        output: "X"
      }
    ],
    input: {
      description: "하나의 문자를 입력받습니다.",
      type: "string"
    },
    output: {
      description: "입력받은 문자를 그대로 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_008 // Basic Input/Output // code // 두 정수의 연산
  {
    problemId: "data-types-pythonAlgorithm_008",
    image: null,
    title: "두 정수의 연산",
    description: `두 개의 정수를 입력받아 다음 연산의 결과를 순서대로 출력하는 프로그램을 작성하세요:
    1. 덧셈 (+)
    2. 뺄셈 (-)
    3. 곱셈 (*)
    4. 나눗셈 (/)
    5. 몫 (//)
    6. 나머지 (%)
    각 결과는 새로운 줄에 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Basic Input/Output",
    subTopic: "Arithmetic Operations",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "사용자 입력을 받아 기본적인 산술 연산을 수행하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["사실적 지식", "개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "input() 함수의 사용법",
      "print() 함수의 사용법",
      "기본적인 산술 연산자",
      "형 변환 (문자열을 정수로)"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Input",
      "Output",
      "Arithmetic Operations"
    ],
    programmingConcepts: ["입력", "출력", "변수", "산술 연산", "형 변환"],
    skillsPracticed: ["input() 함수 사용", "print() 함수 사용", "변수 할당", "기본 산술 연산 적용", "문자열을 정수로 변환"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받는 방법 이해하기",
      "문자열을 정수로 변환하는 방법 익히기",
      "기본적인 산술 연산을 수행하고 결과를 출력하는 방법 학습하기"
    ],
    commonMistakes: [
      "입력받은 값을 정수로 변환하지 않고 문자열 그대로 사용하는 실수",
      "0으로 나누는 경우를 고려하지 않는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수로 받은 값은 문자열입니다. int()를 사용하여 정수로 변환하세요.",
        "각 연산의 결과를 별도의 print() 문으로 출력하세요."
      ],
      intermediate: [
        "나눗셈 결과는 파이썬의 기본 출력 형식을 그대로 사용하세요.",
        "0으로 나누는 경우를 처리하기 위해 조건문을 사용할 수 있습니다."
      ],
      advanced: [
        "함수를 정의하여 코드를 모듈화할 수 있습니다.",
        "예외 처리를 사용하여 잘못된 입력을 처리할 수 있습니다."
      ]
    },
    relatedConcepts: ["사용자 입력", "형 변환", "산술 연산", "출력 형식"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 input() 함수는 항상 문자열을 반환합니다.", "나눗셈 연산 시 0으로 나누면 ZeroDivisionError가 발생합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "10\n5",
        expected: "15\n5\n50\n2.0\n2\n0"
      },
      {
        input: "7\n3",
        expected: "10\n4\n21\n2.3333333333333335\n2\n1"
      }
    ],
    solution: `a = int(input())
b = int(input())
print(a + b)
print(a - b)
print(a * b)
print(a / b if b != 0 else "Cannot divide by zero")
print(a // b if b != 0 else "Cannot divide by zero")
print(a % b if b != 0 else "Cannot divide by zero")`,
    examples: [
      {
        input: `8
4`,
        output: `12
4
32
2.0
2
0`
      }
    ],
    input: {
      description: "두 개의 정수를 각각 새로운 줄에 입력받습니다.",
      type: "integer"
    },
    output: {
      description: "6개의 연산 결과를 각각 새로운 줄에 출력합니다. 나눗셈 결과는 파이썬의 기본 출력 형식을 사용합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_009 // Comparison Operations // multiple_choice - 정수와 문자열 비교
  {
    problemId: "data-types-pythonAlgorithm_009",
    image: pythonAlgorithm_009,
    title: "정수와 문자열 비교",
    description: `아래 코드의 결과를 예상해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Comparison Operations",
    subTopic: "Type Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "데이터 타입 간 비교 연산을 이해하는 초급 수준의 질문입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "데이터 타입의 개념",
      "비교 연산자의 사용법"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Comparison Operators",
      "Data Types",
      "Type Conversion"
    ],
    programmingConcepts: ["비교 연산", "데이터 타입", "암시적 형변환"],
    skillsPracticed: ["데이터 타입 식별", "비교 연산 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 다른 데이터 타입 간의 비교 연산 이해",
      "암시적 형변환의 개념 파악"
    ],
    commonMistakes: [
      "문자열과 정수의 비교 시 자동 형변환이 일어난다고 오해하는 실수",
      "다른 프로그래밍 언어의 동작과 혼동하는 실수"
    ],
    hints: {
      beginner: [
        "Python에서 '=='는 값의 동등성을 비교합니다.",
        "Python은 서로 다른 타입을 비교할 때 자동으로 형변환을 하지 않습니다."
      ],
      intermediate: [
        "정수 0과 문자열 '0'은 서로 다른 데이터 타입입니다.",
        "Python에서는 타입이 다르면 값이 같아도 동등하지 않다고 판단합니다."
      ],
      advanced: [
        "명시적으로 형변환을 하면 비교 결과가 달라질 수 있습니다. (예: int('0') == 0)"
      ]
    },
    relatedConcepts: ["데이터 타입", "비교 연산자", "형변환"],
    learningStyle: ["시각적 학습", "논리적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 '=='는 값의 동등성을 비교합니다."]
      }
    },
    choices: [
      { id: "A", text: "True" },
      { id: "B", text: "False" },
      { id: "C", text: "0" },
      { id: "D", text: "'0'" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. Python에서 0 == '0'은 False를 반환합니다. Python은 서로 다른 데이터 타입(여기서는 정수와 문자열)을 비교할 때 자동으로 형변환을 수행하지 않습니다. 따라서 정수 0과 문자열 '0'은 서로 다른 것으로 간주됩니다.",
    // examples: [
    //   {
    //     input: "print(0 == '0')",
    //     output: "False"
    //   }
    // ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_010 // Comparison Operations // code // 입력값 비교
  {
    problemId: "data-types-pythonAlgorithm_010",
    image: null,
    title: "입력값 비교",
    description: `사용자로부터 하나의 정수를 입력받아, 그 값이 10과 비교하여 크다, 작다, 또는 같다를 판단하여 출력하는 프로그램을 작성하세요.
    - 입력값이 10보다 크면 "크다"를 출력
    - 입력값이 10보다 작으면 "작다"를 출력
    - 입력값이 10과 같으면 "같다"를 출력`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Comparison Operations",
    subTopic: "User Input Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "사용자 입력을 받아 기본적인 비교 연산을 수행하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "input() 함수의 사용법",
      "print() 함수의 사용법",
      "기본적인 비교 연산자",
      "if-elif-else 문의 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Input",
      "Output",
      "Comparison Operations",
      "Conditional Statements"
    ],
    programmingConcepts: ["입력", "출력", "변수", "비교 연산", "조건문"],
    skillsPracticed: ["input() 함수 사용", "print() 함수 사용", "변수 할당", "비교 연산자 적용", "조건문 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받는 방법 이해하기",
      "문자열을 정수로 변환하는 방법 익히기",
      "비교 연산자를 사용하여 값을 비교하는 방법 학습하기",
      "조건문을 사용하여 다양한 상황에 대응하는 방법 익히기"
    ],
    commonMistakes: [
      "입력받은 값을 정수로 변환하지 않고 문자열 그대로 사용하는 실수",
      "비교 연산자를 잘못 사용하는 실수 (예: '=' 대신 '==')",
      "조건문의 순서를 잘못 배치하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수로 받은 값은 문자열입니다. int()를 사용하여 정수로 변환하세요.",
        "비교 연산자 >, <, == 를 사용하여 10과 비교하세요."
      ],
      intermediate: [
        "if-elif-else 구조를 사용하여 세 가지 경우를 모두 처리하세요.",
        "조건의 순서가 중요합니다. 가장 구체적인 조건부터 검사하세요."
      ],
      advanced: [
        "사용자의 입력이 유효한 정수인지 확인하는 예외 처리를 추가할 수 있습니다."
      ]
    },
    relatedConcepts: ["사용자 입력", "형 변환", "비교 연산", "조건문"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 input() 함수는 항상 문자열을 반환합니다.", "int() 함수를 사용하여 문자열을 정수로 변환할 수 있습니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "15",
        expected: "크다"
      },
      {
        input: "5",
        expected: "작다"
      },
      {
        input: "10",
        expected: "같다"
      }
    ],
    solution: `num = int(input())
  if num > 10:
      print("크다")
  elif num < 10:
      print("작다")
  else:
      print("같다")`,
    examples: [
      {
        input: `12`,
        output: "크다"
      }
    ],
    input: {
      description: "하나의 정수를 입력받습니다.",
      type: "integer"
    },
    output: {
      description: "입력받은 정수가 10과 비교하여 크다, 작다, 같다 중 하나를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_011 // Comparison Operations // multiple_choice // 문자열과 정수 비교
  {
    problemId: "data-types-pythonAlgorithm_011",
    image: pythonAlgorithm_011,
    title: "문자열과 정수 비교",
    description: `다음 코드의 실행 결과를 예상해보세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Comparison Operations",
    subTopic: "String and Integer Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열과 정수의 비교 연산에 대한 이해를 테스트하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "input() 함수의 특성",
      "문자열과 정수의 비교 연산"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Comparison Operations",
      "Data Types",
      "String"
    ],
    programmingConcepts: ["입력", "비교 연산", "데이터 타입"],
    skillsPracticed: ["코드 분석", "데이터 타입 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "input() 함수가 반환하는 데이터 타입 이해하기",
      "문자열과 정수의 비교 연산 결과 예측하기"
    ],
    commonMistakes: [
      "input() 함수의 반환값이 문자열임을 간과하는 실수",
      "문자열과 정수의 비교 규칙을 잘못 이해하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수는 항상 문자열을 반환합니다.",
        "Python에서 문자열과 정수를 비교하면 어떤 일이 일어날까요?"
      ],
      intermediate: [
        "문자열과 정수를 비교할 때, Python은 어떤 규칙을 따르나요?"
      ],
      advanced: [
        "이 상황에서 의도한 비교를 하려면 어떻게 코드를 수정해야 할까요?"
      ]
    },
    relatedConcepts: ["데이터 타입", "형변환", "비교 연산"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열과 정수를 비교하면 TypeError가 발생합니다."]
      }
    },
    choices: [
      { id: "A", text: "True" },
      { id: "B", text: "False" },
      { id: "C", text: "TypeError 발생" }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. Python에서 input() 함수는 항상 문자열을 반환합니다. 따라서 a는 문자열 '10'이 됩니다. Python에서는 서로 다른 타입(여기서는 문자열과 정수)을 직접 비교할 수 없으므로 TypeError가 발생합니다.",
    // examples: [
    //   {
    //     input: "10",
    //     output: "TypeError: '>' not supported between instances of 'str' and 'int'"
    //   }
    // ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.33
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_012 // String Indexing // code // 문자열 인덱싱(1)
  {
    problemId: "data-types-pythonAlgorithm_012",
    image: null,
    title: "문자열 인덱싱(1)",
    description: `"Slope" 문자열을 한 글자씩 줄바꿈하여 세로로 출력하는 프로그램을 작성하세요. 문자열 인덱싱을 사용하여 각 문자에 접근해야 합니다.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Indexing",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 인덱싱의 기본 개념을 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념",
      "문자열 인덱싱",
      "반복문 (for 루프)"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "String",
      "Indexing",
      "Loop"
    ],
    programmingConcepts: ["문자열", "인덱싱", "반복문", "출력"],
    skillsPracticed: ["문자열 다루기", "인덱스를 이용한 문자 접근", "반복문 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 인덱싱을 이용해 개별 문자에 접근하는 방법 이해하기",
      "반복문을 사용하여 문자열의 각 문자를 처리하는 방법 학습하기",
      "줄바꿈을 포함한 출력 형식 지정하기"
    ],
    commonMistakes: [
      "인덱스 범위를 잘못 지정하는 실수",
      "줄바꿈을 누락하는 실수",
      "불필요하게 복잡한 방법으로 접근하는 실수"
    ],
    hints: {
      beginner: [
        "문자열의 각 문자는 0부터 시작하는 인덱스로 접근할 수 있습니다.",
        "for 루프를 사용하여 문자열의 각 문자를 순회할 수 있습니다."
      ],
      intermediate: [
        "print() 함수는 기본적으로 줄바꿈을 포함합니다.",
        "range() 함수를 사용하여 문자열의 길이만큼 반복할 수 있습니다."
      ],
      advanced: [
        "문자열 자체를 반복 가능한(iterable) 객체로 사용할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "인덱싱", "반복문", "출력 형식"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 문자열은 불변(immutable)입니다.", "인덱싱은 0부터 시작합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "S\nl\no\np\ne"
      }
    ],
    solution: `word = "Slope"
  for i in range(len(word)):
      print(word[i])`,
    examples: [
      {
        input: ``,
        output: `S
l
o
p
e`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'Slope' 문자열의 각 문자를 새로운 줄에 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_013 // String Indexing // code // 문자열 인덱싱(2)
  {
    problemId: "data-types-pythonAlgorithm_013",
    image: null,
    title: "문자열 인덱싱(2)",
    description: `주어진 문자열 "SlopeXcelerity"에서 문자열 인덱싱을 사용하여 "loop"라는 단어를 만들어 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Indexing",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 인덱싱의 개념을 이해하고 특정 인덱스의 문자들을 조합하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념",
      "문자열 인덱싱",
      "문자열 연결"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Intermediate",
      "String",
      "Indexing",
      "String Concatenation"
    ],
    programmingConcepts: ["문자열", "인덱싱", "문자열 연결", "출력"],
    skillsPracticed: ["문자열 다루기", "인덱스를 이용한 문자 접근", "문자열 조작"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 인덱싱을 이용해 특정 위치의 문자에 접근하는 방법 이해하기",
      "여러 문자를 조합하여 새로운 문자열을 만드는 방법 학습하기",
      "문제 해결을 위한 논리적 사고력 향상하기"
    ],
    commonMistakes: [
      "잘못된 인덱스 사용",
      "중복된 문자 사용",
      "문자열 연결 연산자 (+) 누락"
    ],
    hints: {
      beginner: [
        "문자열의 각 문자는 0부터 시작하는 인덱스로 접근할 수 있습니다.",
        "'l'은 문자열의 첫 번째 문자입니다."
      ],
      intermediate: [
        "두 번째 'o'는 문자열의 중간 부분에 있습니다.",
        "문자열 연결에는 + 연산자를 사용할 수 있습니다."
      ],
      advanced: [
        "마지막 'p'는 문자열의 끝 부분에서 찾을 수 있습니다.",
        "인덱스는 음수를 사용하여 문자열의 끝에서부터 셀 수도 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "인덱싱", "문자열 연결", "문자열 슬라이싱"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 문자열은 불변(immutable)입니다.", "인덱싱은 0부터 시작합니다."]
      }
    },
    initialCode: `word = "SlopeXcelerity"
# 여기에 코드를 작성하세요
`,
    testCases: [
      {
        input: "",
        expected: "loop"
      }
    ],
    solution: `word = "SlopeXcelerity"
  result = word[0] + word[2] + word[9] + word[-1]
  print(result)`,
    examples: [
      {
        input: ``,
        output: `loop`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'SlopeXcelerity' 문자열에서 추출한 'loop' 문자열을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_014 // String Slicing // code // 문자열 슬라이싱
  {
    problemId: "data-types-pythonAlgorithm_014",
    image: null,
    title: "문자열 슬라이싱",
    description: `주어진 문자열 "SlopeXcelerity"에서 문자열 슬라이싱을 사용하여 "SlopeX"라는 부분 문자열을 추출하여 출력하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Slicing",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 슬라이싱의 기본 개념을 이해하고 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념",
      "문자열 슬라이싱"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "String",
      "Slicing"
    ],
    programmingConcepts: ["문자열", "슬라이싱", "출력"],
    skillsPracticed: ["문자열 다루기", "슬라이싱을 이용한 부분 문자열 추출"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 슬라이싱을 이용해 부분 문자열을 추출하는 방법 이해하기",
      "슬라이싱의 시작 인덱스와 끝 인덱스 개념 학습하기"
    ],
    commonMistakes: [
      "슬라이싱의 끝 인덱스를 잘못 지정하는 실수",
      "콜론(:)을 사용하지 않고 인덱싱처럼 접근하는 실수",
      "슬라이싱 범위를 잘못 이해하는 실수"
    ],
    hints: {
      beginner: [
        "문자열 슬라이싱의 기본 형식은 string[start:end]입니다.",
        "슬라이싱에서 end 인덱스는 포함되지 않습니다."
      ],
      intermediate: [
        "SlopeX는 문자열의 처음부터 시작합니다.",
        "X의 위치를 정확히 파악하세요."
      ],
      advanced: [
        "끝 인덱스를 생략하면 문자열의 끝까지 슬라이싱됩니다."
      ]
    },
    relatedConcepts: ["문자열", "인덱싱", "슬라이싱", "부분 문자열"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 문자열 슬라이싱은 [start:end] 형식을 사용합니다.", "끝 인덱스는 결과에 포함되지 않습니다."]
      }
    },
    initialCode: `word = "SlopeXcelerity"
# 여기에 코드를 작성하세요
`,
    testCases: [
      {
        input: "",
        expected: "SlopeX"
      }
    ],
    solution: `word = "SlopeXcelerity"
  result = word[:6]
  print(result)`,
    examples: [
      {
        input: ``,
        output: `SlopeX`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'SlopeXcelerity' 문자열에서 추출한 'SlopeX' 부분 문자열을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_015 // String Slicing // code // 문자열 뒤집기
  {
    problemId: "data-types-pythonAlgorithm_015",
    image: null,
    title: "문자열 뒤집기",
    description: `주어진 문자열 "SlopeX"를 거꾸로 출력하는 프로그램을 작성하세요. 문자열 슬라이싱을 사용하여 해결하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Slicing",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 슬라이싱의 고급 기능을 이해하고 적용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념",
      "문자열 슬라이싱",
      "슬라이싱의 step 매개변수"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Intermediate",
      "String",
      "Slicing",
      "Reverse String"
    ],
    programmingConcepts: ["문자열", "슬라이싱", "출력", "문자열 뒤집기"],
    skillsPracticed: ["문자열 다루기", "슬라이싱을 이용한 문자열 조작"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 슬라이싱의 step 매개변수를 이용해 문자열을 뒤집는 방법 이해하기",
      "문자열 조작의 고급 기법 학습하기"
    ],
    commonMistakes: [
      "슬라이싱의 step 매개변수를 사용하지 않고 복잡한 방법으로 접근하는 실수",
      "슬라이싱 순서를 잘못 이해하는 실수",
      "음수 step을 사용할 때 시작과 끝 인덱스를 지정하지 않는 실수"
    ],
    hints: {
      beginner: [
        "문자열 슬라이싱의 기본 형식은 string[start:end:step]입니다.",
        "step에 음수를 사용하면 문자열을 역순으로 순회합니다."
      ],
      intermediate: [
        "전체 문자열을 역순으로 슬라이싱하려면 시작과 끝 인덱스를 비워둘 수 있습니다.",
        "step으로 -1을 사용하면 문자열을 한 문자씩 뒤에서부터 순회합니다."
      ],
      advanced: [
        "이 문제는 한 줄의 코드로 해결할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "인덱싱", "슬라이싱", "문자열 뒤집기"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 문자열 슬라이싱은 [start:end:step] 형식을 사용합니다.", "step에 음수를 사용하면 역순으로 순회합니다."]
      }
    },
    initialCode: `word = "SlopeX"
# 여기에 코드를 작성하세요
`,
    testCases: [
      {
        input: "",
        expected: "XepolS"
      }
    ],
    solution: `word = "SlopeX"
  print(word[::-1])`,
    examples: [
      {
        input: ``,
        output: `XepolS`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'SlopeX' 문자열을 거꾸로 뒤집어 'XepolS'로 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.5,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-20",
    lastUpdated: "2024-10-20",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_016 // String Manipulation // code // 날짜 문자열 변경
  {
    problemId: "data-types-pythonAlgorithm_016",
    image: null,
    title: "날짜 문자열 변경",
    description: `주어진 날짜 문자열 "2024.10.21"을 "2024-10-21" 형식으로 변경하는 프로그램을 작성하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Methods",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 함수를 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념",
      "문자열 함수 (특히 replace())"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "String",
      "String Methods",
      "Date Formatting"
    ],
    programmingConcepts: ["문자열", "문자열 함수", "출력"],
    skillsPracticed: ["문자열 다루기", "replace() 함수 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열 함수를 이용해 문자열을 수정하는 방법 이해하기",
      "replace() 함수의 사용법 학습하기",
      "간단한 날짜 형식 변경 작업 수행하기"
    ],
    commonMistakes: [
      "replace() 함수의 사용법을 잘못 이해하는 실수",
      "원본 문자열이 변경되지 않는다는 점을 간과하는 실수",
      "새로운 문자열을 저장하거나 출력하지 않는 실수"
    ],
    hints: {
      beginner: [
        "replace() 함수는 문자열의 특정 부분을 다른 문자열로 교체합니다.",
        "replace() 함수의 기본 형식은 string.replace(old, new)입니다."
      ],
      intermediate: [
        "replace() 함수는 원본 문자열을 변경하지 않고 새로운 문자열을 반환합니다.",
        "변경된 결과를 저장하거나 직접 출력해야 합니다."
      ],
      advanced: [
        "이 문제는 한 줄의 코드로 해결할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "문자열 함수", "불변성(Immutability)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 문자열은 불변(immutable)입니다.", "replace() 함수는 새로운 문자열을 반환합니다."]
      }
    },
    initialCode: `date = "2024.10.21"
# 여기에 코드를 작성하세요
`,
    testCases: [
      {
        input: "",
        expected: "2024-10-21"
      }
    ],
    solution: `date = "2024.10.21"
  print(date.replace(".", "-"))`,
    examples: [
      {
        input: ``,
        output: `2024-10-21`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'2024.10.21' 문자열을 '2024-10-21' 형식으로 변경하여 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_017 // String Manipulation // code // split() 함수(1)
  {
    problemId: "data-types-pythonAlgorithm_017",
    image: null,
    title: "split() 함수(1)",
    description: `주어진 문자열 "사과,바나나,오렌지,딸기"를 쉼표(,)를 기준으로 나누고, 각 과일 이름을 한 줄에 하나씩 출력하는 프로그램을 작성하세요. split() 함수를 사용하여 해결하세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Methods",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "split() 함수의 기본적인 사용법을 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념",
      "문자열 함수 (특히 split())",
      "반복문 (for 루프)"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "String",
      "String Methods",
      "Split Function"
    ],
    programmingConcepts: ["문자열", "문자열 함수", "리스트", "반복문", "출력"],
    skillsPracticed: ["문자열 다루기", "split() 함수 사용", "for 루프 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "split() 함수를 이용해 문자열을 분리하는 방법 이해하기",
      "분리된 문자열 요소들을 반복문을 통해 처리하는 방법 학습하기"
    ],
    commonMistakes: [
      "split() 함수의 반환값이 리스트라는 점을 간과하는 실수",
      "split() 함수의 인자를 지정하지 않는 실수",
      "분리된 요소들을 출력할 때 줄바꿈을 하지 않는 실수"
    ],
    hints: {
      beginner: [
        "split(',') 함수를 사용하여 문자열을 쉼표 기준으로 나눌 수 있습니다.",
        "split() 함수의 결과는 리스트입니다."
      ],
      intermediate: [
        "for 루프를 사용하여 분리된 각 요소를 순회할 수 있습니다.",
        "print() 함수는 기본적으로 출력 후 줄바꿈을 합니다."
      ],
      advanced: [
        "리스트 컴프리헨션과 join() 함수를 사용하여 더 간결한 코드를 작성할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "문자열 함수", "리스트", "반복문"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["split() 함수는 문자열을 리스트로 분할합니다.", "print() 함수는 기본적으로 출력 후 줄바꿈을 합니다."]
      }
    },
    initialCode: `fruits = "사과,바나나,오렌지,딸기"
# 여기에 코드를 작성하세요
`,
    testCases: [
      {
        input: "",
        expected: "사과\n바나나\n오렌지\n딸기"
      }
    ],
    solution: `fruits = "사과,바나나,오렌지,딸기"
  fruit_list = fruits.split(',')
  for fruit in fruit_list:
      print(fruit)`,
    examples: [
      {
        input: ``,
        output: `사과
바나나
오렌지
딸기`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'사과,바나나,오렌지,딸기' 문자열을 쉼표로 분리하여 각 과일 이름을 한 줄에 하나씩 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_018 // String Methods // multiple_choice // split() 함수(2)
  {
    problemId: "data-types-pythonAlgorithm_018",
    image: pythonAlgorithm_018,
    title: "split() 함수(2)",
    description: `다음 코드를 실행한 후, number 변수에 저장되는 자료구조의 타입은 무엇인가요?`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Methods",
    subTopic: "split() Function",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "split() 함수의 기본적인 동작과 반환 값에 대한 이해를 테스트하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "기억"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 메소드",
      "split() 함수의 기본 사용법",
      "Python의 기본 자료구조"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "String Methods",
      "split() Function",
      "Data Structures"
    ],
    programmingConcepts: ["문자열", "문자열 메소드", "리스트"],
    skillsPracticed: ["split() 함수 이해", "자료구조 식별"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "split() 함수의 반환 값 타입 이해하기",
      "문자열 메소드가 새로운 객체를 생성함을 인식하기"
    ],
    commonMistakes: [
      "split() 함수가 원본 문자열을 변경한다고 오해하는 실수",
      "split() 함수의 반환 값을 문자열이라고 잘못 이해하는 실수"
    ],
    hints: {
      beginner: [
        "split() 함수는 문자열을 특정 구분자를 기준으로 나눕니다.",
        "나누어진 각 부분은 별도의 요소로 저장됩니다.",
        "split() 함수의 결과를 변수에 할당하면, 그 변수는 리스트 형태의 데이터를 갖게 됩니다."
      ],
      intermediate: [
        "Python에서 여러 요소를 순서대로 저장하는 기본적인 자료구조는 무엇인가요?",
        "split() 함수는 항상 리스트를 반환합니다. 이는 문자열이 하나의 요소로만 나뉘어도 마찬가지입니다."
      ],
      advanced: [
        "split() 함수의 결과를 변수에 다시 할당하면, 변수의 타입이 문자열에서 리스트로 변경됩니다.",
        "리스트는 Python에서 가변(mutable) 자료형이므로, split() 후에 결과를 수정할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "리스트", "메소드", "자료구조"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["split() 함수는 문자열을 리스트로 분할합니다."]
      }
    },
    choices: [
      { id: "A", text: "문자열 (String)" },
      { id: "B", text: "리스트 (List)" },
      { id: "C", text: "튜플 (Tuple)" },
      { id: "D", text: "딕셔너리 (Dictionary)" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. split() 함수는 문자열을 주어진 구분자(여기서는 쉼표)를 기준으로 나누고, 그 결과를 리스트로 반환합니다. 따라서 number 변수에는 ['1', '2', '3', '4', '5']와 같은 리스트가 저장됩니다.",
    examples: [
      {
        input: `number = '1,2,3,4,5'
  number = number.split(',')
  print(type(number))`,
        output: "<class 'list'>"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_019 // String Methods // multiple_choice // split() 함수(3)
  {
    problemId: "data-types-pythonAlgorithm_019",
    image: pythonAlgorithm_019,
    title: "split() 함수(3)",
    description: `다음 코드를 실행한 후, n1과 n2의 자료구조 형태와 저장된 값은 무엇인가요?`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Methods",
    subTopic: "split() Function and Multiple Assignment",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "split() 함수의 동작과 다중 할당의 개념을 이해해야 하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 메소드",
      "split() 함수의 사용법",
      "다중 할당의 개념"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Intermediate",
      "String Methods",
      "split() Function",
      "Multiple Assignment",
      "Data Types"
    ],
    programmingConcepts: ["문자열", "문자열 메소드", "변수 할당"],
    skillsPracticed: ["split() 함수 이해", "다중 할당 이해", "데이터 타입 식별"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "split() 함수의 기본 동작 이해하기",
      "다중 할당의 개념 파악하기",
      "문자열에서 분리된 값의 데이터 타입 이해하기"
    ],
    commonMistakes: [
      "split() 함수가 정수형으로 변환한다고 오해하는 실수",
      "다중 할당 시 변수의 개수와 split() 결과의 개수가 일치해야 함을 간과하는 실수"
    ],
    hints: {
      beginner: [
        "split() 함수는 기본적으로 공백을 기준으로 문자열을 나눕니다.",
        "split() 함수의 결과는 항상 문자열 리스트입니다."
      ],
      intermediate: [
        "다중 할당 시, 우변의 값들이 좌변의 변수들에 순서대로 할당됩니다.",
        "split() 함수는 문자열을 숫자 타입으로 변환하지 않습니다."
      ],
      advanced: [
        "Python에서 변수에 저장된 값의 타입을 확인하려면 type() 함수를 사용할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "리스트", "변수 할당", "데이터 타입"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["split() 함수는 문자열을 리스트로 분할합니다.", "다중 할당은 우변의 값을 좌변의 변수에 순서대로 할당합니다."]
      }
    },
    choices: [
      { id: "A", text: "n1과 n2는 정수형(int)이며, n1 = 3, n2 = 5로 저장됩니다." },
      { id: "B", text: "n1과 n2는 문자열(str)이며, n1 = '3', n2 = '5'로 저장됩니다." },
      { id: "C", text: "n1과 n2는 리스트(list)이며, n1 = ['3'], n2 = ['5']로 저장됩니다." },
      { id: "D", text: "n1과 n2는 튜플(tuple)이며, n1 = ('3',), n2 = ('5',)로 저장됩니다." }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. split() 함수는 문자열을 공백을 기준으로 나누어 문자열 리스트를 반환합니다. 이 리스트의 각 요소가 n1과 n2에 순서대로 할당됩니다. 따라서 n1과 n2는 모두 문자열(str) 타입이며, 각각 '3'과 '5'의 값을 갖게 됩니다.",
    examples: [
      {
        input: `word = '3 5'
  n1, n2 = word.split()
  print(type(n1), n1)
  print(type(n2), n2)`,
        output: `<class 'str'> 3
  <class 'str'> 5`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_020 // String Methods and Type Conversion // code // 문자열 데이터 활용(1)
  {
    problemId: "data-types-pythonAlgorithm_020",
    image: null,
    title: "문자열 데이터 활용(1)",
    description: `두 개의 정수가 공백으로 구분된 문자열을 입력받아, 이를 분리하고 정수로 변환한 후 두 수의 합을 출력하는 프로그램을 작성하세요.
    split() 함수를 사용해도 되고, 인덱싱을 사용해도 됩니다. 같은 정답이라도 코드는 정말 다양하게 작성할 수 있다는걸 잊지마세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Methods and Type Conversion",
    subTopic: "split() and int() Functions",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "split() 함수와 int() 함수를 조합하여 사용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 메소드 (특히 split())",
      "타입 변환 (특히 int())",
      "사용자 입력 받기 (input() 함수)"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Intermediate",
      "String Methods",
      "Type Conversion",
      "User Input"
    ],
    programmingConcepts: ["문자열", "문자열 메소드", "타입 변환", "사용자 입력", "산술 연산"],
    skillsPracticed: ["split() 함수 사용", "int() 함수 사용", "input() 함수 사용", "기본 산술 연산"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "split() 함수를 이용해 문자열을 분리하는 방법 이해하기",
      "int() 함수를 이용해 문자열을 정수로 변환하는 방법 학습하기",
      "사용자로부터 입력을 받고 처리하는 과정 익히기"
    ],
    commonMistakes: [
      "split() 함수의 결과를 직접 정수로 변환하려는 실수",
      "split() 함수의 결과 중 일부만 사용하는 실수",
      "문자열을 정수로 변환하지 않고 그대로 더하려는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 사용하여 사용자로부터 입력을 받으세요.",
        "split() 함수를 사용하여 입력받은 문자열을 나누세요."
      ],
      intermediate: [
        "split() 함수의 결과는 문자열 리스트입니다.",
        "int() 함수를 사용하여 문자열을 정수로 변환할 수 있습니다."
      ],
      advanced: [
        "리스트 언패킹을 사용하여 split() 결과를 직접 변수에 할당할 수 있습니다.",
        "map() 함수를 사용하여 여러 값을 한 번에 정수로 변환할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "리스트", "타입 변환", "사용자 입력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "split() 함수는 문자열을 리스트로 분할합니다.",
          "int() 함수는 문자열을 정수로 변환합니다.",
          "input() 함수는 사용자로부터 문자열 입력을 받습니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "3 5",
        expected: "8"
      },
      {
        input: "10 20",
        expected: "30"
      },
      {
        input: "7 13",
        expected: "20"
      }
    ],
    solution: `numbers = input().split()
  num1 = int(numbers[0])
  num2 = int(numbers[1])
  print(num1 + num2)`,
    examples: [
      {
        input: `3 5`,
        output: `8`
      }
    ],
    input: {
      description: "두 개의 정수가 공백으로 구분된 한 줄의 문자열을 입력받습니다.",
      type: "string"
    },
    output: {
      description: "입력받은 두 정수의 합을 출력합니다.",
      type: "integer"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_021 // String Methods and Type Conversion // code // 문자열 데이터 활용(2)
  {
    problemId: "data-types-pythonAlgorithm_021",
    image: null,
    title: "문자열 데이터 활용(2)",
    description: `세 개의 정수가 공백으로 구분된 문자열을 입력받아, 이를 분리하고 정수로 변환한 후 세 수의 합을 출력하는 프로그램을 작성하세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Methods and Type Conversion",
    subTopic: "split() and int() Functions",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "split() 함수와 int() 함수를 조합하여 사용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 메소드 (특히 split())",
      "타입 변환 (특히 int())",
      "사용자 입력 받기 (input() 함수)",
      "리스트 다루기"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Intermediate",
      "String Methods",
      "Type Conversion",
      "User Input",
      "List Manipulation"
    ],
    programmingConcepts: ["문자열", "문자열 메소드", "타입 변환", "사용자 입력", "리스트", "산술 연산"],
    skillsPracticed: ["split() 함수 사용", "int() 함수 사용", "input() 함수 사용", "리스트 조작", "기본 산술 연산"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "split() 함수를 이용해 문자열을 분리하는 방법 이해하기",
      "int() 함수를 이용해 문자열을 정수로 변환하는 방법 학습하기",
      "리스트의 모든 요소에 대해 연산을 수행하는 방법 익히기"
    ],
    commonMistakes: [
      "split() 함수의 결과를 직접 정수로 변환하려는 실수",
      "일부 입력값만 처리하는 실수",
      "문자열을 정수로 변환하지 않고 그대로 더하려는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 사용하여 사용자로부터 입력을 받으세요.",
        "split() 함수를 사용하여 입력받은 문자열을 나누세요."
      ],
      intermediate: [
        "split() 함수의 결과는 문자열 리스트입니다.",
        "int() 함수를 사용하여 각 문자열을 정수로 변환할 수 있습니다."
      ],
      advanced: [
        "리스트 컴프리헨션을 사용하여 모든 요소를 한 번에 정수로 변환할 수 있습니다.",
        "sum() 함수를 사용하여 리스트의 모든 요소를 더할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "리스트", "타입 변환", "사용자 입력", "리스트 컴프리헨션"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "split() 함수는 문자열을 리스트로 분할합니다.",
          "int() 함수는 문자열을 정수로 변환합니다.",
          "input() 함수는 사용자로부터 문자열 입력을 받습니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "7 77 777",
        expected: "861"
      },
      {
        input: "1 10 100",
        expected: "111"
      },
      {
        input: "5 50 500",
        expected: "555"
      }
    ],
    solution: `numbers = input().split()
  numbers = [int(num) for num in numbers]
  print(sum(numbers))`,
    examples: [
      {
        input: `7 77 777`,
        output: `861`
      }
    ],
    input: {
      description: "세 개의 정수가 공백으로 구분된 한 줄의 문자열을 입력받습니다.",
      type: "string"
    },
    output: {
      description: "입력받은 세 정수의 합을 출력합니다.",
      type: "integer"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_022 // Conditional Statements // multiple_choice // 숫자 비교(1)
  {
    problemId: "data-types-pythonAlgorithm_022",
    image: pythonAlgorithm_022,
    title: "숫자 비교(1)",
    description: `다음 코드를 실행했을 때, 사용자가 '5 10'을 입력한다면 어떤 결과가 출력될까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Conditional Statements",
    subTopic: "if-else Statement",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "조건문과 입력 처리의 기본 개념을 이해해야 하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "조건문 (if-else)",
      "사용자 입력 처리",
      "문자열 분할과 정수 변환"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Conditional Statements",
      "User Input",
      "Type Conversion"
    ],
    programmingConcepts: ["조건문", "사용자 입력", "타입 변환", "문자열 분할"],
    skillsPracticed: ["코드 분석", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "조건문의 동작 방식 이해하기",
      "사용자 입력 처리 과정 파악하기",
      "코드의 실행 흐름 분석하기"
    ],
    commonMistakes: [
      "조건문의 실행 순서를 잘못 이해하는 실수",
      "문자열과 정수의 비교를 혼동하는 실수",
      "else 문의 실행 조건을 잘못 해석하는 실수"
    ],
    hints: {
      beginner: [
        "input().split()의 결과는 문자열 리스트입니다.",
        "int() 함수는 문자열을 정수로 변환합니다."
      ],
      intermediate: [
        "조건문은 n[0]와 n[1]의 값을 비교합니다.",
        "else 문은 if 조건이 거짓일 때 실행됩니다."
      ],
      advanced: [
        "'프로그램 종료'는 조건에 상관없이 항상 출력됩니다."
      ]
    },
    relatedConcepts: ["조건문", "사용자 입력", "타입 변환", "문자열 처리"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["input() 함수는 문자열을 반환합니다.", "split() 함수는 문자열을 리스트로 분할합니다."]
      }
    },
    choices: [
      { id: "A", text: "첫 번째 숫자가 더 큽니다.\n프로그램 종료" },
      { id: "B", text: "두 번째 숫자가 더 큽니다.\n프로그램 종료" },
      { id: "C", text: "첫 번째 숫자가 더 큽니다." },
      { id: "D", text: "두 번째 숫자가 더 큽니다." },
      { id: "E", text: "프로그램 종료" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 사용자가 '5 10'을 입력하면, n[0]은 5가 되고 n[1]은 10이 됩니다. 조건문에서 5 > 10은 거짓이므로 else 문이 실행되어 '두 번째 숫자가 더 큽니다.'가 출력됩니다. 그 후 조건문 밖의 print 문이 실행되어 '프로그램 종료'가 출력됩니다.",
    examples: [
      {
        input: `5 10`,
        output: `두 번째 숫자가 더 큽니다.
  프로그램 종료`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_023 // Conditional Statements and Boolean Output // code // 숫자 비교(2)
  {
    problemId: "data-types-pythonAlgorithm_023",
    image: null,
    title: "숫자 비교(2)",
    description: `두 개의 정수를 입력받아 두 숫자가 같으면 True를, 다르면 False를 출력하는 프로그램을 작성하세요. 입력은 공백으로 구분된 두 정수입니다.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Conditional Statements and Boolean Output",
    subTopic: "Equality Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "조건문과 비교 연산자를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "조건문",
      "비교 연산자",
      "사용자 입력 처리",
      "문자열 분할과 정수 변환"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Conditional Statements",
      "Boolean",
      "User Input",
      "Type Conversion"
    ],
    programmingConcepts: ["조건문", "비교 연산", "사용자 입력", "타입 변환", "불리언 출력"],
    skillsPracticed: ["입력 처리", "조건문 작성", "비교 연산 수행", "불리언 값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자 입력을 처리하는 방법 이해하기",
      "문자열을 정수로 변환하는 방법 학습하기",
      "조건문을 사용하여 값을 비교하는 방법 익히기",
      "불리언 값을 출력하는 방법 배우기"
    ],
    commonMistakes: [
      "입력받은 값을 정수로 변환하지 않고 문자열 그대로 비교하는 실수",
      "비교 연산자를 잘못 사용하는 실수 (예: '=' 대신 '==')",
      "True와 False를 문자열로 출력하는 실수"
    ],
    hints: {
      beginner: [
        "input().split()을 사용하여 두 숫자를 입력받으세요.",
        "int()를 사용하여 입력받은 문자열을 정수로 변환하세요."
      ],
      intermediate: [
        "== 연산자를 사용하여 두 숫자가 같은지 비교하세요.",
        "조건문의 결과를 직접 출력하면 True 또는 False가 출력됩니다."
      ],
      advanced: [
        "한 줄로 입력, 변환, 비교, 출력을 모두 수행할 수 있습니다."
      ]
    },
    relatedConcepts: ["조건문", "비교 연산자", "불리언 타입", "타입 변환"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input().split()은 공백으로 구분된 입력을 리스트로 반환합니다.",
          "int()는 문자열을 정수로 변환합니다.",
          "==는 두 값이 같은지 비교합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "7 17",
        expected: "False"
      },
      {
        input: "10 10",
        expected: "True"
      },
      {
        input: "5 15",
        expected: "False"
      }
    ],
    solution: `a, b = map(int, input().split())
  print(a == b)`,
    examples: [
      {
        input: `7 17`,
        output: `False`
      }
    ],
    input: {
      description: "공백으로 구분된 두 정수를 입력받습니다.",
      type: "string"
    },
    output: {
      description: "두 숫자가 같으면 True를, 다르면 False를 출력합니다.",
      type: "boolean"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_024 // Data Type Comparison // multiple_choice // 정수와 문자열 비교
  {
    problemId: "data-types-pythonAlgorithm_024",
    image: pythonAlgorithm_024,
    title: "정수와 문자열 비교",
    description: `다음 Python 코드의 실행 결과는 무엇일까요?`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Type Comparison",
    subTopic: "Integer vs String Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "데이터 타입의 차이와 비교 연산의 기본을 이해해야 하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 데이터 타입",
      "비교 연산자",
      "정수와 문자열의 차이"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Comparison Operators",
      "Type Checking"
    ],
    programmingConcepts: ["데이터 타입", "비교 연산", "타입 체크"],
    skillsPracticed: ["데이터 타입 이해", "비교 연산 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "정수와 문자열의 차이점 이해하기",
      "Python에서의 타입 비교 방식 학습하기",
      "비교 연산자의 동작 원리 파악하기"
    ],
    commonMistakes: [
      "정수와 문자열을 동일하게 취급하는 실수",
      "비교 연산자가 타입을 자동으로 변환한다고 오해하는 실수",
      "문자열 '7'과 정수 7을 같다고 생각하는 실수"
    ],
    hints: {
      beginner: [
        "Python에서 정수와 문자열은 서로 다른 데이터 타입입니다.",
        "== 연산자는 값뿐만 아니라 타입도 비교합니다."
      ],
      intermediate: [
        "Python은 암시적 타입 변환을 수행하지 않습니다.",
        "정수 7과 문자열 '7'은 같은 값을 나타내지만 다른 타입입니다."
      ],
      advanced: [
        "타입을 확인하려면 type() 함수를 사용할 수 있습니다.",
        "명시적으로 타입을 변환하지 않는 한, 다른 타입의 값들은 항상 다르다고 판단됩니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 체크", "비교 연산자", "타입 변환"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["== 연산자는 값과 타입을 모두 비교합니다.", "정수와 문자열은 서로 다른 타입입니다."]
      }
    },
    choices: [
      { id: "A", text: "True" },
      { id: "B", text: "False" },
      { id: "C", text: "오류 발생" },
      { id: "D", text: "7" },
      { id: "E", text: "'7'" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. Python에서 정수 7과 문자열 '7'은 서로 다른 데이터 타입이므로 == 연산자로 비교했을 때 False를 반환합니다. == 연산자는 값뿐만 아니라 타입도 비교하기 때문입니다. 따라서 a == b는 False가 되어 이를 출력합니다.",
    examples: [
      {
        input: `a = 7
  b = '7'
  print(a == b)`,
        output: `False`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_025 // Data Type Comparison // multiple_choice // 어디까지 갈 수 있을까?
  {
    problemId: "data-types-pythonAlgorithm_025",
    image: pythonAlgorithm_025,
    title: "어디까지 갈 수 있을까?",
    description: `당신은 천안에서 출발하는 여행을 계획하고 있습니다. 당신의 차는 기름 1L로 10km를 주행할 수 있습니다. 주유한 기름의 양(L)을 입력받아, 갈 수 있는 가장 먼 도시를 출력하는 프로그램을 작성하세요.
  
    - 20L 이하: 대전
    - 50L 이하: 대구
    - 80L 이하: 울산
    - 80L 초과: 부산
  
    입력은 양의 정수로 주어집니다.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Conditional Statements",
    subTopic: "Nested Conditional Statements",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "중첩 조건문을 사용하여 여러 조건을 처리하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "조건문 (if-elif-else)",
      "사용자 입력 처리",
      "비교 연산자"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Beginner",
      "Conditional Statements",
      "Nested Conditionals",
      "User Input"
    ],
    programmingConcepts: ["조건문", "중첩 조건문", "사용자 입력", "비교 연산"],
    skillsPracticed: ["조건문 작성", "중첩 조건 처리", "사용자 입력 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "중첩 조건문을 사용하여 복잡한 조건을 처리하는 방법 이해하기",
      "사용자 입력을 받아 적절한 출력을 생성하는 프로그램 작성하기",
      "비교 연산자를 사용하여 다양한 조건을 표현하는 방법 학습하기"
    ],
    commonMistakes: [
      "조건의 순서를 잘못 배치하여 의도하지 않은 결과가 나오는 실수",
      "중첩 조건문의 들여쓰기를 잘못하여 로직 오류가 발생하는 실수",
      "입력값의 유효성 검사를 누락하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 사용하여 사용자로부터 기름량을 입력받으세요.",
        "int() 함수를 사용하여 입력받은 값을 정수로 변환하세요."
      ],
      intermediate: [
        "조건문은 가장 적은 기름량부터 검사하는 것이 좋습니다.",
        "elif를 사용하여 여러 조건을 연결할 수 있습니다."
      ],
      advanced: [
        "입력값이 양의 정수인지 확인하는 유효성 검사를 추가해보세요.",
        "함수를 사용하여 코드를 모듈화할 수 있습니다."
      ]
    },
    relatedConcepts: ["조건문", "사용자 입력", "비교 연산", "데이터 유효성 검사"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "if-elif-else 구문을 사용하여 여러 조건을 처리할 수 있습니다.",
          "input() 함수는 문자열을 반환하므로 int()로 변환해야 합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "15",
        expected: "대전"
      },
      {
        input: "40",
        expected: "대구"
      },
      {
        input: "70",
        expected: "울산"
      },
      {
        input: "100",
        expected: "부산"
      }
    ],
    solution: `fuel = int(input())
  
  if fuel <= 20:
      print("대전")
  elif fuel <= 50:
      print("대구")
  elif fuel <= 80:
      print("울산")
  else:
      print("부산")`,
    examples: [
      {
        input: `30`,
        output: `대구`
      }
    ],
    input: {
      description: "주유한 기름의 양(L)을 나타내는 양의 정수를 입력받습니다.",
      type: "integer"
    },
    output: {
      description: "입력받은 기름량으로 갈 수 있는 가장 먼 도시의 이름을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_026 // Boolean Logic // multiple_choice // 논리회로 AND Gate(1)
  {
    problemId: "data-types-pythonAlgorithm_026",
    image: pythonAlgorithm_026,
    title: "논리회로 AND Gate(1)",
    description: `논리 회로의 기본 요소인 AND 게이트에 대한 문제입니다. AND 게이트는 두 개의 입력이 모두 1(True)일 때만 출력이 1(True)이 되는 논리 게이트입니다. 다음 Python 코드의 출력 결과는 무엇일까요?`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Boolean Logic",
    subTopic: "AND Gate",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "논리 연산의 기본 개념을 이해하고 있는지 확인하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Boolean 데이터 타입",
      "논리 연산자 (특히 and)",
      "AND 게이트의 기본 동작"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Boolean Logic",
      "AND Gate",
      "Logical Operators"
    ],
    programmingConcepts: ["Boolean 연산", "논리 게이트", "조건식"],
    skillsPracticed: ["논리적 사고", "코드 해석"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "AND 게이트의 기본 동작 원리 이해하기",
      "Python에서의 논리 연산자 사용법 학습하기",
      "간단한 논리 연산의 결과 예측하기"
    ],
    commonMistakes: [
      "Boolean 값을 숫자와 혼동하는 실수",
      "AND 연산의 결과를 잘못 해석하는 실수"
    ],
    hints: {
      beginner: [
        "AND 연산은 모든 입력이 True일 때만 True를 반환합니다.",
        "True and True의 결과는 무엇일까요?"
      ],
      intermediate: [
        "Python에서 True는 1로, False는 0으로 간주될 수 있습니다.",
        "AND 게이트의 진리표를 떠올려보세요."
      ],
      advanced: [
        "Python에서 and 연산자는 불리언 값뿐만 아니라 다른 타입의 값들과도 사용될 수 있습니다."
      ]
    },
    relatedConcepts: ["Boolean 대수", "논리 회로", "진리표"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["and 연산자는 두 피연산자가 모두 True일 때만 True를 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "True" },
      { id: "B", text: "False" },
      { id: "C", text: "1" },
      { id: "D", text: "0" },
      { id: "E", text: "오류 발생" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. AND 게이트는 모든 입력이 True일 때만 True를 출력합니다. 이 경우 a와 b 모두 True이므로, a and b의 결과는 True가 됩니다.",
    examples: [
      {
        input: `a = True
  b = True
  
  result = a and b
  
  print(result)`,
        output: `True`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_027 // Boolean Logic // code // 논리회로 AND Gate(2)
  {
    problemId: "data-types-pythonAlgorithm_027",
    image: null,
    title: "논리회로 AND Gate(2)",
    description: `두 개의 정수를 줄을 바꿔 입력받아, 두 입력값이 모두 1일 때만 True를 출력하고 그 외의 경우에는 False를 출력하는 프로그램을 작성하세요.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Conditional Statements and Boolean Output",
    subTopic: "Multiple Input Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "조건문과 비교 연산자를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "조건문",
      "비교 연산자",
      "사용자 입력 처리",
      "논리 연산자 (and)"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Conditional Statements",
      "Boolean",
      "User Input",
      "Logical Operators"
    ],
    programmingConcepts: ["조건문", "비교 연산", "사용자 입력", "논리 연산", "불리언 출력"],
    skillsPracticed: ["입력 처리", "조건문 작성", "비교 연산 수행", "논리 연산 수행", "불리언 값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "여러 줄의 사용자 입력을 처리하는 방법 이해하기",
      "조건문을 사용하여 여러 값을 비교하는 방법 익히기",
      "논리 연산자(and)를 사용하여 복합 조건을 만드는 방법 학습하기",
      "불리언 값을 출력하는 방법 배우기"
    ],
    commonMistakes: [
      "입력받은 값을 정수로 변환하지 않고 문자열 그대로 비교하는 실수",
      "두 조건을 별도의 if 문으로 처리하는 실수",
      "True와 False를 문자열로 출력하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 두 번 사용하여 두 정수를 입력받으세요.",
        "int()를 사용하여 입력받은 문자열을 정수로 변환하세요."
      ],
      intermediate: [
        "== 연산자를 사용하여 각 입력값이 1인지 비교하세요.",
        "and 연산자를 사용하여 두 조건을 결합하세요."
      ],
      advanced: [
        "조건문의 결과를 직접 출력하면 True 또는 False가 출력됩니다."
      ]
    },
    relatedConcepts: ["조건문", "비교 연산자", "논리 연산자", "불리언 타입", "타입 변환"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input()은 사용자로부터 한 줄의 입력을 받습니다.",
          "int()는 문자열을 정수로 변환합니다.",
          "==는 두 값이 같은지 비교합니다.",
          "and는 두 조건이 모두 참일 때 True를 반환합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "1\n1",
        expected: "True"
      },
      {
        input: "1\n0",
        expected: "False"
      },
      {
        input: "0\n1",
        expected: "False"
      },
      {
        input: "0\n0",
        expected: "False"
      }
    ],
    solution: `a = int(input())
  b = int(input())
  print(a == 1 and b == 1)`,
    examples: [
      {
        input: `1
1`,
        output: `True`
      }
    ],
    input: {
      description: "두 개의 정수를 줄을 바꿔 입력받습니다.",
      type: "integer"
    },
    output: {
      description: "두 입력값이 모두 1이면 True를, 그렇지 않으면 False를 출력합니다.",
      type: "boolean"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_028 // Boolean Logic // multiple_choice // 논리회로 OR Gate(1)
  {
    problemId: "data-types-pythonAlgorithm_028",
    image: pythonAlgorithm_028,
    title: "논리회로 OR Gate(1)",
    description: `논리 회로의 기본 요소인 OR 게이트에 대한 문제입니다. OR 게이트는 두 개의 입력 중 하나라도 1(True)이면 출력이 1(True)이 되는 논리 게이트입니다. 다음 Python 코드의 출력 결과는 무엇일까요?`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Boolean Logic",
    subTopic: "OR Gate",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "논리 연산의 기본 개념을 이해하고 있는지 확인하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Boolean 데이터 타입",
      "논리 연산자 (특히 or)",
      "OR 게이트의 기본 동작"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Boolean Logic",
      "OR Gate",
      "Logical Operators"
    ],
    programmingConcepts: ["Boolean 연산", "논리 게이트", "조건식"],
    skillsPracticed: ["논리적 사고", "코드 해석"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "OR 게이트의 기본 동작 원리 이해하기",
      "Python에서의 논리 연산자 사용법 학습하기",
      "간단한 논리 연산의 결과 예측하기"
    ],
    commonMistakes: [
      "Boolean 값을 숫자와 혼동하는 실수",
      "OR 연산의 결과를 잘못 해석하는 실수"
    ],
    hints: {
      beginner: [
        "OR 연산은 입력 중 하나라도 True이면 True를 반환합니다.",
        "True or False의 결과는 무엇일까요?"
      ],
      intermediate: [
        "Python에서 True는 1로, False는 0으로 간주될 수 있습니다.",
        "OR 게이트의 진리표를 떠올려보세요."
      ],
      advanced: [
        "Python에서 or 연산자는 불리언 값뿐만 아니라 다른 타입의 값들과도 사용될 수 있습니다."
      ]
    },
    relatedConcepts: ["Boolean 대수", "논리 회로", "진리표"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["or 연산자는 두 피연산자 중 하나라도 True이면 True를 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "True" },
      { id: "B", text: "False" },
      { id: "C", text: "1" },
      { id: "D", text: "0" },
      { id: "E", text: "오류 발생" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. OR 게이트는 입력 중 하나라도 True이면 True를 출력합니다. 이 경우 a가 True이므로, a or b의 결과는 True가 됩니다. b의 값에 상관없이 결과는 True입니다.",
    examples: [
      {
        input: `a = True
  b = False
  
  result = a or b
  
  print(result)`,
        output: `True`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_029 // Boolean Logic // code // 논리회로 OR Gate(2)
  {
    problemId: "data-types-pythonAlgorithm_029",
    image: null,
    title: "논리회로 OR Gate(2)",
    description: `두 개의 정수를 줄을 바꿔 입력받아, 두 입력값 중 하나라도 1이면 True를 출력하고 그 외의 경우에는 False를 출력하는 프로그램을 작성하세요.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Conditional Statements and Boolean Output",
    subTopic: "Multiple Input Comparison",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "조건문과 논리 연산자를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "조건문",
      "비교 연산자",
      "사용자 입력 처리",
      "논리 연산자 (or)"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Conditional Statements",
      "Boolean",
      "User Input",
      "Logical Operators"
    ],
    programmingConcepts: ["조건문", "비교 연산", "사용자 입력", "논리 연산", "불리언 출력"],
    skillsPracticed: ["입력 처리", "조건문 작성", "비교 연산 수행", "논리 연산 수행", "불리언 값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "여러 줄의 사용자 입력을 처리하는 방법 이해하기",
      "조건문을 사용하여 여러 값을 비교하는 방법 익히기",
      "논리 연산자(or)를 사용하여 복합 조건을 만드는 방법 학습하기",
      "불리언 값을 출력하는 방법 배우기"
    ],
    commonMistakes: [
      "입력받은 값을 정수로 변환하지 않고 문자열 그대로 비교하는 실수",
      "두 조건을 별도의 if 문으로 처리하는 실수",
      "True와 False를 문자열로 출력하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 두 번 사용하여 두 정수를 입력받으세요.",
        "int()를 사용하여 입력받은 문자열을 정수로 변환하세요."
      ],
      intermediate: [
        "== 연산자를 사용하여 각 입력값이 1인지 비교하세요.",
        "or 연산자를 사용하여 두 조건을 결합하세요."
      ],
      advanced: [
        "조건문의 결과를 직접 출력하면 True 또는 False가 출력됩니다."
      ]
    },
    relatedConcepts: ["조건문", "비교 연산자", "논리 연산자", "불리언 타입", "타입 변환"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input()은 사용자로부터 한 줄의 입력을 받습니다.",
          "int()는 문자열을 정수로 변환합니다.",
          "==는 두 값이 같은지 비교합니다.",
          "or는 두 조건 중 하나라도 참이면 True를 반환합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "1\n1",
        expected: "True"
      },
      {
        input: "1\n0",
        expected: "True"
      },
      {
        input: "0\n1",
        expected: "True"
      },
      {
        input: "0\n0",
        expected: "False"
      }
    ],
    solution: `a = int(input())
  b = int(input())
  print(a == 1 or b == 1)`,
    examples: [
      {
        input: `1
  0`,
        output: `True`
      }
    ],
    input: {
      description: "두 개의 정수를 줄을 바꿔 입력받습니다.",
      type: "integer"
    },
    output: {
      description: "두 입력값 중 하나라도 1이면 True를, 그렇지 않으면 False를 출력합니다.",
      type: "boolean"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_030 // Loops // code // 문자열 여러 번 출력
  {
    problemId: "data-types-pythonAlgorithm_030",
    image: null,
    title: "문자열 여러 번 출력",
    description: `"SlopeX Go!" 문자열을 10번 반복하여 출력하는 프로그램을 작성하세요. 각 출력은 새로운 줄에 이루어져야 합니다. 반복문을 사용하여 해결하세요.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Control Flow",
    subTopic: "Loops",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "반복문의 기본 개념을 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "반복문 (for 또는 while 루프)",
      "print() 함수의 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "String",
      "Repetition"
    ],
    programmingConcepts: ["반복문", "문자열", "출력"],
    skillsPracticed: ["반복문 작성", "문자열 출력", "코드 구조화"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "반복문을 사용하여 특정 작업을 여러 번 수행하는 방법 이해하기",
      "문자열을 여러 번 출력하는 프로그램 작성하기",
      "반복 횟수를 제어하는 방법 학습하기"
    ],
    commonMistakes: [
      "반복 횟수를 잘못 설정하는 실수",
      "무한 루프에 빠지는 실수",
      "들여쓰기를 잘못하여 반복문 내부 코드를 잘못 배치하는 실수"
    ],
    hints: {
      beginner: [
        "for 루프를 사용할 때는 range() 함수를 활용할 수 있습니다.",
        "while 루프를 사용할 때는 카운터 변수가 필요합니다."
      ],
      intermediate: [
        "range(10)은 0부터 9까지의 숫자를 생성합니다.",
        "print() 함수는 기본적으로 줄바꿈을 포함합니다."
      ],
      advanced: [
        "한 줄의 for 루프로 이 문제를 해결할 수 있습니다."
      ]
    },
    relatedConcepts: ["반복문", "문자열", "출력", "범위(range)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 for 루프는 iterable 객체를 순회합니다.", "range() 함수는 숫자 시퀀스를 생성합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "SlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!\nSlopeX Go!"
      }
    ],
    solution: `for _ in range(10):
      print("SlopeX Go!")`,
    examples: [
      {
        input: ``,
        output: `SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!
SlopeX Go!`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "'SlopeX Go!' 문자열을 10번 반복하여 각각 새로운 줄에 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_031 // Loops // code // 1부터 10까지 숫자 출력
  {
    problemId: "data-types-pythonAlgorithm_031",
    image: null,
    title: "1부터 10까지 숫자 출력",
    description: `1부터 10까지의 숫자를 순서대로 출력하는 프로그램을 작성하세요. 각 숫자는 새로운 줄에 출력되어야 합니다. 반복문을 사용하여 해결하세요.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Control Flow",
    subTopic: "Loops",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "반복문의 기본 개념을 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "반복문 (for 또는 while 루프)",
      "print() 함수의 사용법",
      "range() 함수의 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "Numbers",
      "Sequence"
    ],
    programmingConcepts: ["반복문", "숫자 시퀀스", "출력"],
    skillsPracticed: ["반복문 작성", "숫자 출력", "range() 함수 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "반복문을 사용하여 숫자 시퀀스를 생성하는 방법 이해하기",
      "1부터 시작하는 숫자 시퀀스를 출력하는 프로그램 작성하기",
      "range() 함수를 사용하여 숫자 범위를 지정하는 방법 학습하기"
    ],
    commonMistakes: [
      "range() 함수의 시작 값을 0으로 설정하여 0부터 9까지 출력하는 실수",
      "반복 횟수를 잘못 설정하여 10을 포함하지 않거나 11까지 출력하는 실수",
      "들여쓰기를 잘못하여 반복문 내부 코드를 잘못 배치하는 실수"
    ],
    hints: {
      beginner: [
        "range() 함수는 시작 값, 종료 값, 그리고 선택적으로 단계 값을 인자로 받습니다.",
        "range(1, 11)은 1부터 10까지의 숫자를 생성합니다."
      ],
      intermediate: [
        "for 루프를 사용하면 range() 함수의 결과를 쉽게 순회할 수 있습니다.",
        "print() 함수는 기본적으로 줄바꿈을 포함합니다."
      ],
      advanced: [
        "list comprehension을 사용하여 더 간결한 코드를 작성할 수 있습니다."
      ]
    },
    relatedConcepts: ["반복문", "숫자 시퀀스", "출력", "범위(range)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python의 for 루프는 iterable 객체를 순회합니다.", "range(start, stop) 함수는 start부터 stop-1까지의 숫자 시퀀스를 생성합니다."]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "1\n2\n3\n4\n5\n6\n7\n8\n9\n10"
      }
    ],
    solution: `for i in range(1, 11):
      print(i)`,
    examples: [
      {
        input: ``,
        output: `1
2
3
4
5
6
7
8
9
10`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "1부터 10까지의 숫자를 각각 새로운 줄에 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_032 // Loops // code // 1부터 10까지 짝수 출력
  {
    problemId: "data-types-pythonAlgorithm_032",
    image: null,
    title: "1부터 10까지 짝수 출력",
    description: `1부터 10까지의 숫자 중에서 짝수만 순서대로 출력하는 프로그램을 작성하세요. 각 숫자는 새로운 줄에 출력되어야 합니다. 반복문을 사용하여 해결하세요.
  
기본적으로 아래 두 가지 방법을 사용할 수 있습니다.
1. range() 함수의 step 값을 활용하여 짝수만 생성하는 방법
2. % 연산자를 사용하여 각 숫자가 짝수인지 확인하는 방법

하지만 잊지마세요.
문제를 해결할 수 있는 방법은 이것말고도 다양합니다.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Control Flow",
    subTopic: "Loops and Conditionals",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "반복문과 조건문의 기본 개념을 이해하고 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "반복문 (for 루프)",
      "조건문 (if 문)",
      "print() 함수의 사용법",
      "range() 함수의 사용법",
      "나머지 연산자 (%) 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "Conditionals",
      "Numbers",
      "Even Numbers"
    ],
    programmingConcepts: ["반복문", "조건문", "숫자 시퀀스", "출력", "나머지 연산"],
    skillsPracticed: ["반복문 작성", "조건문 작성", "숫자 출력", "range() 함수 사용", "나머지 연산 활용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "반복문과 조건문을 조합하여 특정 조건을 만족하는 숫자만 출력하는 방법 이해하기",
      "나머지 연산자를 사용하여 짝수를 판별하는 방법 학습하기",
      "range() 함수의 step 값을 활용하여 특정 패턴의 숫자 시퀀스를 생성하는 방법 이해하기"
    ],
    commonMistakes: [
      "range() 함수의 시작 값을 0으로 설정하여 0부터 출력하는 실수",
      "조건문에서 나머지 연산자 (%)를 잘못 사용하는 실수",
      "range() 함수의 step 값을 잘못 설정하는 실수"
    ],
    hints: {
      beginner: [
        "range(2, 11, 2)는 2부터 10까지의 짝수를 생성합니다.",
        "if 문을 사용하여 각 숫자가 짝수인지 확인할 수 있습니다."
      ],
      intermediate: [
        "짝수는 2로 나누었을 때 나머지가 0인 수입니다.",
        "range(start, stop, step) 함수에서 step 값을 2로 설정하면 짝수만 생성할 수 있습니다."
      ],
      advanced: [
        "list comprehension을 사용하여 더 간결한 코드를 작성할 수 있습니다."
      ]
    },
    relatedConcepts: ["반복문", "조건문", "숫자 시퀀스", "출력", "나머지 연산", "범위(range)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "Python의 for 루프는 iterable 객체를 순회합니다.",
          "range(start, stop, step) 함수는 start부터 stop-1까지 step 간격으로 숫자 시퀀스를 생성합니다.",
          "% 연산자는 나머지를 계산합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "2\n4\n6\n8\n10"
      }
    ],
    solution: `# 방법 1: % 연산자 사용
  for i in range(1, 11):
      if i % 2 == 0:
          print(i)
  
  # 방법 2: range() 함수의 step 값 활용
  # for i in range(2, 11, 2):
  #     print(i)`,
    examples: [
      {
        input: ``,
        output: `2
4
6
8
10`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "1부터 10까지의 짝수를 각각 새로운 줄에 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_033 // Loops // code // 1부터 10까지의 합 계산
  {
    problemId: "data-types-pythonAlgorithm_033",
    image: pythonAlgorithm_033,
    title: "1부터 10까지의 합 계산",
    description: `다음 Python 코드는 1부터 10까지의 숫자를 더하는 프로그램입니다. 문제에서는 숫자를 더해가는 방법으로 누적합을 사용하려고 합니다. 4번째 줄에 들어갈 가장 적절한 코드는 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Loops and Accumulation",
    subTopic: "Cumulative Sum",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "반복문과 누적합의 개념을 이해하고 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "반복문 (for 루프)",
      "변수의 누적",
      "range() 함수의 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "Accumulation",
      "Sum"
    ],
    programmingConcepts: ["반복문", "변수 누적", "숫자 시퀀스", "합계 계산"],
    skillsPracticed: ["반복문 이해", "누적합 계산", "코드 완성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "반복문을 사용하여 숫자의 합을 계산하는 방법 이해하기",
      "변수를 사용하여 값을 누적하는 방법 학습하기",
      "range() 함수를 사용하여 숫자 시퀀스를 생성하는 방법 이해하기"
    ],
    commonMistakes: [
      "누적 대신 단순 할당을 사용하는 실수",
      "반복문 내에서 total 변수를 초기화하는 실수",
      "range() 함수의 범위를 잘못 설정하는 실수"
    ],
    hints: {
      beginner: [
        "누적합을 계산할 때는 이전 값에 새로운 값을 더해야 합니다.",
        "total 변수는 각 반복마다 업데이트되어야 합니다."
      ],
      intermediate: [
        "+=  연산자를 사용하면 누적합을 간단히 표현할 수 있습니다.",
        "i 변수는 현재 반복에서의 숫자를 나타냅니다."
      ]
    },
    relatedConcepts: ["반복문", "변수 누적", "숫자 시퀀스", "합계 계산", "범위(range)"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "range(1, 11)은 1부터 10까지의 숫자를 생성합니다.",
          "+= 연산자는 변수에 값을 더하고 그 결과를 다시 변수에 할당합니다."
        ]
      }
    },
    choices: [
      { id: "A", text: "total + i" },
      { id: "B", text: "total = total +i" },
      { id: "C", text: "i" },
      { id: "D", text: "total = i" },
      { id: "E", text: "total + 1" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. total += i는 total = total + i와 동일한 의미로, 현재 total 값에 i를 더한 결과를 다시 total에 할당합니다. 이렇게 하면 1부터 10까지의 숫자가 순차적으로 total에 누적되어 더해집니다.",
    examples: [
      {
        input: `total = 0
  for i in range(1, 11):
      total += i
  print(total)`,
        output: `55`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_034 // Loops // code // 1부터 10까지 홀수의 합
  {
    problemId: "data-types-pythonAlgorithm_034",
    image: null,
    title: "1부터 10까지 홀수의 합",
    description: `1부터 10까지의 숫자 중에서 홀수만의 합을 계산하여 출력하는 프로그램을 작성하세요. 나머지 값을 판별하면서 홀수를 구하든, step 값을 활용하든 어떤 방법이든 상관없습니다.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Loops and Conditionals",
    subTopic: "Sum of Odd Numbers",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "반복문, 조건문, 그리고 누적합의 개념을 조합하여 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "반복문 (for 루프)",
      "조건문 (if 문)",
      "변수의 누적",
      "나머지 연산자 (%) 사용법",
      "range() 함수의 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "Conditionals",
      "Accumulation",
      "Odd Numbers"
    ],
    programmingConcepts: ["반복문", "조건문", "변수 누적", "숫자 시퀀스", "합계 계산", "나머지 연산"],
    skillsPracticed: ["반복문 작성", "조건문 작성", "누적합 계산", "홀수 판별"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "반복문과 조건문을 조합하여 특정 조건을 만족하는 숫자들의 합을 계산하는 방법 이해하기",
      "나머지 연산자를 사용하여 홀수를 판별하는 방법 학습하기",
      "변수를 사용하여 값을 누적하는 방법 익히기"
    ],
    commonMistakes: [
      "홀수 판별 조건을 잘못 작성하는 실수 (예: i % 2 == 0)",
      "누적 대신 단순 할당을 사용하는 실수",
      "range() 함수의 범위를 잘못 설정하는 실수"
    ],
    hints: {
      beginner: [
        "range(1, 11)을 사용하여 1부터 10까지의 숫자를 생성할 수 있습니다.",
        "if 문을 사용하여 각 숫자가 홀수인지 확인할 수 있습니다."
      ],
      intermediate: [
        "홀수는 2로 나누었을 때 나머지가 1인 수입니다. 이를 i % 2 == 1로 표현할 수 있습니다.",
        "+=  연산자를 사용하면 누적합을 간단히 표현할 수 있습니다."
      ],
      advanced: [
        "list comprehension과 sum() 함수를 사용하여 더 간결한 코드를 작성할 수 있습니다."
      ]
    },
    relatedConcepts: ["반복문", "조건문", "변수 누적", "숫자 시퀀스", "합계 계산", "나머지 연산", "범위(range)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "range(1, 11)은 1부터 10까지의 숫자를 생성합니다.",
          "% 연산자는 나머지를 계산합니다.",
          "+= 연산자는 변수에 값을 더하고 그 결과를 다시 변수에 할당합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "",
        expected: "25"
      }
    ],
    solution: `total = 0
  for i in range(1, 11):
      if i % 2 == 1:
          total += i
  print(total)`,
    examples: [
      {
        input: ``,
        output: `25`
      }
    ],
    input: {
      description: "이 문제는 입력이 필요 없습니다.",
      type: "none"
    },
    output: {
      description: "1부터 10까지의 홀수의 합을 출력합니다.",
      type: "integer"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_035 // Loops // code // 0부터 입력값까지 출력
  {
    problemId: "data-types-pythonAlgorithm_035",
    image: null,
    title: "0부터 입력값까지 출력",
    description: `사용자로부터 양의 정수를 입력받아, 0부터 해당 숫자까지 모든 정수를 출력하는 프로그램을 작성하세요. 
    입력받은 숫자가 음수인 경우 "양의 정수를 입력해주세요."라는 메시지를 출력하세요.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Loops and Input Handling",
    subTopic: "Number Sequence Generation",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "사용자 입력 처리, 조건문, 반복문을 조합하여 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "사용자 입력 받기 (input() 함수)",
      "문자열을 정수로 변환하기 (int() 함수)",
      "조건문 (if-else 문)",
      "반복문 (for 또는 while 루프)",
      "range() 함수의 사용법"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "Conditionals",
      "User Input",
      "Number Sequence"
    ],
    programmingConcepts: ["사용자 입력", "조건문", "반복문", "숫자 시퀀스", "타입 변환"],
    skillsPracticed: ["사용자 입력 처리", "조건문 작성", "반복문 작성", "숫자 시퀀스 생성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받고 처리하는 방법 이해하기",
      "조건문을 사용하여 입력값의 유효성을 검사하는 방법 학습하기",
      "반복문을 사용하여 숫자 시퀀스를 생성하고 출력하는 방법 익히기"
    ],
    commonMistakes: [
      "입력값을 정수로 변환하지 않는 실수",
      "음수 입력에 대한 처리를 하지 않는 실수",
      "range() 함수의 범위를 잘못 설정하는 실수 (예: range(n)이 아닌 range(n+1)을 사용해야 함)"
    ],
    hints: {
      beginner: [
        "input() 함수를 사용하여 사용자 입력을 받을 수 있습니다.",
        "int() 함수를 사용하여 입력받은 문자열을 정수로 변환할 수 있습니다."
      ],
      intermediate: [
        "if-else 문을 사용하여 입력값이 양수인지 확인할 수 있습니다.",
        "range() 함수를 사용하여 0부터 입력받은 숫자까지의 시퀀스를 생성할 수 있습니다."
      ],
      advanced: [
        "예외 처리(try-except)를 사용하여 사용자가 정수가 아닌 값을 입력했을 때의 오류를 처리할 수 있습니다."
      ]
    },
    relatedConcepts: ["사용자 입력", "조건문", "반복문", "숫자 시퀀스", "타입 변환", "범위(range)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input() 함수는 사용자로부터 입력을 받습니다.",
          "int() 함수는 문자열을 정수로 변환합니다.",
          "range(n+1)은 0부터 n까지의 숫자 시퀀스를 생성합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "5",
        expected: "0\n1\n2\n3\n4\n5"
      },
      {
        input: "-3",
        expected: "양의 정수를 입력해주세요."
      },
      {
        input: "0",
        expected: "0"
      }
    ],
    solution: `n = int(input())
  if n < 0:
      print("양의 정수를 입력해주세요.")
  else:
      for i in range(n + 1):
          print(i)`,
    examples: [
      {
        input: `5`,
        output: `0
1
2
3
4
5`
      },
      {
        input: `-3`,
        output: `양의 정수를 입력해주세요.`
      }
    ],
    input: {
      description: "양의 정수를 입력합니다.",
      type: "integer"
    },
    output: {
      description: "0부터 입력한 숫자까지의 정수를 각 줄에 출력하거나, 음수 입력 시 오류 메시지를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_036 // String // multiple_choice // 문자열 길이 구하기
  {
    problemId: "data-types-pythonAlgorithm_036",
    image: null,
    title: "문자열 길이 구하기",
    description: `Python에서 문자열의 길이를 구하는 올바른 함수는 무엇인가요?`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "String Length",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "문자열 조작의 기본 개념을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열의 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "String",
      "Length"
    ],
    programmingConcepts: ["문자열", "내장 함수"],
    skillsPracticed: ["문자열 길이 구하기"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 문자열의 길이를 구하는 올바른 함수를 식별하기"
    ],
    commonMistakes: [
      "length() 함수와 같이 다른 프로그래밍 언어의 함수를 혼동하는 실수",
      "size() 함수와 같이 존재하지 않는 함수를 선택하는 실수"
    ],
    hints: {
      beginner: [
        "Python의 내장 함수 중 하나입니다.",
        "이 함수의 이름은 '길이'를 의미하는 영어 단어의 축약형입니다."
      ],
      intermediate: [
        "이 함수는 문자열뿐만 아니라 리스트, 튜플 등의 길이도 구할 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열", "내장 함수", "시퀀스 타입"],
    learningStyle: ["개념적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "len() 함수는 Python의 내장 함수입니다."
        ]
      }
    },
    choices: [
      { id: "A", text: "length()" },
      { id: "B", text: "size()" },
      { id: "C", text: "len()" },
      { id: "D", text: "count()" },
      { id: "E", text: "strlen()" }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. Python에서 문자열의 길이를 구하는 올바른 함수는 len()입니다. 이 함수는 문자열뿐만 아니라 리스트, 튜플 등 다양한 시퀀스 타입의 길이를 반환할 수 있는 범용적인 내장 함수입니다.",
    examples: [
      {
        input: `text = "Hello, World!"
  print(len(text))`,
        output: `13`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_037 // Loops // code // 문자열 길이만큼 반복해서 출력하기
  {
    problemId: "data-types-pythonAlgorithm_037",
    image: null,
    title: "문자열 길이만큼 반복해서 출력하기",
    description: `사용자로부터 문자열을 입력받아, 그 문자열의 길이만큼 해당 문자열을 반복해서 출력하는 프로그램을 작성하세요.
  
예를 들어, "Hello"를 입력했다면 "Hello"가 5번 반복되어 출력되어야 합니다. 문제를 풀어내는 여러가지 방법이 있지만, 문제출제 의도에 따라 반복문을 사용해서 문제를 해결해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation and Loops",
    subTopic: "String Repetition",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "문자열 조작과 반복문을 조합하여 적용하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "사용자 입력 받기 (input() 함수)",
      "문자열의 길이 구하기 (len() 함수)",
      "반복문 (for 루프)",
      "문자열 출력"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "String",
      "Loops",
      "Repetition"
    ],
    programmingConcepts: ["문자열", "반복문", "사용자 입력", "문자열 길이"],
    skillsPracticed: ["사용자 입력 처리", "반복문 작성", "문자열 조작"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받고 처리하는 방법 이해하기",
      "len() 함수를 사용하여 문자열의 길이를 구하는 방법 학습하기",
      "반복문을 사용하여 문자열을 반복 출력하는 방법 익히기"
    ],
    commonMistakes: [
      "len() 함수 사용을 잊는 실수",
      "반복문의 범위를 잘못 설정하는 실수",
      "문자열을 한 줄에 모두 출력하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수를 사용하여 사용자 입력을 받을 수 있습니다.",
        "len() 함수를 사용하여 입력받은 문자열의 길이를 구할 수 있습니다."
      ],
      intermediate: [
        "for 루프를 사용하여 문자열을 반복 출력할 수 있습니다.",
        "range() 함수와 len() 함수를 조합하여 반복 횟수를 설정할 수 있습니다."
      ],
      advanced: [
        "print() 함수는 기본적으로 출력 후 줄바꿈을 합니다. 별도의 end 파라미터 설정이 필요 없습니다."
      ]
    },
    relatedConcepts: ["문자열", "반복문", "사용자 입력", "문자열 길이", "범위(range)"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input() 함수는 사용자로부터 입력을 받습니다.",
          "len() 함수는 문자열의 길이를 반환합니다.",
          "range(n)은 0부터 n-1까지의 숫자 시퀀스를 생성합니다.",
          "print() 함수는 기본적으로 출력 후 줄바꿈을 합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "Hello",
        expected: "Hello\nHello\nHello\nHello\nHello"
      },
      {
        input: "Python",
        expected: "Python\nPython\nPython\nPython\nPython\nPython"
      },
      {
        input: "A",
        expected: "A"
      }
    ],
    solution: `text = input()
  for _ in range(len(text)):
      print(text)`,
    examples: [
      {
        input: `Hello`,
        output: `Hello
Hello
Hello
Hello
Hello`
      }
    ],
    input: {
      description: "문자열을 입력합니다.",
      type: "string"
    },
    output: {
      description: "입력한 문자열을 그 길이만큼 반복하여 각 줄에 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_038 // Loops // code // 무한루프 종료 조건
  {
    problemId: "data-types-pythonAlgorithm_038",
    image: null,
    title: "무한루프 종료 조건",
    description: `사용자로부터 계속해서 문자열을 입력받는 프로그램을 작성하세요. 사용자가 '종료'를 입력하면 프로그램을 종료합니다. 입력받은 각 문자열은 즉시 출력되어야 하며, '종료'를 입력받으면 "프로그램을 종료합니다."라는 메시지를 출력한 후 프로그램이 종료되어야 합니다.`,
  
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Loops and Conditional Statements",
    subTopic: "User Input and Loop Control",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "반복문과 조건문을 조합하여 사용자 입력을 처리하는 초급-중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "사용자 입력 받기 (input() 함수)",
      "반복문 (while 루프)",
      "조건문 (if 문)",
      "문자열 비교"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "Beginner",
      "Loops",
      "Conditionals",
      "User Input",
      "Loop Control"
    ],
    programmingConcepts: ["반복문", "조건문", "사용자 입력", "루프 제어"],
    skillsPracticed: ["사용자 입력 처리", "반복문 작성", "조건문 작성", "프로그램 흐름 제어"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "무한 루프를 사용하여 지속적으로 사용자 입력을 받는 방법 이해하기",
      "조건문을 사용하여 특정 입력에 따라 프로그램 흐름을 제어하는 방법 학습하기",
      "사용자 입력에 따라 프로그램을 종료하는 방법 익히기"
    ],
    commonMistakes: [
      "무한 루프를 만들고 적절한 종료 조건을 설정하지 않는 실수",
      "사용자 입력을 즉시 출력하지 않는 실수",
      "종료 조건 확인 후 불필요한 출력을 하는 실수"
    ],
    hints: {
      beginner: [
        "while True를 사용하여 무한 루프를 만들 수 있습니다.",
        "input() 함수를 사용하여 사용자 입력을 받을 수 있습니다."
      ],
      intermediate: [
        "if 문을 사용하여 입력값이 '종료'인지 확인할 수 있습니다.",
        "break 문을 사용하여 루프를 종료할 수 있습니다."
      ],
      advanced: [
        "try-except 구문을 사용하여 예외 상황(예: Ctrl+C 입력)을 처리할 수 있습니다."
      ]
    },
    relatedConcepts: ["반복문", "조건문", "사용자 입력", "문자열 비교", "프로그램 종료"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "while True는 무한 루프를 생성합니다.",
          "input() 함수는 사용자로부터 입력을 받습니다.",
          "break 문은 가장 가까운 루프를 즉시 종료합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "Hello\nPython\n종료\n",
        expected: "Hello\nPython\n프로그램을 종료합니다."
      },
      {
        input: "Test\n종료\n",
        expected: "Test\n프로그램을 종료합니다."
      },
      {
        input: "종료\n",
        expected: "프로그램을 종료합니다."
      }
    ],
    solution: `while True:
    text = input()
    if text == '종료':
        print("프로그램을 종료합니다.")
        break
    print(text)`,
    examples: [
      {
        input: `Hello
Python
종료`,
        output: `Hello
Python
프로그램을 종료합니다.`
      }
    ],
    input: {
      description: "여러 줄의 문자열을 입력합니다. '종료'를 입력하면 프로그램이 종료됩니다.",
      type: "string"
    },
    output: {
      description: "입력받은 각 문자열을 출력하고, '종료' 입력 시 종료 메시지를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-21",
    lastUpdated: "2024-10-21",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_039 // Lists // code // 리스트에 추가하기
  {
    problemId: "data-types-pythonAlgorithm_039",
    image: null,
    title: "리스트에 추가하기",
    description: `주어진 도시 리스트에 새로운 도시를 추가하는 프로그램을 작성하세요. 주어진 리스트의 제일 끝에 '수원'이라는 도시를 추가해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트에 요소를 추가하는 가장 기본적인 조작을 다루는 초보자 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 선언 및 초기화",
      "리스트 메소드 (append)",
      "리스트 출력"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "List Methods"
    ],
    programmingConcepts: ["리스트", "리스트 메소드"],
    skillsPracticed: ["리스트 조작", "리스트 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "초기화된 리스트에 요소를 추가하는 방법 이해하기",
      "리스트를 출력하는 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "append() 메소드 사용법을 잘못 이해하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "append() 메소드는 리스트의 끝에 요소를 추가합니다.",
        "print() 함수를 사용하여 리스트를 출력할 수 있습니다."
      ],
      intermediate: [
        "각 단계마다 print()를 사용하여 리스트의 변화를 확인할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "리스트 메소드", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "append() 메소드는 리스트의 끝에 요소를 추가합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 도시 리스트
city = ["천안", "서울", "부산"]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "['천안', '서울', '부산', '수원']"
      }
    ],
    solution: `# 초기 도시 리스트
city = ["천안", "서울", "부산"]

# "수원"을 리스트 끝에 추가
city.append("수원")

# 최종 리스트 출력
print(city)`,
    examples: [
      {
        input: "",
        output: `['천안', '서울', '부산', '수원']`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "최종 도시 리스트를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_040 // Lists // multiple_choice // 리스트 요소 삽입하기
  {
    problemId: "data-types-pythonAlgorithm_040",
    image: null,
    title: "리스트 요소 삽입하기",
    description: `다음 리스트가 주어져 있습니다.

city = ["천안", "서울", "부산", "수원"]

"천안" 다음에 "대전"을 추가하고 싶습니다. 올바른 코드는 무엇일까요?`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트의 특정 위치에 요소를 삽입하는 기본적인 개념을 이해해야 하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 메소드 (insert)",
      "리스트 인덱싱"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "List Methods"
    ],
    programmingConcepts: ["리스트", "리스트 메소드", "인덱싱"],
    skillsPracticed: ["리스트 조작", "인덱스 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트의 특정 위치에 요소를 삽입하는 방법 이해하기",
      "리스트 인덱싱 개념 적용하기",
      "insert() 메소드의 사용법 익히기"
    ],
    commonMistakes: [
      "리스트 인덱스를 잘못 이해하는 실수",
      "insert() 메소드의 인자 순서를 혼동하는 실수"
    ],
    hints: {
      beginner: [
        "리스트의 인덱스는 0부터 시작합니다.",
        "insert() 메소드는 (인덱스, 요소) 순서로 인자를 받습니다."
      ],
      intermediate: [
        "'천안' 다음 위치의 인덱스를 생각해보세요."
      ]
    },
    relatedConcepts: ["리스트", "리스트 메소드", "인덱싱"],
    learningStyle: ["시각적 학습", "논리적 학습"],
    type: "multiple_choice",
    questionType: "single_correct",
    answerFormat: "Multiple Choice",
    choices: [
      {
        id: "A",
        text: "city.insert(0, '대전')"
      },
      {
        id: "B",
        text: "city.insert(1, '대전')"
      },
      {
        id: "C",
        text: "city.insert('대전', 1)"
      },
      {
        id: "D",
        text: "city.append('대전')"
      }
    ],
    correctAnswer: "B",
    explanation: "정답은 B입니다. city.insert(1, '대전')이 올바른 코드입니다. 리스트의 인덱스는 0부터 시작하므로, '천안' 다음 위치의 인덱스는 1입니다. insert() 메소드는 (인덱스, 요소) 순서로 인자를 받아, 지정된 인덱스 위치에 새 요소를 삽입합니다.",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "insert() 메소드는 (인덱스, 요소) 순서로 인자를 받습니다.",
          "리스트의 인덱스는 0부터 시작합니다."
        ]
      }
    },
    testCases: null,
    solution: null,
    examples: null,
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "올바른 선택지를 고르세요.",
      type: "single_choice"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["웹 브라우저 또는 Python 학습 환경"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_041 // Lists // code // 리스트 요소 삭제하기
  {
    problemId: "data-types-pythonAlgorithm_041",
    image: null,
    title: "리스트 요소 삭제하기",
    description: `다음과 같이 초기화된 city 리스트가 있습니다.

city = ["천안", "대전", "서울", "부산", "수원", "한국"]

실수로 우리나라 이름인 "한국"이 리스트에 추가되었습니다. 이를 삭제하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트에서 요소를 삭제하는 기본적인 조작을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 메소드 (remove)",
      "리스트 출력"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "List Methods"
    ],
    programmingConcepts: ["리스트", "리스트 메소드"],
    skillsPracticed: ["리스트 조작", "리스트 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트에서 특정 요소를 삭제하는 방법 이해하기",
      "remove() 메소드의 사용법 익히기",
      "리스트를 출력하는 방법 복습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "remove() 메소드 사용법을 잘못 이해하는 실수",
      "리스트 출력 시 print() 함수를 올바르게 사용하지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "remove() 메소드는 리스트에서 지정된 값을 가진 첫 번째 항목을 제거합니다.",
        "print() 함수를 사용하여 리스트를 출력할 수 있습니다."
      ],
      intermediate: [
        "remove() 메소드는 리스트를 직접 수정합니다. 따로 할당할 필요가 없습니다."
      ]
    },
    relatedConcepts: ["리스트", "리스트 메소드", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "remove() 메소드는 리스트에서 지정된 값을 가진 첫 번째 항목을 제거합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 도시 리스트
city = ["천안", "대전", "서울", "부산", "수원", "한국"]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "['천안', '대전', '서울', '부산', '수원']"
      }
    ],
    solution: `# 초기 도시 리스트
city = ["천안", "대전", "서울", "부산", "수원", "한국"]

# "한국"을 리스트에서 제거
city.remove("한국")

# 수정된 리스트 출력
print(city)`,
    examples: [
      {
        input: "",
        output: `['천안', '대전', '서울', '부산', '수원']`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "수정된 도시 리스트를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_042 // Lists // code // 리스트 인덱싱
  {
    problemId: "data-types-pythonAlgorithm_042",
    image: null,
    title: "리스트 인덱싱",
    description: `city 리스트에서 광역시만 출력하는 프로그램을 작성하세요. 광역시는 부산과 대전입니다.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트 인덱싱의 기본 개념을 이해하고 적용해야 하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 인덱싱",
      "리스트 생성 및 출력"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "Indexing"
    ],
    programmingConcepts: ["리스트", "인덱싱", "리스트 생성"],
    skillsPracticed: ["리스트 인덱싱", "리스트 생성", "리스트 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트 인덱싱을 사용하여 특정 요소에 접근하는 방법 이해하기",
      "새로운 리스트를 생성하고 초기화하는 방법 학습하기",
      "리스트를 출력하는 방법 복습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "리스트 인덱스를 잘못 사용하는 실수",
      "새로운 리스트를 생성하지 않고 개별 요소만 출력하는 실수",
      "반복문이나 조건문을 사용하려는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "리스트의 인덱스는 0부터 시작합니다.",
        "새로운 리스트는 대괄호 []를 사용하여 생성할 수 있습니다."
      ],
      intermediate: [
        "리스트에서 여러 요소를 한 번에 선택하려면 쉼표를 사용할 수 있습니다.",
        "print() 함수를 사용하여 리스트를 출력할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "인덱싱", "리스트 생성", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "리스트의 인덱스는 0부터 시작합니다.",
          "리스트에서 여러 요소를 선택할 때는 [index1, index2] 형식을 사용합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 도시 리스트
city = ["서울", "부산", "대전", "천안", "수원"]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "['부산', '대전']"
      }
    ],
    solution: `# 초기 도시 리스트
city = ["서울", "부산", "대전", "천안", "수원"]

# 광역시(부산, 대전)만 선택하여 새 리스트 생성
metropolitan_cities = [city[1], city[2]]

# 새로운 리스트 출력
print(metropolitan_cities)`,
    examples: [
      {
        input: "",
        output: `['부산', '대전']`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "광역시만 포함된 새로운 리스트를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_043 // Lists // code // 리스트 요소 개수 구하기
  {
    problemId: "data-types-pythonAlgorithm_043",
    image: null,
    title: "리스트 요소 개수 구하기",
    description: `city 리스트의 요소 개수를 구하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트의 길이를 구하는 기본적인 개념을 다루는 초보자 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "len() 함수 사용법",
      "변수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "Length"
    ],
    programmingConcepts: ["리스트", "내장 함수", "변수"],
    skillsPracticed: ["리스트 길이 구하기", "변수 사용", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트의 길이를 구하는 방법 이해하기",
      "len() 함수의 사용법 익히기",
      "변수에 값을 저장하고 사용하는 방법 복습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "len() 함수를 잘못 사용하는 실수",
      "결과를 변수에 저장하지 않고 바로 출력하려는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "len() 함수는 리스트를 인자로 받아 그 길이를 반환합니다.",
        "결과를 변수에 저장하면 나중에 다시 사용하기 편리합니다."
      ],
      intermediate: [
        "print() 함수를 사용하여 결과를 출력할 수 있습니다.",
        "f-string을 사용하면 결과를 더 보기 좋게 출력할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "내장 함수", "변수", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "len() 함수는 리스트의 길이를 반환합니다.",
          "변수에 값을 할당할 때는 '=' 연산자를 사용합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 도시 리스트
city = ["천안", "서울", "대전", "대구", "울산", "부산", "충주", "수원", "강릉", "광주"]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "10"
      }
    ],
    solution: `# 초기 도시 리스트
city = ["천안", "서울", "대전", "대구", "울산", "부산", "충주", "수원", "강릉", "광주"]

# 리스트의 길이를 구하여 변수에 저장
city_count = len(city)

# 결과 출력
print(f"{city_count}")`,
    examples: [
      {
        input: "",
        output: `10`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "리스트의 요소 개수를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_044 // Lists // code // 리스트 요소의 합 구하기
  {
    problemId: "data-types-pythonAlgorithm_044",
    image: null,
    title: "리스트 요소의 합 구하기",
    description: `numbers 리스트의 모든 요소의 합을 구하는 프로그램을 작성하세요. 방법은 다양합니다. 하나씩 인덱싱을 해도, 반복문과 누적합을 사용해도, sum()이라는 함수를 사용해도 됩니다.
    
어떤 방법으로 결과를 출력할지 결정하는건 개발자의 몫입니다. 그러니 고민해보세요. 어떤 방법이 제일 효율적일지.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트 요소의 합을 구하는 기본적인 개념을 다루는 초보자 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "sum() 함수 사용법",
      "변수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "Sum"
    ],
    programmingConcepts: ["리스트", "내장 함수", "변수"],
    skillsPracticed: ["리스트 요소의 합 구하기", "변수 사용", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트 요소의 합을 구하는 방법 이해하기",
      "sum() 함수의 사용법 익히기",
      "변수에 값을 저장하고 사용하는 방법 복습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "sum() 함수를 잘못 사용하는 실수",
      "결과를 변수에 저장하지 않고 바로 출력하려는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "sum() 함수는 리스트를 인자로 받아 모든 요소의 합을 반환합니다.",
        "결과를 변수에 저장하면 나중에 다시 사용하기 편리합니다."
      ],
      intermediate: [
        "print() 함수를 사용하여 결과를 출력할 수 있습니다.",
        "f-string을 사용하면 결과를 더 보기 좋게 출력할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "내장 함수", "변수", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "sum() 함수는 리스트의 모든 요소의 합을 반환합니다.",
          "변수에 값을 할당할 때는 '=' 연산자를 사용합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 숫자 리스트
numbers = [10, 20, 30, 40, 50]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "150"
      }
    ],
    solution: `# 초기 숫자 리스트
numbers = [10, 20, 30, 40, 50]

# 리스트의 모든 요소의 합을 구하여 변수에 저장
total = sum(numbers)

# 결과 출력
print(f"{total}")`,
    examples: [
      {
        input: "",
        output: `150`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "리스트 요소들의 합을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_045 // Lists // code // 리스트 요소의 평균값 구하기
  {
    problemId: "data-types-pythonAlgorithm_045",
    image: null,
    title: "리스트 요소의 평균값 구하기",
    description: `numbers 리스트 모든 요소의 평균값을 구하는 프로그램을 작성하세요.

평균값의 소수점 둘째 자리까지 반올림하여 출력해야합니다.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "리스트 요소의 평균을 구하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "sum() 함수 사용법",
      "len() 함수 사용법",
      "산술 연산",
      "반올림 함수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "Average",
      "Arithmetic"
    ],
    programmingConcepts: ["리스트", "내장 함수", "변수", "산술 연산", "반올림"],
    skillsPracticed: ["리스트 요소의 평균 구하기", "변수 사용", "산술 연산", "반올림", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트 요소의 평균을 구하는 방법 이해하기",
      "sum()과 len() 함수의 사용법 익히기",
      "기본적인 산술 연산 복습하기",
      "반올림 함수 사용법 학습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "sum()이나 len() 함수를 잘못 사용하는 실수",
      "나눗셈 결과를 정수로 처리하는 실수",
      "반올림을 하지 않거나 잘못 적용하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "sum() 함수는 리스트의 모든 요소의 합을 반환합니다.",
        "len() 함수는 리스트의 길이를 반환합니다.",
        "평균은 '합계 / 개수'로 계산합니다."
      ],
      intermediate: [
        "round() 함수를 사용하여 소수점 둘째 자리까지 반올림할 수 있습니다.",
        "f-string을 사용하면 결과를 더 보기 좋게 출력할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "내장 함수", "변수", "산술 연산", "반올림", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "sum() 함수는 리스트의 모든 요소의 합을 반환합니다.",
          "len() 함수는 리스트의 길이를 반환합니다.",
          "round(number, ndigits) 함수는 숫자를 지정된 소수점 자리수로 반올림합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 숫자 리스트
numbers = [15, 22, 38, 41, 59]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "35.00"
      }
    ],
    solution: `# 초기 숫자 리스트
numbers = [15, 22, 38, 41, 59]

# 리스트의 모든 요소의 합 구하기
total = sum(numbers)

# 리스트의 길이 구하기
count = len(numbers)

# 평균 계산
average = total / count

# 결과 출력 (소수점 둘째 자리까지 반올림)
print(f"{round(average, 2):.2f}")`,
    examples: [
      {
        input: "",
        output: `35.00`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "리스트 요소들의 평균값을 소수점 둘째 자리까지 반올림하여 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_046 // Lists // code // 리스트 요소의 최소값과 최대값 구하기
  {
    problemId: "data-types-pythonAlgorithm_046",
    image: null,
    title: "리스트 요소의 최소값과 최대값 구하기",
    description: `numbers 리스트에서 최소값과 최대값을 구하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 1,
    difficultyDescription: "리스트 요소의 최소값과 최대값을 구하는 기본적인 개념을 다루는 초보자 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "min()과 max() 함수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "Min",
      "Max"
    ],
    programmingConcepts: ["리스트", "내장 함수", "변수"],
    skillsPracticed: ["리스트 요소의 최소값과 최대값 구하기", "변수 사용", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트 요소의 최소값과 최대값을 구하는 방법 이해하기",
      "min()과 max() 함수의 사용법 익히기",
      "변수에 값을 저장하고 사용하는 방법 복습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "min()이나 max() 함수를 잘못 사용하는 실수",
      "결과를 변수에 저장하지 않고 바로 출력하려는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "min() 함수는 리스트의 최소값을 반환합니다.",
        "max() 함수는 리스트의 최대값을 반환합니다."
      ],
      intermediate: [
        "결과를 변수에 저장하면 나중에 다시 사용하기 편리합니다.",
        "f-string을 사용하면 결과를 더 보기 좋게 출력할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "내장 함수", "변수", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "min() 함수는 리스트의 최소값을 반환합니다.",
          "max() 함수는 리스트의 최대값을 반환합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 숫자 리스트
numbers = [28, 14, 33, 51, 10, 89, 62, 75]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "10\n89"
      }
    ],
    solution: `# 초기 숫자 리스트
numbers = [28, 14, 33, 51, 10, 89, 62, 75]

# 최소값 구하기
min_value = min(numbers)

# 최대값 구하기
max_value = max(numbers)

# 결과 출력
print(f"{min_value}")
print(f"{max_value}")`,
    examples: [
      {
        input: "",
        output: `10
89`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "리스트의 최소값과 최대값을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_047 // Lists // code // 리스트 요소 정렬하기
  {
    problemId: "data-types-pythonAlgorithm_047",
    image: null,
    title: "리스트 요소 정렬하기",
    description: `numbers 리스트를 오름차순과 내림차순으로 정렬하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "리스트 요소를 정렬하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "sort() 메소드와 sorted() 함수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Data Structures",
      "Sorting"
    ],
    programmingConcepts: ["리스트", "정렬", "메소드", "함수"],
    skillsPracticed: ["리스트 정렬", "오름차순 정렬", "내림차순 정렬", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트를 오름차순과 내림차순으로 정렬하는 방법 이해하기",
      "sort() 메소드와 sorted() 함수의 사용법 익히기",
      "정렬된 리스트를 출력하는 방법 복습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "sort()와 sorted()의 차이점을 혼동하는 실수",
      "내림차순 정렬 시 reverse 매개변수를 사용하지 않는 실수",
      "원본 리스트가 변경되는 것을 인지하지 못하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "sort() 메소드는 원본 리스트를 직접 수정합니다.",
        "sorted() 함수는 정렬된 새로운 리스트를 반환합니다."
      ],
      intermediate: [
        "내림차순 정렬을 위해 reverse=True를 사용할 수 있습니다.",
        "정렬 후에는 반드시 리스트를 출력하여 결과를 확인하세요."
      ]
    },
    relatedConcepts: ["리스트", "정렬", "메소드", "함수", "매개변수", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "sort() 메소드는 리스트를 직접 정렬합니다: list.sort()",
          "sorted() 함수는 정렬된 새 리스트를 반환합니다: sorted(list)",
          "내림차순 정렬을 위해 reverse=True를 사용합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 숫자 리스트
numbers = [28, 14, 33, 51, 10, 89, 62, 75]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "[10, 14, 28, 33, 51, 62, 75, 89]\n[89, 75, 62, 51, 33, 28, 14, 10]"
      }
    ],
    solution: `# 초기 숫자 리스트
numbers = [28, 14, 33, 51, 10, 89, 62, 75]

# 오름차순 정렬
sorted_asc = sorted(numbers)
print(f"{sorted_asc}")

# 내림차순 정렬
sorted_desc = sorted(numbers, reverse=True)
print(f"{sorted_desc}")

# 참고: 원본 리스트를 직접 정렬하려면 아래와 같이 할 수 있습니다.
# numbers.sort()  # 오름차순 정렬
# numbers.sort(reverse=True)  # 내림차순 정렬`,
    examples: [
      {
        input: "",
        output: `[10, 14, 28, 33, 51, 62, 75, 89]
[89, 75, 62, 51, 33, 28, 14, 10]`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "오름차순과 내림차순으로 정렬된 리스트를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_048 // Lists // code // 리스트에서 50보다 작은 수의 개수 구하기
  {
    problemId: "data-types-pythonAlgorithm_048",
    image: null,
    title: "리스트에서 50보다 작은 수의 개수 구하기",
    description: `numbers 리스트에서 50보다 작은 수의 개수를 구하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "리스트 요소를 순회하며 조건을 확인하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "for 루프 사용법",
      "조건문 사용법",
      "변수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Loops",
      "Conditional Statements",
      "Counting"
    ],
    programmingConcepts: ["리스트", "반복문", "조건문", "변수"],
    skillsPracticed: ["리스트 순회", "조건 확인", "카운팅", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트의 요소를 순회하는 방법 이해하기",
      "조건문을 사용하여 특정 조건을 만족하는 요소 확인하기",
      "카운터 변수를 사용하여 개수를 세는 방법 학습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "for 루프 구문을 잘못 작성하는 실수",
      "조건문에서 비교 연산자를 잘못 사용하는 실수",
      "카운터 변수를 초기화하지 않는 실수",
      "카운터 증가를 잊는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "for 루프를 사용하여 리스트의 각 요소에 접근할 수 있습니다.",
        "if 문을 사용하여 각 요소가 50보다 작은지 확인할 수 있습니다."
      ],
      intermediate: [
        "카운터 변수를 0으로 초기화하고, 조건을 만족할 때마다 1씩 증가시키세요.",
        "f-string을 사용하면 결과를 더 보기 좋게 출력할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "반복문", "조건문", "변수", "카운팅", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "for 문의 기본 구조: for item in list:",
          "if 문의 기본 구조: if condition:",
          "비교 연산자 '<'를 사용하여 50보다 작은지 확인합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 숫자 리스트
numbers = [62, 45, 31, 74, 51, 23, 93, 18, 49, 59]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "5"
      }
    ],
    solution: `# 초기 숫자 리스트
numbers = [62, 45, 31, 74, 51, 23, 93, 18, 49, 59]

# 50보다 작은 수의 개수를 저장할 변수 초기화
count = 0

# 리스트의 각 요소를 확인
for num in numbers:
    # 요소가 50보다 작으면 카운트 증가
    if num < 50:
        count += 1

# 결과 출력
print(f"{count}")`,
    examples: [
      {
        input: "",
        output: `5`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "50보다 작은 수의 개수를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_049 // Lists // code // 리스트에서 짝수의 합 구하기
  {
    problemId: "data-types-pythonAlgorithm_049",
    image: null,
    title: "리스트에서 짝수의 합 구하기",
    description: `numbers 리스트에서 짝수들의 합을 구하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "리스트 순회, 조건 확인, 합 계산 등 기본적인 개념을 조합하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "for 루프 사용법",
      "조건문 사용법",
      "모듈로 연산자(%) 이해",
      "변수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Loops",
      "Conditional Statements",
      "Arithmetic"
    ],
    programmingConcepts: ["리스트", "반복문", "조건문", "변수", "산술 연산"],
    skillsPracticed: ["리스트 순회", "조건 확인", "합 계산", "출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트의 요소를 순회하는 방법 이해하기",
      "조건문을 사용하여 짝수를 판별하는 방법 학습하기",
      "변수를 사용하여 합을 누적하는 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "for 루프 구문을 잘못 작성하는 실수",
      "짝수 판별 조건을 잘못 작성하는 실수",
      "합계 변수를 초기화하지 않는 실수",
      "결과 출력 시 불필요한 텍스트를 추가하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "for 루프를 사용하여 리스트의 각 요소에 접근할 수 있습니다.",
        "if 문과 % 연산자를 사용하여 짝수를 판별할 수 있습니다."
      ],
      intermediate: [
        "합계 변수를 0으로 초기화하고, 짝수일 때마다 해당 값을 더해주세요.",
        "최종 결과는 print() 함수를 사용하여 단순히 숫자만 출력하세요."
      ]
    },
    relatedConcepts: ["리스트", "반복문", "조건문", "변수", "산술 연산", "모듈로 연산", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "for 문의 기본 구조: for item in list:",
          "if 문의 기본 구조: if condition:",
          "짝수 판별: if number % 2 == 0:",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 숫자 리스트
numbers = [13, 22, 8, 31, 16, 25, 44, 37, 50]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "140"
      }
    ],
    solution: `# 초기 숫자 리스트
numbers = [13, 22, 8, 31, 16, 25, 44, 37, 50]

# 짝수의 합을 저장할 변수 초기화
even_sum = 0

# 리스트의 각 요소를 확인
for num in numbers:
    # 요소가 짝수인 경우 합계에 추가
    if num % 2 == 0:
        even_sum += num

# 결과 출력 (단순히 숫자만 출력)
print(even_sum)`,
    examples: [
      {
        input: "",
        output: `140`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "리스트에서 짝수들의 합을 단순히 숫자로만 출력합니다.",
      type: "number"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_050 // Lists // code // 두 리스트의 요소 합 비교하기
  {
    problemId: "data-types-pythonAlgorithm_050",
    image: null,
    title: "두 리스트의 요소 합 비교하기",
    description: `두 개의 정수 리스트 list1과 list2가 주어집니다. 각 리스트의 모든 요소의 합을 비교하여, 어느 리스트의 합이 더 큰지 비교하는 프로그램을 작성하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Lists",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "두 리스트의 합을 계산하고 비교하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "sum() 함수 사용법",
      "조건문 사용법",
      "변수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Lists",
      "Sum",
      "Comparison",
      "Conditional Statements"
    ],
    programmingConcepts: ["리스트", "함수", "조건문", "변수", "비교 연산"],
    skillsPracticed: ["리스트 요소의 합 계산", "조건 비교", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "리스트의 모든 요소의 합을 구하는 방법 이해하기",
      "sum() 함수의 사용법 익히기",
      "조건문을 사용하여 값을 비교하는 방법 학습하기",
      "비교 결과에 따라 적절한 출력을 하는 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "sum() 함수를 잘못 사용하는 실수",
      "비교 연산자를 잘못 사용하는 실수",
      "조건문 구조를 잘못 작성하는 실수",
      "결과 출력 시 지정된 형식을 따르지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "sum() 함수는 리스트의 모든 요소의 합을 반환합니다.",
        "if-elif-else 구문을 사용하여 세 가지 경우(list1이 큰 경우, list2가 큰 경우, 같은 경우)를 비교할 수 있습니다."
      ],
      intermediate: [
        "비교 결과를 변수에 저장한 후 출력하는 방법도 고려해보세요.",
        "코드의 가독성을 높이기 위해 중간 결과를 변수에 저장하는 것이 좋습니다."
      ]
    },
    relatedConcepts: ["리스트", "함수", "조건문", "변수", "비교 연산", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "sum() 함수는 리스트의 모든 요소의 합을 반환합니다.",
          "if-elif-else 구문의 기본 구조: if condition: ... elif condition: ... else: ...",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 두 개의 정수 리스트
list1 = [3, 7, 2, 9, 5]
list2 = [1, 8, 4, 6, 10]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "list2"
      }
    ],
    solution: `# 두 개의 정수 리스트
list1 = [3, 7, 2, 9, 5]
list2 = [1, 8, 4, 6, 10]

# list1의 합 계산
sum1 = sum(list1)

# list2의 합 계산
sum2 = sum(list2)

# 두 합 비교
if sum1 > sum2:
    result = "list1"
elif sum2 > sum1:
    result = "list2"
else:
    result = "equal"

# 결과 출력
print(result)`,
    examples: [
      {
        input: "",
        output: `list2`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "두 리스트의 합 비교 결과를 출력합니다. ('list1', 'list2', 또는 'equal' 중 하나)",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_051 // Input and Output // code // 슬로퍼의 무게 실험
  {
    problemId: "data-types-pythonAlgorithm_051",
    image: pythonAlgorithm_051,
    title: "👻슬로퍼의 무게 실험",
    description: `👻슬로퍼는 물리 시간에 무게추를 사용한 실험을 하고 있습니다. 그런데 가지고 있는 무게추들의 단위가 g과 kg으로 섞여 있어서 혼란스럽습니다. 모든 무게를 g 단위로 통일하여 사용하고 싶어 합니다.

👻슬로퍼를 위해 kg 값을 입력하면 g 단위로 자동 변환해주는 프로그램을 작성해주세요.

요구사항:
1. 사용자로부터 kg 단위의 무게를 실수(float)로 입력받으세요.
2. 입력받은 kg 값을 g으로 변환하세요. (1 kg = 1000 g)
3. 변환된 g 값을 정수(int)로 반올림하여 출력하세요.
4. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Input and Output",
    subTopic: "Type Conversion",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "사용자 입력, 단위 변환, 반올림 등 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "input() 함수 사용법",
      "float() 함수 사용법",
      "산술 연산",
      "round() 함수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "Beginner",
      "Input",
      "Type Conversion",
      "Arithmetic",
      "Rounding"
    ],
    programmingConcepts: ["입력", "형변환", "산술 연산", "반올림", "출력"],
    skillsPracticed: ["사용자 입력 처리", "단위 변환", "반올림", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받는 방법 이해하기",
      "문자열을 실수로 변환하는 방법 학습하기",
      "단위 변환을 위한 산술 연산 수행하기",
      "결과를 반올림하여 정수로 변환하는 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 float()로 변환하지 않는 실수",
      "kg을 g으로 변환할 때 곱셈 연산을 잘못 사용하는 실수",
      "반올림을 하지 않거나 잘못 적용하는 실수",
      "결과를 정수로 출력하지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수로 받은 값은 문자열입니다. float()를 사용하여 실수로 변환해야 합니다.",
        "1 kg = 1000 g 임을 기억하세요."
      ],
      intermediate: [
        "round() 함수를 사용하여 반올림할 수 있습니다.",
        "print() 함수 내에서 형변환을 할 수도 있습니다."
      ]
    },
    relatedConcepts: ["입력", "형변환", "산술 연산", "반올림", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input() 함수는 사용자로부터 입력을 받습니다.",
          "float() 함수는 문자열을 실수로 변환합니다.",
          "round() 함수는 숫자를 반올림합니다.",
          "int() 함수는 숫자를 정수로 변환합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "2.5",
        expected: "2500"
      },
      {
        input: "0.1",
        expected: "100"
      },
      {
        input: "3.14",
        expected: "3140"
      }
    ],
    solution: `# 사용자로부터 kg 단위의 무게 입력 받기
weight_kg = float(input())

# kg을 g으로 변환 (1 kg = 1000 g)
weight_g = weight_kg * 1000

# g으로 변환된 값을 반올림하여 정수로 변환
result = round(weight_g)

# 결과 출력
print(result)`,
    examples: [
      {
        input: "2.5",
        output: `2500`
      }
    ],
    input: {
      description: "kg 단위의 무게를 실수로 입력하세요.",
      type: "float"
    },
    output: {
      description: "g 단위로 변환된 무게를 정수로 출력합니다.",
      type: "integer"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_052 // Input and Output // code // 60분은 1시간 ⏰
  {
    problemId: "data-types-pythonAlgorithm_052",
    image: null,
    title: "60분은 1시간 ⏰",
    description: `우리는 보통 시간을 표현할 때 60분을 넘어가면 시간 단위로 표현합니다.

⏰ 70분 ➡️ 1시간 10분
⏰ 150 ➡️ 2시간 30분

이렇게 분 단위의 시간을 시간과 분으로 변환해주는 프로그램을 작성해보세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Input and Output",
    subTopic: "Time Conversion",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "사용자 입력, 시간 변환, 조건문 등 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "input() 함수 사용법",
      "int() 함수 사용법",
      "산술 연산 (나눗셈, 나머지)",
      "조건문 (if-else) 사용법",
      "문자열 포매팅",
      "print() 함수 사용법"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "Beginner",
      "Input",
      "Arithmetic",
      "Conditional Statements",
      "String Formatting"
    ],
    programmingConcepts: ["입력", "산술 연산", "조건문", "문자열 포매팅", "출력"],
    skillsPracticed: ["사용자 입력 처리", "시간 단위 변환", "조건에 따른 출력 형식 결정", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "사용자로부터 입력을 받는 방법 이해하기",
      "나눗셈과 나머지 연산을 활용한 시간 단위 변환 학습하기",
      "조건문을 사용하여 다른 출력 형식 적용하기",
      "문자열 포매팅을 통해 결과 출력하는 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 int()로 변환하지 않는 실수",
      "나눗셈 연산자(/)와 나머지 연산자(%)를 혼동하는 실수",
      "조건문 작성 시 들여쓰기를 잘못하는 실수",
      "60분 미만일 때와 이상일 때의 출력 형식을 구분하지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "나눗셈 연산자(/)와 나머지 연산자(%)를 활용하세요.",
        "조건문(if-else)을 사용하여 60분 미만인 경우와 이상인 경우를 구분하세요.",
        "시간 = 분 // 60 (몫)",
        "남은 분 = 분 % 60 (나머지)"
      ],
      intermediate: [
        "f-string을 사용하면 결과를 더 쉽게 포매팅할 수 있습니다.",
        "조건문에서 60분 미만인 경우를 먼저 처리하면 코드가 더 간결해질 수 있습니다."
      ]
    },
    relatedConcepts: ["입력", "산술 연산", "조건문", "문자열 포매팅", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input() 함수는 사용자로부터 입력을 받습니다.",
          "int() 함수는 문자열을 정수로 변환합니다.",
          "// 연산자는 나눗셈의 몫을 구합니다.",
          "% 연산자는 나눗셈의 나머지를 구합니다.",
          "if-else 문의 기본 구조: if condition: ... else: ...",
          "f-string을 사용하여 문자열 포매팅을 할 수 있습니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "30",
        expected: "30분"
      },
      {
        input: "70",
        expected: "1시간10분"
      },
      {
        input: "150",
        expected: "2시간30분"
      }
    ],
    solution: `# 사용자로부터 분 단위의 시간 입력 받기
minutes = int(input())

# 시간과 분으로 변환
hours = minutes // 60
remaining_minutes = minutes % 60

# 결과 출력
if minutes < 60:
    print(f"{minutes}분")
else:
    print(f"{hours}시간{remaining_minutes}분")`,
    examples: [
      {
        input: "70",
        output: `1시간10분`
      },
      {
        input: "30",
        output: `30분`
      }
    ],
    input: {
      description: "분 단위의 시간을 정수로 입력하세요.",
      type: "integer"
    },
    output: {
      description: "변환된 시간을 '시간분' 또는 '분' 형식으로 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_053 // String Manipulation // code // 👻슬로퍼의 엑셀작업(1)
  {
    problemId: "data-types-pythonAlgorithm_053",
    image: null,
    title: "👻슬로퍼의 엑셀작업(1)",
    description: `👻슬로퍼는 엑셀 프로그램을 사용하다가 날짜와 관련된 데이터를 발견했습니다. 데이터들이 '2024.10.22' 형태로 되어 있었는데, 이를 '2024년10월22일' 형태로 모두 변경해야 합니다.
  
슬로퍼가 빨리 퇴근할 수 있도록 프로그램을 만들어주세요.

요구사항:
1. 주어진 dates 리스트의 각 날짜를 새로운 형식으로 변환하세요.
2. 변환된 날짜를 새로운 리스트에 저장하세요.
3. 최종 결과 리스트를 출력하세요.
4. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "String Manipulation",
    subTopic: "Date Formatting",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "문자열 조작, 리스트 처리 등 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 메소드 (split, replace)",
      "리스트 조작",
      "for 루프 사용법",
      "f-string 사용법"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "Beginner",
      "String Manipulation",
      "List Processing",
      "Date Formatting"
    ],
    programmingConcepts: ["문자열 조작", "리스트 처리", "반복문", "문자열 포매팅"],
    skillsPracticed: ["문자열 분할", "문자열 치환", "리스트 순회", "새 리스트 생성", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열을 분할하고 조작하는 방법 이해하기",
      "리스트를 순회하며 각 요소를 처리하는 방법 학습하기",
      "새로운 형식으로 문자열을 조합하는 방법 익히기",
      "처리된 데이터를 새 리스트에 저장하는 방법 학습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "문자열 분할 시 구분자를 잘못 사용하는 실수",
      "년, 월, 일을 올바르게 조합하지 못하는 실수",
      "리스트 순회 시 인덱스 오류를 범하는 실수",
      "결과를 새 리스트에 저장하지 않고 출력하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "split() 메소드를 사용하여 날짜를 년, 월, 일로 분리할 수 있습니다.",
        "f-string을 사용하면 새로운 형식의 문자열을 쉽게 만들 수 있습니다."
      ],
      intermediate: [
        "리스트 컴프리헨션을 사용하면 코드를 더 간결하게 만들 수 있습니다.",
        "replace() 메소드를 활용하여 문자열을 변환하는 방법도 고려해보세요."
      ]
    },
    relatedConcepts: ["문자열 메소드", "리스트 처리", "반복문", "문자열 포매팅", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "split() 메소드는 문자열을 특정 구분자로 나눕니다.",
          "f-string을 사용하여 문자열 포매팅을 할 수 있습니다.",
          "리스트 컴프리헨션: [expression for item in iterable]",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 주어진 날짜 리스트
  dates = ['2024.10.22', '2023.05.15', '2025.12.31', '2022.01.01', '2024.02.29']
  
  # 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "['2024년10월22일', '2023년05월15일', '2025년12월31일', '2022년01월01일', '2024년02월29일']"
      }
    ],
    solution: `# 주어진 날짜 리스트
  dates = ['2024.10.22', '2023.05.15', '2025.12.31', '2022.01.01', '2024.02.29']
  
  # 날짜 형식 변환 함수
  def convert_date(date):
      year, month, day = date.split('.')
      return f"{year}년{month}월{day}일"
  
  # 모든 날짜 변환
  converted_dates = [convert_date(date) for date in dates]
  
  # 결과 출력
  print(converted_dates)`,
    examples: [
      {
        input: "",
        output: `['2024년10월22일', '2023년05월15일', '2025년12월31일', '2022년01월01일', '2024년02월29일']`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "변환된 날짜 형식을 포함한 새로운 리스트를 출력합니다.",
      type: "list"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_054 // String Manipulation // code // 👻슬로퍼의 엑셀작업(2)
  {
    problemId: "data-types-pythonAlgorithm_054",
    image: null,
    title: "👻슬로퍼의 엑셀작업(2)",
    description: `👻슬로퍼는 고객 데이터베이스를 정리하고 있습니다. 엑셀 파일에 저장된 전화번호 데이터가 '01011111111, 01011111112' 형식으로 되어 있어 가독성이 떨어집니다. 슬로퍼는 이를 '010-1111-1111' 형태로 변환하여 보기 쉽게 만들고 싶어합니다.
  
주어진 전화번호 리스트의 모든 번호를 하이픈(-)이 포함된 형식으로 변환하고, 각각 새로운 줄에 출력하는 프로그램을 작성해주세요.

요구사항:
1. 주어진 phone_numbers 리스트의 각 전화번호를 변환하세요.
2. 변환된 형식은 '010-XXXX-XXXX'여야 합니다.
3. 각 변환된 전화번호를 새로운 줄에 출력하세요.
4. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "문자열 처리",
    subTopic: "전화번호 형식 변환",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "문자열 슬라이싱, 문자열 포매팅 등 기본적인 문자열 처리 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 슬라이싱",
      "문자열 포매팅",
      "for 루프 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "초보자",
      "문자열 처리",
      "문자열 포매팅",
      "리스트 처리"
    ],
    programmingConcepts: ["문자열 처리", "반복문", "문자열 포매팅"],
    skillsPracticed: ["문자열 슬라이싱", "문자열 포매팅", "리스트 순회", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "문자열을 슬라이싱하여 필요한 부분을 추출하는 방법 학습하기",
      "문자열 포매팅을 사용하여 원하는 형식으로 변환하는 방법 이해하기",
      "리스트를 순회하며 각 요소를 처리하는 방법 익히기",
      "처리된 데이터를 적절한 형식으로 출력하는 방법 학습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "문자열 슬라이싱 인덱스를 잘못 사용하는 실수",
      "문자열 포매팅 문법을 잘못 적용하는 실수",
      "리스트 순회 시 인덱스 오류를 범하는 실수",
      "변환된 전화번호를 새로운 줄에 출력하지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "문자열 슬라이싱을 사용하여 전화번호의 각 부분(010, XXXX, XXXX)을 분리할 수 있습니다.",
        "f-string을 사용하면 분리된 부분을 쉽게 조합할 수 있습니다."
      ],
      intermediate: [
        "정규표현식(re 모듈)을 사용하면 더 복잡한 패턴의 전화번호도 처리할 수 있습니다.",
        "리스트 컴프리헨션을 사용하여 코드를 더 간결하게 만들 수 있습니다."
      ]
    },
    relatedConcepts: ["문자열 메소드", "리스트 처리", "반복문", "문자열 포매팅", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "문자열 슬라이싱: string[start:end]",
          "f-string을 사용한 문자열 포매팅: f'{variable}'",
          "리스트 순회: for item in list",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
  initialCode: `# 주어진 전화번호 리스트
phone_numbers = ['01011111111', '01011111112', '01011111113', '01011111114', '01011111115','01011111116', '01011111117', '01011111118', '01011111119', '01011111120']
  
# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "010-1111-1111\n010-1111-1112\n010-1111-1113\n010-1111-1114\n010-1111-1115\n010-1111-1116\n010-1111-1117\n010-1111-1118\n010-1111-1119\n010-1111-1120"
      }
    ],
    solution: `# 주어진 전화번호 리스트
  phone_numbers = ['01011111111', '01011111112', '01011111113', '01011111114', '01011111115',
                   '01011111116', '01011111117', '01011111118', '01011111119', '01011111120']
  
  # 각 전화번호를 변환하여 출력
  for number in phone_numbers:
      # 전화번호를 세 부분으로 나누기
      part1 = number[:3]
      part2 = number[3:7]
      part3 = number[7:]
      
      # 하이픈을 포함한 형식으로 변환
      formatted_number = f"{part1}-{part2}-{part3}"
      
      # 변환된 전화번호 출력
      print(formatted_number)`,
    examples: [
      {
        input: "",
        output: `010-1111-1111
010-1111-1112
010-1111-1113
010-1111-1114
010-1111-1115
010-1111-1116
010-1111-1117
010-1111-1118
010-1111-1119
010-1111-1120`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "하이픈(-)이 포함된 형식으로 변환된 전화번호를 각각 새로운 줄에 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_055 // List Processing // code // 👻슬로퍼의 신발가게👟
  {
    problemId: "data-types-pythonAlgorithm_055",
    image: pythonAlgorithm_054,
    title: "👻슬로퍼의 신발가게👟",
    description: `👻슬로퍼는 온라인 신발 쇼핑몰을 운영하고 있습니다. 최근 물가 상승으로 인해 모든 상품의 가격을 10% 인상해야 하는 상황에 놓였습니다. 슬로퍼는 이 작업을 빠르게 처리해야 합니다.
  
주어진 가격 리스트의 모든 가격을 10% 인상하고, 인상된 가격을 각각 새로운 줄에 출력하는 프로그램으로 슬로퍼를 도와주세요.

요구사항:
1. 주어진 prices 리스트의 각 가격을 10% 인상하세요.
2. 인상된 가격을 정수로 반올림하세요. (소수점 이하는 버립니다)
3. 각 인상된 가격을 새로운 줄에 출력하세요.
4. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "리스트 처리",
    subTopic: "가격 계산",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "리스트 처리, 간단한 수학 연산, 반복문 등 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 조작",
      "for 루프 사용법",
      "산술 연산",
      "반올림 함수 사용법",
      "print() 함수 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "초보자",
      "리스트 처리",
      "산술 연산",
      "반올림",
      "출력 포매팅"
    ],
    programmingConcepts: ["리스트 처리", "반복문", "산술 연산", "반올림", "출력 포매팅"],
    skillsPracticed: ["리스트 순회", "가격 계산", "반올림", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "리스트를 순회하며 각 요소를 처리하는 방법 학습하기",
      "간단한 수학 연산을 프로그래밍으로 구현하는 방법 이해하기",
      "반올림 함수를 사용하여 결과를 정수로 변환하는 방법 익히기",
      "각 결과를 새로운 줄에 출력하는 방법 학습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "가격 인상 계산을 잘못하는 실수 (예: 10%를 0.1이 아닌 0.01로 계산)",
      "반올림을 하지 않거나 잘못 적용하는 실수",
      "결과를 정수로 변환하지 않는 실수",
      "각 가격을 새로운 줄에 출력하지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "10% 인상은 현재 가격에 1.1을 곱하는 것과 같습니다.",
        "round() 함수를 사용하여 결과를 반올림할 수 있습니다."
      ],
      intermediate: [
        "리스트 컴프리헨션을 사용하면 코드를 더 간결하게 만들 수 있습니다.",
        "print() 함수의 end 매개변수를 사용하여 출력 형식을 조정할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트 처리", "반복문", "산술 연산", "반올림", "출력 포매팅", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "round() 함수는 숫자를 반올림합니다.",
          "int() 함수는 숫자를 정수로 변환합니다.",
          "print() 함수는 기본적으로 줄바꿈을 포함합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 주어진 가격 리스트
prices = [50000, 89000, 120000, 75000, 99000]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "55000\n97900\n132000\n82500\n108900"
      }
    ],
    solution: `# 주어진 가격 리스트
  prices = [50000, 89000, 120000, 75000, 99000]
  
  # 각 가격을 10% 인상하고 반올림하여 출력
  for price in prices:
      # 가격 10% 인상 및 정수로 반올림
      new_price = round(price * 1.1)
      
      # 새 가격 출력
      print(new_price)`,
    examples: [
      {
        input: "",
        output: `55000
97900
132000
82500
108900`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "10% 인상된 가격을 각각 새로운 줄에 정수로 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_056 // List Processing // code // 👻슬로퍼의 실적분석
  {
    problemId: "data-types-pythonAlgorithm_056",
    image: null,
    title: "👻슬로퍼의 실적분석",
    description: `👻슬로퍼는 자신의 온라인 쇼핑몰의 일주일 간 판매실적을 분석하려고 합니다. 매일의 판매액이 담긴 리스트가 주어집니다. 이 데이터를 바탕으로 최저 매출, 최고 매출, 그리고 평균 매출을 계산하여 출력하는 프로그램을 작성해주세요.
  
  요구사항:
  1. 주어진 sales 리스트에서 최저 매출을 찾아 출력하세요.
  2. 주어진 sales 리스트에서 최고 매출을 찾아 출력하세요.
  3. 주어진 sales 리스트의 평균 매출을 계산하여 출력하세요. (소수점 아래는 버립니다)
  4. 각 결과는 새로운 줄에 출력하세요.
  5. 결과는 숫자만 출력하세요.
  6. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "리스트 처리와 기본 통계",
    subTopic: "최소값, 최대값, 평균 계산",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "리스트 처리, 기본 통계 계산, 내장 함수 사용 등 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 처리",
      "기본 통계 개념 (최소값, 최대값, 평균)",
      "내장 함수 사용 (min(), max(), sum())",
      "정수 나눗셈"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "초보자",
      "리스트 처리",
      "기본 통계",
      "내장 함수"
    ],
    programmingConcepts: ["리스트 처리", "기본 통계 계산", "내장 함수 사용", "정수 나눗셈"],
    skillsPracticed: ["리스트 데이터 분석", "최소값/최대값 찾기", "평균 계산", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "리스트에서 최소값과 최대값을 찾는 방법 학습하기",
      "리스트의 평균을 계산하는 방법 이해하기",
      "Python 내장 함수를 활용하는 방법 익히기",
      "간단한 데이터 분석 과정 경험하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "최소값이나 최대값을 잘못 계산하는 실수",
      "평균 계산 시 소수점을 처리하지 않는 실수",
      "결과를 새로운 줄에 출력하지 않는 실수",
      "불필요한 텍스트를 함께 출력하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "min()과 max() 함수를 사용하면 리스트의 최소값과 최대값을 쉽게 찾을 수 있습니다.",
        "sum() 함수를 사용하면 리스트의 모든 요소의 합을 구할 수 있습니다."
      ],
      intermediate: [
        "평균을 계산할 때 // 연산자를 사용하면 소수점 아래를 버릴 수 있습니다.",
        "리스트 컴프리헨션을 사용하여 더 복잡한 조건의 통계를 계산할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트 메소드", "기본 통계", "내장 함수", "정수 나눗셈", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "min(list)는 리스트의 최소값을 반환합니다.",
          "max(list)는 리스트의 최대값을 반환합니다.",
          "sum(list)는 리스트의 모든 요소의 합을 반환합니다.",
          "len(list)는 리스트의 길이를 반환합니다.",
          "// 연산자는 나눗셈의 몫(정수)을 반환합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 주어진 일주일 간의 판매실적 리스트
sales = [1200, 1600, 1900, 1300, 1500, 2000, 1800]

# 여기에 코드를 작성하세요`,
    testCases: [
      {
        input: "",
        expected: "1200\n2000\n1614"
      }
    ],
    solution: `# 주어진 일주일 간의 판매실적 리스트
  sales = [1200, 1600, 1900, 1300, 1500, 2000, 1800]
  
  # 최저 매출 계산 및 출력
  min_sales = min(sales)
  print(min_sales)
  
  # 최고 매출 계산 및 출력
  max_sales = max(sales)
  print(max_sales)
  
  # 평균 매출 계산 및 출력 (소수점 아래 버림)
  avg_sales = sum(sales) // len(sales)
  print(avg_sales)`,
    examples: [
      {
        input: "",
        output: `1200
2000
1614`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "최저 매출, 최고 매출, 평균 매출을 각각 새로운 줄에 정수로 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // data-types-pythonAlgorithm_057 // Loops and Arithmetic Operations // code // 거꾸로 구구단
  {
    problemId: "data-types-pythonAlgorithm_057",
    image: null,
    title: "거꾸로 구구단",
    description: `구구단을 외우는 👻슬로퍼를 위해 특별한 구구단 프로그램을 만들어주세요. 이 프로그램은 사용자가 입력한 숫자의 구구단을 1부터 9까지 거꾸로 출력합니다.

요구사항:
1. 사용자로부터 1부터 9 사이의 정수를 입력받으세요.
2. 입력받은 숫자의 구구단을 9부터 1까지 거꾸로 출력하세요.
3. 각 결과는 "X * Y = Z" 형식으로 출력하세요. (X는 입력받은 숫자, Y는 9부터 1까지의 숫자, Z는 계산 결과)
4. 각 결과는 새로운 줄에 출력하세요.
5. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "반복문과 산술 연산",
    subTopic: "구구단 출력",
    category: "Beginner Python Algorithm Problems",
    difficulty: 2,
    difficultyDescription: "사용자 입력 처리, 반복문 사용, 기본 산술 연산 등을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "사용자 입력 처리 (input() 함수)",
      "for 루프 사용법",
      "역순 반복 (range() 함수의 step 인자)",
      "문자열 포매팅",
      "기본 산술 연산"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "초보자",
      "반복문",
      "사용자 입력",
      "산술 연산",
      "구구단"
    ],
    programmingConcepts: ["반복문", "사용자 입력", "산술 연산", "문자열 포매팅"],
    skillsPracticed: ["사용자 입력 처리", "역순 반복", "구구단 계산", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "사용자로부터 입력을 받고 검증하는 방법 학습하기",
      "역순으로 반복하는 for 루프 사용법 이해하기",
      "간단한 산술 연산을 프로그래밍으로 구현하는 방법 익히기",
      "결과를 지정된 형식으로 출력하는 방법 학습하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "사용자 입력을 정수로 변환하지 않는 실수",
      "range() 함수의 인자를 잘못 사용하여 역순 반복을 구현하지 못하는 실수",
      "구구단 계산 시 곱셈 순서를 잘못 적용하는 실수",
      "출력 형식을 정확히 지키지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "input() 함수로 받은 값은 문자열입니다. int()를 사용하여 정수로 변환해야 합니다.",
        "range(9, 0, -1)을 사용하면 9부터 1까지 역순으로 반복할 수 있습니다."
      ],
      intermediate: [
        "f-string을 사용하면 결과를 쉽게 포매팅할 수 있습니다.",
        "while 루프를 사용하여 사용자 입력이 유효할 때까지 반복해서 입력받을 수 있습니다."
      ]
    },
    relatedConcepts: ["반복문", "사용자 입력", "산술 연산", "문자열 포매팅", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "input() 함수는 사용자로부터 입력을 받습니다.",
          "int() 함수는 문자열을 정수로 변환합니다.",
          "range(start, stop, step) 함수로 역순 시퀀스를 생성할 수 있습니다.",
          "f-string을 사용하여 문자열을 포매팅할 수 있습니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: ``,
    testCases: [
      {
        input: "3",
        expected: "3 * 9 = 27\n3 * 8 = 24\n3 * 7 = 21\n3 * 6 = 18\n3 * 5 = 15\n3 * 4 = 12\n3 * 3 = 9\n3 * 2 = 6\n3 * 1 = 3"
      },
      {
        input: "7",
        expected: "7 * 9 = 63\n7 * 8 = 56\n7 * 7 = 49\n7 * 6 = 42\n7 * 5 = 35\n7 * 4 = 28\n7 * 3 = 21\n7 * 2 = 14\n7 * 1 = 7"
      }
    ],
    solution: `# 사용자로부터 1부터 9 사이의 정수 입력 받기
  number = int(input())
  
  # 9부터 1까지 역순으로 반복
  for i in range(9, 0, -1):
      # 구구단 계산 및 출력
      result = number * i
      print(f"{number} * {i} = {result}")`,
    examples: [
      {
        input: "3",
        output: `3 * 9 = 27
3 * 8 = 24
3 * 7 = 21
3 * 6 = 18
3 * 5 = 15
3 * 4 = 12
3 * 3 = 9
3 * 2 = 6
3 * 1 = 3`
      }
    ],
    input: {
      description: "1부터 9 사이의 정수를 입력하세요.",
      type: "integer"
    },
    output: {
      description: "입력한 숫자의 구구단을 9부터 1까지 거꾸로 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  ////////////////////////////////////////////////////////////////end
  // id: 1, title: 'Beginner Python Algorithm Problems'
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////start
  // id: 4, title: 'Sorting Algorithms'
  ////////////////////////////////////////////////////////////////

  // sorting-pythonAlgorithm_001 // Sorting Algorithms // code // 두 정수 스왑(1)
  {
    problemId: "sorting-pythonAlgorithm_001",
    image: sorting_001,
    title: "두 정수 스왑(1)",
    description: `👻슬로퍼는 두 변수의 값을 서로 바꾸는 방법을 배우고 있습니다. 두 정수 a와 b의 값을 서로 교환하는 프로그램을 작성해 주세요.
  
요구사항:
1. 두 정수 a와 b의 초기값은 각각 10과 7로 설정되어 있습니다.
2. a와 b의 값을 서로 교환하세요.
3. 교환 후의 a와 b의 값을 출력하세요.
4. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "변수와 데이터 교환",
    subTopic: "변수 스왑",
    category: "Sorting Algorithms",
    difficulty: 2,
    difficultyDescription: "변수 값 교환의 기본 개념을 다루는 초보자 수준의 문제입니다.",
    cognitiveLevel: "이해",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식", "개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수 개념",
      "할당 연산자 사용법"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "초보자",
      "변수",
      "스왑",
      "데이터 교환"
    ],
    programmingConcepts: ["변수", "할당", "데이터 교환"],
    skillsPracticed: ["변수 조작", "값 교환", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "두 변수의 값을 교환하는 방법 이해하기",
      "임시 변수를 사용한 값 교환 기법 학습하기",
      "변수 값 출력 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "임시 변수를 사용하지 않고 직접 값을 교환하려는 실수",
      "스왑 후 결과를 출력하지 않는 실수",
      "변수 이름을 잘못 사용하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "임시 변수를 사용하면 두 변수의 값을 쉽게 교환할 수 있습니다.",
        "print() 함수를 사용하여 결과를 출력할 수 있습니다."
      ],
      intermediate: [
        "Python에서는 a, b = b, a와 같은 방식으로 직접 교환할 수도 있습니다.",
        "f-string을 사용하면 변수 값을 쉽게 문자열에 포함시킬 수 있습니다."
      ]
    },
    relatedConcepts: ["변수", "할당", "데이터 타입", "출력", "주석"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "변수는 값을 저장하는 컨테이너입니다.",
          "= 연산자는 변수에 값을 할당합니다.",
          "print() 함수는 콘솔에 출력합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 변수 값 설정
a = 10
b = 7

# 여기에 코드를 작성하세요

# 결과 출력
print(a)
print(b)
`,
    testCases: [
      {
        input: "",
        expected: "7\n10"
      }
    ],
    solution: `# 초기 변수 값 설정
  a = 10
  b = 7
  
  # 두 변수의 값을 교환
  temp = a  # 임시 변수에 a의 값을 저장
  a = b     # a에 b의 값을 할당
  b = temp  # b에 임시 변수(원래 a의 값)를 할당
  
  # 결과 출력
  print(f"a = {a}")
  print(f"b = {b}")`,
    examples: [
      {
        input: "",
        output: `7
10`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "스왑된 a와 b의 값을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // sorting-pythonAlgorithm_002 // Sorting Algorithms // code // 두 정수 스왑(2)
  {
    problemId: "sorting-pythonAlgorithm_002",
    image: null,
    title: "두 정수 스왑(2)",
    description: `👻슬로퍼는 리스트 내의 두 정수 요소의 위치를 바꾸는 방법을 배우고 있습니다. 주어진 리스트에서 두 정수의 위치를 서로 교환하는 프로그램을 작성해 주세요.
  
요구사항:
1. 주어진 리스트 numbers는 [10, 7]입니다.
2. 리스트의 두 요소의 위치를 서로 바꾸세요.
3. 위치를 바꾼 후의 리스트를 출력하세요.
4. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "리스트 조작",
    subTopic: "요소 위치 교환",
    category: "Sorting Algorithms",
    difficulty: 2,
    difficultyDescription: "리스트 조작의 기본 개념을 다루는 초보자 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 인덱싱"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "초보자",
      "리스트",
      "스왑"
    ],
    programmingConcepts: ["리스트", "인덱싱", "데이터 교환"],
    skillsPracticed: ["리스트 조작", "요소 교환", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "리스트 내 요소의 위치를 교환하는 방법 이해하기",
      "리스트 인덱싱을 사용하여 요소에 접근하는 방법 학습하기",
      "리스트 조작 결과를 출력하는 방법 익히기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "리스트 인덱스를 잘못 사용하는 실수",
      "임시 변수를 사용하지 않고 직접 값을 교환하려는 실수",
      "스왑 후 결과를 출력하지 않는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "리스트의 첫 번째 요소는 인덱스 0, 두 번째 요소는 인덱스 1입니다.",
        "임시 변수를 사용하면 두 요소의 값을 쉽게 교환할 수 있습니다."
      ],
      intermediate: [
        "Python에서는 numbers[0], numbers[1] = numbers[1], numbers[0]와 같은 방식으로 직접 교환할 수도 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "인덱싱", "변수", "할당", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "리스트는 대괄호 []로 생성합니다.",
          "리스트의 요소에 접근할 때는 인덱스를 사용합니다. 예: numbers[0]",
          "print() 함수는 콘솔에 출력합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 리스트 설정
numbers = [10, 7]

# 여기에 코드를 작성하세요

# 결과 출력
print(numbers)`,
    testCases: [
      {
        input: "",
        expected: "[7, 10]"
      }
    ],
    solution: `# 초기 리스트 설정
  numbers = [10, 7]
  
  # 두 요소의 위치를 교환
  temp = numbers[0]       # 첫 번째 요소를 임시 변수에 저장
  numbers[0] = numbers[1] # 두 번째 요소를 첫 번째 위치에 할당
  numbers[1] = temp       # 임시 변수의 값(원래 첫 번째 요소)을 두 번째 위치에 할당
  
  # 결과 출력
  print(numbers)`,
    examples: [
      {
        input: "",
        output: "[7, 10]"
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "스왑된 요소를 포함한 리스트를 출력합니다.",
      type: "list"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // sorting-pythonAlgorithm_003 // Sorting Algorithms // code // 크기 비교 후 스왑(1)
  {
    problemId: "sorting-pythonAlgorithm_003",
    image: null,
    title: "크기 비교 후 스왑(1)",
    description: `👻슬로퍼는 두 정수를 비교하고 필요한 경우 스왑하는 방법을 배우고 있습니다. 두 변수에 저장된 정수 데이터의 크기를 비교하고, 첫 번째 변수의 값이 두 번째 변수의 값보다 작거나 같으면 그대로 출력하고, 크다면 스왑한 후 결과를 출력하는 프로그램을 작성해 주세요.
  
요구사항:
1. 두 정수 a와 b가 주어집니다. (a = 10, b = 7)
2. a와 b의 크기를 비교하세요.
3. a가 b보다 작거나 같으면 현재 상태 그대로 a와 b를 출력하세요.
4. a가 b보다 크면 a와 b의 값을 서로 교환한 후 출력하세요.
5. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "조건문과 스왑",
    subTopic: "크기 비교 및 교환",
    category: "Sorting Algorithms",
    difficulty: 3,
    difficultyDescription: "조건문과 변수 교환의 기본 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수 개념",
      "조건문 (if-else) 사용법",
      "비교 연산자 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "초보자",
      "조건문",
      "비교 연산",
      "스왑"
    ],
    programmingConcepts: ["조건문", "비교 연산", "변수", "스왑"],
    skillsPracticed: ["조건 분기", "크기 비교", "변수 교환", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "조건문을 사용하여 두 값을 비교하는 방법 이해하기",
      "필요한 경우에만 변수 값을 교환하는 로직 구현하기",
      "비교 결과에 따라 다른 동작을 수행하는 프로그램 작성하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "조건문의 비교 연산자를 잘못 사용하는 실수",
      "스왑 로직을 조건문 밖에 작성하는 실수",
      "결과 출력을 누락하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "if-else 문을 사용하여 a와 b의 크기를 비교할 수 있습니다.",
        "스왑할 때는 임시 변수를 사용하면 편리합니다."
      ],
      intermediate: [
        "Python에서는 a, b = b, a와 같은 방식으로 직접 교환할 수도 있습니다.",
        "f-string을 사용하면 변수 값을 쉽게 문자열에 포함시킬 수 있습니다."
      ]
    },
    relatedConcepts: ["조건문", "비교 연산", "변수", "스왑", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "if-else 문은 조건에 따라 코드 실행을 분기합니다.",
          "비교 연산자 >는 '크다'를 의미합니다.",
          "print() 함수는 콘솔에 출력합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 변수 값 설정
a = 10
b = 7

# 여기에 코드를 작성하세요

# 결과 출력
print(f"a = {a}, b = {b}")`,
    testCases: [
      {
        input: "",
        expected: "a = 7, b = 10"
      }
    ],
    solution: `# 초기 변수 값 설정
  a = 10
  b = 7
  
  # a와 b의 크기 비교 후 필요시 스왑
  if a > b:
      # a가 b보다 크므로 스왑
      temp = a
      a = b
      b = temp
  
  # 결과 출력
  print(f"a = {a}, b = {b}")`,
    examples: [
      {
        input: "",
        output: "a = 7, b = 10"
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "비교 및 필요시 스왑 후의 a와 b의 값을 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // sorting-pythonAlgorithm_004 // Sorting Algorithms // code // 크기 비교 후 스왑(2)
  {
    problemId: "sorting-pythonAlgorithm_004",
    image: null,
    title: "크기 비교 후 스왑(2)",
    description: `👻슬로퍼는 리스트 내의 두 정수를 비교하고 필요한 경우 스왑하는 방법을 배우고 있습니다. 리스트에 저장된 두 정수 데이터의 크기를 비교하고, 첫 번째 요소가 두 번째 요소보다 작거나 같으면 그대로 출력하고, 크다면 스왑한 후 결과를 출력하는 프로그램을 작성해 주세요.
  
요구사항:
1. 두 정수를 포함하는 리스트 numbers가 주어집니다. (numbers = [10, 7])
2. numbers[0]과 numbers[1]의 크기를 비교하세요.
3. numbers[0]이 numbers[1]보다 작거나 같으면 현재 상태 그대로 리스트를 출력하세요.
4. numbers[0]이 numbers[1]보다 크면 두 요소의 위치를 서로 교환한 후 리스트를 출력하세요.
5. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "리스트와 조건문",
    subTopic: "리스트 요소 비교 및 교환",
    category: "Sorting Algorithms",
    difficulty: 3,
    difficultyDescription: "리스트 조작, 조건문, 그리고 요소 교환의 기본 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 인덱싱",
      "조건문 (if-else) 사용법",
      "비교 연산자 사용법"
    ],
    estimatedTime: "10분",
    tags: [
      "Python",
      "초보자",
      "리스트",
      "조건문",
      "비교 연산",
      "스왑"
    ],
    programmingConcepts: ["리스트", "조건문", "비교 연산", "요소 교환"],
    skillsPracticed: ["리스트 조작", "조건 분기", "크기 비교", "요소 교환", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "리스트 요소에 접근하고 비교하는 방법 이해하기",
      "조건문을 사용하여 리스트 요소를 비교하는 방법 학습하기",
      "필요한 경우에만 리스트 요소를 교환하는 로직 구현하기",
      "비교 결과에 따라 다른 동작을 수행하는 프로그램 작성하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "리스트 인덱스를 잘못 사용하는 실수",
      "조건문의 비교 연산자를 잘못 사용하는 실수",
      "스왑 로직을 조건문 밖에 작성하는 실수",
      "결과 출력을 누락하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "리스트의 첫 번째 요소는 인덱스 0, 두 번째 요소는 인덱스 1입니다.",
        "if-else 문을 사용하여 numbers[0]과 numbers[1]의 크기를 비교할 수 있습니다."
      ],
      intermediate: [
        "Python에서는 numbers[0], numbers[1] = numbers[1], numbers[0]와 같은 방식으로 직접 교환할 수도 있습니다.",
        "리스트를 출력할 때 print() 함수를 사용하면 됩니다."
      ]
    },
    relatedConcepts: ["리스트", "인덱싱", "조건문", "비교 연산", "요소 교환", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "리스트는 대괄호 []로 생성합니다.",
          "리스트의 요소에 접근할 때는 인덱스를 사용합니다. 예: numbers[0]",
          "if-else 문은 조건에 따라 코드 실행을 분기합니다.",
          "비교 연산자 >는 '크다'를 의미합니다.",
          "print() 함수는 콘솔에 출력합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 리스트 설정
numbers = [10, 7]

# 여기에 코드를 작성하세요

# 결과 출력
print(numbers)`,
    testCases: [
      {
        input: "",
        expected: "[7, 10]"
      }
    ],
    solution: `# 초기 리스트 설정
  numbers = [10, 7]
  
  # numbers[0]과 numbers[1]의 크기 비교 후 필요시 스왑
  if numbers[0] > numbers[1]:
      # numbers[0]이 numbers[1]보다 크므로 스왑
      numbers[0], numbers[1] = numbers[1], numbers[0]
  
  # 결과 출력
  print(numbers)`,
    examples: [
      {
        input: "",
        output: "[7, 10]"
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "비교 및 필요시 스왑 후의 리스트를 출력합니다.",
      type: "list"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // sorting-pythonAlgorithm_005 // Sorting Algorithms // code // 크기 비교 후 스왑(3)
  {
    problemId: "sorting-pythonAlgorithm_005",
    image: null,
    title: "크기 비교 후 스왑(3)",
    description: `👻슬로퍼는 리스트 내의 세 정수를 비교하고 필요한 경우 스왑하는 방법을 배우고 있습니다. 리스트에 저장된 세 정수 데이터의 크기를 비교하고, 오름차순으로 정렬되도록 필요한 경우 스왑한 후 결과를 출력하는 프로그램을 작성해 주세요.
  
  요구사항:
  1. 세 정수를 포함하는 리스트 numbers가 주어집니다. (numbers = [10, 7, 15])
  2. numbers의 요소들을 비교하여 오름차순으로 정렬하세요.
  3. 정렬 과정에서 필요한 경우에만 요소들의 위치를 교환하세요.
  4. 최종적으로 정렬된 리스트를 출력하세요.
  5. 코드에 주석을 추가하여 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "리스트와 정렬",
    subTopic: "버블 정렬의 기초",
    category: "Sorting Algorithms",
    difficulty: 4,
    difficultyDescription: "리스트 조작, 다중 비교, 그리고 반복적인 요소 교환의 개념을 다루는 중급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 인덱싱",
      "조건문 (if-else) 사용법",
      "반복문 (for) 사용법",
      "비교 연산자 사용법"
    ],
    estimatedTime: "15분",
    tags: [
      "Python",
      "초중급",
      "리스트",
      "정렬",
      "버블 정렬",
      "비교 연산",
      "스왑"
    ],
    programmingConcepts: ["리스트", "반복문", "조건문", "비교 연산", "요소 교환"],
    skillsPracticed: ["리스트 조작", "다중 요소 비교", "버블 정렬 구현", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "리스트 내 여러 요소를 비교하고 정렬하는 방법 이해하기",
      "버블 정렬의 기본 원리 학습하기",
      "반복문과 조건문을 조합하여 정렬 알고리즘 구현하기",
      "코드에 주석을 다는 습관 기르기"
    ],
    commonMistakes: [
      "리스트 인덱스를 잘못 사용하는 실수",
      "비교 횟수를 잘못 설정하여 모든 요소를 비교하지 않는 실수",
      "스왑 로직을 잘못 구현하는 실수",
      "정렬 후 결과 출력을 누락하는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "리스트의 요소들을 순차적으로 비교하면서 필요할 때마다 스왑하세요.",
        "두 개의 중첩된 for 루프를 사용하면 모든 요소를 비교할 수 있습니다."
      ],
      intermediate: [
        "버블 정렬의 기본 원리를 적용해보세요.",
        "Python의 다중 할당 기능을 사용하면 임시 변수 없이 스왑할 수 있습니다."
      ]
    },
    relatedConcepts: ["리스트", "버블 정렬", "반복문", "조건문", "비교 연산", "요소 교환"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "리스트는 대괄호 []로 생성합니다.",
          "리스트의 요소에 접근할 때는 인덱스를 사용합니다. 예: numbers[0]",
          "for 루프를 사용하여 리스트를 순회할 수 있습니다.",
          "if 문은 조건에 따라 코드 실행을 분기합니다.",
          "print() 함수는 콘솔에 출력합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 리스트 설정
numbers = [10, 7, 15]

# 여기에 코드를 작성하세요

# 결과 출력
print(numbers)`,
    testCases: [
      {
        input: "",
        expected: "[7, 10, 15]"
      }
    ],
    solution: `# 초기 리스트 설정
  numbers = [10, 7, 15]
  
  # 버블 정렬 구현
  for i in range(len(numbers)):
      for j in range(len(numbers) - 1 - i):
          # 인접한 두 요소를 비교하고 필요시 스왑
          if numbers[j] > numbers[j + 1]:
              numbers[j], numbers[j + 1] = numbers[j + 1], numbers[j]
  
  # 결과 출력
  print(numbers)`,
    examples: [
      {
        input: "",
        output: "[7, 10, 15]"
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "정렬된 리스트를 출력합니다.",
      type: "list"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  // sorting-pythonAlgorithm_006 // Sorting Algorithms // code // 선택 정렬 알고리즘
  {
    problemId: "sorting-pythonAlgorithm_006",
    image: null,
    title: "선택 정렬 알고리즘",
    description: `👻슬로퍼는 선택 정렬 알고리즘을 배우고 있습니다. 10개의 정수가 들어있는 리스트를 선택 정렬을 사용하여 오름차순으로 정렬하는 프로그램을 작성해 주세요.

요구사항:
1. 10개의 정수를 포함하는 리스트 numbers가 주어집니다. (numbers = [64, 34, 25, 12, 22, 11, 90, 88, 45, 73])
2. 선택 정렬 알고리즘을 구현하여 numbers 리스트를 오름차순으로 정렬하세요.
3. 정렬 과정에서 각 패스마다 현재 상태의 리스트를 출력하세요.
4. 최종적으로 정렬된 리스트를 출력하세요.
5. 코드에 주석을 추가하여 선택 정렬의 각 단계를 설명하세요.`,
    
    subject: "컴퓨터 과학",
    language: "Python",
    version: "3.x",
    topic: "정렬 알고리즘",
    subTopic: "선택 정렬",
    category: "Sorting Algorithms",
    difficulty: 5,
    difficultyDescription: "선택 정렬 알고리즘의 구현과 리스트 조작을 다루는 중급 수준의 문제입니다.",
    cognitiveLevel: "적용",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "리스트 개념",
      "리스트 인덱싱",
      "반복문 (for) 사용법",
      "조건문 (if) 사용법",
      "비교 연산자 사용법"
    ],
    estimatedTime: "20분",
    tags: [
      "Python",
      "중급",
      "리스트",
      "정렬",
      "선택 정렬",
      "알고리즘"
    ],
    programmingConcepts: ["리스트", "반복문", "조건문", "비교 연산", "요소 교환", "알고리즘 구현"],
    skillsPracticed: ["선택 정렬 구현", "리스트 조작", "알고리즘 분석", "결과 출력", "주석 작성"],
    standardsAlignment: ["CSTA K-12 CS 표준", "ISTE 학생을 위한 표준"],
    learningObjectives: [
      "선택 정렬 알고리즘의 원리 이해하기",
      "선택 정렬을 Python으로 구현하는 방법 학습하기",
      "정렬 과정을 단계별로 출력하여 알고리즘의 동작 과정 이해하기",
      "코드에 주석을 달아 알고리즘의 각 단계를 설명하는 습관 기르기"
    ],
    commonMistakes: [
      "최소값을 찾는 과정에서 인덱스 범위를 잘못 설정하는 실수",
      "현재 인덱스와 최소값 인덱스가 다를 때만 교환해야 하는 것을 놓치는 실수",
      "각 패스마다 리스트 상태를 출력하는 것을 잊는 실수",
      "주석을 작성하지 않거나 불충분하게 작성하는 실수"
    ],
    hints: {
      beginner: [
        "외부 루프는 리스트의 처음부터 끝까지 진행합니다.",
        "내부 루프는 현재 위치 다음부터 끝까지 최소값을 찾습니다."
      ],
      intermediate: [
        "최소값의 인덱스를 저장하고, 내부 루프가 끝난 후에 현재 위치와 교환하세요.",
        "Python의 다중 할당 기능을 사용하면 임시 변수 없이 요소를 교환할 수 있습니다."
      ]
    },
    relatedConcepts: ["선택 정렬", "시간 복잡도", "공간 복잡도", "in-place 정렬"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "프로그래밍",
    answerFormat: "코드",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: [
          "리스트는 대괄호 []로 생성합니다.",
          "range() 함수로 반복 범위를 지정할 수 있습니다.",
          "len() 함수로 리스트의 길이를 얻을 수 있습니다.",
          "print() 함수는 콘솔에 출력합니다.",
          "주석은 #을 사용하여 작성합니다."
        ]
      }
    },
    initialCode: `# 초기 리스트 설정
numbers = [64, 34, 25, 12, 22, 11, 90, 88, 45, 73]

# 여기에 선택 정렬 알고리즘을 구현하세요

# 최종 정렬된 리스트 출력
print("정렬된 리스트:", numbers)`,
    testCases: [
      {
        input: "",
        expected: "정렬된 리스트: [11, 12, 22, 25, 34, 45, 64, 73, 88, 90]"
      }
    ],
    solution: `# 초기 리스트 설정
  numbers = [64, 34, 25, 12, 22, 11, 90, 88, 45, 73]
  
  # 선택 정렬 알고리즘 구현
  for i in range(len(numbers)):
      # 현재 위치를 최소값으로 가정
      min_idx = i
      # 현재 위치 다음부터 끝까지 최소값 찾기
      for j in range(i+1, len(numbers)):
          if numbers[j] < numbers[min_idx]:
              min_idx = j
      
      # 현재 위치와 찾은 최소값 위치가 다르면 교환
      if min_idx != i:
          numbers[i], numbers[min_idx] = numbers[min_idx], numbers[i]
      
      # 현재 패스의 정렬 상태 출력
      print(f"패스 {i+1}: {numbers}")
  
  # 최종 정렬된 리스트 출력
  print("정렬된 리스트:", numbers)`,
    examples: [
      {
        input: "",
        output: `패스 1: [11, 34, 25, 12, 22, 64, 90, 88, 45, 73]
패스 2: [11, 12, 25, 34, 22, 64, 90, 88, 45, 73]
패스 3: [11, 12, 22, 34, 25, 64, 90, 88, 45, 73]
패스 4: [11, 12, 22, 25, 34, 64, 90, 88, 45, 73]
패스 5: [11, 12, 22, 25, 34, 64, 90, 88, 45, 73]
패스 6: [11, 12, 22, 25, 34, 45, 90, 88, 64, 73]
패스 7: [11, 12, 22, 25, 34, 45, 64, 88, 90, 73]
패스 8: [11, 12, 22, 25, 34, 45, 64, 73, 90, 88]
패스 9: [11, 12, 22, 25, 34, 45, 64, 73, 88, 90]
패스 10: [11, 12, 22, 25, 34, 45, 64, 73, 88, 90]
정렬된 리스트: [11, 12, 22, 25, 34, 45, 64, 73, 88, 90]`
      }
    ],
    input: {
      description: "이 문제는 사용자 입력이 필요하지 않습니다.",
      type: "none"
    },
    output: {
      description: "각 패스마다의 정렬 상태와 최종 정렬된 리스트를 출력합니다.",
      type: "string"
    },
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.4,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-22",
    lastUpdated: "2024-10-22",
    versionNumber: 1.0
  },

  
  ////////////////////////////////////////////////////////////////end
  // id: 4, title: 'Sorting Algorithms'
  ////////////////////////////////////////////////////////////////
]

