import React, { useState } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  fetchSignInMethodsForEmail
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // 추가
import { auth, db } from '../../../Firebase';
import SignupStep1 from './SignupStep1';
import SignupStep2 from './SignupStep2';
import SignupStep3 from './SignupStep3';
import SignupIntro from './SignupIntro';

const SignupPage = ({ onClose }) => {
  const [step, setStep] = useState(0);  // 0으로 초기화
  const [formData, setFormData] = useState({
    programmingExperience: '',
    learningSpeed: '',
    learningChallenges: [],
    collaborationPreference: '',
    progressTracking: '',
    preferredLearningTimes: [],
    supplementaryLearning: null,
    email: '',
    password: '',
    phoneNumber: '',
    name: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate(); // 추가

  const handleNextStep = async () => {
    if (step === 0) {
      setStep(step + 1);
    } else if (step === 1) {
      setStep(step + 1);
    } else if (step === 2) {
      try {
        // 이메일 중복 확인 (Firebase Auth 사용)
        // console.log('이메일 중복 확인 중:', formData.email);
        const signInMethods = await fetchSignInMethodsForEmail(auth, formData.email);
        if (signInMethods.length > 0) {
          throw new Error('이미 가입된 이메일입니다.');
        }

        // console.log('사용자 생성 중...');
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        const user = userCredential.user;
        // console.log('사용자 생성 완료:', user.uid);

        // Firestore에 사용자 정보 저장
        // console.log('Firestore에 사용자 정보 저장 중...');
        await setDoc(doc(db, 'users', user.uid), {
          programmingExperience: formData.programmingExperience,
          learningSpeed: formData.learningSpeed,
          learningChallenges: formData.learningChallenges,
          collaborationPreference: formData.collaborationPreference,
          progressTracking: formData.progressTracking,
          preferredLearningTimes: formData.preferredLearningTimes,
          supplementaryLearning: formData.supplementaryLearning,
          email: formData.email,
          phoneNumber: formData.phoneNumber || '',
          name: formData.name || '',
        });
        // console.log('Firestore에 사용자 정보 저장 완료');

        // 로그인 상태로 유지하고 랜딩 페이지로 리디렉션
        setStep(3); // 3단계로 이동
        navigate('/'); // 랜딩 페이지로 리디렉션
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          setError('이미 가입된 아이디입니다.');
        } else {
          setError('회원가입 중 오류가 발생했습니다. 다시 시도해 주세요.');
        }
      }
    }
  };

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Firestore에 사용자 정보 저장
      await setDoc(doc(db, 'users', user.uid), {
        programmingExperience: formData.programmingExperience,
        learningSpeed: formData.learningSpeed,
        learningChallenges: formData.learningChallenges,
        collaborationPreference: formData.collaborationPreference,
        progressTracking: formData.progressTracking,
        preferredLearningTimes: formData.preferredLearningTimes,
        supplementaryLearning: formData.supplementaryLearning,
        email: user.email,
        phoneNumber: formData.phoneNumber || '',
        name: user.displayName || '',
        isAdmin: false, // 기본값 설정 (관리자는 수동으로 설정)
      });

      // 다음 단계로 이동
      setStep(3); // 3단계로 이동
      navigate('/'); // 랜딩 페이지로 리디렉션
    } catch (error) {
      console.error('구글 회원가입 실패:', error);
      if (error.code === 'auth/popup-closed-by-user') {
        setError('팝업이 닫혔습니다. 다시 시도해주세요.');
      } else if (error.code === 'auth/cancelled-popup-request') {
        setError('팝업 요청이 취소되었습니다. 팝업 차단을 해제해주세요.');
      } else if (error.message.includes('Cross-Origin-Opener-Policy')) {
        setError('COOP 설정으로 인해 팝업이 차단되었습니다. 브라우저 설정을 확인해주세요.');
      } else {
        setError('회원가입 과정에서 문제가 발생했습니다. 팝업 차단을 해제하고 다시 시도해주세요.');
      }
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const updateFormData = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  return (
    <div className="px-2 py-6 flex flex-col h-[80vh] rounded-lg">
      <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
        <span className="text-[#D00070]">Go SlopeX 🚀</span>
      </h1>
      
      {step < 4 && (
        <>
          <div className="w-full mb-12">
            <div className="w-full h-2 bg-gray-200 rounded-full mb-4">
              <div 
                className="h-full bg-[#D00070] rounded-full transition-all duration-300 ease-in-out" 
                style={{width: `${(step / 3) * 100}%`}}
              ></div>
            </div>
            <div className="flex justify-between items-center">
              {['약관동의', '학습경험', '계정정보', '완료'].map((label, index) => (
                <div key={index} className="flex flex-col items-center w-1/4">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center mb-2 ${
                    step >= index ? 'bg-[#D00070]' : 'bg-gray-300'
                  }`}>
                    {step > index ? (
                      <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    ) : (
                      <span className="text-white font-bold">{index + 1}</span>
                    )}
                  </div>
                  <span className={`text-xs ${step >= index ? 'text-[#D00070] font-semibold' : 'text-gray-500'}`}>
                    {label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {error && (
        <div className="text-red-500 text-center mb-4">
          {error}
        </div>
      )}

      <div className="flex-grow overflow-y-auto">
        {step === 0 && (
          <SignupIntro 
            onNext={handleNextStep} 
            formData={formData}
            updateFormData={updateFormData}
          />
        )}
        {step === 1 && (
          <SignupStep1 
            formData={formData} 
            updateFormData={updateFormData} 
            onNext={handleNextStep} 
          />
        )}
        {step === 2 && (
          <SignupStep2 
            formData={formData} 
            updateFormData={updateFormData} 
            onNext={handleNextStep} 
            onPrevious={handlePreviousStep} 
            onGoogleSignup={handleGoogleSignup} 
          />
        )}
        {step === 3 && <SignupStep3 onClose={onClose} />}
      </div>
    </div>
  );
};

export default SignupPage;