import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../Firebase';
import { collection, addDoc, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';

export const createGroup = createAsyncThunk(
  'group/createGroup',
  async (groupData, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const docRef = await addDoc(collection(db, 'groups'), {
        ...groupData,
        approved: false,
        createdAt: new Date(),
        createdBy: auth.user.uid
      });
      return { id: docRef.id, ...groupData, approved: false, createdBy: auth.user.uid };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchGroups = createAsyncThunk(
  'group/fetchGroups',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const q = query(collection(db, 'groups'), where('createdBy', '==', auth.user.uid));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveGroup = createAsyncThunk(
  'group/approveGroup',
  async (groupId, { rejectWithValue }) => {
    try {
      const groupRef = doc(db, 'groups', groupId);
      await updateDoc(groupRef, { approved: true });
      return groupId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    groups: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.groups.push(action.payload);
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchGroups.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.groups = action.payload;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(approveGroup.fulfilled, (state, action) => {
        const group = state.groups.find(g => g.id === action.payload);
        if (group) {
          group.approved = true;
        }
      });
  }
});

export default groupSlice.reducer;