import React, { useState } from 'react';
import Tile from './tileComponents/Tile';
import TileContainer from './tileComponents/TileContainer';
import ContentsModal from '../modal/ContentsModal';
import { allTilesData } from '../../../../data/AllTileData';

const StarterContents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  const openModal = (tile) => {
    setSelectedTile(tile);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 타일 렌더링 로직을 별도의 함수로 분리
  const renderTiles = () => allTilesData.starterTiles.map((tile, index) => (
    <Tile
      key={index}
      {...tile}
      onClick={() => openModal(tile)}
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4 text-[#969696]">
          <span className="text-[#000]">시작하기</span>
          <br />
          두렵다면
        </h2>
      </div>
      <TileContainer tiles={renderTiles()} containerId="starter-tile-scroll" />
      {selectedTile && (
        <ContentsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          imgSrc={selectedTile.img}
          title1={selectedTile.title1}
          title2={selectedTile.title2}
          description={selectedTile.description}
          modalContent={selectedTile.modalContent}
          status={selectedTile.status}
          openInNewTab={selectedTile.modalContent.openInNewTab}
        />
      )}
    </div>
  );
};

export default StarterContents;