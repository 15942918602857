import React, { useEffect, useRef, useState, useCallback } from 'react';
import Footer from '../../../footer/Footer';

const LmsHomeMain = () => {
  const spotlightRef = useRef(null);
  const textRef = useRef(null);

  // FinalCredit 관련 상태 및 레퍼런스
  const [opacity, setOpacity] = useState(1);
  const [secondOpacity, setSecondOpacity] = useState(0);
  const [isFixed, setIsFixed] = useState(true);
  const creditContainerRef = useRef(null);

  const [gradientColors, setGradientColors] = useState(['#ffffff', '#fbcfe8', '#D00070']);
  const [gradientPositions, setGradientPositions] = useState([0, 50, 100]);

  const handleScroll = useCallback(() => {
    if (!creditContainerRef.current) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = creditContainerRef.current.offsetTop;
    const scrollRange = 500;
    const secondScrollRange = 500;
    const totalScrollRange = scrollRange + secondScrollRange;

    if (scrollTop > offsetTop && scrollTop < offsetTop + totalScrollRange) {
      const newOpacity = Math.max(1 - (scrollTop - offsetTop) / scrollRange, 0);
      setOpacity(newOpacity);

      const secondTextOpacity = Math.min((scrollTop - offsetTop - scrollRange) / secondScrollRange, 1);
      setSecondOpacity(secondTextOpacity);
    }

    setIsFixed(scrollTop < offsetTop + totalScrollRange);

    // 그라데이션 색상 및 위치 변경 로직
    const scrollPercentage = Math.min((scrollTop - offsetTop) / totalScrollRange, 1);
    const newColors = [
      `hsl(${360 * (1 - scrollPercentage)}, 100%, 100%)`,
      `hsl(${330 * (1 - scrollPercentage)}, 100%, 80%)`,
      `hsl(${300 * (1 - scrollPercentage)}, 100%, 50%)`
    ];
    setGradientColors(newColors);

    // 그라데이션 위치 변경
    const newPositions = [
      0,
      50 - scrollPercentage * 25,
      100 - scrollPercentage * 50
    ];
    setGradientPositions(newPositions);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFixed(entry.isIntersecting);
      },
      { rootMargin: '0px', threshold: 0 }
    );

    if (creditContainerRef.current) {
      observer.observe(creditContainerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="bg-white mt-20 md:mt-10">
      <div className="max-w-[1440px] mx-auto px-[10%] h-auto min-h-[170px] flex flex-col md:flex-row justify-between items-start md:items-center py-4 sm:py-0">
        <span className="text-[40px] sm:text-[45px] font-extrabold leading-tight sm:leading-none mb-2 sm:mb-0">
          <span className="text-[#D00070]">SlopeX AI.</span>
          <br className="md:hidden"/> 그리고 LMS.
        </span>
        <span className="text-[16px] sm:text-[20px] font-bold mb-4 md:mb-0">강력하고 다양한 동기부여 장치,<br/>SlopeX AI, LMS까지.</span>
      </div>
      <div className="bg-black h-[calc(60vh-5px)] relative overflow-hidden">
        <div ref={spotlightRef} className="absolute inset-0 z-10 transition-all duration-300 ease-out"></div>
        <div ref={textRef} className="absolute bottom-0 left-0 right-0 z-20 text-center pb-48">
          <p className="text-2xl md:text-3xl font-semibold text-white opacity-0 animate-fadeIn animation-delay-1000 mb-2">
            적응형 학습을 탑재한
          </p>
          <h2 className="text-4xl md:text-6xl font-bold mb-4 text-white opacity-0 animate-fadeIn">
            <span
              className="bg-clip-text text-transparent"
              style={{
                backgroundImage: `linear-gradient(to right, ${gradientColors.map((color, index) => `${color} ${gradientPositions[index]}%`).join(', ')})`
              }}
            >
              똑똑한 LMS의 탄생
            </span>
          </h2>
        </div>
      </div>
      

      <div className="relative h-[300vh] bg-black -mt-1" ref={creditContainerRef}>
        <div className={`sticky top-0 h-screen w-full ${isFixed ? '' : 'relative'}`}>
          <div
            className="absolute top-0 left-0 w-full text-center h-screen flex items-center justify-center transition-opacity duration-500"
            style={{ opacity }}
          >
            <p className="text-lg text-[#fff] font-bold">
              <span className="block md:inline"><span className='text-[#D00070]'>SlopeX AI</span>와 강력한 LMS로</span>
            </p>
          </div>
          <div
            className="absolute top-0 left-0 w-full text-center h-screen flex items-center justify-center transition-opacity duration-500"
            style={{ opacity: secondOpacity }}
          >
            <p className="text-lg text-[#fff] font-bold">
              <span className="block md:inline">반드시 목표까지 도달시키겠습니다.</span>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LmsHomeMain;