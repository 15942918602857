/* 
  쿠키 설정으로 닫기 버튼을 눌린 사용자는 다시 뜨지않도록 주의.
  정기구독을 신청한 사람은 안뜨도록.
*/

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa'; // 닫기 버튼 아이콘
import Cookies from 'js-cookie';

const TopBanner = ({ onClose, isSubscribed, isVisible }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const bannerClosed = Cookies.get('bannerClosed');
    if (!bannerClosed && !isSubscribed) {
      setShouldRender(true);
    }

    // 스크롤바 이슈 해결을 위한 패딩 추가
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflowY = 'scroll';

    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflowY = '';
    };
  }, [isSubscribed]);

  const handleClose = () => {
    setShouldRender(false);
    Cookies.set('bannerClosed', 'true', { expires: 1 }); // 1일 동�� 유효
    onClose();
  };

  if (!shouldRender || !isVisible) return null;

  return (
    <div className="bg-[#D00070] h-[60px] transition-all duration-500 ease-in-out w-screen max-w-[100vw] overflow-x-hidden">
      <div className="h-[60px] flex justify-center items-center w-full">
        <div className="max-w-[1440px] w-full flex justify-center items-center px-4 relative">
          <Link to="##" className="text-white text-[16px] md:text-[20px] font-medium">
            <span className="font-extrabold">LMS / SlopeX AI 개발중 🚀</span>
          </Link>
          <button
            className="text-white absolute right-4 mr-[3%] focus:outline-none"
            onClick={handleClose}
          >
            <FaTimes size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;