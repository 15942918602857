import React, { useState } from 'react';

const guides = [
  { id: 1, title: '01CS LMS 이용가이드(1) 준비중', date: '2024-09-05', content: '이용 가이드 준비중입니다. 👾' },
  { id: 2, title: '01CS LMS 이용가이드(2) 준비중', date: '2024-09-05', content: '이용 가이드 준비중입니다. 👾' },
  { id: 3, title: '01CS LMS 이용가이드(3) 준비중', date: '2024-09-05', content: '이용 가이드 준비중입니다. 👾' },
  { id: 4, title: '01CS LMS 이용가이드(4) 준비중', date: '2024-09-05', content: '이용 가이드 준비중입니다. 👾' },
  { id: 5, title: '01CS LMS 이용가이드(5) 준비중', date: '2024-09-05', content: '이용 가이드 준비중입니다. 👾' },
  // { id: 6, title: '기타 이용 가이드', date: '2024-04-10', content: '기타 이용 가이드입니다.' },
];

const TabC = () => {
  const [activeGuides, setActiveGuides] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleGuideClick = (id) => {
    setActiveGuides((prevActiveGuides) =>
      prevActiveGuides.includes(id)
        ? prevActiveGuides.filter((guideId) => guideId !== id)
        : [...prevActiveGuides, id]
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentGuides = guides.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(guides.length / itemsPerPage);

  const handlePageClick = (page) => {
    if (typeof page === 'number') {
      setCurrentPage(page);
    } else {
      setCurrentPage((prevPage) => {
        let newPage = page === 'next' ? prevPage + 1 : prevPage - 1;
        if (newPage < 1) newPage = 1;
        if (newPage > totalPages) newPage = totalPages;
        return newPage;
      });
    }
  };

  return (
    <div className="max-w-[380px] md:max-w-[800px] mx-auto mt-12 space-y-6">
      {currentGuides.map((guide) => (
        <div key={guide.id} className="border-b pb-4">
          <h3
            className={`text-sm md:text-xl font-bold cursor-pointer flex justify-between items-center ${
              activeGuides.includes(guide.id) ? 'text-[#D00070]' : 'text-[#333]'
            }`}
            onClick={() => handleGuideClick(guide.id)}
          >
            {/* No. 부분에 고정 너비 설정 */}
            <span className="w-[45px] md:w-[100px] tracking-[-0.1em] md:tracking-normal">{`No.${guide.id}`}</span>
            
            {/* 제목 부분에 flex-grow로 남은 공간을 차지 */}
            <span className="flex-1 px-2 md:px-12 text-left">{guide.title}</span>
            
            {/* 날짜 부분에 고정 너비 설정 */}
            <span className="w-[85px] md:w-[150px] text-right tracking-[-0.1em] md:tracking-normal text-gray-600">{guide.date}</span>
          </h3>
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              activeGuides.includes(guide.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <p className="text-xs md:text-base text-gray-600 mt-4">{guide.content}</p>
          </div>
        </div>
      ))}
  
      {/* Pagination */}
      <div className="flex justify-center mt-4 space-x-2">
        {/* Previous page button */}
        <button
          className="px-3 py-1 rounded bg-gray-200 text-gray-600"
          onClick={() => handlePageClick('prev')}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>
  
        {/* Page number buttons */}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-3 py-1 rounded ${
              currentPage === index + 1 ? 'bg-[#D00070] text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handlePageClick(index + 1)}
          >
            {index + 1}
          </button>
        ))}
  
        {/* Next page button */}
        <button
          className="px-3 py-1 rounded bg-gray-200 text-gray-600"
          onClick={() => handlePageClick('next')}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default TabC;