import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'firebase/auth';
import { auth } from '../../Firebase';
import { openSignupModal } from '../../store/reducers/signupModalSlice';

const AuthBtn = ({ buttonStyle, linkStyle, closeMenu }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      window.location.href = '/';
      if (closeMenu) closeMenu();
    } catch (error) {
      console.error('로그아웃 실패', error);
    }
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (closeMenu) closeMenu();
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    dispatch(openSignupModal());
    if (closeMenu) closeMenu();
  };

  return (
    <div className="flex gap-4">
      {user ? (
        <>
          <button
            onClick={handleLogout}
            className={`border border-[#D00070] text-white md:text-[#D00070] py-2 px-5 rounded-full hover:border-[#D00070] hover:text-[#D00070] transition duration-300 ${buttonStyle}`}
          >
            로그아웃
          </button>
          <a
            href="/lms/study"
            target="_blank"
            rel="noopener noreferrer"
            className={`bg-white md:bg-[#D00070] text-[#D00070] md:text-[#fff] md:border-[#D00070] font-extrabold py-2 px-5 rounded-full border-2 border-transparent hover:shadow-lg transition duration-300 animate-subtle-pulse ${buttonStyle}`}
          >
            <span className="inline-block">LMS 개발중</span>
          </a>
        </>
      ) : (
        <>
          <a
            href="/login"
            className={`border border-[#D00070]/50 text-[#D00070] py-2 px-6 rounded-full hover:border-[#D00070] hover:text-[#D00070] transition duration-300 ${linkStyle}`}
            onClick={handleLinkClick}
          >
            로그인
          </a>
          <button
            onClick={handleSignupClick}
            className={`bg-white md:bg-[#D00070] text-[#D00070] md:text-[#fff] md:border-[#D00070] font-extrabold py-2 px-5 rounded-full border-2 border-transparent hover:shadow-lg transition duration-300 animate-subtle-pulse ${buttonStyle}`}
          >
            회원가입
          </button>
        </>
      )}
    </div>
  );
};

export default AuthBtn;