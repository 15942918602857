import React, { useState, useEffect } from 'react';

const SearchClass = () => {
  const [inputValue, setInputValue] = useState('');
  const [inputHeight, setInputHeight] = useState('auto');

  const placeholder = "예) 처음 코딩을 배워보려고 하는데, 초급자가 시작할 수 있는 강의를 추천해줘";

  useEffect(() => {
    adjustInputHeight();
  }, [inputValue]);

  const adjustInputHeight = () => {
    const textarea = document.getElementById('search-input');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setInputHeight(`${textarea.scrollHeight}px`);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="w-full md:w-[70%] mx-auto px-[3%] pt-20 pb-6">
      <h1 className="text-xl md:text-2xl font-bold my-2 md:my-4">&nbsp;강의 추천 및 커리큘럼 생성</h1>
      <div className="mb-4 md:mb-6 border border-gray-300 p-4">
        <textarea
          id="search-input"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="w-full p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100 resize-none overflow-hidden"
          style={{ height: inputHeight }}
          disabled
        />
        <p className="text-sm text-gray-500 mt-2">
          SlopeX AI 기술 구현중입니다.
          단순히 강의찾는 경험을 넘어, 학습자의 요구에 맞는 강의를 추천하고 방향성을 제시합니다.
          사용자의 현재 상태(프로그래밍에 대한 공부를 얼마나 했는지),
          요구사항(어떤 강의를 듣고 싶은지),
          목적(프로그래밍을 배우고자 하는 목적이 무엇인지),
          목표(프로그래밍을 배워서 어떤 것을 얻고 싶은지) 등을 바탕으로 강의를 찾아드리는 경험을 제공합니다.
          기본적으로 적응형 학습이 가능하도록 설계되어 있지만, 더 나은 사용자 경험을 제공하고자 노력하고 있습니다.</p>
      </div>
    </div>
  );
};

export default SearchClass;
