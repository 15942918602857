// src/components/lms/modal/LmsModal.js
import React, { useState, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { usePreventScroll } from '../../../hook/usePreventScroll';

const LmsModal = ({ isOpen, onClose, title, content, linkText, linkUrl, customStyles = {} }) => {
  const [visible, setVisible] = useState(false);
  const [animating, setAnimating] = useState(false);
  const modalContentRef = useRef(null);

  usePreventScroll(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setTimeout(() => setAnimating(true), 10);
    } else {
      setAnimating(false);
      setTimeout(() => setVisible(false), 300);
    }
  }, [isOpen]);

  if (!visible) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${
        animating ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleBackgroundClick}
    >
      <div
        ref={modalContentRef}
        className={`bg-white rounded-lg w-full relative shadow-lg py-6 px-8 transition-transform transition-opacity duration-300 max-w-[800px] overflow-y-auto max-h-[80vh] ${
          animating ? 'transform scale-100 opacity-100' : 'transform scale-95 opacity-0'
        }`}
        style={customStyles}
        onClick={(e) => e.stopPropagation()}
      >
        {/* 닫기 버튼 */}
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl"
          onClick={onClose}
        >
          <FaTimes />
        </button>

        {/* 모달 제목 */}
        {title && <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>}

        {/* 모달 내용 */}
        {content && (
          <p className="text-gray-600 text-center mb-4" style={{ whiteSpace: 'pre-line' }}>
            {content}
          </p>
        )}

        {/* 링크가 있을 경우 표시 */}
        {linkText && linkUrl && (
          <div className="text-center">
            <a
              href={linkUrl}
              className="text-[#D00070] font-bold hover:underline mt-4 block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default LmsModal;