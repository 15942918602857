// SignupStep2.js
import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { auth, db } from '../../../Firebase';
// import { setLoading } from '../../../features/authSlice';
// import { doc, setDoc } from 'firebase/firestore';
// import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const SignupStep2 = ({ formData, updateFormData, onNext, onPrevious }) => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [error] = useState('');
  // const dispatch = useDispatch();

  useEffect(() => {
    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email.trim());
    };
    setIsEmailValid(validateEmail(formData.email));
  }, [formData.email]);

  useEffect(() => {
    const validatePassword = (password) => {
      return password.length >= 8 && /[!@#$%^&*]/.test(password);
    };
    setIsPasswordValid(validatePassword(formData.password));
  }, [formData.password]);

  useEffect(() => {
    setIsPasswordConfirmed(formData.password === formData.passwordConfirm);
  }, [formData.password, formData.passwordConfirm]);

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && isEmailValid && isPasswordValid && isPasswordConfirmed && formData.name && formData.phoneNumber) {
  //     onNext();
  //   }
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  // const handleGoogleSignup = async () => {
  //   const provider = new GoogleAuthProvider();
  //   try {
  //     dispatch(setLoading(true));
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     // Firestore에 사용자 정보 저장
  //     await setDoc(doc(db, 'users', user.uid), {
  //       programmingExperience: formData.programmingExperience,
  //       learningSpeed: formData.learningSpeed,
  //       learningChallenges: formData.learningChallenges,
  //       collaborationPreference: formData.collaborationPreference,
  //       progressTracking: formData.progressTracking,
  //       preferredLearningTimes: formData.preferredLearningTimes,
  //       supplementaryLearning: formData.supplementaryLearning,
  //       email: user.email,
  //       phoneNumber: formData.phoneNumber || '',
  //       name: user.displayName || '',
  //       isAdmin: false,
  //     });

  //     // 다음 단계로 이동
  //     onNext();
  //   } catch (error) {
  //     console.error('구글 회원가입 실패:', error);
  //     if (error.code === 'auth/popup-closed-by-user') {
  //       setError('회원가입 팝업이 닫혔습니다. 다시 시도해 주세요.');
  //     } else if (error.code === 'auth/cancelled-popup-request') {
  //       setError('팝업 요청이 취소되었습니다. 팝업 차단을 해제해주세요.');
  //     } else {
  //       setError('구글 회원가입을 실패했습니다. 다시 시도해 주세요.');
  //     }
  //   } finally {
  //     dispatch(setLoading(false));
  //   }
  // };

  return (
    <div className="w-full p-4">
      <div className="w-full space-y-4">
        <div>
          <label htmlFor="email" className="block mb-2 font-semibold text-gray-700">이메일</label>
          <input
            id="email"
            type="email"
            placeholder="이메일"
            className="input input-bordered w-full px-4 py-2 border-[#D00070] focus:outline-none focus:border-[#D00070] rounded-md"
            value={formData.email || ''}
            onChange={(e) => updateFormData('email', e.target.value.trim())}
            required
          />
          {!isEmailValid && formData.email && <p className="text-red-500 text-sm mt-1">유효한 이메일 형식을 입력해주세요.</p>}
        </div>
        <div>
          <label htmlFor="password" className="block mb-2 font-semibold text-gray-700">비밀번호</label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="비밀번호"
              className="input input-bordered w-full px-4 py-2 border-[#D00070] focus:outline-none focus:border-[#D00070] rounded-md pr-10"
              value={formData.password || ''}
              onChange={(e) => updateFormData('password', e.target.value)}
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? "비밀번호 숨기기" : "비밀번호 보기"}
            >
              {showPassword ? (
                <svg className="h-6 w-6 text-[#D00070]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  {/* 눈 아이콘 */}
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
              ) : (
                <svg className="h-6 w-6 text-[#D00070]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  {/* 눈 감은 아이콘 */}
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a10.025 10.025 0 011.563-3.029M9.878 9.878a3 3 0 014.243 4.243M9.88 9.88L6.59 6.59M17.532 17.532l3.29 3.29M3 3l3.59 3.59" />
                </svg>
              )}
            </button>
          </div>
          {!isPasswordValid && formData.password && <p className="text-red-500 text-sm mt-1">비밀번호는 최소 8자 이상이어야 하며, 특수 문자가 포함되어야 합니다.</p>}
        </div>
        <div>
          <label htmlFor="passwordConfirm" className="block mb-2 font-semibold text-gray-700">비밀번호 확인</label>
          <div className="relative">
            <input
              id="passwordConfirm"
              type={showPasswordConfirm ? "text" : "password"}
              placeholder="비밀번호 확인"
              className="input input-bordered w-full px-4 py-2 border-[#D00070] focus:outline-none focus:border-[#D00070] rounded-md pr-10"
              value={formData.passwordConfirm || ''}
              onChange={(e) => updateFormData('passwordConfirm', e.target.value)}
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={togglePasswordConfirmVisibility}
              aria-label={showPasswordConfirm ? "비밀번호 확인 숨기기" : "비밀번호 확인 보기"}
            >
              {showPasswordConfirm ? (
                <svg className="h-6 w-6 text-[#D00070]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  {/* 눈 아이콘 */}
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
              ) : (
                <svg className="h-6 w-6 text-[#D00070]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  {/* 눈 감은 아이콘 */}
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a10.025 10.025 0 011.563-3.029M9.878 9.878a3 3 0 014.243 4.243M9.88 9.88L6.59 6.59M17.532 17.532l3.29 3.29M3 3l3.59 3.59" />
                </svg>
              )}
            </button>
          </div>
          {!isPasswordConfirmed && formData.passwordConfirm && <p className="text-red-500 text-sm mt-1">비밀번호가 일치하지 않습니다.</p>}
        </div>
        <div>
          <label htmlFor="name" className="block mb-2 font-semibold text-gray-700">이름(닉네임)</label>
          <input
            id="name"
            type="text"
            placeholder="이름 또는 닉네임"
            className="input input-bordered w-full px-4 py-2 border-[#D00070] focus:outline-none focus:border-[#D00070] rounded-md"
            value={formData.name || ''}
            onChange={(e) => updateFormData('name', e.target.value.trim())}
            required
          />
        </div>
        <div>
          <label htmlFor="phoneNumber" className="block mb-2 font-semibold text-gray-700">전화번호</label>
          <input
            id="phoneNumber"
            type="tel"
            placeholder="전화번호"
            className="input input-bordered w-full px-4 py-2 border-[#D00070] focus:outline-none focus:border-[#D00070] rounded-md"
            value={formData.phoneNumber || ''}
            onChange={(e) => updateFormData('phoneNumber', e.target.value.trim())}
            required
          />
        </div>
      </div>

      {error && (
        <div className="text-red-500 text-center mb-4">
          {error}
        </div>
      )}

      <div className="flex gap-4 mt-6">
        <button
          type="button"
          className="btn flex-1 px-6 py-3 text-[#D00070] border border-[#D00070] hover:bg-[#D00070] hover:text-white transition duration-300 rounded-md"
          onClick={onPrevious}
        >
          이전
        </button>
        <button
          type="button"
          className={`btn flex-1 px-10 py-3 text-white text-lg transition duration-300 bg-[#D00070] hover:bg-[#c20066] rounded-md ${
            isEmailValid && isPasswordValid && isPasswordConfirmed && formData.name && formData.phoneNumber ? '' : 'opacity-50 cursor-not-allowed'
          }`}
          onClick={onNext}
          disabled={!isEmailValid || !isPasswordValid || !isPasswordConfirmed || !formData.name || !formData.phoneNumber}
        >
          가입하기
        </button>
      </div>

      <div className="mt-6 text-center">
        <p className="mb-6 text-center text-gray-600">구글, 네이버 아이디 회원가입 <br/>개발중 입니다. 🧑🏻‍💻</p>
        {/* <button
          onClick={handleGoogleSignup}
          className="w-full flex items-center justify-center px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-100 transition duration-300"
        >
          <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
            <path
              fill="#4285F4"
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
              fill="#34A853"
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
              fill="#FBBC05"
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
              fill="#EA4335"
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
            <path fill="none" d="M1 1h22v22H1z" />
          </svg>
          구글 아이디로 가입하기
        </button> */}
        {/* <br/> */}
      </div>
    </div>
  );
};

export default SignupStep2;