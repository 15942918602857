import React, { useState } from 'react';

const questions = [
  { id: 1, category: '서비스 전반', question: '01CS는 어떤 서비스인가요?', answer: '컴퓨터 과학(Computer Science) 분야를 공부할 수 있는 서비스입니다. 인터랙티브 + 코드 시각화로 단순히 문법만 배우는게 아니라, 컴퓨터구조/자료구조 등을 이해하면서 컴퓨터 과학이라는 분야의 기초를 단단하게 다질 수 있습니다.' },
  { id: 2, category: '서비스 전반', question: '다른 교육도 있나요?', answer: '네! 컴퓨터과학 뿐만 아니라 기초공학교육을 위해서 기초수학부터 공학계열의 다양한 분야에 대해서도 교육을 제공해드리려고 합니다. 우리의 꿈은 누구나 기초공학교육을 받고, 공학기술로 산업의 기반을 탄탄하게 다지는 것입니다.' },
  { id: 3, category: '서비스 전반', question: 'ZOOM 강의 모집은 언제하나요?', answer: '현재는 기존 학생들을 대상으로 기존에 존재하는 사업자를 통해서 진행하고 있습니다. 추후 SlopeXcelerity 회사 설립이 완료되고, 결제까지 가능해지는 시점부터는 새로운 회원분들에게도 오픈하고 저를 비롯한 코치님들의 강의에 참여 가능하도록 하겠습니다.' },
  { id: 4, category: '기기 이용', question: '모바일에서 이용 가능한가요?', answer: '네, 모바일에서도 공학교육이 가능하도록 2025년 1월 오픈을 목표로 개발중에 있습니다. APP 버전도 빠르게 출시하겠습니다. 현재는 PC나 테블릿에서 편리하게 사용할 수 있도록 개발중입니다. 아무래도 LMS는 메뉴나 화면에 표시해야할 요소가 너무 많다보니, 모바일보다는 PC나 테스크탑을 이용하길 권장드리고 있습니다. 하지만 모바일에서도 간편하게 10~20분 정도 공부할 수 있도록 인터랙티브 요소를 포함한 교육 콘텐츠도 지속적으로 개발될 예정입니다.' },
  // { id: 4, category: '기기 이용', question: '인터넷 연결이 필요한가요?', answer: '네, 필요합니다.' },
];

const TabA = () => {
  const [activeQuestions, setActiveQuestions] = useState([]);

  const handleQuestionClick = (id) => {
    setActiveQuestions((prevActiveQuestions) =>
      prevActiveQuestions.includes(id)
        ? prevActiveQuestions.filter((q) => q !== id)
        : [...prevActiveQuestions, id]
    );
  };

  const groupedQuestions = questions.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {});

  return (
    <div className="space-y-8 max-w-[380px] md:max-w-[800px] mx-auto">
      {Object.keys(groupedQuestions).map((category) => (
        <div key={category}>
          <h2 className="text-xl font-bold text-[#D00070] mt-12 mb-6">{`[${category}]`}</h2>
          {groupedQuestions[category].map((q) => (
            <div key={q.id} className="border-b pb-6">
              <h3
                className={`text-lg mt-6 font-bold cursor-pointer ${
                  activeQuestions.includes(q.id) ? 'text-[#D00070]' : 'text-[#333]'
                }`}
                onClick={() => handleQuestionClick(q.id)}
              >
                {q.question}
              </h3>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeQuestions.includes(q.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="text-gray-600 mt-4">{q.answer}</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TabA;