export const softwareEngineeringProblems = [
  {
    problemId: "python_diagram_001",
    title: "프로그래밍 다이어그램 이해하기",
    description: "코드를 작성하기 전, 약속된 도형과 화살표 등을 사용하여 워크플로 혹은 프로세스를 보여주는 다이어그램은 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Variables",
    subTopic: "Diagrams",
    difficulty: 1,
    difficultyDescription: "프로그래밍 기초 개념을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "기본적인 프로그래밍 개념",
      "다이어그램의 일반적인 용도"
    ],
    estimatedTime: "2분",
    tags: [
      "Programming",
      "Beginner",
      "Diagrams",
      "Workflow"
    ],
    programmingConcepts: ["알고리즘", "프로세스 흐름"],
    skillsPracticed: ["프로그래밍 개념 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "프로그래밍에서 사용되는 다이어그램의 종류 이해",
      "알고리즘 설계 과정에서 다이어그램의 역할 인식"
    ],
    commonMistakes: [
      "UML 다이어그램과 혼동",
      "데이터 흐름 다이어그램과 혼동",
      "마인드맵과 혼동"
    ],
    hints: {
      beginner: [
        "이 다이어그램은 알고리즘의 논리적 흐름을 나타냅니다.",
        "프로그래밍 초보자들이 코드를 작성하기 전에 자주 사용합니다."
      ],
      intermediate: [
        "이 다이어그램은 시작, 처리, 결정, 입출력 등을 나타내는 기호를 사용합니다."
      ],
      advanced: []
    },
    relatedConcepts: ["알고리즘", "의사코드", "프로그램 설계"],
    learningStyle: ["시각적 학습"],
    type: "input_answer",
    questionType: "conceptual",
    answerFormat: "Text",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["이 개념은 특정 프로그래밍 언어에 국한되지 않습니다."]
      }
    },
    correctAnswer: "순서도",
    acceptableAnswers: ["순서도", "Flowchart", "FlowChart", "flowchart", "flow chart", "플로우차트"],
    solution: "정답은 '순서도'(flowchart)입니다. 순서도는 프로그램의 논리적 흐름을 시각적으로 표현하는 도구로, 알고리즘이나 프로세스의 각 단계를 기호와 화살표를 사용하여 나타냅니다.",
    examples: [
      {
        input: "순서도",
        output: "정답입니다!"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["텍스트 입력이 가능한 기기"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },
  {
    problemId: "sw_eng_001",
    title: "소프트웨어 개발 생명주기(SDLC)",
    description: "다음 중 소프트웨어 개발 생명주기(SDLC)의 일반적인 단계가 아닌 것은 무엇인가요?",
    subject: "Software Engineering",
    language: "한국어",
    topic: "Software Engineering Basics",
    subTopic: "SDLC",
    difficulty: 2,
    difficultyDescription: "소프트웨어 개발 생명주기의 기본 단계를 이해하는 문제입니다.",
    cognitiveLevel: "이해",
    prerequisiteKnowledge: ["기본적인 소프트웨어 개발 과정"],
    estimatedTime: "2분",
    tags: ["Software Engineering", "SDLC", "Development Process"],
    learningObjectives: [
      "소프트웨어 개발 생명주기의 주요 단계 이해",
      "각 단계의 목적 파악",
    ],
    commonMistakes: ["SDLC 단계의 순서를 혼동함"],
    hints: ["소프트웨어 개발의 주요 활동들을 생각해보세요."],
    relatedConcepts: ["애자일 방법론", "폭포수 모델"],
    learningStyle: ["이론적 학습"],
    assessmentType: "객관식",
    languageLevel: "중간",
    type: "multiple_choice",
    choices: [
      "요구사항 분석",
      "설계",
      "구현",
      "마케팅",
    ],
    correctAnswer: 3,
    solution: "소프트웨어 개발 생명주기(SDLC)의 일반적인 단계는 요구사항 분석, 설계, 구현, 테스트, 유지보수 등입니다. 마케팅은 소프트웨어 개발 과정 자체의 단계가 아니라 비즈니스 활동에 해당합니다. 따라서 정답은 4번 '마케팅'입니다.",
    examples: [],
    promptForFineTuning: "소프트웨어 개발 생명주기(SDLC)의 주요 단계는 무엇인가요?",
    completionForFineTuning: "소프트웨어 개발 생명주기(SDLC)의 주요 단계는 일반적으로 요구사항 분석, 설계, 구현, 테스트, 배포, 유지보수로 구성됩니다. 각 단계는 소프트웨어 개발 과정에서 중요한 역할을 하며, 프로젝트의 성공적인 완료를 위해 체계적으로 수행됩니다.",
  },
  {
    problemId: "sw_eng_002",
    title: "요구사항 추출 기법",
    description: "다음 중 요구사항 추출 기법이 아닌 것은 무엇인가요?",
    subject: "Software Engineering",
    language: "한국어",
    topic: "Requirements Elicitation",
    subTopic: "Requirements Gathering Techniques",
    difficulty: 3,
    difficultyDescription: "요구사항 추출 기법의 종류를 이해하는 문제입니다.",
    cognitiveLevel: "적용",
    prerequisiteKnowledge: ["요구사항 공학 기초"],
    estimatedTime: "3분",
    tags: ["Software Engineering", "Requirements Engineering", "Elicitation Techniques"],
    learningObjectives: [
      "다양한 요구사항 추출 기법 이해",
      "각 기법의 특징 파악",
    ],
    commonMistakes: ["요구사항 추출 기법과 분석 기법을 혼동함"],
    hints: ["요구사항을 수집하는 방법들을 생각해보세요."],
    relatedConcepts: ["인터뷰", "설문조사", "브레인스토밍"],
    learningStyle: ["적용 학습"],
    assessmentType: "객관식",
    languageLevel: "중간",
    type: "multiple_choice",
    choices: [
      "인터뷰",
      "프로토타이핑",
      "관찰",
      "코드 리뷰",
    ],
    correctAnswer: 3,
    solution: "코드 리뷰는 요구사항 추출 기법이 아니라 소프트웨어 품질 보증 활동의 일부입니다. 인터뷰, 프로토타이핑, 관찰은 모두 요구사항을 추출하는 데 사용되는 기법입니다. 따라서 정답은 4번 '코드 리뷰'입니다.",
    examples: [],
    promptForFineTuning: "요구사항 추출에 사용되는 주요 기법들은 무엇인가요?",
    completionForFineTuning: "요구사항 추출에 사용되는 주요 기법들로는 인터뷰, 설문조사, 브레인스토밍, 관찰, 프로토타이핑, 워크샵, 문서 분석 등이 있습니다. 각 기법은 상황과 프로젝트의 특성에 따라 선택적으로 사용되며, 때로는 여러 기법을 조합하여 사용하기도 합니다.",
  },
  {
    problemId: "sw_eng_003",
    title: "소프트웨어 아키텍처 스타일",
    description: "다음 중 소프트웨어 아키텍처 스타일이 아닌 것은 무엇인가요?",
    subject: "Software Engineering",
    language: "한국어",
    topic: "Software Architecture",
    subTopic: "Architecture Styles",
    difficulty: 4,
    difficultyDescription: "소프트웨어 아키텍처 스타일의 종류를 이해하는 문제입니다.",
    cognitiveLevel: "분석",
    prerequisiteKnowledge: ["소프트웨어 아키텍처 기초"],
    estimatedTime: "3분",
    tags: ["Software Engineering", "Software Architecture", "Architecture Styles"],
    learningObjectives: [
      "다양한 소프트웨어 아키텍처 스타일 이해",
      "각 스타일의 특징 파악",
    ],
    commonMistakes: ["아키텍처 스타일과 디자인 패턴을 혼동함"],
    hints: ["시스템의 전체적인 구조를 설명하는 방식을 생각해보세요."],
    relatedConcepts: ["클라이언트-서버", "마이크로서비스", "레이어드 아키텍처"],
    learningStyle: ["분석적 학습"],
    assessmentType: "객관식",
    languageLevel: "어려움",
    type: "multiple_choice",
    choices: [
      "파이프-필터",
      "이벤트-드리븐",
      "마이크로서비스",
      "싱글톤",
    ],
    correctAnswer: 3,
    solution: "싱글톤은 소프트웨어 아키텍처 스타일이 아니라 디자인 패턴의 한 종류입니다. 파이프-필터, 이벤트-드리븐, 마이크로서비스는 모두 소프트웨어 아키텍처 스타일입니다. 따라서 정답은 4번 '싱글톤'입니다.",
    examples: [],
    promptForFineTuning: "소프트웨어 아키텍처 스타일의 주요 종류와 특징은 무엇인가요?",
    completionForFineTuning: "소프트웨어 아키텍처 스타일의 주요 종류로는 클라이언트-서버, 레이어드, 파이프-필터, 이벤트-드리븐, 마이크로서비스 등이 있습니다. 각 스타일은 시스템의 구조를 조직화하는 방식을 제공하며, 시스템의 특성과 요구사항에 따라 선택됩니다. 예를 들어, 마이크로서비스 아키텍처는 시스템을 독립적으로 배포 가능한 작은 서비스들로 분해하여 확장성과 유연성을 높입니다.",
  },
  {
    problemId: "sw_eng_004",
    title: "테스트 케이스 설계 기법",
    description: "다음 중 블랙박스 테스트 기법이 아닌 것은 무엇인가요?",
    subject: "Software Engineering",
    language: "한국어",
    topic: "Test Case Design",
    subTopic: "Testing Techniques",
    difficulty: 3,
    difficultyDescription: "테스트 케이스 설계 기법의 종류를 이해하는 문제입니다.",
    cognitiveLevel: "적용",
    prerequisiteKnowledge: ["소프트웨어 테스팅 기초"],
    estimatedTime: "3분",
    tags: ["Software Engineering", "Software Testing", "Test Case Design"],
    learningObjectives: [
      "블랙박스 테스트와 화이트박스 테스트의 차이 이해",
      "다양한 테스트 케이스 설계 기법 파악",
    ],
    commonMistakes: ["블랙박스 테스트와 화이트박스 테스트 기법을 혼동함"],
    hints: ["테스트 대상 시스템의 내부 구조를 알고 있는지 여부를 고려해보세요."],
    relatedConcepts: ["경계값 분석", "동등 분할", "결정 테이블"],
    learningStyle: ["적용 학습"],
    assessmentType: "객관식",
    languageLevel: "중간",
    type: "multiple_choice",
    choices: [
      "경계값 분석",
      "동등 분할",
      "결정 테이블",
      "구문 커버리지",
    ],
    correctAnswer: 3,
    solution: "구문 커버리지는 화이트박스 테스트 기법입니다. 경계값 분석, 동등 분할, 결정 테이블은 모두 블랙박스 테스트 기법입니다. 블랙박스 테스트는 시스템의 내부 구조를 모르는 상태에서 수행되는 테스트입니다. 따라서 정답은 4번 '구문 커버리지'입니다.",
    examples: [],
    promptForFineTuning: "블랙박스 테스트와 화이트박스 테스트의 차이점과 각각의 주요 기법은 무엇인가요?",
    completionForFineTuning: "블랙박스 테스트는 시스템의 내부 구조를 모르는 상태에서 수행되는 테스트로, 주요 기법으로는 경계값 분석, 동등 분할, 결정 테이블 등이 있습니다. 반면 화이트박스 테스트는 시스템의 내부 구조를 알고 수행되는 테스트로, 구문 커버리지, 분기 커버리지, 경로 커버리지 등의 기법이 사용됩니다. 블랙박스 테스트는 기능적 요구사항을 검증하는 데 중점을 두고, 화이트박스 테스트는 내부 로직과 구조의 정확성을 검증하는 데 중점을 둡니다.",
  },
  {
    problemId: "sw_eng_005",
    title: "소프트웨어 유지보수 유형",
    description: "다음 중 소프트웨어 유지보수의 유형이 아닌 것은 무엇인가요?",
    subject: "Software Engineering",
    language: "한국어",
    topic: "Software Maintenance",
    subTopic: "Maintenance Types",
    difficulty: 3,
    difficultyDescription: "소프트웨어 유지보수의 유형을 이해하는 문제입니다.",
    cognitiveLevel: "이해",
    prerequisiteKnowledge: ["소프트웨어 유지보수 기초"],
    estimatedTime: "2분",
    tags: ["Software Engineering", "Software Maintenance", "Maintenance Types"],
    learningObjectives: [
      "소프트웨어 유지보수의 다양한 유형 이해",
      "각 유형의 목적 파악",
    ],
    commonMistakes: ["유지보수 유형과 개발 단계를 혼동함"],
    hints: ["소프트웨어 변경의 목적을 고려해보세요."],
    relatedConcepts: ["수정 유지보수", "적응 유지보수", "완전 유지보수"],
    learningStyle: ["이론적 학습"],
    assessmentType: "객관식",
    languageLevel: "중간",
    type: "multiple_choice",
    choices: [
      "수정 유지보수",
      "적응 유지보수",
      "완전 유지보수",
      "개발 유지보수",
    ],
    correctAnswer: 3,
    solution: "'개발 유지보수'는 존재하지 않는 용어입니다. 소프트웨어 유지보수의 주요 유형은 수정 유지보수(오류 수정), 적응 유지보수(환경 변화에 대응), 완전 유지보수(새로운 기능 추가)입니다. 따라서 정답은 4번 '개발 유지보수'입니다.",
    examples: [],
    promptForFineTuning: "소프트웨어 유지보수의 주요 유형과 그 목적은 무엇인가요?",
    completionForFineTuning: "소프트웨어 유지보수의 주요 유형은 수정 유지보수, 적응 유지보수, 완전 유지보수로 나뉩니다. 수정 유지보수는 소프트웨어의 오류를 수정하거나 결함을 제거하는 활동입니다. 적응 유지보수는 소프트웨어를 변경하여 새로운 환경이나 요구사항에 맞게 조정하는 활동입니다. 완전 유지보수는 소프트웨어에 새로운 기능을 추가하거나 기존 기능을 개선하는 활동입니다. 각 유형의 목적은 소프트웨어의 품질을 유지하고 변화에 대응하는 것입니다.",
  },
];