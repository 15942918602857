   // src/components/lms/nav/menuItems.js
   export const menuItems = [
    {
      name: '학습시작',
      link: '/lms/class',
      content: {
        title: '강의분야',
        mainMenu: [
          { name: '전체 강의보기', link: '/lms/class' },
          // { name: '00MATH', link: '#' },
          { name: '01CS', link: '/lms/class' },
          // { name: '02Phys', link: '#' },
          // { name: '03Chem', link: '#' },
          // { name: '04Robo', link: '#' },
          // { name: '08Semi', link: '#' },
          // { name: '11Circ', link: '#' },
        ],
        subMenu: [
          { 
            title: '01CS 역량테스트', 
            items: [
              { name: '알고리즘 문제풀이', link: '/lms/py-algorithm' },
              { name: '기억력 테스트', link: '/lms/memory-test' },
              { name: '블록코딩 테스트', link: '#' },
              { name: '코딩 테스트(월 1회)', link: '#' },
            ]
          },
          { 
            title: '00MATH 역량테스트', 
            items: [
              { name: '수리력 테스트', link: '#' },
              { name: '기초수학 테스트(월 1회)', link: '#' }
            ]
          },
          { 
            title: '23CHEM 역량테스트', 
            items: [
              { name: '원소 주기율표 챌린지', link: '#' }
            ]
          }
        ]
      }
    },
    {
      name: '학습관리',
      link: '/lms/study',
      content: {
        title: '나의 학습',
        mainMenu: [
          { name: '학습 데이터 분석', link: '/lms/study' },
          // { name: '피드백 확인', link: '#' },
          { name: '강의 관리', link: '#' },
          // { name: '학습일정 관리', link: '#' }
        ],
        subMenu: [
          // { 
          //   title: '그룹 관리', 
          //   items: [
          //     { name: '코치 신청', link: '#' },
          //     { name: '그룹 스터디', link: '#' },
          //     { name: '그룹 데이터', link: '#' }
          //   ]
          // },
          { 
            title: '학습 자료', 
            items: [
              { name: '자료실', link: '/class', target: '_blank', rel: 'noopener noreferrer' }
            ]
          }
        ]
      }
    },
    {
      name: '설정',
      link: '/lms/more',
      content: {
        title: '설정',
        mainMenu: [
          { name: '그룹 강의 문의', link: '/lms/more/group-management' },
          { name: '내 정보 관리하기', link: '/lms/more/myinfo' },
          { name: '청구 및 구독', link: '/lms/more/pay' },
        ],
        subMenu: [
          { 
            title: '도움 받기', 
            items: [
              { name: '고객센터', link: '/customer', target: '_blank', rel: 'noopener noreferrer' }
            ]
          },
          { 
            title: 'Sloper 전용 메뉴',
            requiresSloper: true, // Sloper 권한 필요
            items: [
              { name: '사용자 권한 관리하기', link: '/lms/user_sloper_admin' },
              { name: '데이터 분석', link: '/lms/sloper_analytics' },
              { name: '그룹 관리', link: '/lms/group-management' }
            ]
          }
        ]
      }
    },
  ];