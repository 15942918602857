//각 코스 별 커리큘럼 정보

// 각 코스 별 커리큘럼 정보
export const courseData = {
    pythonCursor: {
      id: "pythonCursor",
      title: "기초 파이썬 + CURSOR",
      description: "비전공자, 청소년도 할 수 있는 파이썬 프로그래밍 기초와 AI 코딩 도구 CURSOR 사용법을 배웁니다. 우리는 강사, 교재없이 주도적인 학습이 가능한 서비스를 개발하는 중입니다.",
      image: "/assets/courses/python-cursor.jpg",
      duration: "12 Steps",
      level: "입문",
      curriculum: [
        {
          week: 1,
          title: "프로그래밍 개념 & CURSOR",
          content: "프로그래밍의 기초 개념부터 익혀봅니다. CURSOR 프로그램을 설치하고, 기본적인 사용법을 익힙니다."
        },
        {
          week: 2,
          title: "변수와 리스트, 내장함수",
          content: "생성형 AI를 활용하여 다양한 데이터 타입을 사용해봅니다. 그리고 내장함수에 대해서 알아봅니다."
        },
        {
          week: 3,
          title: "조건문과 반복문",
          content: "if-else 문, for 루프, while 루프를 학습하고 CURSOR를 이용한 코드 최적화를 실습합니다."
        },
        {
          week: 4,
          title: "함수와 라이브러리 개념 이해하기",
          content: "라이브러리에 대한 개념을 익히면서, 다양한 함수를 사용해봅니다."
        },
        {
          week: 5,
          title: "클래스와 객체지향 프로그래밍",
          content: "클래스의 개념, 객체 생성, 메서드와 속성에 대해 학습하고 CURSOR를 활용한 클래스 설계를 해봅니다."
        },
        {
          week: 6,
          title: "인공지능 기초 개념",
          content: "인공지능의 기본 개념, 머신러닝과 딥러닝의 차이, AI의 응용 분야에 대해 학습합니다."
        },
        {
          week: 7,
          title: "Numpy 라이브러리 기초",
          content: "Numpy의 기본 사용법, 배열 생성 및 조작, 수학적 연산에 대해 학습하고 CURSOR를 이용한 코드 최적화를 실습합니다."
        },
        {
          week: 8,
          title: "Pandas 라이브러리 기초",
          content: "Pandas의 기본 구조인 Series와 DataFrame, 데이터 로딩 및 저장, 기본적인 데이터 처리 방법을 학습합니다."
        },
        {
          week: 9,
          title: "데이터 분석 및 시각화 프로젝트 (1)",
          content: "실제 데이터셋을 활용하여 Pandas로 데이터를 분석하고, Matplotlib을 이용해 시각화하는 프로젝트를 수행합니다."
        },
        {
          week: 10,
          title: "실무 자동화 프로젝트",
          content: "파이썬을 이용한 업무 자동화 프로젝트를 수행합니다. 엑셀 파일 처리, 이메일 자동 발송 등의 실무적인 태스크를 자동화합니다."
        },
        {
          week: 11,
          title: "챗봇 생성 프로젝트(1)",
          content: "간단한 규칙 기반 챗봇을 제작해보고, 챗봇을 학습시키기 위한 데이터셋을 준비합니다."
        },
        {
          week: 12,
          title: "챗봇 생성 프로젝트(2)",
          content: "OpenAI의 API를 활용하여 Fine-tuning 기반 AI 챗봇을 구현해봅니다."
        }
      ],
      sloperMessage: "더 이상 프로그래밍 기술은 개발자의 전유물이 아닙니다. 현대사회에 살아남기 위한 유리한 스킬을 배워보세요. 공학기술로 여러분의 삶이 더 나아지길 응원합니다."
    }
  };