import React from 'react';
import { Line } from 'react-chartjs-2';

const MonthlyStudyChart = ({ data }) => {
  const chartData = {
    labels: data.map(d => d.date),
    datasets: [
      {
        label: '일일 학습 시간',
        data: data.map(d => d.count),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: '학습 시간 (시간)',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: context => `날짜: ${context[0].label}`,
          label: context => `학습 시간: ${context.raw}시간`,
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default MonthlyStudyChart;