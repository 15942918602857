import React from 'react';
import { Pie } from 'react-chartjs-2';

const ProblemSolvingChart = ({ solved, total }) => {
  const chartData = {
    labels: ['푼 문제', '남은 문제'],
    datasets: [
      {
        data: [solved, total - solved],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
      },
    ],
  };

  return <Pie data={chartData} />;
};

export default ProblemSolvingChart;