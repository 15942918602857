export const computerArchitectureProblems = [
    {
      problemId: "com_architecture_beginner_001",
      title: "CPU의 주요 구성 요소",
      description: "다음 중 CPU의 주요 구성 요소가 아닌 것은 무엇인가요?",
      subject: "Computer Science",
      language: "한국어",
      topic: "CPU 아키텍처",
      subTopic: "CPU Components",
      difficulty: 2,
      difficultyDescription: "CPU의 기본 구조를 이해하는 문제입니다.",
      cognitiveLevel: "이해",
      prerequisiteKnowledge: ["기본 컴퓨터 구조"],
      estimatedTime: "2분",
      tags: ["Computer Architecture", "CPU", "Hardware"],
      learningObjectives: [
        "CPU의 주요 구성 요소 이해",
        "각 구성 요소의 역할 파악",
      ],
      commonMistakes: ["캐시 메모리와 주 메모리를 혼동함"],
      hints: ["CPU 내부의 핵심 구성 요소를 생각해보세요."],
      relatedConcepts: ["컴퓨터 구조", "프로세서 설계"],
      learningStyle: ["이론적 학습"],
      assessmentType: "객관식",
      languageLevel: "중간",
      type: "multiple_choice",
      choices: [
        "산술 논리 장치(ALU)",
        "제어 장치(Control Unit)",
        "레지스터(Registers)",
        "하드 디스크(Hard Disk)",
      ],
      correctAnswer: 3,
      solution: "CPU의 주요 구성 요소는 ALU, 제어 장치, 레지스터입니다. 하드 디스크는 보조 기억 장치로, CPU의 구성 요소가 아닙니다. 따라서 정답은 4번입니다.",
      examples: [],
      promptForFineTuning: "CPU의 주요 구성 요소는 무엇인가요?",
      completionForFineTuning: "CPU의 주요 구성 요소는 산술 논리 장치(ALU), 제어 장치(Control Unit), 레지스터(Registers)입니다. 각 요소는 연산 수행, 명령어 해석 및 제어, 데이터 임시 저장의 역할을 담당합니다.",
    },
    {
      problemId: "com_architecture_beginner_002",
      title: "캐시 메모리의 특징",
      description: "다음 중 캐시 메모리의 특징으로 올바른 것은 무엇인가요?",
      subject: "Computer Science",
      language: "한국어",
      topic: "메모리 계층 구조",
      subTopic: "Cache Memory",
      difficulty: 3,
      difficultyDescription: "메모리 계층 구조와 캐시의 역할을 이해하는 문제입니다.",
      cognitiveLevel: "분석",
      prerequisiteKnowledge: ["기본 컴퓨터 구조", "메모리 시스템"],
      estimatedTime: "3분",
      tags: ["Computer Architecture", "Cache Memory", "Memory Hierarchy"],
      learningObjectives: [
        "캐시 메모리의 특징 이해",
        "메모리 계층 구조에서 캐시의 역할 파악",
      ],
      commonMistakes: ["캐시 메모리와 주 메모리의 특성을 혼동함"],
      hints: ["캐시 메모리의 속도와 용량, 그리고 목적을 고려해보세요."],
      relatedConcepts: ["메모리 계층 구조", "지역성 원리"],
      learningStyle: ["분석적 학습"],
      assessmentType: "객관식",
      languageLevel: "중간",
      type: "multiple_choice",
      choices: [
        "주 메모리보다 용량이 크다",
        "CPU와 주 메모리 사이의 속도 차이를 줄인다",
        "프로그램의 전체 코드와 데이터를 저장한다",
        "비휘발성 메모리이다",
      ],
      correctAnswer: 1,
      solution: "캐시 메모리는 CPU와 주 메모리 사이의 속도 차이를 줄이기 위해 사용됩니다. 주 메모리보다 용량은 작지만 속도가 빠르며, 자주 사용되는 데이터를 임시 저장합니다. 따라서 정답은 2번입니다.",
      examples: [],
      promptForFineTuning: "캐시 메모리의 주요 특징과 역할은 무엇인가요?",
      completionForFineTuning: "캐시 메모리는 CPU와 주 메모리 사이의 속도 차이를 줄이기 위해 사용되는 고속의 소용량 메모리입니다. 자주 사용되는 데이터를 저장하여 CPU의 데이터 접근 시간을 단축시키고, 시스템의 전체적인 성능을 향상시킵니다.",
    },
    {
      problemId: "com_architecture_beginner_003",
      title: "파이프라인 처리 방식",
      description: "CPU의 파이프라인 처리 방식에 대한 설명으로 옳지 않은 것은?",
      subject: "Computer Science",
      language: "한국어",
      topic: "CPU 파이프라인",
      subTopic: "Pipeline Processing",
      difficulty: 4,
      difficultyDescription: "CPU의 고급 처리 기법인 파이프라인에 대한 이해를 평가하는 문제입니다.",
      cognitiveLevel: "평가",
      prerequisiteKnowledge: ["CPU 구조", "명령어 실행 과정"],
      estimatedTime: "4분",
      tags: ["Computer Architecture", "CPU", "Pipeline Processing"],
      learningObjectives: [
        "파이프라인 처리 방식의 개념 이해",
        "파이프라인의 장단점 파악",
      ],
      commonMistakes: ["파이프라인 처리와 병렬 처리를 혼동함"],
      hints: ["파이프라인 처리의 각 단계와 전체 처리 시간을 고려해보세요."],
      relatedConcepts: ["명령어 실행 주기", "슈퍼스칼라 아키텍처"],
      learningStyle: ["분석적 학습"],
      assessmentType: "객관식",
      languageLevel: "어려움",
      type: "multiple_choice",
      choices: [
        "명령어 실행을 여러 단계로 나누어 처리한다",
        "전체 처리 시간을 단축시킬 수 있다",
        "모든 명령어의 실행 시간이 동일해진다",
        "데이터 의존성 문제가 발생할 수 있다",
      ],
      correctAnswer: 2,
      solution: "파이프라인 처리 방식은 명령어 실행을 여러 단계로 나누어 처리하며, 전체 처리 시간을 단축시킬 수 있습니다. 그러나 모든 명령어의 실행 시간이 동일해지는 것은 아니며, 오히려 데이터 의존성 등의 문제로 인해 실행 시간이 달라질 수 있습니다. 따라서 정답은 3번입니다.",
      examples: [],
      promptForFineTuning: "CPU의 파이프라인 처리 방식의 특징과 장단점은 무엇인가요?",
      completionForFineTuning: "파이프라인 처리 방식은 명령어 실행을 여러 단계로 나누어 동시에 여러 명령어를 처리함으로써 전체 처리 시간을 단축시킵니다. 장점으로는 처리 속도 향상이 있고, 단점으로는 데이터 의존성 문제와 분기 예측의 어려움이 있습니다. 모든 명령어의 실행 시간이 동일해지는 것은 아니며, 파이프라인 해저드로 인해 실행 시간이 달라질 수 있습니다.",
    },
  ];