// src/components/lms/LmsMore.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LmsEmoji from '../../components/lms/container/emoji/LmsEmoji';
import LmsModal from '../../components/lms/modal/LmsModal';
import EndingCredit from '../../components/company/container/ending/EndingCredit';
import Footer from '../../components/footer/Footer';

const LmsMore = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: '',
    content: '',
    linkText: '',
    linkUrl: ''
  });

  // 모달 열기 함수
  const openModal = (modalContent) => {
    setModalData(modalContent);
    setIsModalOpen(true);
  };

  // 모달 닫기 함수
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const bottomItems = [
    { icon: '👬👬👬', label: '그룹 강의 문의', link: '/lms/more/group-management' },
    { icon: '🧑🏻‍💻', label: '내 정보 관리하기', link: '/lms/more/myinfo' },
    { icon: '🧾', label: '청구 및 구독', link: '/lms/more/pay' }
  ];

  return (
    <div>
      <div className="max-w-[1440px] mx-auto flex flex-col items-center mt-3 md:py-6">
        {/* 최상단 제목 */}
        <h1 className="text-5xl md:text-[64px] text-center font-extrabold mb-10 mt-16">
          <span className='text-[#D00070]'>SlopeX</span>celerity
          <span className="block md:inline"> 지원</span>
        </h1>

        {/* LmsEmoji 컴포넌트로 변경 */}
        <LmsEmoji onEmojiClick={openModal} />

        {/* 하단 타일 형식 그룹 */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[24px] justify-center items-center mt-10 mb-20">
          {bottomItems.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              className="flex flex-col justify-center items-center w-[310px] h-[180px] bg-gray-100 hover:bg-gray-200 rounded-lg shadow-lg transition-all cursor-pointer"
            >
              <span className="text-3xl">{item.icon}</span>
              <span className="mt-4 text-center text-blue-600" style={{ fontSize: '17px' }}>{item.label}</span>
            </Link>
          ))}
        </div>

        {/* LmsModal 컴포넌트 */}
        <LmsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalData.title}
          content={modalData.content}
          linkText={modalData.linkText}
          linkUrl={modalData.linkUrl}
        />

      </div>
      <EndingCredit />
      <Footer />
    </div>
  );
};

export default LmsMore;