import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../features/authSlice';
import LmsTopNav from '../../components/lms/nav/LmsTopNav';
import LmsUserInfo from '../../components/lms/container/LmsUserInfo';
import LoginModal from '../../components/modal/LoginModal';
import LmsHome from './LmsHomePage';
import LmsStudy from './LmsMyStudyPage';
import LmsClass from './LmsClassPage';
import LmsMore from './LmsMorePage';
import MyInfo from '../../components/lms/myInfo/MyInfo';
import MemoryTest from '../../components/memory/MemoryTest';
import ClassCoursePage from '../course/ClassCoursePage';
import GoodbyeScreen from '../../components/lms/myInfo/GoodbyeScreen';
import PayPage from '../PayPage';
import EndingCredit from '../../components/company/container/ending/EndingCredit';
import Footer from '../../components/footer/Footer';
import UserAuthManagement from '../../components/lms/container/sloper_admin/UserAuthManagement';
import SloperAnalytics from '../../components/lms/container/sloper_admin/SloperAnalytics';
import GroupManagement from '../../components/lms/container/group/GroupManagement';
import ProblemTest from '../../components/lms/container/class/problem/ProblemTest';
import CurriculumOverview from '../../components/lms/container/class/curriculum/CurriculumOverview';
import PythonAlgorithmPage from '../../pages/lms/PythonAlgorithmPage';

const LmsLandingPage = () => {
  const navigate = useNavigate();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);

  const handleNavigation = useCallback((path) => {
    navigate(path);
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            dispatch(setUserData(userData));
          }
        } catch (error) {
          console.error('사용자 데이터 가져오기 오류:', error);
        }
      } else {
        dispatch(setUserData(null));
        setIsLoginModalOpen(true);
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <div className="flex flex-col min-h-screen relative">
      <div className="fixed top-0 left-0 right-0 z-50">
        <LmsTopNav onNavigate={handleNavigation} />
      </div>
      <div className="flex-1 pt-16 pb-20"> {/* 상단 네비게이션과 하단 버튼을 위한 패딩 */}
        <Routes>
          {/* 기존 라우트들 */}
          <Route path="home" element={<LmsHome />} />
          <Route path="study" element={
            <>
              {userData && <LmsUserInfo userData={userData} />}
              <LmsStudy />
            </>
          } />
          <Route path="class" element={<LmsClass />} />
          <Route path="more" element={
            <>
              {userData && <LmsUserInfo userData={userData} />}
              <LmsMore />
            </>
          } />

          {/* 내 정보, 그룹관리 */}
          <Route path="myinfo" element={<MyInfo />} />
          <Route path="more/group-management" element={<GroupManagement />} />

          {/* 다른 라우트들 */}
          <Route path="memory-test" element={<MemoryTest />} />
          <Route path="course/python-cursor" element={<ClassCoursePage />} />
          <Route path="goodbye" element={<GoodbyeScreen />} />
          <Route path="pay" element={<PayPage />} />
          <Route path="more/myinfo" element={
            <>
              <MyInfo />
              <EndingCredit />
              <Footer />
            </>
          } />
          <Route path="more/pay" element={
            <>
              <PayPage />
              <EndingCredit />
              <Footer />
            </>
          } />

          {/* 파이썬 알고리즘 100 문제 페이지 */}
          <Route path="py-algorithm" element={<PythonAlgorithmPage />} />

          {/* sloper 전용 메뉴 */}
          <Route path="user_sloper_admin" element={<UserAuthManagement />} />
          <Route path="sloper_analytics" element={<SloperAnalytics />} />

          {/* 커리큘럼 */}
          <Route path="curriculum/:moduleType" element={<CurriculumOverview />} />

          {/* 테스트 */}
          <Route path="test/:moduleType/:chapterId/:index" element={<ProblemTest />} />

          {/* 처음 접근했을때 */}
          <Route path="/" element={<Navigate to="home" />} />
        </Routes>
      </div>
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
    </div>
  );
};

export default LmsLandingPage;