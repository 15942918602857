import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'react-calendar-heatmap/dist/styles.css';
import 'react-tooltip/dist/react-tooltip.css';

import ProblemSolvingChart from './charts/ProblemSolvingChart';
import WeeklyStudyTimeChart from './charts/WeeklyStudyTimeChart';
import CumulativeStudyTimeChart from './charts/CumulativeStudyTimeChart';
import StudyPatternChart from './charts/StudyPatternChart';
import StrengthWeaknessChart from './charts/StrengthWeaknessChart';
import MonthlyStudyChart from './charts/MonthlyStudyChart';
import WeekdayStudyChart from './charts/WeekdayStudyChart';

import {
  mockData,
  studyTimeData,
  studyData,
  weeklyData,
  monthlyWeekdayData,
  totalStudyDays,
  totalStudyHours,
  averageStudyHours,
} from './charts/LmsData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
);

const LmsAnalytics = () => {
  const { solved, total } = mockData.todayProblemSolving;

  const TextInfo = ({ title, content }) => (
    <div>
      <h3 className="font-semibold">{title}</h3>
      <p>{content}</p>
    </div>
  );

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="min-w-[380px] md:max-w-[1440px] w-full mx-auto bg-white shadow-md rounded-md p-0 md:p-8">
        <div className="mt-6 grid grid-cols-3 gap-4 text-center">
          <div className="bg-blue-100 p-3 rounded">
            <p className="font-bold text-lg">{totalStudyDays}</p>
            <p className="text-sm">총 학습일</p>
          </div>
          <div className="bg-green-100 p-3 rounded">
            <p className="font-bold text-lg">{totalStudyHours}</p>
            <p className="text-sm">총 학습시간</p>
          </div>
          <div className="bg-yellow-100 p-3 rounded">
            <p className="font-bold text-lg">{averageStudyHours}</p>
            <p className="text-sm">일평균 학습시간</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 text-center">주간 학습 시간 추세</h3>
            <WeeklyStudyTimeChart data={studyTimeData} />
          </div>

          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 text-center">금일 알고리즘 문제 풀이</h3>
            <ProblemSolvingChart solved={solved} total={total} />
          </div>

          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 text-center">누적 학습 시간 비교</h3>
            <CumulativeStudyTimeChart
              monthlyStudyTime={mockData.monthlyStudyTime}
              cumulativeStudyTime={mockData.cumulativeStudyTime}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 text-center">학습 패턴 분석</h3>
            <StudyPatternChart data={[8, 5, 6, 3]} />
          </div>

          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-4 text-center">학습자 강점 및 약점</h3>
            <StrengthWeaknessChart data={[8, 6, 4, 7]} />
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow mb-8">
          <h3 className="text-xl font-semibold mb-4 text-center">이번 달 학습 기록</h3>
          <MonthlyStudyChart data={studyData} />
          <div className="mt-6 grid grid-cols-2 md:grid-cols-5 gap-4">
            {weeklyData.map((week, index) => (
              <div key={index} className="bg-blue-100 p-3 rounded text-center">
                <p className="font-bold">{week.week}</p>
                <p>총 {week.totalHours}시간</p>
                <p>평균 {week.avgHours}시간/일</p>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow mb-8">
          <h3 className="text-xl font-semibold mb-4 text-center">이번 달 요일별 학습 시간</h3>
          <WeekdayStudyChart data={monthlyWeekdayData} />
          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">
              각 요일별로 4주간의 학습 시간을 누적하여 표시합니다. 색상별로 각 주차의 학습 시간을 구분할 수 있습니다.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          <TextInfo title="최근 학습 날짜" content={mockData.recentStudyDate} />
          <TextInfo title="최근 학습 강의" content={mockData.recentLecture} />
          <TextInfo title="Sloper 피드백" content={mockData.sloperFeedback} />
          <TextInfo title="AI 진단" content={mockData.aiDiagnosis} />
          <TextInfo title="학습 커리큘럼 추천" content={mockData.curriculumRecommendation} />
        </div>
      </div>
    </div>
  );
};

export default LmsAnalytics;