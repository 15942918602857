// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import signupModalReducer from './store/reducers/signupModalSlice';
import modalReducer from './store/reducers/modalSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    signupModal: signupModalReducer,
    modal: modalReducer,
  },
});

export default store;