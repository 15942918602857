import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SignupPage from '../../pages/auth/signup/SignupPage';
import { closeSignupModal } from '../../store/reducers/signupModalSlice';
import { usePreventScroll } from '../../hook/usePreventScroll';

const SignupModal = () => {
  const isOpen = useSelector((state) => state.signupModal.isOpen);
  const dispatch = useDispatch();
  const modalContentRef = useRef(null);

  usePreventScroll(isOpen);

  useEffect(() => {
    if (isOpen && modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleClose = () => {
    dispatch(closeSignupModal());
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleBackgroundClick}>
      <div 
        ref={modalContentRef}
        className="w-full w-[95%] md:w-[40%] mx-auto bg-white rounded-lg relative max-h-[90vh] overflow-y-auto p-6"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <SignupPage onClose={handleClose} />
      </div>
    </div>
  );
};

export default SignupModal;