import React, { useState } from 'react';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';
import ContentsModal from '../modal/ContentsModal'; // 모달 컴포넌트

const InfoClassContents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  // 타일 데이터
  const tilesData = [
    {
      title1: '⛳️',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>Course Class</span><br/>탄탄한 커리큘럼을 따라
        </span>
      ),
      description: '코스 학습으로 목표까지 도달.',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: '01CS가 준비한 탄탄한 커리큘럼',
        modalDetails: `어디부터 어떻게 시작해야할지 모르겠다면,
        01CS의 코스 학습을 통해 목표를 달성해보세요.
        
        소프트웨어 개발에는 프로그래밍 언어와 함께
        소프트웨어 공학, 자료구조 등을 공부하면
        훨씬 더 질 높은 제품을 만들 수 있습니다.`,
        modalExtraInfo: '코스 구경하기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '🎯',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>Module Class</span><br/>
          <span style={{ color: '#000' }}>원하는 과목만 골라서</span>
        </span>
      ),
      description: '내가 배우고 싶은 과목 하나만 선택',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: '하나씩 골라서 원하는대로',
        modalDetails: `한 과목만 공부하고 싶다면,
        01CS의 단일 학습을 통해 원하는 과목만 골라서 학습해보세요.
        
        컴퓨터 구조, 자료구조, 알고리즘 등 원하는 과목을 골라서
        학습할 수 있도록 합니다.
        
        누구나 시작할 수 있는 난이도로,
        대학강의 A+ 수준까지 끌어올리는것이 우리의 목표입니다.`,
        modalExtraInfo: '강의 찾아보기 〉',
        // modalLink: '#'
      },
    },
  ];

  const openModal = (tile) => {
    setSelectedTile(tile); // 타일 데이터를 저장
    setIsModalOpen(true);  // 모달 열기
  };

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  // 타일들을 렌더링
  const tiles = tilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      img={tile.img}
      width={tile.width}
      height={tile.height}
      mobileWidth={tile.mobileWidth}
      mobileHeight={tile.mobileHeight}
      title1Margin={tile.title1Margin}
      title2Margin={tile.title2Margin}
      customStyles={tile.customStyles}
      onClick={() => openModal(tile)} // 타일 클릭 시 모달 열기
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4 text-[#969696]">
          <span className="text-[#000]">Course </span><br />& Module
        </h2>
      </div>

      {/* 타일 컨테이너 */}
      <TileContainer tiles={tiles} containerId="info-class-tile-scroll" />

      {/* 모달 컴포넌트 */}
      {selectedTile && (
        <ContentsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          imgSrc={selectedTile.img}
          title1={selectedTile.title1}
          title2={selectedTile.title2}
          description={selectedTile.description}
          modalContent={selectedTile.modalContent}
        />
      )}
    </div>
  );
};

export default InfoClassContents;