import React, { useRef, useEffect } from 'react';
import { usePreventScroll } from '../../hook/usePreventScroll';

const ShareModal = ({ isOpen, onClose, shareUrl, title, description }) => {
  const modalContentRef = useRef(null);
  usePreventScroll(isOpen);

  useEffect(() => {
    if (isOpen && !window.Kakao.isInitialized()) {
      console.warn('Kakao SDK is not initialized');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert('링크가 복사되었습니다!');
    } catch (err) {
      console.error('링크 복사 실패:', err);
      alert('링크 복사에 실패하였습니다.');
    }
  };

  const handleKakaoShare = () => {
    if (window.Kakao && window.Kakao.isInitialized()) {
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: title,
          description: description,
          imageUrl: 'https://www.01cs.kr/path/to/share/image.jpg', // 공유할 이미지 URL
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
        buttons: [
          {
            title: '결과 보기',
            link: {
              mobileWebUrl: shareUrl,
              webUrl: shareUrl,
            },
          },
        ],
      });
    } else {
      console.error('Kakao SDK is not initialized');
      alert('카카오톡 공유 기능을 사용할 수 없습니다.');
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={handleBackgroundClick}>
      <div ref={modalContentRef} className="bg-white rounded-lg p-6 w-80 relative overflow-y-auto max-h-[80vh]" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="닫기"
        >
          &times;
        </button>
        <h2 className="text-xl font-semibold mb-4">{title || '공유하기'}</h2>
        <div className="flex flex-col space-y-4">
          <button
            onClick={handleCopyLink}
            className="w-full bg-[#D00070] text-white py-2 rounded hover:bg-[#A0005A] transition-colors"
          >
            링크 복사하기
          </button>
          <button
            onClick={handleKakaoShare}
            className="w-full bg-[#FEE500] text-[#000000] py-2 rounded hover:bg-[#FDD835] transition-colors"
          >
            카카오톡으로 공유하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;