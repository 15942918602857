// 기초 파이썬 + Course 커리큘럼 컴포넌트
// 해당 컴포넌트는 page/course/classCoursePage.js 안에 들어가야함.
// CourseInfo 컴포넌트가 해당 컴포넌트 props로 받아옴.
//
import React from 'react';
import { FaCalendar, FaClock, FaUsers, FaGraduationCap, FaCheckCircle } from 'react-icons/fa';

const PythonCursor = ({ courseInfo }) => {
  const { title, description, level, curriculum, sloperMessage } = courseInfo;

  const benefits = [
    "CURSOR를 활용한 트렌디한 학습경험",
    "생성형 AI를 활용한 프로그래밍 공부법",
    "개인별 맞춤 피드백 제공",
    "베타버전 출시 전까지 교육자료 무료 제공"
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white mt-12">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">{title}</h1>
      <p className="text-gray-600 mb-6">{description}</p>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="flex items-center">
          <FaCalendar className="text-blue-500 mr-2" />
          <span>시작일: 2024년 9월 30일(월)</span>
        </div>
        <div className="flex items-center">
          <FaClock className="text-blue-500 mr-2" />
          <span>수업 시간: 매주 월, 수 18:30-20:30</span>
        </div>
        <div className="flex items-center">
          <FaUsers className="text-blue-500 mr-2" />
          <span>그룹 강의 가능</span>
        </div>
        <div className="flex items-center">
          <FaGraduationCap className="text-blue-500 mr-2" />
          <span>난이도: {level}</span>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-3">강의 특징</h2>
        <ul className="space-y-2">
          {benefits.map((benefit, index) => (
            <li key={index} className="flex items-start">
              <FaCheckCircle className="text-green-500 mt-1 mr-2" />
              <span>{benefit}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-3">12 Steps</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {curriculum.slice(0, 12).map((week) => (
            <div key={week.week} className="bg-gray-100 p-3 rounded">
              <h3 className="font-semibold">{week.week} Step: {week.title}</h3>
              <p className="text-sm text-gray-600">{week.content}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-blue-50 p-4 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-blue-800 mb-2">Sloper 메시지</h2>
        <p className="text-blue-700 italic">{sloperMessage}</p>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-4">
        <h2 className="text-2xl font-semibold text-gray-800 mb-3">월 21,000원.</h2>
        {/* <p className="text-3xl font-bold text-blue-600">구독 시스템 구축 중</p> */}
        <p className="text-gray-600">😎 모든 강의, LMS, SlopeX AI, Sloper의 도움까지 모든 서비스 이용 가능합니다.</p>
        <p className="text-gray-600">👻 연 단위 결제시 40% 할인! 12개월 동안 모든 서비스를 누리는데 151,200원</p>
      </div>

      <button className="w-full bg-[#d00070] text-3xl text-white font-extrabold py-8 px-4 mb-24 rounded-lg hover:bg-blue-600 transition duration-300">
        강의 개발중
      </button>
    </div>
  );
};

export default PythonCursor;