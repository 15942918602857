import React from 'react';

const ScoreDisplay = ({ round, timeLeft }) => {

  return (
    <div className="mb-4 text-center">
      <p className="text-xl text-[#D00070] font-bold">제한시간 3분</p>
      <p className="text-4xl font-bold">라운드 {round}</p>
      {/* <p className="text-xl font-bold">점수: {score}</p> */}
    </div>
  );
};

export default ScoreDisplay;