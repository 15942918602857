import React, { useState } from 'react';
import TileContainer from '../landing/container/contents/tileComponents/TileContainer';
import Tile from '../landing/container/contents/tileComponents/Tile';
import ContentsModal from '../landing/container/modal/ContentsModal';
import careerBack01 from '../../assets/tile/artworkImage.webp';
import careerBack02 from '../../assets/tile/artworkImage02.webp';
import careerBack03 from '../../assets/popup.webp';

export const careerTilesData = [
  {
    title1: '채용연계형 인턴',
    title2: '슬로퍼(Sloper) 인턴 모집',
    description: 'Sloper는 회사 소속의 팀원을 뜻합니다.',
    img: careerBack01,
    title1Color: '#000',
    title2Color: '#000',
    descriptionColor: '#fff',
    width: 'md:w-[400px]',
    height: 'md:h-[500px]',
    mobileWidth: 'w-[310px]',
    mobileHeight: 'h-[450px]',
    status: ['careersEmployment', 'careersIntern'],
    modalContent: {
      modalTitle: `Sloper는
      SlopeXcelerity에 소속된 팀원입니다.`,
      modalDetails: `채용연계형 인턴으로 SlopeXcelerity와 함께
      성장할 인재를 기다립니다.
      `,
      modalExtraInfo: '채용 예정 🚀',
    },
    // modalLink: '#'
  },
  {
    title1: 'Tech',
    title2: 'Developer',
    description: '생성형 AI를 적극 활용하는 개발자를 찾습니다.',
    img: careerBack02,
    title1Color: '#000',
    title2Color: '#fff',
    descriptionColor: '#000',
    width: 'md:w-[400px]',
    height: 'md:h-[500px]',
    mobileWidth: 'w-[310px]',
    mobileHeight: 'h-[450px]',
    status: ['careersEmployment', 'careersDev'],
    modalContent: {
      modalTitle: '개발자 채용',
      modalDetails: `소프트웨어 개발 구조를 이해하고,
      다양한 개발 프로젝트를 진행한 경험이 있는
      개발자를 찾습니다.

      전공, 경력 여부와 상관없이
      생성형 AI를 적극적으로 사용할 수 있어야 합니다.`,
      modalExtraInfo: '채용 예정 🚀',
    },
    // modalLink: '#'
  },
  {
    title1: 'Service',
    title2: 'Product Manager',
    description: '기초공학교육 서비스 기획자를 찾습니다.',
    img: careerBack03,
    title1Color: '#000',
    title2Color: '#000',
    descriptionColor: '#000',
    width: 'md:w-[400px]',
    height: 'md:h-[500px]',
    mobileWidth: 'w-[310px]',
    mobileHeight: 'h-[450px]',
    status: ['careersEmployment', 'careersPlanner'],
    modalContent: {
      modalTitle: 'Product Manager 채용',
      modalDetails: `공학의 한 분야를 도맡아
      SlopeXcelerity가 나아가고자 하는 방향으로
      서비스를 기획하고 프로젝트를 관리할 수 있어야 합니다.
      `,
      modalExtraInfo: '채용 예정 🚀',
    },
    // modalLink: '#'
  },
];

const CareersTile = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTile, setSelectedTile] = useState(null);
  
    const openModal = (tile) => {
      setSelectedTile(tile);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    // 타일들을 렌더링
    const tiles = careerTilesData.map((tile, index) => (
      <Tile
        key={index}
        title1={tile.title1}
        title2={tile.title2}
        description={tile.description}
        title1Color={tile.title1Color}
        title2Color={tile.title2Color}
        descriptionColor={tile.descriptionColor}
        img={tile.img}
        backgroundColor={tile.backgroundColor}
        width={tile.width} // 데스크탑 너비 적용
        height={tile.height} // 데스크탑 높이 적용
        mobileWidth={tile.mobileWidth} // 모바일 너비 적용
        mobileHeight={tile.mobileHeight} // 모바일 높이 적용
        title1Margin={tile.title1Margin} // title1과 title2 간의 간격
        title2Margin={tile.title2Margin} // title2와 description 간의 간격
        status={tile.status} // status prop 추가
        onClick={() => openModal(tile)}
      />
    ));
  
    return (
      <div className="relative w-full py-8 mt-6">
        <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
          <h2 className="text-3xl font-bold mb-4 text-[#969696]">
            <span className="text-[#000]">Sloper</span>
          </h2>
        </div>
        <TileContainer tiles={tiles} containerId="tile-scroll" />
        {selectedTile && (
          <ContentsModal
            isOpen={isModalOpen}
            onClose={closeModal}
            imgSrc={selectedTile.img}
            title1={selectedTile.title1}
            title2={selectedTile.title2}
            description={selectedTile.description}
            modalContent={selectedTile.modalContent}
            status={selectedTile.status}
          />
        )}
      </div>
    );
};

export default CareersTile;