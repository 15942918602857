import React, { useState, useEffect } from 'react';
import PythonAlgorithm from '../../components/pythonAlgorithm/PythonAlgorithm';
import { pyAlgorithm, algorithmCategories } from '../../data/pythonAlgorithmProblems';

const PythonAlgorithmPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [problems, setProblems] = useState([]);

  // 선택된 카테고리에 해당하는 문제들을 필터링하는 함수
  const getProblemsForCategory = (categoryId) => {
    const selectedCard = algorithmCategories.find(card => card.id === categoryId);
    return pyAlgorithm.filter(problem => problem.category === selectedCard.title);
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 'Beginner Python Algorithm Problems' 카테고리를 자동으로 선택
    const defaultCategory = algorithmCategories.find(category => category.title === 'Beginner Python Algorithm Problems');
    if (defaultCategory) {
      setSelectedCategory(defaultCategory.id);
      setProblems(getProblemsForCategory(defaultCategory.id));
    }
  }, []);

  useEffect(() => {
    // 선택된 카테고리가 변경될 때마다 문제 목록 업데이트
    if (selectedCategory) {
      setProblems(getProblemsForCategory(selectedCategory));
    }
  }, [selectedCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {algorithmCategories.map((card) => (
            <div 
              key={card.id}
              className={`rounded-lg shadow-lg overflow-hidden cursor-pointer transform hover:scale-105 transition duration-300 ${selectedCategory === card.id ? 'ring-2 ring-[#d00070]' : ''}`}
              onClick={() => handleCategoryClick(card.id)}
            >
              <div className={`${card.backgroundColor} h-4 w-full`}></div>
              <div className="p-3 bg-white">
                <h2 className="text-sm font-semibold text-gray-800">{card.title}</h2>
              </div>
            </div>
          ))}
        </div>
        {selectedCategory && (
          <div className="mt-12 bg-white rounded-lg shadow-xl p-6">
            <PythonAlgorithm 
              problems={problems} 
              categoryId={selectedCategory}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PythonAlgorithmPage;
