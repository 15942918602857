// src/services/ProgressService.js
import { db } from '../../../../../Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export const ProgressService = {
  async getUserProgress(userId) {
    const userProgressRef = doc(db, 'users', userId, 'progress', 'curriculum');
    const docSnap = await getDoc(userProgressRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    return {};
  },

  async updateUserProgress(userId, moduleType, chapterId, problemId) {
    const userProgressRef = doc(db, 'users', userId, 'progress', 'curriculum');
    const currentProgress = await this.getUserProgress(userId);
    
    if (!currentProgress[moduleType]) {
      currentProgress[moduleType] = {};
    }
    if (!currentProgress[moduleType][chapterId]) {
      currentProgress[moduleType][chapterId] = {};
    }
    if (!currentProgress[moduleType][chapterId][problemId]) {
      currentProgress[moduleType][chapterId][problemId] = true;
    }

    await setDoc(userProgressRef, currentProgress, { merge: true });
  },

  async isChapterCompleted(userId, moduleType, chapterId, totalProblems) {
    const progress = await this.getUserProgress(userId);
    const chapterProgress = progress[moduleType]?.[chapterId];
    if (!chapterProgress) return false;
    
    const completedProblems = Object.keys(chapterProgress).length;
    return completedProblems === totalProblems;
  },

  async getCompletedConceptsCount(userId, moduleType, chapterId) {
    const progress = await this.getUserProgress(userId);
    const chapterProgress = progress[moduleType]?.[chapterId];
    if (!chapterProgress) return 0;
    
    return Object.keys(chapterProgress).filter(problemId => problemId.startsWith('concept_')).length;
  },

  async getLastCompletedProblemIndex(userId, moduleType, chapterId) {
    const progress = await this.getUserProgress(userId);
    const chapterProgress = progress[moduleType]?.[chapterId];
    if (!chapterProgress) return -1;
    
    const completedProblems = Object.keys(chapterProgress);
    if (completedProblems.length === 0) return -1;
    
    const lastCompletedProblem = Math.max(...completedProblems.map(id => parseInt(id.split('_')[1])));
    return lastCompletedProblem;
  }
};