import React from 'react';

const SignupStep1 = ({ formData, updateFormData, onNext }) => {
  const programmingOptions = ['없음', '블록코딩', '텍스트코딩'];
  const learningSpeedOptions = ['빠르게: 개념 위주의 학습', '보통: 밸런스있는 학습', '천천히: 깊이 있는 학습'];
  const learningChallenges = ['컴퓨팅 사고력 향상', '알고리즘 테스트', '수리력 강화', '개발자 도전', 'Web/App 개발', '생성형AI 활용', '인공지능 개발', '수학역량 강화'];
  const collaborationPreferences = ['개인 학습 선호', '협업 학습 선호'];
  const progressTrackingOptions = ['매일(정해진 시간에 루틴으로)', '주간(일주일 학습시간을 정하고 주도적으로)'];
  const preferredLearningTimes = ['평일 오전', '평일 오후', '주말 오전', '주말 오후'];

  const handlePreferredTimeClick = (time) => {
    const currentSelections = formData.preferredLearningTimes || [];
    if (currentSelections.includes(time)) {
      updateFormData('preferredLearningTimes', currentSelections.filter(item => item !== time));
    } else if (currentSelections.length < 2) {
      updateFormData('preferredLearningTimes', [...currentSelections, time]);
    }
  };

  const handleLearningChallengeClick = (challenge) => {
    const currentSelections = formData.learningChallenges || [];
    if (currentSelections.includes(challenge)) {
      // 이미 선택된 항목을 제거
      updateFormData('learningChallenges', currentSelections.filter(item => item !== challenge));
    } else {
      // 선택 개수 제한 없이 추가
      updateFormData('learningChallenges', [...currentSelections, challenge]);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto space-y-8 pb-8">
        {/* 프로그래밍 경험 */}
        <div>
          <label className="block text-base text-gray-700 mb-3">
            <span className="block md:inline">학습 경험은 <span className='font-extrabold'>LMS 〉 내 정보 관리하기</span>에서</span> 입력 가능합니다.
          </label>
          <a href="/##" className="block text-base text-[#D00070] mb-3">테스트로 학습 진단하러가기(준비중)</a><br/>
          <label className="block text-lg text-gray-700 mb-3">
            [ 프로그래밍 학습경험 ]
          </label>
          <div className="flex space-x-4">
            {programmingOptions.map((option, index) => (
              <button
                key={index}
                className={`flex-1 p-3 rounded-lg text-sm transition-colors ${
                  formData.programmingExperience === option
                    ? 'bg-[#D00070] text-white font-extrabold'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
                onClick={() => updateFormData('programmingExperience', option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        {/* 선호하는 학습 속도 */}
        <div>
          <label className="block text-lg text-gray-700 mb-3">
            [ 선호하는 학습 속도 ]
          </label>
          <div className="flex flex-col space-y-2">
            {learningSpeedOptions.map((option, index) => (
              <button
                key={index}
                className={`p-3 rounded-lg text-sm transition-colors ${
                  formData.learningSpeed === option
                    ? 'bg-[#D00070] text-white font-extrabold'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
                onClick={() => updateFormData('learningSpeed', option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        {/* 학습 챌린지 */}
        <div>
          <label className="block text-lg text-gray-700 mb-3">
            [ 학습 챌린지(복수 선택가능) ]
          </label>
          <div className="grid grid-cols-2 gap-3">
            {learningChallenges.map((challenge, index) => (
              <label
                key={index}
                className="flex items-center p-3 rounded-lg text-sm transition-colors bg-gray-100 hover:bg-gray-200 cursor-pointer"
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={(formData.learningChallenges || []).includes(challenge)}
                  onChange={() => handleLearningChallengeClick(challenge)}
                />
                <span className={`w-5 h-5 mr-3 inline-block border-2 rounded-md ${
                  (formData.learningChallenges || []).includes(challenge)
                    ? 'bg-[#D00070] border-[#D00070]'
                    : 'border-gray-400'
                }`}>
                  {(formData.learningChallenges || []).includes(challenge) && (
                    <svg className="w-4 h-4 text-white fill-current" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                    </svg>
                  )}
                </span>
                <span className={`${(formData.learningChallenges || []).includes(challenge) ? 'text-[#D00070] font-semibold' : 'text-gray-700'}`}>
                  {challenge}
                </span>
              </label>
            ))}
          </div>
        </div>

        {/* 협업 선호도 */}
        <div>
          <label className="block text-lg text-gray-700 mb-3">
            [ 협업 선호도 ]
          </label>
          <div className="flex space-x-4">
            {collaborationPreferences.map((preference, index) => (
              <button
                key={index}
                className={`flex-1 p-3 rounded-lg text-sm transition-colors ${
                  formData.collaborationPreference === preference
                    ? 'bg-[#D00070] text-white font-extrabold'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
                onClick={() => updateFormData('collaborationPreference', preference)}
              >
                {preference}
              </button>
            ))}
          </div>
        </div>

        {/* 진도 추적 */}
        <div>
          <label className="block text-lg text-gray-700 mb-3">
            [ 진도 추적 ]
          </label>
          <div className="flex flex-col space-y-2">
            {progressTrackingOptions.map((option, index) => (
              <button
                key={index}
                className={`p-3 rounded-lg text-sm transition-colors ${
                  formData.progressTracking === option
                    ? 'bg-[#D00070] text-white font-extrabold'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
                onClick={() => updateFormData('progressTracking', option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        {/* 선호하는 학습시간대 */}
        <div>
          <label className="block text-lg text-gray-700 mb-3">
            [ 선호하는 학습시간대(최대 2개 선택) ]
          </label>
          <div className="grid grid-cols-2 gap-3">
            {preferredLearningTimes.map((time, index) => (
              <button
                key={index}
                className={`p-3 rounded-lg text-sm transition-colors ${
                  (formData.preferredLearningTimes || []).includes(time)
                    ? 'bg-[#D00070] text-white font-extrabold'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
                onClick={() => handlePreferredTimeClick(time)}
              >
                {time}
              </button>
            ))}
          </div>
        </div>

        {/* 보충 학습 추천 */}
        <div>
          <label className="block text-lg text-gray-700 mb-3">
            [ 보충 학습 추천 ]
          </label>
          <div className="flex space-x-4">
            <button
              className={`flex-1 p-3 rounded-lg text-sm transition-colors ${
                formData.supplementaryLearning === true
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              onClick={() => updateFormData('supplementaryLearning', true)}
            >
              추천 받기
            </button>
            <button
              className={`flex-1 p-3 rounded-lg text-sm transition-colors ${
                formData.supplementaryLearning === false
                  ? 'bg-[#D00070] text-white font-extrabold'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              onClick={() => updateFormData('supplementaryLearning', false)}
            >
              추천 받지 않기
            </button>
          </div>
        </div>
      </div>

      {/* 다음 단계 버튼 */}
      <div className="mt-auto pt-4 border-t">
        <button
          className="w-full bg-[#D00070] text-2xl font-extrabold text-white py-4 px-4 rounded hover:bg-[#B0005E] transition duration-300"
          onClick={onNext}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default SignupStep1;