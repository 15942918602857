// src/data/LmsData.js

export const mockData = {
  totalStudyTime: 50,
  yesterdayStudyTime: 5,
  todayStudyTime: 3,
  weeklyStudyTime: 20,
  monthlyStudyTime: 60,
  cumulativeStudyTime: 200,
  recentStudyDate: '2024-04-25',
  recentLecture: '머신러닝 기초',
  sloperFeedback: '적극적인 학습 태도',
  todayProblemSolving: { solved: 2, total: 3 },
  studyPatternAnalysis: '아침 시간 집중',
  weakPoints: '수학 문제 해결',
  strengths: '코딩 실력 향상',
  aiDiagnosis: '추가적인 수학 학습 필요',
  curriculumRecommendation: '심화 수학 강좌 추천',
};

export const studyTimeData = [
  { name: '월', 시간: 10 },
  { name: '화', 시간: 10 },
  { name: '수', 시간: 10 },
  { name: '목', 시간: 10 },
  { name: '금', 시간: 10 },
  { name: '토', 시간: 10 },
  { name: '일', 시간: 10 },
];

// 한 달간의 학습 데이터 생성 함수
export const generateMonthData = () => {
  const today = new Date();
  const data = [];
  for (let i = 30; i >= 0; i--) {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    const studyHours = 1; // 고정된 낮은 수치
    data.push({
      date: date.toISOString().split('T')[0],
      count: studyHours,
    });
  }
  return data;
};

export const studyData = generateMonthData();

// 주간 데이터 계산 함수
export const calculateWeeklyData = (data) => {
  const weeklyData = [];
  for (let i = 0; i < data.length; i += 7) {
    const weekData = data.slice(i, i + 7);
    const totalHours = weekData.reduce((sum, day) => sum + day.count, 0);
    const avgHours = (totalHours / 7).toFixed(1);
    weeklyData.push({
      week: `${i / 7 + 1}주차`,
      totalHours,
      avgHours,
    });
  }
  return weeklyData;
};

export const weeklyData = calculateWeeklyData(studyData);

// 요일별 학습 시간 생성 함수
export const generateMonthlyWeekdayData = () => {
  const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
  const data = weekdays.map((day) => ({
    name: day,
    '1주차': 1, // 고정된 낮은 수치
    '2주차': 1,
    '3주차': 1,
    '4주차': 1,
  }));
  return data;
};

export const monthlyWeekdayData = generateMonthlyWeekdayData();

// 학습 통계 계산
export const totalStudyDays = studyData.filter((day) => day.count > 0).length;
export const totalStudyHours = studyData.reduce((sum, day) => sum + day.count, 0);
export const averageStudyHours = (totalStudyHours / totalStudyDays).toFixed(1);