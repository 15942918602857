// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  signInWithEmailAndPassword, 
  // signInWithPopup, 
  // GoogleAuthProvider
} from 'firebase/auth';
import { auth } from '../../Firebase';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSignupModal } from '../../store/reducers/signupModalSlice';

const LoginPage = () => {
  // 상태 및 함수들 정의
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 이메일 변경 핸들러
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // 비밀번호 변경 핸들러
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // 로그인 핸들러
  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      // 이메일 및 비밀번호로 로그인 시도
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); // 로그인 성공 시 홈페이지로 이동
    } catch (error) {
      console.error('로그인 실패:', error);
      if (error.code === 'auth/user-not-found') {
        setError('등록되지 않은 이메일입니다.');
      } else if (error.code === 'auth/wrong-password') {
        setError('잘못된 비밀번호입니다.');
      } else {
        setError('로그인에 실패했습니다. 다시 시도해 주세요.');
      }
    }
  };

  // 구글 로그인 핸들러
  // const handleGoogleLogin = async () => {
  //   const provider = new GoogleAuthProvider();
  //   setError('');

  //   try {
  //     await signInWithPopup(auth, provider);
  //     navigate('/');
  //   } catch (error) {
  //     console.error('구글 로그인 실패:', error);
  //     if (error.code === 'auth/popup-closed-by-user') {
  //       setError('로그인 팝업이 닫혔습니다. 다시 시도해 주세요.');
  //     } else if (error.code === 'auth/cancelled-popup-request') {
  //       setError('팝업 요청이 취소되었습니다. 팝업 차단을 해제해주세요.');
  //     } else {
  //       setError('구글 로그인을 실패했습니다. 다시 시도해 주세요.');
  //     }
  //   }
  // };

  // 비밀번호 표시 토글 함수
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-6 bg-white rounded-lg max-w-md w-full mx-4">
        <h2 className="text-2xl font-bold mb-10 text-center">공학의 기초부터 탄탄하게 🏗️</h2>

        <form className="space-y-6" onSubmit={handleLogin}>
          {/* 이메일 입력 필드 */}
          <div>
            <label htmlFor="email" className="text-sm font-medium text-[#D00070] block mb-2">
              이메일
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-2 border border-[#D00070] rounded-md text-[#D00070] focus:outline-none focus:border-[#D00070] placeholder-[#D00070]"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>

          {/* 비밀번호 입력 필드 */}
          <div>
            <label htmlFor="password" className="text-sm font-medium text-[#D00070] block mb-2">
              비밀번호
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="w-full p-2 border border-[#D00070] rounded-md text-[#D00070] focus:outline-none focus:border-[#D00070] placeholder-[#D00070]"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? "비밀번호 숨기기" : "비밀번호 보기"}
              >
                {showPassword ? (
                  <svg className="h-6 w-6 text-[#D00070]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    {/* 눈 아이콘 */}
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6 text-[#D00070]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    {/* 눈 감은 아이콘 */}
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a10.025 10.025 0 011.563-3.029M9.878 9.878a3 3 0 014.243 4.243M9.88 9.88L6.59 6.59M17.532 17.532l3.29 3.29M3 3l3.59 3.59" />
                  </svg>
                )}
              </button>
            </div>
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          {/* 로그인 버튼 */}
          <button type="submit" className="w-full bg-[#D00070] text-white py-2 rounded-md hover:bg-[#B0005E] transition duration-300">
            로그인
          </button>
        </form>

        <div className="mt-6 text-center">
          <hr className="my-4 border-t border-gray-300" />
          {/* <p className="text-gray-600 mb-2">or</p>
          <button
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-100 transition duration-300"
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
              <path
                fill="#4285F4"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              />
              <path
                fill="#34A853"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              />
              <path
                fill="#FBBC05"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              />
              <path
                fill="#EA4335"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              />
              <path fill="none" d="M1 1h22v22H1z" />
            </svg>
            구글 아이디로 로그인
          </button>
          <br/>
          <p className="text-gray-600 mb-2">✅ 네이버 아이디 회원가입 승인 심사중 👷🏻</p> */}
        </div>

        {/* 아이디 찾기, 비밀번호 찾기, 회원가입 링크 추가 */}
        <div className="mt-6 text-sm text-center">
          <Link to="/find-email" className="text-[#D00070] hover:underline mr-10">아이디 찾기</Link>
          <Link to="/reset-password" className="text-[#D00070] hover:underline mr-10">비밀번호 찾기</Link>
          <button onClick={() => dispatch(openSignupModal())} className="text-[#D00070] hover:underline">회원가입</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;