import React from 'react';
import NaverTalkBtn from './NaverTalkBtn';

const CustomBtn = () => {
  return (
    <div>
      <NaverTalkBtn />
    </div>
  );
}

export default CustomBtn;