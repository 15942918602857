// src/pages/CompanyPage.js

import React from 'react';
import CompanyMain from '../components/company/container/CompanyMain';
import EndingCredits from '../components/company/container/ending/EndingCredit';
import Identity from '../components/company/container/Identity';

const CompanyPage = () => {
  return (
    <div>
      <CompanyMain />
      <Identity />
      <EndingCredits />
    </div>
  );
};

export default CompanyPage;