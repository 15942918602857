import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGroups, approveGroup } from '../../../../features/groupSlice';

const GroupList = ({ isSloper }) => {
  const dispatch = useDispatch();
  
  // groups selector를 메모이제이션
  const groups = useSelector(state => state.group?.groups || [], 
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
  );
  
  const status = useSelector(state => state.group?.status || 'idle');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchGroups());
    }
  }, [status, dispatch]);

  const handleApprove = useCallback((groupId) => {
    dispatch(approveGroup(groupId));
  }, [dispatch]);

  // 렌더링할 그룹 목록을 메모이제이션
  const renderedGroups = useMemo(() => {
    return groups.map(group => (
      <div key={group.id} className="mb-4 p-4 border rounded">
        <h3 className="text-lg font-semibold">{group.name}</h3>
        <p className="mb-2">{group.description}</p>
        <p className="text-sm text-gray-600">상태: {group.approved ? '승인됨' : '승인 대기중'}</p>
        {isSloper && !group.approved && (
          <button 
            onClick={() => handleApprove(group.id)}
            className="mt-2 bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
          >
            승인하기
          </button>
        )}
      </div>
    ));
  }, [groups, isSloper, handleApprove]); // handleApprove를 의존성 배열에 추가

  if (status === 'loading') {
    return <div>로딩 중...</div>;
  }

  if (status === 'failed') {
    return <div>오류가 발생했습니다.</div>;
  }

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">그룹 목록</h2>
      {groups.length === 0 ? (
        <p>그룹이 없습니다.</p>
      ) : (
        renderedGroups
      )}
    </div>
  );
};

export default GroupList;