import React, { useState } from 'react';

const PayPage = () => {
  const [monthlyPrice] = useState(14900);
  const [activeTab, setActiveTab] = useState('monthly');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderCards = (isYearly) => {
    const priceMultiplier = isYearly ? 12 * 0.6 : 1;
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Free 카드 */}
        <div className="border border-gray-300 rounded-lg w-[250px] h-[450px] md:w-[350px] md:h-[500px]">
          <div className="h-[40%] bg-gray-100 p-4 flex flex-col justify-between">
            <div className="mt-4">
              <p className="text-2xl font-bold">Free</p>
              <p className="text-[#C83232] text-4xl font-bold mt-2">0<span className="text-sm text-black">원</span></p>
            </div>
            <div className="py-4">
              <button className="w-full font-semibold border border-[#C83232] bg-[#fff] text-[#C83232] px-4 py-2 rounded-md hover:bg-[#C83232] hover:text-white transition-colors duration-300 text-md">
                무료로 이용하기
              </button>
            </div>
          </div>
          <div className="h-[60%] p-6 font-semibold text-sm leading-[28px]">
            <ul className="list-disc pl-5">
              <li>01CS 기초 강의 제공</li>
              <li>제한된 문제 은행 접근</li>
            </ul>
          </div>
        </div>

        {/* Pro 카드 */}
        <div className="border border-gray-300 rounded-lg w-[250px] h-[450px] md:w-[350px] md:h-[500px]">
          <div className="h-[40%] bg-gray-100 p-4 flex flex-col justify-between">
            <div className="mt-4">
              <p className="text-2xl font-bold">Pro</p>
              <p className="text-[#C83232] text-4xl font-bold mt-2">{(monthlyPrice * priceMultiplier).toLocaleString()}<span className="text-sm text-black">원</span></p>
            </div>
            <div className="py-4">
              <button className="w-full font-semibold border border-[#C83232] bg-[#fff] text-[#C83232] px-4 py-2 rounded-md hover:bg-[#C83232] hover:text-white transition-colors duration-300 text-md">
                {isYearly ? '1년 학습권 이용하기' : '1개월 학습권 이용하기'}
              </button>
            </div>
          </div>
          <div className="h-[60%] p-6 font-semibold text-sm leading-[28px]">
            <ul className="list-disc pl-5">
              <li>SlopeX AI Agent 무제한 사용</li>
              <li>모든 교육 콘텐츠 무료 제공</li>
              <li>개인화된 학습 경로</li>
              <li>전담 지원</li>
              <li>LMS 제공</li>
            </ul>
          </div>
        </div>

        {/* Business 카드 */}
        <div className="border border-[#D00070] rounded-lg w-[250px] h-[450px] md:w-[350px] md:h-[500px] mb-24">
          <div className="h-[40%] bg-[#d00070]/10 p-4 flex flex-col justify-between">
            <div className="mt-4">
              <p className="text-2xl font-bold">Business</p>
              <p className="text-[#D00070] text-4xl font-bold mt-2">{(monthlyPrice * 2 * priceMultiplier).toLocaleString()}<span className="text-sm text-black">원</span></p>
            </div>
            <div className="py-4">
              <button className="w-full font-semibold border border-[#D00070] bg-[#fff] text-[#D00070] px-4 py-2 rounded-md hover:bg-[#D00070] hover:text-white transition-colors duration-300 text-md">
                {isYearly ? '1년 학습권 이용하기' : '1개월 학습권 이용하기'}
              </button>
            </div>
          </div>
          <div className="h-[60%] p-6 font-semibold text-sm leading-[28px]">
            <ul className="list-disc pl-5">
              <li>Pro 플랜 포함</li>
              <li>강사 계정 지원</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-[1440px] mx-auto flex flex-col items-center justify-center pt-36 p-4">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold">SlopeX Plan</h1>
      </div>
      <div className="flex justify-center mb-8">
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'monthly' ? 'bg-black text-white' : 'bg-white text-black border border-black'}`}
          onClick={() => handleTabClick('monthly')}
        >
          월 결제
        </button>
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'yearly' ? 'bg-black text-white' : 'bg-white text-black border border-black'}`}
          onClick={() => handleTabClick('yearly')}
        >
          연 결제
        </button>
      </div>
      {renderCards(activeTab === 'yearly')}
    </div>
  );
};

export default PayPage;