import React from 'react';
import CareersMain from '../components/careers/CareersMain';

const CareersPage = () => {
  return (
    <div>
      <CareersMain />
    </div>
  );
};

export default CareersPage;
