// 현재 학습 중인 강의 목록을 표시하는 곳.
// 타일 형태로 표시되어, 강의 이름/진행도/완료 여부/마지막 공부시간 표시.
// 학습중인 강의가 없을 경우, "현재 학습중인 강의가 없습니다. 이런 강의는 어떠신가요?" 메시지 표시와 추천 강의 목록(타일 형태) 표시
// 강의 타일 클릭 시, 해당 강의로 이동(이어서 시작)
// 추천 강의 타일 클릭 시, 해당 강의로 이동(새로 시작)
import React from 'react';
import { useLms } from '../../../../context/LmsContext';

const LmsActiveClass = () => {
  const { data } = useLms();
  const { activeClasses } = data; // 수정된 부분

  if (!activeClasses || activeClasses.length === 0) {
    return (
      <div className="flex flex-wrap justify-center p-4">
        <h1 className='font-extrabold'>진행중인 강의</h1>
        <p>현재 학습중인 강의가 없습니다. 이런 강의는 어떠신가요?</p>
        {/* 추천 강의 타일 컴포넌트 추가 */}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap justify-center p-4">
      <h1 className='font-extrabold'>진행중인 강의</h1>
      {activeClasses.map((course) => (
        <div key={course.id} className="bg-white p-4 m-2 rounded-lg shadow">
          <h2 className="text-lg font-semibold">{course.title}</h2>
          <p>진행도: {course.progress}%</p>
          <p>완료 여부: {course.isCompleted ? '완료' : '진행 중'}</p>
          <p>마지막 공부 시간: {course.lastStudyTime}</p>
          {/* 강의로 이동하는 링크 버튼 추가 */}
        </div>
      ))}
    </div>
  );
};

export default LmsActiveClass;