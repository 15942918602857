import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../Firebase';
import Skeleton from '../skeleton/Skeleton'; // Skeleton 컴포넌트 임포트

const PrivateRoute = ({ element: Element }) => {
  const [user, loading] = useAuthState(auth); // Firebase의 현재 인증 상태를 확인

  if (loading) {
    return <Skeleton />; // 로딩 중일 때 Skeleton 컴포넌트를 표시
  }

  return user ? <Element /> : <Navigate to="/login" />; // 로그인된 사용자만 접근 가능
};

export default PrivateRoute;