import React, { useState } from 'react';
import { serviceAgreement, privacyPolicy } from '../../../components/footer/Agreements';

const SignupIntro = ({ onNext }) => {
  const [agreements, setAgreements] = useState({
    termsOfService: false,
    privacyPolicy: false,
  });

  const handleAgreementChange = (e) => {
    const { name, checked } = e.target;
    setAgreements(prev => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (agreements.termsOfService && agreements.privacyPolicy) {
      onNext();
    } else {
      alert('모든 약관에 동의해주세요.');
    }
  };

  const isAllChecked = agreements.termsOfService && agreements.privacyPolicy;

  return (
    <div className="flex flex-col items-center max-w-3xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">약관 동의</h2>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="mb-4">
          <label className="flex items-center cursor-pointer mb-2">
            <input
              type="checkbox"
              name="termsOfService"
              checked={agreements.termsOfService}
              onChange={handleAgreementChange}
              className="mr-2 accent-[#D00070]"
            />
            <span className="font-semibold">이용약관에 동의합니다.</span>
          </label>
          <div className="bg-[#fff] p-4 rounded-lg h-20 md:h-28 overflow-y-auto text-sm">
            <pre className="whitespace-pre-wrap">{serviceAgreement}</pre>
          </div>
        </div>
        <div className="mb-6">
          <label className="flex items-center cursor-pointer mb-2">
            <input
              type="checkbox"
              name="privacyPolicy"
              checked={agreements.privacyPolicy}
              onChange={handleAgreementChange}
              className="mr-2 accent-[#D00070]"
            />
            <span className="font-semibold">개인정보 처리방침에 동의합니다.</span>
          </label>
          <div className="bg-[#fff] p-4 rounded-lg h-20 md:h-28 overflow-y-auto text-sm">
            <pre className="whitespace-pre-wrap">{privacyPolicy}</pre>
          </div>
        </div>
        <button
          type="submit"
          className={`w-full bg-[#D00070] text-2xl font-extrabold text-white py-4 px-4 rounded hover:bg-[#B0005E] transition duration-300 ${!isAllChecked && 'opacity-50 cursor-not-allowed'}`}
          disabled={!isAllChecked}
        >
          다 음
        </button>
      </form>
    </div>
  );
};

export default SignupIntro;