import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png';
import AuthBtn from './AuthBtn';
import TopBanner from './TopBanner';

const Header = ({ toggleMobileMenu, isMainPage, openSignupModal }) => {
  const [isTopBannerVisible, setIsTopBannerVisible] = useState(true);
  const location = useLocation();
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [headerOpacity, setHeaderOpacity] = useState(1);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleTopBannerClose = useCallback(() => {
    setIsTopBannerVisible(false);
  }, []);

  const handleLinkClick = useCallback((path, external) => {
    if (external) {
      window.open(path, '_blank', 'noopener noreferrer');
    } else {
      navigate(path);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setHeaderOpacity(currentScrollY === 0 ? 1 : 0.75);
      const shouldHide = currentScrollY > lastScrollY && currentScrollY > 60;
      setIsHeaderVisible(!shouldHide);
      setIsTopBannerVisible(!shouldHide && isTopBannerVisible);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, isTopBannerVisible]);

  useEffect(() => {
    if (!isMainPage && isTopBannerVisible) {
      handleTopBannerClose();
    }
  }, [isMainPage, location, isTopBannerVisible, handleTopBannerClose]);

  const navItems = [
    { label: 'PRICING', path: '/pricing' },
    { label: 'CUSTOMER', path: '/customer' },
    { label: 'CAREERS', path: '/careers' },
    { label: 'COMPANY', path: 'https://slopexcelerity.com', external: true }
  ];

  return (
    <div className="fixed w-screen max-w-[100vw] overflow-x-hidden z-[10] transition-all duration-500 ease-in-out" style={{
      top: isHeaderVisible ? 0 : `-${isTopBannerVisible ? '125px' : '65px'}`,
      height: isTopBannerVisible ? 'calc(60px + 65px)' : '65px',
    }}>
      <TopBanner 
        onClose={handleTopBannerClose} 
        isSubscribed={false}
        isVisible={isTopBannerVisible}
      />
      <header
        style={{ 
          opacity: headerOpacity,
          transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        }}
        className={`bg-white h-[65px] flex items-center w-full ${
          isHeaderVisible ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="max-w-[1160px] w-full flex items-center justify-between px-4 mx-auto">
          <div className="flex items-center w-[100px] md:w-[10%]">
            <img src={logo} alt="01CS" className="h-auto w-auto cursor-pointer" onClick={handleLogoClick} />
          </div>
          <nav className="hidden md:flex md:flex-1 justify-center gap-10">
            {navItems.map((item) => (
              <button 
                key={item.path}
                className={`text-gray-700 text-sm hover:text-[#D00070] ${item.className || ''}`} 
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(item.path, item.external);
                }}
              >
                {item.label}
              </button>
            ))}
          </nav>
          <div className="hidden md:flex">
            <AuthBtn openSignupModal={openSignupModal} />
          </div>
          <div className="flex md:hidden ml-auto w-auto">
            <button onClick={toggleMobileMenu} className="focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;