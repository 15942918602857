import { createSlice } from '@reduxjs/toolkit';

const signupModalSlice = createSlice({
  name: 'signupModal',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openSignupModal: (state) => {
      state.isOpen = true;
    },
    closeSignupModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openSignupModal, closeSignupModal } = signupModalSlice.actions;
export default signupModalSlice.reducer;