import React, { useState, useEffect } from 'react';
import ShareModal from '../modal/ShareModal';

const MemoryTestResult = ({ score, round, onRestart, onGoHome, onGoToRules }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      console.warn('Kakao SDK is not initialized');
    }
  }, []);

  // 점수에 따른 세부 결과 메시지 및 등급 생성 함수 수정
  const getResultDetails = () => {
    const grades = [
      { min: 95, grade: "S++", message: "탁월한 기억력! 정말 대단해요! 🎉" },
      { min: 90, grade: "S+", message: "천재적인 기억력의 소유자입니다! 🧠✨" },
      { min: 85, grade: "S", message: "뛰어난 기억력을 가지고 있습니다. 훌륭해요! 👍" },
      { min: 80, grade: "A+", message: "매우 우수한 기억력을 보유하고 있습니다. 잘했어요! 🌟" },
      { min: 75, grade: "A", message: "상위권 기억력의 소유자입니다. 계속 노력하세요! 💪" },
      { min: 70, grade: "B+", message: "좋은 기억력을 가지고 있습니다. 조금만 더! 😊" },
      { min: 65, grade: "B", message: "평균 이상의 기억력을 보유하고 있습니다. 잘하고 있어요! 👍" },
      { min: 60, grade: "C+", message: "기억력 향상의 여지가 있습니다. 포기하지 마세요! 💡" },
      { min: 50, grade: "C", message: "기억력 훈련이 필요합니다. 꾸준히 연습하세요! 🌱" },
      { min: 40, grade: "D+", message: "노력이 필요해요. 계속 도전하세요! 💪" },
      { min: 30, grade: "D", message: "집중적인 기억력 개선이 필요합니다. 화이팅! 🥇" },
      { min: 0, grade: "F", message: "노력이 절실히 필요합니다. 더 열심히 해봐요! 🔥" }
    ];

    for (let gradeInfo of grades) {
      if (score >= gradeInfo.min) {
        return gradeInfo;
      }
    }
    return grades[grades.length - 1];
  };

  const resultDetails = getResultDetails();

  const handleShareClick = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 mt-24" onClick={onGoToRules}>
      <div className="relative bg-white px-4 p-16 rounded-lg shadow-lg max-w-2xl w-[600px] h-[640px] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={() => window.location.href = '/'}
          className="absolute top-4 right-4 text-[#D00070] hover:text-[#A0005A] transition-colors"
        >
          홈 〉
        </button>
        
        <h2 className="text-3xl font-bold mb-6 text-center text-[#D00070]">기억력 테스트 결과</h2>
        
        <div className="mb-6 text-center">
          <p className="text-sm font-bold mb-2">{resultDetails.message}</p>
          <p className="text-xl">Level <span className="text-2xl font-bold text-[#D00070]">{resultDetails.grade}</span></p>
        </div>
       
        <table className="w-full mb-6 border-collapse border border-gray-300">
          <tbody>
            <tr>
              <td className="p-2 border border-gray-300">최종 점수</td>
              <td className="p-2 border border-gray-300 font-bold">{score}</td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-300">등급</td>
              <td className="p-2 border border-gray-300 font-bold">{resultDetails.grade}</td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-300">클리어한 라운드</td>
              <td className="p-2 border border-gray-300 font-bold">{round}</td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-300">평균 반응 시간</td>
              <td className="p-2 border border-gray-300 font-bold">{(10 / round).toFixed(2)}초</td>
            </tr>
          </tbody>
        </table>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">상세 분석:</h3>
          <p className="mb-2">{resultDetails.message}</p>
          <p>당신의 기억력은 상위 {100 - Math.floor(score / 10) * 10}% 수준입니다.</p>
        </div>

        <div className="text-center mt-6 flex flex-row justify-center space-x-4">
          <button
            onClick={handleShareClick}
            className="bg-[#FEE500] text-[#000000] px-4 py-2 rounded-lg hover:bg-[#FDD835] transition-colors flex-1 max-w-[150px]"
          >
            카카오톡 공유
          </button>
          <button
            onClick={onRestart}
            className="bg-[#D00070] text-white px-4 py-2 rounded-lg hover:bg-[#A0005A] transition-colors flex-1 max-w-[150px]"
          >
            다시 테스트하기
          </button>
        </div>

        <ShareModal
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
          shareUrl={`https://www.01cs.kr/memory-test-result/${score}`}
          title="01CS 기억력 테스트 결과"
          description={`나의 기억력 점수는 ${score}점입니다! (등급: ${resultDetails.grade})`}
        />
      </div>
    </div>
  );
};

export default MemoryTestResult;
