import React, { createContext, useContext, useState } from 'react';
import { mockData, studyTimeData } from '../components/lms/container/study/charts/LmsData';

const LmsContext = createContext();

export const useLms = () => useContext(LmsContext);

export const LmsProvider = ({ children }) => {
  const [data, setData] = useState({
    mockData,
    studyTimeData,
    activeClasses: [], // 초기값 설정
    completeClasses: [], // 초기값 설정
    // 기타 필요한 데이터 추가
  });

  const updateMockData = (newMockData) => {
    setData((prevData) => ({
      ...prevData,
      mockData: { ...prevData.mockData, ...newMockData },
    }));
  };

  const addActiveClass = (course) => {
    setData((prevData) => ({
      ...prevData,
      activeClasses: [...prevData.activeClasses, course],
    }));
  };

  const completeClass = (courseId) => {
    setData((prevData) => {
      const updatedActive = prevData.activeClasses.filter(course => course.id !== courseId);
      const completedCourse = prevData.activeClasses.find(course => course.id === courseId);
      return {
        ...prevData,
        activeClasses: updatedActive,
        completeClasses: [...prevData.completeClasses, { ...completedCourse, isCompleted: true }],
      };
    });
  };

  // 다른 상태 업데이트 함수들도 추가 가능

  return (
    <LmsContext.Provider value={{ data, updateMockData, addActiveClass, completeClass }}>
      {children}
    </LmsContext.Provider>
  );
};