import React, { useState, useCallback } from 'react';
import SearchClass from '../../components/lms/container/class/SearchClass';
import FilterClass from '../../components/lms/container/class/FilterClass';
import ClassResults from '../../components/lms/container/class/ClassResults';

const LmsClassPage = () => {
  const [filteredTiles, setFilteredTiles] = useState([]);

  // useCallback을 사용하여 함수의 참조를 안정화
  const handleFilterChange = useCallback((newFilteredTiles) => {
    setFilteredTiles(newFilteredTiles);
  }, []);

  return (
    <div>
      <SearchClass />
      <FilterClass onFilterChange={handleFilterChange} />
      <ClassResults tiles={filteredTiles} />
    </div>
  );
};

export default LmsClassPage;