import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAnyModalOpen: false,
  scrollbarWidth: 0,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.isAnyModalOpen = action.payload;
    },
    setScrollbarWidth: (state, action) => {
      state.scrollbarWidth = action.payload;
    },
  },
});

export const { setModalOpen, setScrollbarWidth } = modalSlice.actions;
export default modalSlice.reducer;