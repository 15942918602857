import React, { useState } from 'react';
import UserAuth from './UserAuth';

const UserAuthManagement = () => {
  const [isUserAuthExpanded, setIsUserAuthExpanded] = useState(true);

  const toggleUserAuth = () => {
    setIsUserAuthExpanded(prev => !prev);
  };

  return (
    <div className="mt-8 py-12 max-w-[1160px] mx-auto">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 rounded-t-lg p-4">
        <button 
          onClick={toggleUserAuth} 
          className="w-full flex justify-between items-center text-white"
        >
          <h2 className="text-xl font-semibold">사용자 권한 관리</h2>
          <span className="bg-white bg-opacity-20 rounded-full p-1">
            {isUserAuthExpanded 
              ? <span className="text-2xl">&uarr;</span>
              : <span className="text-2xl">&darr;</span>
            }
          </span>
        </button>
      </div>
      <div className={`bg-white shadow-lg rounded-b-lg overflow-hidden transition-all duration-300 ease-in-out ${isUserAuthExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="p-4">
          <UserAuth />
        </div>
      </div>
    </div>
  );
};

export default UserAuthManagement;