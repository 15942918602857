import React, { useState, useEffect, useCallback } from 'react';

const TileContainer = ({ tiles, containerId }) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  // 스크롤 상태 감지 함수
  const handleScroll = useCallback(() => {
    const scrollContainer = document.getElementById(containerId);
    const scrollLeft = scrollContainer.scrollLeft;
    const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < maxScrollLeft);
  }, [containerId]);

  useEffect(() => {
    const scrollContainer = document.getElementById(containerId);

    const checkScrollable = () => {
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      setCanScrollLeft(scrollContainer.scrollLeft > 0);
      setCanScrollRight(maxScrollLeft > 0);
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkScrollable);
    };
  }, [containerId, handleScroll]);

  const scrollLeft = () => {
    document.getElementById(containerId).scrollBy({ left: -410, behavior: 'smooth' });
  };

  const scrollRight = () => {
    document.getElementById(containerId).scrollBy({ left: 410, behavior: 'smooth' });
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {/* 좌우 스크롤 버튼 */}
      {canScrollLeft && (
        <button
          onClick={scrollLeft}
          className={`absolute top-1/2 left-5 z-10 -translate-y-1/2 w-16 h-16 flex items-center justify-center bg-[#fff]/60 hover:bg-[#fff]/80 rounded-full shadow-lg hidden md:flex`}
          style={{
            opacity: showButtons ? 1 : 0,
            transform: showButtons ? 'scale(1)' : 'scale(0.9)',
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
        >
          <span className="text-3xl text-black" style={{ fontWeight: '900' }}>
            〈
          </span>
        </button>
      )}

      {canScrollRight && (
        <button
          onClick={scrollRight}
          className={`absolute top-1/2 right-5 z-10 -translate-y-1/2 w-16 h-16 flex items-center justify-center bg-[#fff]/60 hover:bg-[#fff]/80 rounded-full shadow-lg hidden md:flex`}
          style={{
            opacity: showButtons ? 1 : 0,
            transform: showButtons ? 'scale(1)' : 'scale(0.9)',
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
        >
          <span className="text-3xl text-black ml-1" style={{ fontWeight: '900' }}>
            〉
          </span>
        </button>
      )}

      {/* 타일 컨테이너 */}
      <div id={containerId} className="flex justify-start overflow-x-auto no-scrollbar w-full px-2 py-10">
        {tiles}
      </div>
    </div>
  );
};

export default TileContainer;