// src/features/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  userData: null, // 사용자 데이터를 저장할 상태 추가
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { uid, email, displayName, ...userData } = action.payload;
      state.user = { uid, email, displayName }; // Firebase 사용자 객체에서 필요한 부분만 저장
      state.userData = userData; // 추가적인 사용자 데이터 저장
    },
    clearUser: (state) => {
      state.user = null;
      state.userData = null; // 사용자 데이터도 초기화
    },
    setUserData: (state, action) => {
      state.userData = action.payload; // 사용자 데이터를 저장
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setUser, clearUser, setUserData, setLoading } = authSlice.actions;
export default authSlice.reducer;