import React from 'react';
import { Radar } from 'react-chartjs-2';

const StrengthWeaknessChart = ({ data }) => {
  const chartData = {
    labels: ['코딩 실력', '알고리즘', '수학', '프로젝트 관리'],
    datasets: [
      {
        label: '능력치',
        data: data,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 1,
      },
    ],
  };

  return <Radar data={chartData} />;
};

export default StrengthWeaknessChart;