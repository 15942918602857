// 사용자에 커리큘럼에 맞춘 강의를 순서대로 추천
// 타일 형태로 표시하되, 순서를 표시하여 사용자가 한 단계씩 나아가고 있다는 느낌을 받을 수 있는 UI/UX 디자인
// 강의 이름/완료 여부/학습 마지막 날짜 표시
// 강의 타일 클릭 시, 해당 강의로 이동(새로 시작)
// 완료된 강의는 타일 완료 처리(디자인은 고민해야함)

import React from 'react';
import { useLms } from '../../../../context/LmsContext';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaLock, FaPlay } from 'react-icons/fa';

const LmsCurriculumClass = () => {
  const { data } = useLms();
  const { curriculumClasses } = data; // 예시 데이터 구조

  if (!curriculumClasses || curriculumClasses.length === 0) {
    return (
      <div className="flex flex-wrap justify-center p-4">
        <h1 className='font-extrabold'>커리큘럼 강의</h1>
        <p>커리큘럼에 맞춘 강의가 없습니다. 추천 강의를 확인해보세요!</p>
        {/* 추천 강의 타일 컴포넌트 추가 */}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap justify-center p-4">
      <h1 className='font-extrabold'>커리큘럼 강의</h1>
      {curriculumClasses.map((course, index) => (
        <Link to={`/lms/course/${course.id}`} key={course.id} className="bg-white p-4 m-2 rounded-lg shadow transition transform hover:scale-105 hover:shadow-xl">
          <h2 className="text-lg font-semibold">{course.title}</h2>
          <div className="flex items-center mt-2">
            <div className={`w-6 h-6 rounded-full flex items-center justify-center ${
              course.isCompleted ? 'bg-green-500' :
              course.isCurrent ? 'bg-yellow-500' : 'bg-gray-300'
            }`}>
              {course.isCompleted ? <FaCheckCircle className="text-white" /> :
               course.isCurrent ? <FaPlay className="text-white" /> :
               <FaLock className="text-gray-500" />}
            </div>
            <span className="ml-2 text-sm">
              {course.isCompleted ? '완료' : course.isCurrent ? '현재 진행 중' : '잠김'}
            </span>
          </div>
          <p className="text-sm text-gray-600 mt-1">학습 마지막 날짜: {course.lastStudyDate ? course.lastStudyDate : '미학습'}</p>
        </Link>
      ))}
    </div>
  );
};

export default LmsCurriculumClass;