// src/components/lms/container/LearningData/LearningDataService.js
import { db } from '../../../../Firebase';
import { doc, setDoc, increment, serverTimestamp, getDoc, collection, getDocs } from 'firebase/firestore';

export const LearningDataService = {
  /**
   * 문제 시도 기록을 저장합니다.
   * @param {string} userId - 사용자 ID
   * @param {string} moduleType - 모듈 유형 (예: 'python-beginner')
   * @param {string} chapterId - 챕터 ID
   * @param {string} problemId - 문제 ID
   * @param {boolean} isCorrect - 문제 정답 여부
   * @param {number} timeTaken - 문제 풀이에 소요된 시간 (초 단위)
   */
  async saveProblemAttempt(userId, moduleType, chapterId, problemId, isCorrect, timeTaken) {
    try {
      const userProblemRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
      const userChapterRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId);
      const userModuleRef = doc(db, 'users', userId, 'learningData', moduleType);

      // 문제 데이터 업데이트
      await setDoc(userProblemRef, {
        attempts: increment(1),
        isCorrect: isCorrect,
        timeTaken: increment(timeTaken),
        lastAttemptDate: serverTimestamp()
      }, { merge: true });

      // 챕터 데이터 업데이트
      await setDoc(userChapterRef, {
        problemsAttempted: increment(1),
        problemsCompleted: isCorrect ? increment(1) : 0,
        totalTimeTaken: increment(timeTaken)
      }, { merge: true });

      // 모듈 데이터 업데이트
      await setDoc(userModuleRef, {
        lastAccessDate: serverTimestamp(),
        problemsAttempted: increment(1),
        problemsCompleted: isCorrect ? increment(1) : 0,
        totalTimeTaken: increment(timeTaken)
      }, { merge: true });
    } catch (error) {
      console.error('문제 시도 저장 중 오류 발생:', error.message, error.code);
      throw error; // 오류를 상위로 전파
    }
  },

  /**
   * 전체 학습 세션 데이터를 초기화합니다.
   * @param {string} userId - 사용자 ID
   * @param {string} moduleType - 모듈 유형
   * @param {string} chapterId - 챕터 ID
   */
  async initializeLearningSession(userId, moduleType, chapterId) {
    try {
      const userProblemRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', 'session');
      await setDoc(userProblemRef, {
        attempts: 0,
        isCorrect: false,
        timeTaken: 0,
        lastAttemptDate: serverTimestamp() // null 대신 serverTimestamp() 사용
      }, { merge: true });
    } catch (error) {
      console.error('학습 세션 초기화 중 오류 발생:', error.message, error.code);
      throw error;
    }
  },

  /**
   * 사용자 피드백을 저장합니다.
   * @param {string} userId - 사용자 ID
   * @param {string} moduleType - 모듈 유형
   * @param {string} chapterId - 챕터 ID
   * @param {string} problemId - 문제 ID
   * @param {string} feedbackText - 피드백 내용
   * @param {number} rating - 평가 점수 (1-5)
   */
  async saveFeedback(userId, moduleType, chapterId, problemId, feedbackText, rating) {
    try {
      const feedbackRef = doc(db, 'users', userId, 'feedback', `${moduleType}_${chapterId}_${problemId}_${Date.now()}`);
      await setDoc(feedbackRef, {
        moduleType: moduleType,
        chapterId: chapterId,
        problemId: problemId,
        feedbackText: feedbackText,
        rating: rating,
        dateSubmitted: serverTimestamp()
      });
    } catch (error) {
      console.error('피드백 저장 중 오류 발생:', error.message, error.code);
      throw error;
    }
  },

  /**
   * 컨셉 진행 상황을 저장합니다.
   * @param {string} userId - 사용자 ID
   * @param {string} moduleType - 모듈 유형
   * @param {string} chapterId - 챕터 ID
   * @param {string} problemId - 문제 ID
   */
  async saveConceptProgress(userId, moduleType, chapterId, problemId) {
    try {
      const userProblemRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
      await setDoc(userProblemRef, {
        isCompleted: true,
        completionDate: serverTimestamp()
      }, { merge: true });
    } catch (error) {
      console.error('컨셉 진행 상황 저장 중 오류 발생:', error.message, error.code);
      throw error;
    }
  },

  async saveUserAnswer(userId, moduleType, chapterId, problemId, answer) {
    const userAnswerRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
    await setDoc(userAnswerRef, { answer }, { merge: true });
  },

  async saveUserCode(userId, moduleType, chapterId, problemId, code) {
    const userCodeRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
    await setDoc(userCodeRef, { code }, { merge: true });
  },

  async getUserAnswers(userId, moduleType, chapterId) {
    const userAnswersRef = collection(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems');
    const snapshot = await getDocs(userAnswersRef);
    const answers = {};
    snapshot.forEach(doc => {
      if (doc.data().answer) {
        answers[doc.id] = doc.data().answer;
      }
    });
    return answers;
  },

  async getUserCodes(userId, moduleType, chapterId) {
    const userCodesRef = collection(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems');
    const snapshot = await getDocs(userCodesRef);
    const codes = {};
    snapshot.forEach(doc => {
      if (doc.data().code) {
        codes[doc.id] = doc.data().code;
      }
    });
    return codes;
  },

  async getUserAnswer(userId, moduleType, chapterId, problemId) {
    const userAnswerRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
    const docSnap = await getDoc(userAnswerRef);
    if (docSnap.exists()) {
      return docSnap.data().answer;
    }
    return null;
  },

  async getCorrectAnswers(userId, moduleType, chapterId) {
    const correctAnswersRef = collection(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems');
    const snapshot = await getDocs(correctAnswersRef);
    const correctAnswers = {};
    snapshot.forEach(doc => {
      if (doc.data().isCorrect !== undefined) {
        correctAnswers[doc.id] = doc.data().isCorrect;
      }
    });
    return correctAnswers;
  },

  async saveCorrectAnswer(userId, moduleType, chapterId, problemId, isCorrect) {
    const correctAnswerRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
    await setDoc(correctAnswerRef, { isCorrect }, { merge: true });
  },

  async saveAttempt(userId, moduleType, chapterId, problemId, attemptCount, attemptDate) {
    const problemRef = doc(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems', problemId);
    await setDoc(problemRef, {
      attempts: attemptCount,
      lastAttemptDate: attemptDate
    }, { merge: true });
  },

  async getAttempts(userId, moduleType, chapterId) {
    const problemsRef = collection(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems');
    const snapshot = await getDocs(problemsRef);
    const attempts = {};
    snapshot.forEach(doc => {
      if (doc.data().attempts) {
        attempts[doc.id] = doc.data().attempts;
      }
    });
    return attempts;
  },

  async getLastAttemptDates(userId, moduleType, chapterId) {
    const problemsRef = collection(db, 'users', userId, 'learningData', moduleType, 'chapters', chapterId, 'problems');
    const snapshot = await getDocs(problemsRef);
    const lastAttemptDates = {};
    snapshot.forEach(doc => {
      if (doc.data().lastAttemptDate) {
        lastAttemptDates[doc.id] = doc.data().lastAttemptDate;
      }
    });
    return lastAttemptDates;
  }
};
