import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import AuthBtn from './AuthBtn';

const MobileMenu = ({ isOpen, closeMenu }) => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    closeMenu();
  };

  const menuItems = [
    { label: 'COMPANY', path: '/company' },
    { label: 'CAREERS', path: '/careers' },
    { label: 'CUSTOMER', path: '/customer' },
    { label: 'PRICING', path: '/pricing' },
  ];

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[9999]" onClick={closeMenu}></div>
      )}
      <div
        className={`fixed top-0 right-0 h-full w-[300px] bg-[#D00070] shadow-md transition-transform duration-500 ease-in-out z-[10000] ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <button className="absolute top-6 right-8 text-white focus:outline-none" onClick={closeMenu}>
          <FaTimes size={24} />
        </button>
        <div className="flex flex-col justify-between items-center h-full">
          <nav className="flex flex-col items-center font-extrabold gap-6 mt-36">
            {menuItems.map((item) => (
              <Link 
                key={item.path}
                to={item.path} 
                className={`text-white hover:text-gray-200 ${item.className || ''}`} 
                onClick={handleLinkClick}
              >
                {item.label}
              </Link>
            ))}
          </nav>
          <div className="mt-auto mb-24 w-full px-6 flex justify-center gap-2">
            <AuthBtn
              buttonStyle="border border-white py-2 px-4 rounded-full transition duration-300"
              linkStyle="bg-white text-[#D00070] py-2 px-4 rounded-full border-2 border-transparent animate-pulse transition duration-300"
              closeMenu={closeMenu}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;