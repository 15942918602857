import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { auth, db } from '../../../Firebase';
import { deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const MoreOptions = ({
  showMoreOptions,
  setShowMoreOptions,
  onAccountDeleted
}) => {
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showReauth, setShowReauth] = useState(false);
  const [reauthPassword, setReauthPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    check1: false,
    check2: false,
    check3: false
  });

  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked
    });
  };

  const isAllChecked = checkboxes.check1 && checkboxes.check2 && checkboxes.check3;

  const handleDeleteAccount = async () => {
    const currentUser = auth.currentUser;
    if (currentUser && isAllChecked) {
      try {
        await deleteDoc(doc(db, 'users', currentUser.uid));
        await deleteUser(currentUser);
        onAccountDeleted();
        navigate('/goodbye');
      } catch (error) {
        console.error('Error deleting user account:', error);
        if (error.code === 'auth/requires-recent-login') {
          setShowReauth(true);
        } else {
          setStatusMessage('회원탈퇴 중 오류가 발생했습니다.');
          setTimeout(() => {
            setStatusMessage('');
          }, 3000);
        }
      }
    }
  };

  const handleReauth = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        reauthPassword
      );
      try {
        await reauthenticateWithCredential(currentUser, credential);
        await deleteDoc(doc(db, 'users', currentUser.uid));
        await deleteUser(currentUser);
        setShowReauth(false);
        onAccountDeleted();
        navigate('/goodbye');
      } catch (error) {
        console.error('재인증 및 계정 삭제 오류:', error);
        setStatusMessage('재인증 또는 계정 삭제 중 오류가 발생했습니다.');
        setTimeout(() => {
          setStatusMessage('');
        }, 3000);
      }
    }
  };

  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (showMoreOptions) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [showMoreOptions]);

  return (
    <>
      <hr className="my-4 border-gray-300" />
      <button
        onClick={() => setShowMoreOptions(!showMoreOptions)}
        className="mt-4 mb-4 bg-white border border-gray-300 text-black font-bold py-2 px-4 rounded flex items-center justify-between w-full"
      >
        <span className="font-normal">추가 정보관리 &nbsp;</span>
        {showMoreOptions ? '닫기 🔼' : '열기 🔽'}
      </button>

      <div 
        className="overflow-hidden transition-[max-height] duration-300 ease-in-out mb-24"
        style={{ maxHeight: `${contentHeight}px` }}
      >
        <div ref={contentRef}>
          <div className="flex justify-center">
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="bg-white hover:bg-black hover:text-white text-black border border-black font-bold py-3 px-6 rounded mt-4 transition duration-300 ease-in-out flex items-center justify-center w-64"
            >
              계정 삭제 🗑️
            </button>
          </div>
        </div>
      </div>

      {showMoreOptions && (
        <div className="mt-4">

          <Modal
            isOpen={showDeleteConfirmation}
            onRequestClose={() => setShowDeleteConfirmation(false)}
            className="fixed inset-0 flex items-center justify-center z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75"
          >
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
              <div className="bg-[#d00070] bg-opacity-50 h-[60px] rounded-t-lg flex items-center justify-center">
                <span className="text-white font-bold">SlopeXcelerity 계정 삭제</span>
              </div>
              <div className="p-8">
                <h2 className="text-2xl font-bold mb-4">🚨 계정삭제</h2>
                <p className="mb-8">정말로 탈퇴하시겠습니까?<br />이 작업은 되돌릴 수 없습니다.<br /><br />00MATH, 01CS 등<br />모든 학습자료는 되돌릴 수 없습니다.</p>
                <div className="mb-8">
                  {['check1', 'check2', 'check3'].map((checkboxName, index) => (
                    <label key={checkboxName} className="flex items-center mb-2 cursor-pointer">
                      <div className="relative">
                        <input
                          type="checkbox"
                          name={checkboxName}
                          checked={checkboxes[checkboxName]}
                          onChange={handleCheckboxChange}
                          className="sr-only"
                        />
                        <div className="w-6 h-6 border-2 border-gray-400 rounded-md transition-colors duration-200 ease-in-out bg-white">
                          {checkboxes[checkboxName] && (
                            <svg className="w-4 h-4 text-[#d00070] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                          )}
                        </div>
                      </div>
                      <span className="ml-2 text-sm">{
                        index === 0 ? "모든 데이터가 삭제됨을 이해했습니다." :
                        index === 1 ? "계정 복구가 불가능함을 이해했습니다." :
                        "위의 내용을 모두 확인하였으며 동의합니다."
                      }</span>
                    </label>
                  ))}
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => setShowDeleteConfirmation(false)}
                    className="mr-2 bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                  >
                    취소
                  </button>
                  <button
                    onClick={handleDeleteAccount}
                    className={`bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ${!isAllChecked && 'opacity-50 cursor-not-allowed'}`}
                    disabled={!isAllChecked}
                  >
                    회원탈퇴
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showReauth}
            onRequestClose={() => setShowReauth(false)}
            className="fixed inset-0 flex items-center justify-center z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75"
          >
            <div className="bg-white p-6 rounded-lg w-80">
              <h2 className="text-xl font-bold mb-4">마지막 단계입니다.</h2>
              <p className="mb-4">계정을 삭제하기 위해<br/>비밀번호를 입력해주세요.</p>
              <input
                type="password"
                placeholder="비밀번호"
                value={reauthPassword}
                onChange={(e) => setReauthPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowReauth(false)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                >
                  취소
                </button>
                <button
                  onClick={handleReauth}
                  className="bg-[#D00070] text-white px-4 py-2 rounded"
                  disabled={!reauthPassword}
                >
                  회원탈퇴 완료
                </button>
              </div>
              {statusMessage && <p className="text-red-500 mt-2">{statusMessage}</p>}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default MoreOptions;