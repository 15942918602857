import React from 'react';

const MemoryTimer = ({ timeLeft }) => {
  const percentage = (timeLeft / 180) * 100; // 180초로 변경
  
  return (
    <div className="w-full h-2 bg-gray-200 rounded-full mb-4">
      <div
        className="h-full bg-[#D00070] rounded-full transition-all duration-1000 ease-linear"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default MemoryTimer;