export const serviceAgreement = `제1조 (목적)
이 약관은 주식회사 슬로프엑셀러리티(이하 "회사")가 운영하는 웹사이트에서 제공하는 01CS 서비스(이하 "서비스")의 이용과 관련하여, 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.

제2조 (정의)
1. "사이트"는 회사가 제공하는 인터넷 웹사이트를 의미합니다.
2. "이용자"란 사이트에 접속하여 본 약관에 따라 사이트가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.
3. "회원"이란 회사에 개인정보를 제공하여 회원 등록을 한 자로서, 사이트의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
4. "비회원"이란 회원으로 등록하지 않고 사이트가 제공하는 서비스를 이용하는 자를 말합니다.

제3조 (약관의 명시, 효력 및 변경)
1. 본 약관의 내용은 사이트에 게시하거나 기타의 방법으로 이용자에게 공지함으로써 효력이 발생합니다.
2. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 본 약관을 변경할 수 있으며, 약관이 변경될 경우 적용일자 및 변경사유를 명시하여 변경된 약관을 사이트에 공지합니다.
3. 변경된 약관은 공지된 적용일로부터 효력이 발생하며, 이용자가 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.

제4조 (서비스의 제공 및 변경)
1. 회사는 다음과 같은 서비스를 제공합니다:
   - 01CS 관련 콘텐츠 제공
   - 기타 회사가 정하는 서비스
2. 회사는 서비스의 내용을 변경할 경우 변경된 서비스의 내용을 사이트에 공지하거나 이용자에게 통지합니다.

제5조 (서비스의 중단)
1. 회사는 정기점검, 서버 교체 등 불가피한 경우 서비스 제공을 일시 중단할 수 있으며, 이에 대하여 사이트에 공지합니다.
2. 회사는 천재지변, 전쟁, 정전, 기술적 문제 등 불가항력적 사유로 인하여 서비스 제공이 불가능할 경우 사전 통지 없이 서비스 제공을 중단할 수 있습니다.

제6조 (회원가입)
1. 이용자는 회사가 정한 절차에 따라 회원가입 신청을 하고, 회사는 이를 승낙함으로써 회원가입이 완료됩니다.
2. 회사는 다음 각 호에 해당하는 경우, 회원가입 신청을 거부할 수 있습니다:
   - 가입 신청자가 이 약관에 위반된 정보를 제공한 경우
   - 기타 회사가 판단하기에 부적절하다고 인정되는 경우

제7조 (회원 탈퇴 및 자격 상실)
1. 회원은 언제든지 회원 탈퇴를 요청할 수 있으며, 회사는 이를 즉시 처리합니다.
2. 회사는 다음과 같은 경우, 회원 자격을 제한하거나 정지시킬 수 있습니다:
   - 법령 또는 이 약관을 위반한 경우
   - 서비스의 정상적인 운영을 방해한 경우

제8조 (개인정보 보호)
회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 개인정보 처리에 관한 사항은 별도의 "개인정보 처리방침"에 따릅니다.
`;

export const privacyPolicy = `제1조 (총칙)
주식회사 슬로프엑셀러리티(이하 "회사")는 이용자의 개인정보를 중요하게 생각하며, 개인정보 보호법 및 관련 법령을 준수하고 있습니다. 본 개인정보 처리방침은 이용자가 제공한 개인정보가 어떤 방식으로 이용되고 보호되는지 설명합니다.

제2조 (수집하는 개인정보 항목)
회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집합니다:
1. 회원가입 시: 이름, 이메일, 비밀번호, 전화번호
2. 서비스 이용 시: IP 주소, 쿠키, 방문 기록, 이용 기록

제3조 (개인정보의 수집 및 이용 목적)
회사는 다음의 목적을 위해 이용자의 개인정보를 수집합니다:
1. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
2. 회원 관리: 회원제 서비스 이용에 따른 본인 확인, 개인 식별
3. 서비스 개선 및 신규 서비스 개발

제4조 (개인정보의 보유 및 이용 기간)
회사는 이용자의 개인정보를 원칙적으로 개인정보의 수집 및 이용 목적이 달성된 후 지체 없이 파기합니다. 다만, 관련 법령에 따라 일정 기간 보관해야 하는 경우에는 그에 따릅니다.

제5조 (개인정보의 제3자 제공)
회사는 이용자의 개인정보를 원칙적으로 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다:
1. 이용자가 사전에 동의한 경우
2. 법령에 따라 요청이 있는 경우

제6조 (개인정보의 파기 절차 및 방법)
1. 개인정보는 수집 및 이용 목적이 달성된 후 내부 방침 및 관련 법령에 따라 파기됩니다.
2. 전자적 파일 형태로 저장된 개인정보는 복구할 수 없는 방법을 사용하여 삭제하며, 종이 문서 형태의 개인정보는 분쇄하거나 소각하여 파기합니다.

제7조 (이용자의 권리)
이용자는 언제든지 자신의 개인정보에 대해 열람, 수정, 삭제를 요청할 수 있으며, 이러한 요청은 회사의 고객지원팀을 통해 가능합니다.

제8조 (개인정보 보호책임자)
회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다:

- 성명: 조용진
- 직책: 대표이사
- 연락처: 010-2765-1787
`;