import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'; // Redux Provider 추가
import store from './store'; // store 설정 파일 import

const root = ReactDOM.createRoot(document.getElementById('root'));

// Kakao SDK 로드 및 초기화
const loadKakaoSDK = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    script.onload = () => {
      if (window.Kakao && !window.Kakao.isInitialized()) {
        if (process.env.REACT_APP_KAKAO_JS_KEY) {
          window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
          resolve();
        } else {
          reject(new Error("Kakao JS Key is not defined"));
        }
      } else {
        resolve();
      }
    };
    script.onerror = () => reject(new Error("Failed to load Kakao SDK"));
    document.body.appendChild(script);
  });
};

const renderApp = () => {
  root.render(
    <React.StrictMode>
      <Provider store={store}> {/* Provider로 App을 감쌉니다 */}
        <App />
      </Provider>
    </React.StrictMode>
  );
};

// Kakao SDK 로드 후 앱 렌더링
loadKakaoSDK()
  .then(renderApp)
  .catch(error => {
    renderApp(); // Kakao SDK 초기화에 실패해도 앱은 렌더링
  });