// src/components/company/container/CompanyMain.js

import React, { useState } from 'react';
import EmojiGroup from '../../landing/container/emoji/EmojiGroup';
import ContentsModal from '../../landing/container/modal/ContentsModal';
import NaverBlog from '../../../assets/naver.png';

const CompanyMain = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleEmojiClick = (label) => {
    // 여기에 각 이모지에 대한 모달 내용을 정의합니다.
    const content = {
      '00MATH': {
        modalTitle: '00MATH',
        modalDetails: '공학교육에 필요한 기초수학 과정입니다.',
        modalExtraInfo: '자세히 보기',
      },
      '01CS': {
        modalTitle: '01CS',
        modalDetails: '비전공자, 청소년도 가능한 기초 컴퓨터 과학 과정입니다.',
        modalExtraInfo: '자세히 보기',
        modalLink: '/'
      },
      '19Go': {
        modalTitle: '19Go',
        modalDetails: `두고팡과 함께 바둑을 배워보세요!
                      어린 친구들도 스스로 바둑을 배울 수 있도록 도와드립니다.
                      다양한 바둑 콘텐츠를 경험해보세요`,
        modalExtraInfo: '두고팡 바로가기',
        modalLink: 'https://dogopang.com'
      }
      // 다른 이모지에 대한 내용도 여기에 추가...
    };

    setModalContent(content[label] || {});
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center px-[8%] mt-[15%]">
        <div className="max-w-[1440px] w-full flex flex-col md:flex-row justify-between">
          {/* 왼쪽 텍스트 */}
          <div className="flex flex-col mb-8 mt-24 md:mt-0 md:mb-0 md:mr-4">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
              <span className="text-[#D00070]">SlopeX</span>celerity
            </h1>
            <h2 className="text-xl md:text-xl font-bold text-gray-900 mt-4 md:mt-6">
            <span className='text-[#D00070] font-extrabold'>슬로프엑셀러리티</span>는 <br/>
            세상에 필요한 기초공학교육을 위한<br/>
            다양한 프로젝트에 도전합니다.
              <span className="inline-block">
                <span role="img" aria-label="gear">
                  ⚙️
                </span>
              </span>
            </h2>
          </div>

          {/* 오른쪽 텍스트 */}
          <div className="flex flex-col mt-8 md:mt-0 text-right md:text-right">
            <p className="text-gray-600 mb-1">SlopeX AI</p>
            <a href="##" className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6">
              AI 기술로 효율적인 학습 👾
            </a>
            <p className="text-gray-600 mb-1">기초역량 테스트</p>
            <a href="##" className="text-[#D00070] font-bold hover:underlin mb-4 md:mb-6">
              나의 기초역량 평가는? 👻
            </a>
            <p className="text-gray-600 mb-1">우리의 이야기를 담은</p>
            <a
              href="https://blog.naver.com/slopexcelerity"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#2DB400] font-bold hover:underline flex items-center justify-end"
            >
              SlopeXcelerity 블로그
              <img
                src={NaverBlog}
                alt="데이터 분석"
                className="w-4 h-4 ml-1"
              />
            </a>
          </div>
        </div>
      </div>

      {/* EmojiGroup 컴포넌트에 onEmojiClick prop 전달 */}
      <div className='mb-24'>
        <EmojiGroup onEmojiClick={handleEmojiClick} />
      </div>

      {/* ContentsModal 추가 */}
      <ContentsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalContent={modalContent}
        status={modalContent.status}
      />
    </div>
  );
};

export default CompanyMain;