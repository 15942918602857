import React, { useState } from 'react';
import GroupCreationForm from './GroupCreationForm';
import GroupList from './GroupList';
import { useSelector } from 'react-redux';

const GroupManagement = () => {
  const [showCreationForm, setShowCreationForm] = useState(false);
  const user = useSelector(state => state.auth.user);

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">그룹 관리</h1>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">그룹 생성 및 관리 안내</h2>
        <p className="mb-4">
          누구나 그룹을 생성할 수 있지만, 그룹을 운영하기 위해서는 Sloper의 승인이 필요합니다.
          그룹을 통해 함께 학습하고 정보를 공유해보세요!
        </p>
        <button 
          onClick={() => setShowCreationForm(!showCreationForm)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {showCreationForm ? '그룹 생성 취소' : '그룹 생성하기'}
        </button>
      </div>

      {showCreationForm && <GroupCreationForm />}

      <GroupList isSloper={user?.isSloper} />
    </div>
  );
};

export default GroupManagement;