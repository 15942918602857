import React from 'react';

const GameBoard = ({ gridSize, activeTile, userSequence, isSequencePlaying, isRoundChanging, wrongTile, isCorrect, onTileClick }) => {
  return (
    <div 
      className={`grid gap-1 w-full aspect-square`}
      style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}
    >
      {Array.from({ length: gridSize * gridSize }).map((_, tileIndex) => (
        <button
          key={tileIndex}
          className={`
            aspect-square rounded-lg transition-colors duration-300
            ${activeTile === tileIndex
              ? 'bg-[#D00070]'
              : isCorrect
                ? userSequence.includes(tileIndex) ? 'bg-green-500' : 'bg-gray-300'
                : wrongTile === tileIndex
                  ? 'bg-yellow-400'
                  : isRoundChanging || isSequencePlaying
                    ? 'bg-gray-300'
                    : userSequence.includes(tileIndex)
                      ? 'bg-[#D00070]'
                      : 'bg-gray-300 hover:bg-gray-300 md:hover:bg-gray-400'}
            ${!(isSequencePlaying || isRoundChanging || wrongTile !== null || isCorrect) ? '' : ''}
          `}
          onClick={() => onTileClick(tileIndex)}
          disabled={isSequencePlaying || isRoundChanging || wrongTile !== null || isCorrect}
        />
      ))}
    </div>
  );
};

export default GameBoard;