// ... 기존 코드 ...

export const dataStructureProblems = [
    // ... 기존 문제들 ...
    {
      problemId: "data_structure_001",
      title: "스택(Stack)의 특성",
      description: "다음 중 스택(Stack) 자료구조의 특성으로 올바른 것은 무엇인가요?",
      subject: "Computer Science",
      language: "한국어",
      topic: "스택",
      subTopic: "스택 특성",
      difficulty: 2,
      difficultyDescription: "스택의 기본 특성을 이해하는 문제입니다.",
      cognitiveLevel: "이해",
      prerequisiteKnowledge: ["기본 자료구조 개념"],
      estimatedTime: "2분",
      tags: ["Python", "Beginner", "Data Structures", "Stack"],
      learningObjectives: [
        "스택의 기본 특성 이해",
        "LIFO 원칙 파악",
      ],
      commonMistakes: ["스택과 큐의 특성을 혼동함"],
      hints: ["스택은 '후입선출' 원칙을 따릅니다."],
      relatedConcepts: ["큐", "연결 리스트"],
      learningStyle: ["이론적 학습"],
      assessmentType: "객관식",
      languageLevel: "쉬움",
      type: "multiple_choice",
      choices: [
        "먼저 들어온 데이터가 먼저 나간다 (FIFO)",
        "나중에 들어온 데이터가 먼저 나간다 (LIFO)",
        "데이터의 삽입과 삭제가 양쪽 끝에서 모두 가능하다",
        "중간에 있는 데이터에 직접 접근할 수 있다",
      ],
      correctAnswer: 1,
      solution: "스택은 '후입선출(LIFO: Last In First Out)' 원칙을 따르는 자료구조입니다. 따라서 정답은 2번입니다.",
      examples: [],
      promptForFineTuning: "스택(Stack) 자료구조의 주요 특성은 무엇인가요?",
      completionForFineTuning: "스택의 주요 특성은 '후입선출(LIFO: Last In First Out)' 원칙입니다. 즉, 가장 나중에 들어온 데이터가 가장 먼저 나가게 됩니다.",
    },
    {
      problemId: "data_structure_002",
      title: "큐(Queue)의 연산",
      description: "큐(Queue)에서 데이터를 삽입하는 연산의 이름은 무엇인가요?",
      subject: "Computer Science",
      language: "한국어",
      topic: "큐",
      subTopic: "큐 연산",
      difficulty: 2,
      difficultyDescription: "큐의 기본 연산을 이해하는 문제입니다.",
      cognitiveLevel: "기억",
      prerequisiteKnowledge: ["기본 자료구조 개념"],
      estimatedTime: "2분",
      tags: ["Python", "Beginner", "Data Structures", "Queue"],
      learningObjectives: [
        "큐의 기본 연산 이해",
        "enqueue와 dequeue 개념 파악",
      ],
      commonMistakes: ["push와 enqueue를 혼동함"],
      hints: ["큐에 데이터를 추가하는 연산을 생각해보세요."],
      relatedConcepts: ["스택", "연결 리스트"],
      learningStyle: ["이론적 학습"],
      assessmentType: "객관식",
      languageLevel: "쉬움",
      type: "multiple_choice",
      choices: [
        "push",
        "pop",
        "enqueue",
        "dequeue",
      ],
      correctAnswer: 2,
      solution: "큐에서 데이터를 삽입하는 연산은 'enqueue'입니다. 따라서 정답은 3번입니다.",
      examples: [],
      promptForFineTuning: "큐(Queue)에서 데이터를 삽입하는 연산의 이름은 무엇인가요?",
      completionForFineTuning: "큐에서 데이터를 삽입하는 연산의 이름은 'enqueue'입니다. 이 연산은 큐의 뒤쪽(rear)에 새로운 요소를 추가합니다.",
    },
    {
      problemId: "data_structure_003",
      title: "연결 리스트(Linked List)의 장점",
      description: "다음 중 연결 리스트(Linked List)의 장점이 아닌 것은 무엇인가요?",
      subject: "Computer Science",
      language: "한국어",
      topic: "연결 리스트",
      subTopic: "연결 리스트 장점",
      difficulty: 3,
      difficultyDescription: "연결 리스트의 특성을 이해하고 다른 자료구조와 비교하는 문제입니다.",
      cognitiveLevel: "분석",
      prerequisiteKnowledge: ["기본 자료구조 개념", "배열의 특성"],
      estimatedTime: "3분",
      tags: ["Python", "Intermediate", "Data Structures", "Linked List"],
      learningObjectives: [
        "연결 리스트의 장단점 이해",
        "다른 자료구조와의 비교 분석",
      ],
      commonMistakes: ["배열과 연결 리스트의 특성을 혼동함"],
      hints: ["연결 리스트의 메모리 사용 방식과 접근 방식을 고려해보세요."],
      relatedConcepts: ["배열", "동적 메모리 할당"],
      learningStyle: ["분석적 학습"],
      assessmentType: "객관식",
      languageLevel: "중간",
      type: "multiple_choice",
      choices: [
        "동적인 크기 조절이 가능하다",
        "삽입과 삭제가 효율적이다",
        "메모리를 효율적으로 사용한다",
        "임의의 위치에 있는 요소에 빠르게 접근할 수 있다",
      ],
      correctAnswer: 3,
      solution: "연결 리스트는 임의 접근이 어렵고 순차적으로 탐색해야 하므로, 임의의 위치에 있는 요소에 빠르게 접근하는 것은 장점이 아닙니다. 따라서 정답은 4번입니다.",
      examples: [],
      promptForFineTuning: "연결 리스트(Linked List)의 주요 장점과 단점은 무엇인가요?",
      completionForFineTuning: "연결 리스트의 주요 장점은 동적인 크기 조절이 가능하고, 삽입과 삭제가 효율적이라는 점입니다. 단점으로는 임의 접근이 어렵고, 포인터로 인한 추가 메모리 사용이 있습니다.",
    },
  ];