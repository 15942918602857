import React from 'react';

// statusStyles를 컴포넌트 외부로 이동하고 export 합니다
export const statusStyles = {
  // 강의 종류(단일, 코스)
  module: { text: 'Module 🛰️', bgColor: 'bg-yellow-500', textColor: 'text-white' },
  course: { text: 'Course 🚀', bgColor: 'bg-blue-500', textColor: 'text-white' },
  
  // 강의 상태
  new: { text: 'NEW 🎁', bgColor: 'bg-red-500', textColor: 'text-white' },
  zoom: { text: 'ZOOM', bgColor: 'bg-blue-500', textColor: 'text-white' },
  closed: { text: '모집마감 😎', bgColor: 'bg-gray-500', textColor: 'text-white' },
  best: { text: 'BEST 🌟', bgColor: 'bg-[#d00070]-500', textColor: 'text-black' },
  dev: { text: '개발중 👷🏻', bgColor: 'bg-green-700', textColor: 'text-white' },
  ing: { text: '진행중 🔥', bgColor: 'bg-red-500', textColor: 'text-white' },
  test: { text: '역량 테스트 🎯', bgColor: 'bg-pink-500', textColor: 'text-white' },
  
  // 채용 관련 상태
  careersEmployment: { text: '채용예정 🚀', bgColor: 'bg-red-500', textColor: 'text-white' },
  careersIntern: { text: '인턴 🚀', bgColor: 'bg-green-500', textColor: 'text-white' },
  careersDev: { text: '개발자 🚀', bgColor: 'bg-blue-500', textColor: 'text-white' },
  careersPlanner: { text: '기획자 🚀', bgColor: 'bg-black', textColor: 'text-white' },
  
  // 추가 상태
  forStarter: { text: '입문자를 위하여 🚀', bgColor: 'bg-red-500', textColor: 'text-white' },
};

const Tile = ({
  title1,
  title2,
  description,
  title1Color,
  title2Color,
  descriptionColor,
  img,
  backgroundColor,
  width,
  height,
  mobileWidth,
  mobileHeight,
  title1Margin,
  title2Margin,
  customStyles = {}, // 추가된 customStyles prop
  onClick, // onClick 이벤트를 props로 전달받음
  status, // 배열로 받을 수 있도록 수정
}) => {
  // status를 배열로 처리
  const statuses = Array.isArray(status) ? status : [status];

  return (
    <div className="relative">
      <div className="absolute -top-10 left-6 flex flex-wrap gap-2">
        {statuses.map((stat, index) => {
          const currentStatus = statusStyles[stat];
          if (currentStatus) {
            return (
              <div key={index} className={`${currentStatus.bgColor} ${currentStatus.textColor} text-xs font-extrabold px-3 py-2 z-10 shadow-md`}>
                {currentStatus.text}
              </div>
            );
          }
          return null;
        })}
      </div>
      <div
        className={`rounded-2xl shadow-lg mx-2.5 flex-shrink-0 transform transition-transform duration-300 hover:scale-105 cursor-pointer ${width} ${height} ${mobileWidth} ${mobileHeight}`}
        style={{
          backgroundColor: backgroundColor || '#f0f0f0',
          backgroundImage: img ? `url(${img})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          ...customStyles.container, // customStyles로 추가된 스타일 적용
        }}
        onClick={onClick}
      >
        <div className="p-6 h-full flex flex-col justify-between" style={customStyles.content}> {/* customStyles 적용 */}
          <div>
            <h3 className={`text-sm font-bold ${title1Margin}`} style={{ whiteSpace: 'pre-line', color: title1Color, ...customStyles.title1 }}>
              {title1}
            </h3>
            <h3 className={`text-xl font-extrabold ${title2Margin}`} style={{ whiteSpace: 'pre-line', color: title2Color, ...customStyles.title2 }}>
              {title2}
            </h3>
          </div>
          <p className="font-bold" style={{ whiteSpace: 'pre-line', color: descriptionColor, ...customStyles.description }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tile;