import React, { useState, useEffect, useRef } from 'react';
import Editor from '@monaco-editor/react';

const MonacoEditor = ({ code, setCode, output, setOutput, testCases }) => {
  const editorRef = useRef(null);

  const [pyodide, setPyodide] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fontSize, setFontSize] = useState(16);

  // Pyodide 로딩
  useEffect(() => {
    const loadPyodide = async () => {
      try {
        setIsLoading(true);
        const pyodideInstance = await window.loadPyodide({
          indexURL: "https://cdn.jsdelivr.net/pyodide/v0.23.4/full/",
        });
        setPyodide(pyodideInstance);
      } catch (error) {
        setOutput(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    loadPyodide();
  }, [setOutput]);

  const runCode = async () => {
    if (!pyodide) {
      setOutput("Load Error ⚠️");
      return;
    }

    let allPassed = true;
    let currentOutput = "";
    let codeOutput = "";

    for (let i = 0; i < testCases.length; i++) {
      const { input, expected } = testCases[i];

      // Pyodide의 표준 입력을 설정하기 위해 input 재정의
      pyodide.globals.set("test_input", input);

      // 사용자 코드에 입력을 공급하고 출력을 캡처
      const userCode = `
import sys
from io import StringIO

# 표준 입력과 출력을 StringIO로 재설정
sys.stdin = StringIO(test_input)
sys.stdout = StringIO()

# 사용자의 코드 실행
${code}

# 출력 캡처
output = sys.stdout.getvalue()
      `;

      try {
        // 사용자 코드 실행
        await pyodide.runPythonAsync(userCode);

        // 출력을 가져와서 공백 제거
        const actualOutput = pyodide.globals.get("output").toString().trim();
        codeOutput += `입력: ${input}\n출력: ${actualOutput}\n\n`;

        // 실제 출력과 기대 출력 비교
        if (actualOutput === expected) {
          currentOutput += `TestCase ${i + 1}: True\n`;
        } else {
          currentOutput += `TestCase ${i + 1}: False
(입력값: ${input}, 올바른 출력값: ${expected}, 실제 출력값: ${actualOutput})\n`;
          allPassed = false;
        }
      } catch (err) {
        console.error("상세 오류:", err); // 디버깅을 위해 콘솔에 전체 오류 출력
        let simplifiedError = '오류 발생';
        
        // 에러 메시지 간소화 시도
        const match = err.message.match(/File "<exec>", line (\d+).*\n(\w+Error:.*)/s);
        if (match) {
          const [, lineNumber, errorMessage] = match;
          const adjustedLineNumber = parseInt(lineNumber) - 9; // Pyodide가 추가한 코드 줄 수를 뺍니다
          simplifiedError = `Line ${adjustedLineNumber} >> ${errorMessage.trim()}`;
        }
        
        currentOutput += `TestCase ${i + 1}: False (${simplifiedError})\n`;
        allPassed = false;
      }
    }

    // 전체 결과 출력
    if (allPassed) {
      setOutput(`[코드 실행 결과]\n${codeOutput}\n[테스트 케이스 결과]\n${currentOutput}\nPassed all the test cases 🚀`);
    } else {
      setOutput(`[코드 실행 결과]\n${codeOutput}\n[테스트 케이스 결과]\n${currentOutput}\n[결과] False`);
    }
  };

  const handleEditorChange = (value) => {
    setCode(value || '');
  };

  useEffect(() => {
    // 에디터 인스턴스가 있고, 코드가 변경되었을 때만 값을 업데이트
    if (editorRef.current && code !== editorRef.current.getValue()) {
      editorRef.current.setValue(code);
    }
  }, [code]);

  useEffect(() => {
    const updateFontSize = () => {
      setFontSize(window.innerWidth >= 900 ? 16 : 14);
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);

    return () => window.removeEventListener('resize', updateFontSize);
  }, []);

  return (
    <div className="editor-container">
      <div className="bg-[#1E1E1E] p-4 rounded-t-lg">
        <Editor
          height="200px"
          language="python"
          value={code}
          theme="vs-dark"
          onChange={handleEditorChange}
          onMount={(editor) => {
            editorRef.current = editor;
            editor.setValue(code);
          }}
          options={{
            fontSize: fontSize,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            automaticLayout: true,
            fontFamily: 'Consolas, "Courier New", monospace',
            fontWeight: 'bold',
            lineNumbersMinChars: 1,
            lineDecorationsWidth: 0,
          }}
          className="text-xs md:text-base"
        />
      </div>
      <div className="bg-[#252526] p-4 rounded-b-lg mt-2">
        <div className="flex justify-end mb-4">
          <button
            className="bg-white text-black py-2 px-4 rounded hover:bg-gray-200 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            onClick={runCode}
            disabled={isLoading}
          >
            {isLoading ? '🛰️' : '🚩 Run'}
          </button>
        </div>
        <div className="output-area bg-[#1E1E1E] p-4 rounded">
          <h2 className="text-lg font-extrabold font-mono mb-2 text-[#CCCCCC]">[Result]</h2>
          <pre className="whitespace-pre-wrap text-lg font-bold text-[#D4D4D4] font-mono">{output}</pre>
        </div>
      </div>
    </div>
  );
};

export default MonacoEditor;