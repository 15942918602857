// src/components/company/container/ending/TeamCredit.js

import React from 'react';

const TeamCredit = () => {
  return (
    <div className="grid grid-cols-12 pb-24 gap-y-2 gap-x-6 text-lg bg-black text-white px-[3%] py-12 -mt-1">
      {/* Founder */}
      <div className="text-center col-span-full mb-[10em]">
        <span className="block opacity-60 mb-2">Founder</span>
        <span className="block">조 용 진</span>
      </div>

      {/* <div className="leading-[1.4] col-span-6 text-center sm:text-right lg:col-span-3 lg:col-start-4 opacity-60 mb-6 sm:mb-[8.4em]">
        Developer
      </div>
      <div className="leading-[1.4] col-span-6 text-center sm:text-left lg:col-span-3 mb-[8.4em]">
        조 용진<br />
        David Schnurr<br /> Eric Luhman<br /> 
      </div> */}

      {/* <div className="leading-[1.4] col-span-12 lg:col-span-6 lg:col-start-4 mb-[8.4em] text-center max-w-prose mx-auto">
        <span className="block opacity-60 mb-6">Special Thanks</span>
        <span className="block">
          Avi Nayak, Bob McGrew, Spencer Papay, Srinivas Narayanan, Tifa Chen &amp; Wesam Manassra
        </span>
      </div> */}

      <div className="leading-[1.4] col-span-12 lg:col-span-6 lg:col-start-4 mb-[8.4em] text-center max-w-prose mx-auto">
        <span className="block font-bold">
        <span className="block md:inline">Built by <span className='text-[#D00070]'>SlopeX</span>celerity</span> in Cheonan, Korea<br/>
          Published September 1, MMXXIV
        </span>
      </div>
    </div>
  );
};

export default TeamCredit;