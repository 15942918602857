import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth, db } from './Firebase';
import { setUser, clearUser, setLoading } from './features/authSlice';
import { doc, getDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';

const FirebaseAuthListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          dispatch(setLoading(true));
          const userDocRef = doc(db, 'users', user.uid);
          let userDoc = await getDoc(userDocRef);

          let attempts = 0;
          const maxAttempts = 5;
          const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

          // 최대 5번까지 500ms 간격으로 재시도
          while (!userDoc.exists() && attempts < maxAttempts) {
            await delay(500); // 500ms 대기
            userDoc = await getDoc(userDocRef);
            attempts += 1;
          }

          if (userDoc.exists()) {
            const userData = userDoc.data();
            dispatch(setUser({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              ...userData,
            }));
          } else {
            // 사용자 문서가 존재하지 않으면 로그아웃
            await signOut(auth);
            dispatch(clearUser());
            alert('회원가입을 진행해주세요.');
          }
        } catch (error) {
          // 오류 발생 시 로그아웃 처리
          await signOut(auth);
          dispatch(clearUser());
          alert('로그인 중 오류가 발생했습니다. 문제가 계속되면 관리자에게 문의 부탁드립니다.');
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        dispatch(clearUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return null;
};

export default FirebaseAuthListener;