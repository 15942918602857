import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaCheckCircle, FaLock, FaPlay } from 'react-icons/fa';
import { AuthContext } from '../../../../../context/AuthContext';
import { ProgressService } from './ProgressService';
import { getProblemsForChapter } from './CurriculumManager';
import { 
  pythonCurriculum, 
  softwareEngineeringCurriculum, 
  dataStructuresCurriculum, 
  computerArchitectureCurriculum,
  promptEngineeringCurriculum,
  pyAlgorithmCurriculum
} from '../../../../../data/curriculumData';
import Skeleton from '../../../../skeleton/Skeleton';

const CurriculumOverview = () => {
  const { moduleType } = useParams();
  const [curriculum, setCurriculum] = useState(null);
  const [userProgress, setUserProgress] = useState({});
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      if (moduleType && user) {
        let found = null;
        if (moduleType === 'python-beginner') {
          found = pythonCurriculum.find(c => c.id === moduleType) || pythonCurriculum[0];
        } else if (moduleType === 'software-engineering') {
          found = softwareEngineeringCurriculum.find(c => c.id === moduleType) || softwareEngineeringCurriculum[0];
        } else if (moduleType === 'data-structures-beginner') {
          found = dataStructuresCurriculum.find(c => c.id === moduleType) || dataStructuresCurriculum[0];
        } else if (moduleType === 'computer-architecture-beginner') {
          found = computerArchitectureCurriculum.find(c => c.id === moduleType) || computerArchitectureCurriculum[0];
        } else if (moduleType === 'prompt-engineering-beginner') {
          found = promptEngineeringCurriculum.find(c => c.id === moduleType) || promptEngineeringCurriculum[0];
        } else if (moduleType === 'py-algorithm-100') {
          found = pyAlgorithmCurriculum.find(c => c.id === moduleType) || pyAlgorithmCurriculum[0];
        }
        setCurriculum(found);

        try {
          const progress = await ProgressService.getUserProgress(user.uid);
          setUserProgress(progress);
        } catch (error) {
          console.error('Error fetching user progress:', error);
        }
      }
    };
    fetchData();
  }, [moduleType, user]);

  if (!curriculum) return <Skeleton />;

  const getCompletedItemsCount = (chapterId) => {
    const chapterProgress = userProgress[moduleType]?.[chapterId] || {};
    return Object.keys(chapterProgress).length;
  };

  const isChapterLocked = (index) => {
    if (index === 0) return false;
    for (let i = 0; i < index; i++) {
      const prevChapter = curriculum.chapters[i];
      const prevChapterItems = getProblemsForChapter(moduleType, prevChapter.id);
      const completedItems = getCompletedItemsCount(prevChapter.id);
      if (completedItems !== prevChapterItems.length) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="flex justify-center items-center p-12">
      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-bold mb-6 text-center pt-14">{curriculum.title}</h1>
        <p className="text-lg text-gray-700 mb-8 text-center">{curriculum.description}</p>
        
        <div className="grid grid-cols-1 gap-6">
          {curriculum.chapters.map((chapter, index) => {
            const chapterItems = getProblemsForChapter(moduleType, chapter.id);
            const completedItems = getCompletedItemsCount(chapter.id);
            const isCompleted = completedItems === chapterItems.length;
            const isLocked = isChapterLocked(index);

            return (
              <div 
                key={index} 
                className={`flex items-center p-4 bg-white rounded-lg shadow-md transition ${isLocked ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 hover:shadow-xl'}`}
              >
                <div className={`w-12 h-12 rounded-lg flex items-center justify-center ${
                  isCompleted ? 'bg-purple-500' :
                  !isLocked ? 'bg-purple-300' : 'bg-gray-300'
                }`}>
                  {isCompleted ? <FaCheckCircle className="text-white" /> :
                   !isLocked ? <FaPlay className="text-white" /> :
                   <FaLock className="text-gray-500" />}
                </div>
                <div className="ml-4 flex-grow">
                  {isLocked ? (
                    <span className="inline-block text-lg font-semibold text-gray-500">
                      {chapter.title}
                    </span>
                  ) : (
                    <Link 
                      to={`/lms/test/${moduleType}/${chapter.id}/0`}
                      className="inline-block text-lg font-semibold text-blue-600 hover:underline"
                    >
                      {chapter.title}
                    </Link>
                  )}
                  <p className="text-sm text-gray-600 mt-1">{chapter.description}</p>
                </div>
                <div className="text-right">
                  <span className={`text-sm ${isLocked ? 'text-gray-400' : 'text-gray-600'}`}>
                    ({completedItems}/{chapterItems.length} 문제)
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
  
};

export default CurriculumOverview;
