import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa'; // 닫기 버튼 아이콘
import Cookies from 'js-cookie'; // 쿠키 관리 라이브러리
import popupBackground from '../../assets/tile/popup.webp'; // 배경 이미지 임포트
import { VERSION } from '../version/Version';

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [doNotShowToday, setDoNotShowToday] = useState(false);

  useEffect(() => {
    // 쿠키 확인: 'popupClosed'가 있으면 팝업을 표시하지 않음
    const popupClosed = Cookies.get('popupClosed');
    if (!popupClosed) {
      // 0.5초 후에 팝업 표시
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);

      return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 제거
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false); // 닫기 버튼 클릭 시 팝업 숨김
    if (doNotShowToday) {
      // '오늘은 더이상 열지 않기'가 체크되었으면 쿠키 설정 (1일 동안 유지)
      Cookies.set('popupClosed', 'true', { expires: 1 });
    }
  };

  const handleCheckboxChange = () => {
    setDoNotShowToday(!doNotShowToday); // 체크박스 상태 변경
  };

  if (!isVisible) return null; // 팝업이 보이지 않을 경우 렌더링하지 않음

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[2000]">
      <div className="relative w-[90%] max-w-[500px] h-[600px] bg-cover bg-center rounded-lg shadow-lg overflow-hidden" style={{backgroundImage: `url(${popupBackground})`}}>
        <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-between p-6">
          <button className="self-end text-white focus:outline-none" onClick={handleClose}>
            <FaTimes size={24} />
          </button>

          <div className="text-white font-extrabold text-center">
            <h2 className="text-3xl">SlopeXcelerity</h2>
            <h2 className="text-xl text-[#ffd400] font-extrabold mb-8">버전정보 : {VERSION}</h2>
            
            <p className="text-xl mb-8">SlopeX AI Agent를 1월 중 오픈 예정입니다.</p>
            <p className="text-xl mb-10">인공지능 시대에 필요한<br/>혁신적인 교육 서비스를<br/>제공해드리겠습니다.</p>
            
            <p className="text-xl font-bold">Founder 조용진 올림</p>
            <p className="text-base font-bold">2025. 01. 02(목)</p>
          </div>

          <div className="flex flex-col items-center">
            <button 
              className="bg-white text-black px-6 py-2 rounded-full font-bold mb-4 hover:bg-gray-200 transition duration-300"
              onClick={handleClose}
            >
              닫기
            </button>
            <div className="flex items-center text-white">
              <input
                type="checkbox"
                id="do-not-show"
                className="mr-2"
                checked={doNotShowToday}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="do-not-show" className="text-sm">
                오늘은 더이상 열지 않기
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;