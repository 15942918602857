import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Redux에서 유저 데이터 가져오기
import LmsTopNavMobile from './LmsTopNavMobile';
import { menuItems } from './menuItems';

const LmsTopNav = ({ onNavigate }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [menuClicked, setMenuClicked] = useState(false); // 추가된 상태
  const location = useLocation();

  // Redux에서 user 데이터 가져오기
  const user = useSelector((state) => state.auth.userData);
  const isSloper = user?.isSloper;

  const handleMouseEnter = useCallback((itemName) => {
    if (!menuClicked) { // 메뉴가 클릭되지 않았을 때만 작동
      setActiveMenu(itemName);
      setIsMenuVisible(true);
    }
  }, [menuClicked]);

  const handleMouseLeave = useCallback(() => {
    setIsMenuVisible(false);
    setMenuClicked(false); // 마우스가 떠날 때 클릭 상태 초기화
  }, []);

  const handleClick = useCallback((link, target, rel) => {
    setIsMenuVisible(false);
    setMenuClicked(true); // 메뉴가 클릭됨을 표시
    if (target === '_blank') {
      window.open(link, '_blank', rel);
    } else {
      onNavigate(link);
    }
  }, [onNavigate]);

  useEffect(() => {
    setIsMenuVisible(false);
    setMenuClicked(false); // 경로가 변경될 때 클릭 상태 초기화
  }, [location.pathname]);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== 'undefined') {
        if (window.scrollY > lastScrollY) { // 스크롤 내릴 때
          setIsNavVisible(false);
        } else { // 스크롤 올릴 때
          setIsNavVisible(true);
        }
        // 현재 스크롤 위치 업데이트
        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  // filteredMenuItems 부분을 다음과 같이 수정
  const filteredMenuItems = menuItems.map(item => {
    if (item.content && item.content.subMenu) {
      return {
        ...item,
        content: {
          ...item.content,
          subMenu: item.content.subMenu.filter(subMenu => 
            !subMenu.requiresSloper || (subMenu.requiresSloper && isSloper)
          )
        }
      };
    }
    return item;
  });

  return (
    <>
      <div className={`hidden md:block fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isNavVisible ? 'translate-y-0' : '-translate-y-full'}`}>
        <div className="relative" onMouseLeave={handleMouseLeave}>
          <nav className="bg-white bg-opacity-95 relative z-50">
            <div className="max-w-[1000px] mx-auto px-4">
              <ul className="flex justify-between items-center h-11 text-[12px] relative">
                <li className="flex-shrink-0">
                  <button onClick={() => handleClick('/lms/home')} className="font-bold text-[13px]">
                    <span className="text-[#D00070] font-extrabold">SlopeX AI + LMS</span>
                  </button>
                </li>
                {filteredMenuItems.map((item) => (
                  <li key={item.name} className="relative group px-[10px]"
                      onMouseEnter={() => handleMouseEnter(item.name)}>
                    <button 
                      onClick={() => handleClick(item.link, item.target, item.rel)}
                      className="hover:text-gray-500 text-[13px]"
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <div 
            className={`absolute left-0 right-0 bg-white shadow-lg z-40 transition-all duration-300 ease-in-out overflow-hidden`}
            style={{
              maxHeight: isMenuVisible ? '500px' : '0',
              opacity: isMenuVisible ? 1 : 0,
              visibility: isMenuVisible ? 'visible' : 'hidden',
            }}
          >
            {activeMenu && (
              <div className="py-8">
                <div className="max-w-[1000px] mx-auto px-4">
                  <div className="flex flex-wrap">
                    <div className="w-auto mr-24">
                      <h2 className="text-xs mb-6 whitespace-nowrap">
                        {filteredMenuItems.find(item => item.name === activeMenu)?.content.title}
                      </h2>
                      <ul className="space-y-2">
                        {filteredMenuItems.find(item => item.name === activeMenu)?.content.mainMenu.map((mainItem) => (
                          <li key={mainItem.name}>
                            <button onClick={() => handleClick(mainItem.link, mainItem.target, mainItem.rel)} className="text-[20px] font-extrabold hover:text-black">{mainItem.name}</button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex-1 flex flex-wrap gap-4">
                      {filteredMenuItems.find(item => item.name === activeMenu)?.content.subMenu.map((subMenu, index) => (
                        <div key={index} className="min-w-[100px] px-[2%]">
                          <h3 className="text-xs mb-6">{subMenu.title}</h3>
                          <ul className="space-y-2">
                            {subMenu.items.map((item) => (
                              <li key={item.name}>
                                <button 
                                  onClick={() => handleClick(item.link, item.target, item.rel)}
                                  className="text-[13px] font-bold hover:text-gray-500"
                                >
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isMenuVisible && (
        <div 
          className="fixed inset-0 z-30 bg-white bg-opacity-70 backdrop-blur-sm transition-opacity duration-300 ease-in-out"
          onClick={handleMouseLeave}
        ></div>
      )}
      <div className="md:hidden">
        <LmsTopNavMobile onNavigate={onNavigate} />
      </div>
    </>
  );
};

export default LmsTopNav;