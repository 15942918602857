import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';

const LmsAnalyticsFoundation = ({ initialIsOpen = false }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const contentProps = useSpring({
    opacity: isOpen ? 1 : 0,
    maxHeight: isOpen ? 1800 : 0, // 숫자 값으로 변경
    overflow: 'hidden',
    config: { duration: 300 },
  });

  return (
    <div className="flex justify-center w-full py-12">
      <div className="w-[900px]">
        <button
          className="w-full px-4 py-2 mb-4 bg-gray-100 flex justify-end items-center rounded-lg"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-lg font-semibold">적응형 학습 데이터 알아보기&nbsp;&nbsp;</span>
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        
        <animated.div style={contentProps} className="overflow-y-auto">
          <div className="space-y-8">
          <h2 className="text-2xl font-semibold mb-4">SlopeXcelerity의 적응형 학습은 두 가지 분류의 데이터를 기반으로 만들어집니다.</h2>
          <section>
            <h2 className="text-xl font-semibold mb-4">1. 회원가입 시 수집되는 학습 경험 데이터</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                    <th className="px-4 py-2 border">학습 경험 항목</th>
                    <th className="px-4 py-2 border">설명</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">프로그래밍 학습경험</td>
                    <td className="px-4 py-2 border">사용자의 프로그래밍 경험 수준을 파악하여 적절한 난이도의 학습 콘텐츠를 제공합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">선호하는 학습 속도</td>
                    <td className="px-4 py-2 border">개인의 학습 속도 선호도를 반영하여 맞춤형 학습 일정과 과제 제출 기한을 설정합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">학습 챌린지</td>
                    <td className="px-4 py-2 border">사용자가 느끼는 학습의 어려움을 파악하여 맞춤형 지원과 보충 자료를 제공합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">협업 선호도</td>
                    <td className="px-4 py-2 border">개인의 협업 스타일을 고려하여 그룹 프로젝트 및 페어 프로그래밍 기회를 제공합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">진도 추적</td>
                    <td className="px-4 py-2 border">사용자의 진도 추적 선호도에 따라 맞춤형 진도 보고서와 알림을 제공합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">선호하는 학습시간대</td>
                    <td className="px-4 py-2 border">개인의 선호 학습 시간을 고려하여 최적의 학습 일정과 알림을 설정합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                    <td className="px-4 py-2 border font-medium">보충 학습 추천</td>
                    <td className="px-4 py-2 border">사용자의 선호에 따라 추가적인 학습 자료와 보충 강의를 제공합니다.</td>
                    </tr>
                </tbody>
                </table>
            </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">2. 사용자의 학습과정에서 수집되는 학습 데이터</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">학습 데이터</th>
                      <th className="px-4 py-2 border">설명</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">학습 시간 패턴</td>
                      <td className="px-4 py-2 border">사용자의 학습 시간과 관련된 데이터를 분석하여 개인화된 학습 일정을 제안합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">학습 진도 및 속도</td>
                      <td className="px-4 py-2 border">강의 완료율, 테스트 결과 등 개인의 학습 속도에 맞춘 콘텐츠를 제공합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">문제 해결 능력</td>
                      <td className="px-4 py-2 border">문제의 정답률, 오류 유형, 문제 해결 시간 등을 분석하여 맞춤형 문제를 제공합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">상호작용 데이터</td>
                      <td className="px-4 py-2 border">학습 참여도를 측정하고 개선점을 제안합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">성취도 및 테스트</td>
                      <td className="px-4 py-2 border">00MATH, 01CS 등의 테스트 결과를 종합적으로 분석하여 개인의 강점과 약점을 파악합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">학습 스타일</td>
                      <td className="px-4 py-2 border">선호하는 학습 자료 유형, 복습 패턴 등을 분석하여 최적화된 학습 자료를 추천합니다.</td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="px-4 py-2 border font-medium">협업 선호도</td>
                      <td className="px-4 py-2 border">그룹 프로젝트 참여도, 동료 평가 결과 등을 분석하여 효과적인 협업 학습 기회를 제공합니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-4">데이터 활용 방법</h2>
              <ul className="list-disc list-inside space-y-2">
                <li>
                  <strong>개인화된 학습 경로 제공:</strong> 사용자의 학습 패턴을 분석하여 맞춤형 학습 콘텐츠와 경로를 제안합니다.
                </li>
                <li>
                  <strong>학습 성과 분석:</strong> 전체 사용자 및 개별 사용자의 학습 성과를 평가하고, 이를 기반으로 커리큘럼을 개선합니다.
                </li>
                <li>
                  <strong>알고리즘 개발:</strong> 적응형 학습 알고리즘을 통해 사용자 행동을 예측하고, 학습 효율을 극대화하는 전략을 개발합니다.
                </li>
                <li>
                  <strong>서비스 개선:</strong> 사용자 피드백과 데이터를 분석하여 플랫폼의 기능과 사용자 경험을 지속��으로 개선합니다.
                </li>
                <li>
                  <strong>보안 및 안정성:</strong> 수집된 데이터를 안전하게 관리하고, 시스템의 안정성을 유지하기 위해 활용합니다.
                </li>
              </ul>
            </section>
            
            <section>
              <h2 className="text-xl font-semibold mb-4">데이터 보호 및 프라이버시</h2>
              <p className="bg-gray-100 p-4 rounded">
                모든 수집된 데이터는 엄격한 보안 절차 하에 보호되며, 사용자 동의 없이 제3자와 공유되지 않습니다. 또한, 데이터는 익명화되어 분석에 활용됩니다.
              </p>
            </section>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default LmsAnalyticsFoundation;