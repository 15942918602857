import React from 'react';

const Skeleton = () => {
  const squares = Array.from({ length: 20 }, (_, i) => (
    <div
      key={i}
      className="float-square"
      style={{
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 2}s`
      }}
    />
  ));

  return (
    <div className="relative flex justify-center items-center h-screen overflow-hidden">
      <div className="relative w-8 h-8 border-2 border-[#D00070]">
        {squares}
      </div>
    </div>
  );
};

export default Skeleton;