import React from 'react';

const MemoryTestIntro = ({ onStart }) => {
  return (
    <div className="w-full max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-extrabold text-center text-[#D00070] mb-6">기억력 테스트(3분)</h2>
      <div className="space-y-6">
        <section>
          <h3 className="text-xl font-semibold mb-2">게임 규칙:</h3>
          <ol className="list-decimal list-inside space-y-2">
            <li>화면에 나타나는 타일의 순서를 주의 깊게 관찰하세요.</li>
            <li>타일이 깜빡인 후, 순서대로 타일을 클릭하세요.</li>
            <li>정확한 순서로 클릭하면 다음 라운드로 진행됩니다.</li>
            <li>라운드가 올라갈수록 기억해야 할 타일의 수가 증가합니다.</li>
            <li>잘못된 순서로 클릭하면 해당 라운드가 종료되고 다음 라운드로 넘어갑니다.</li>
          </ol>
        </section>
        <section>
          <h3 className="text-xl font-semibold mb-2">팁:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>타일의 위치를 숫자나 패턴으로 연관 지어 기억해보세요.</li>
            <li>집중력을 유지하고 차분하게 임하세요.</li>
            <li>실수해도 괜찮아요. 여러 번 도전하며 기억력을 향상시켜보세요!</li>
          </ul>
        </section>
      </div>
      <div className="text-center mt-8">
        <button
          onClick={onStart}
          className="bg-[#D00070] text-white px-8 py-3 rounded-lg hover:bg-[#A0005A] transition-colors text-lg font-semibold"
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default MemoryTestIntro;
