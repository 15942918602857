import prompt_engineering_001 from "../assets/prompt_basics/prompt_engineering_001.webp";
import prompt_engineering_002 from "../assets/prompt_basics/prompt_engineering_002.png";
import prompt_engineering_003 from "../assets/best01.png";

export const promptEngineeringProblems = [

    // id: "prompt-fundamentals"
    // title: "거대 언어 모델과 프롬프트 이해"
    // topics: ["Prompt Basics", "Prompt Engineering", "Prompt Structure", "Clear Instructions", "Context Provision", "Large Language Models"]
    //////////////////////////////

    // prompt-engineering_001 // Prompt Fundamentals // concept - 조각상을 만들어가는 과정과 비슷하다
    {
        problemId: "prompt-engineering_001",
        type: "concept",
        title: "조각상을 만들어가는 과정과 비슷하다",
        image: prompt_engineering_001,
        description: `생성형 AI로 내가 하고자 하는걸 만들어가는 과정은 마치 큰 원석을 가지고 조각상으로 만들어가는 과정과 비슷합니다.

        한번에 '어플리케이션을 만들어줘!' 라고 하는건 2024년 현재 불가능하지만, 그 과정을 하나씩 풀어서 만들어 갈수는 있습니다. 놀랍게도 여러분이 지금 공부하고 있는 이 사이트 조차, 개발자가 직접 작성한 코드는 거의 없습니다.

        언제, 얼마나 AI 기술이 발전하게될지 모릅니다.
        그러니 지금 당장 프롬프트를 작성하는 방법을 익혀야합니다.`,
        subject: "Prompt Engineering",
        topic: "Prompt Structure",
        subTopic: "Basic Structure",
        difficulty: 1,
        tags: ["Prompt Engineering", "Beginner", "AI", "Fundamentals"],
        relatedConcepts: ["AI Communication", "Natural Language Processing"],
        author: "SlopeXcelerity",
        dateCreated: "2024-10-17",
        lastUpdated: "2024-10-17",
        versionNumber: 1.0
    },
  
    // prompt-engineering_002 // Prompt Fundamentals // multiple_choice - 효과적인 프롬프트 구성
    {
      problemId: "prompt-engineering_002",
      type: "multiple_choice",
      title: "효과적인 프롬프트 구성",
      image: null,
      description: `프롬프트는 아주 명확하고 구체적일수록 더 좋은 결과를 얻을 수 있는 확률이 높아집니다.
      앞으로 인공지능 기술이 얼마나 빠르게 발전할지 예측하기 쉽지 않지만, 적어도 현재는 프롬프트를 작성하는데 컴퓨터의 기분까지 살필 필요는 없습니다.
      
      '미안한데..','부탁해도 될까?'라는 감정을 고려한 표현은 프롬프트에서 제외해야합니다. 아래 보기중 잘못된 프롬프트 작성법은 무엇일까요?`,
      subject: "Prompt Engineering",
      language: null,
      version: null,
      topic: "Prompt Structure",
      subTopic: "Basic Structure",
      difficulty: 1,
      difficultyDescription: "프롬프트의 기본 구조를 이해하는 초급 수준의 문제입니다.",
      cognitiveLevel: "Understand",
      cognitiveProcess: ["이해", "분석"],
      knowledgeDimension: ["개념적 지식"],
      prerequisiteKnowledge: [
        "AI의 기본 개념",
        "프롬프트의 정의"
      ],
      estimatedTime: "2분",
      tags: [
        "Prompt Engineering",
        "Beginner",
        "AI",
        "Fundamentals"
      ],
      programmingConcepts: ["프롬프트 작성"],
      skillsPracticed: ["프롬프트 구조 이해"],
      standardsAlignment: ["AI Ethics Guidelines"],
      learningObjectives: [
        "효과적인 프롬프트의 구성 요소를 이해한다.",
        "프롬프트 작성 시 불필요한 요소를 식별할 수 있다."
      ],
      commonMistakes: [
        "프롬프트의 모든 요소가 항상 필요하다고 오해함",
        "명확성과 구체성의 중요성을 과소평가함"
      ],
      hints: {
        beginner: [
          "프롬프트는 AI와의 효과적인 커뮤니케이션을 위한 도구입니다.",
          "AI는 주어진 정보만을 바탕으로 작업을 수행합니다.",
          "적어도 아직은 AI의 감정을 고려할 필요가 없습니다."
        ]
      },
      relatedConcepts: ["AI 커뮤니케이션", "자연어 처리"],
      learningStyle: ["개념적 학습"],
      questionType: "conceptual",
      answerFormat: "Single Choice",
      choices: [
        { id: "A", text: "아주 명확하고 구체적으로 작성한다." },
        { id: "B", text: "비슷한 예시를 보여주면서 결과를 요구한다." },
        { id: "C", text: "추상적인 지시를 최대한 줄인다." },
        { id: "D", text: "감정을 고려해서 AI의 기분이 안좋아보이면 조심스럽게 물어본다." }
      ],
      correctAnswer: "D",
      solution: "정답은 D입니다. AI는 감정을 가지고 있지 않으므로, AI의 감정을 고려하는 것은 효과적인 프롬프트 구성에 필요하지 않습니다. 명확한 지시사항, 관련 맥락 제공, 구체적인 요구사항은 모두 효과적인 프롬프트를 구성하는 중요한 요소입니다.",
      examples: [],
      accessibilityFeatures: ["특별한 요구 사항 없음"],
      technologyRequirements: ["없음"],
      estimatedDifficultyParameters: {
        discriminationIndex: 0.3,
        guessingParameter: 0.25
      },
      statisticalData: {
        attempts: 0,
        successRate: 0,
        suggestedProblem: null
      },
      progressData: {
        isCompleted: false
      },
      author: "SlopeXcelerity",
      dateCreated: "2024-10-17",
      lastUpdated: "2024-10-17",
      versionNumber: 1.0
    },

    // prompt-engineering_003 // Multiple_choice - 생성형 AI의 프롬프트 처리 과정
    {
      problemId: "prompt-engineering_003",
      type: "multiple_choice",
      title: "생성형 AI의 프롬프트 처리 과정",
      image: prompt_engineering_002,
      description: `프롬프트는 생성형 AI가 결과를 출력하기 위한 입력값입니다. 사실 더 정확하게는 거대 언어 모델에게 입력하는 값을 프롬프트라고 할 수 있습니다. 생성형 AI는 거대 언어 모델을 사용해서 만든 서비스로 보는게 더 정확합니다.

      그리고 프롬프트를 토큰화하고, 다시 텍스트로 변환하는 과정을 자연어 처리(Natural Language Processing, NLP)라고 합니다. 인공지능 기술의 한 분야입니다.

      생성형 AI가 프롬프트를 처리하는 올바른 과정은 무엇일까요?
      문제를 풀고, 거대 언어 모델에 대해서 알아보러 가보죠.`,
      subject: "Prompt Engineering",
      language: null,
      version: null,
      topic: "Large Language Models",
      subTopic: "Prompt Processing",
      difficulty: 1,
      difficultyDescription: "생성형 AI의 프롬프트 처리 과정을 이해하는 초급 수준의 문제입니다.",
      cognitiveLevel: "Understand",
      cognitiveProcess: ["이해", "분석"],
      knowledgeDimension: ["개념적 지식"],
      prerequisiteKnowledge: [
          "AI의 기본 개념",
          "프롬프트의 정의",
          "자연어 처리 기술"
      ],
      estimatedTime: "3분",
      tags: [
          "Prompt Engineering",
          "Large Language Models",
          "AI",
          "Intermediate"
      ],
      programmingConcepts: ["프롬프트 처리"],
      skillsPracticed: ["프롬프트의 처리 과정을 이해하고 설명"],
      standardsAlignment: ["AI 처리 과정 이해"],
      learningObjectives: [
          "생성형 AI의 프롬프트 처리 과정을 이해한다.",
          "프롬프트가 결과로 전환되는 과정을 설명할 수 있다."
      ],
      commonMistakes: [
          "프롬프트 처리 과정을 잘못 이해함",
          "자연어 처리의 역할을 혼동함"
      ],
      hints: {
          beginner: [
              "프롬프트는 AI에 대한 지시사항입니다.",
              "자연어 처리는 AI가 인간의 언어를 이해하고 생성하는 기술입니다.",
              "프롬프트를 통해 AI는 특정 작업을 수행합니다."
          ]
      },
      relatedConcepts: ["AI 프로세스", "자연어 이해", "확률 기반 생성"],
      learningStyle: ["개념적 학습"],
      questionType: "conceptual",
      answerFormat: "Single Choice",
      choices: [
          { id: "A", text: "프롬프트 입력 → 자연어 처리 → 결과 출력" },
          { id: "B", text: "프롬프트 입력 → 결과 출력" },
          { id: "C", text: "결과 출력 → 프롬프트 입력 → 자연어 처리" },
          { id: "D", text: "자연어 처리 → 프롬프트 입력 → 결과 출력" }
      ],
      correctAnswer: "A",
      solution: "정답은 A입니다. 생성형 AI는 사용자가 프롬프트를 입력하면, 이를 자연어 처리 기술을 통해 이해하고, 계산된 확률을 바탕으로 적절한 결과를 출력합니다.",
      examples: [],
      accessibilityFeatures: ["특별한 요구 사항 없음"],
      technologyRequirements: ["없음"],
      estimatedDifficultyParameters: {
          discriminationIndex: 0.4,
          guessingParameter: 0.25
      },
      statisticalData: {
          attempts: 0,
          successRate: 0,
          suggestedProblem: null
      },
      progressData: {
          isCompleted: false
      },
      author: "SlopeXcelerity",
      dateCreated: "2024-10-17",
      lastUpdated: "2024-10-17",
      versionNumber: 1.0
    },

    // prompt-engineering_004 // Multiple_choice - 생성형 AI 서비스의 사용 목적
    {
        problemId: "prompt-engineering_004",
        type: "multiple_choice",
        title: "생성형 AI 서비스의 사용 목적에 따른 분류",
        image: prompt_engineering_003,
        description: `가장 대표적인 생성형 AI 서비스는 ChatGPT입니다. GPT라는 거대 언어 모델을 기반으로 만들어졌습니다.

        GPT 모델을 기반으로 또 다른 서비스로는 MS Copilot도 있습니다. (2024년 현재 GPT 모델을 개발한 OpenAI의 지분의 49%는 마이크로소프트가 보유하고 있습니다.)
        
        GPT말고도 Llama, claude, Gemini 등 거대 언어 모델의 종류는 다양합니다. 이를 기반으로 만들어진 생성형 AI 서비스의 종류도 다양합니다.
        
        ChatGPT가 정말 많은 일을 해낼 수 있긴 하지만, 우리에게 필요한 작업이 무엇인지에 따라서 필요한 생성형 AI 서비스도 달라질 수 있습니다. 우리가 생성형 AI 서비스를 선택할때, 잘못된 방법은 무엇일까요?`,
        subject: "Prompt Engineering",
        language: null,
        version: null,
        topic: "Large Language Models",
        subTopic: "Service Purposes",
        difficulty: 2,
        difficultyDescription: "생성형 AI 서비스의 사용 목적을 이해하는 중급 수준의 문제입니다.",
        cognitiveLevel: "Understand",
        cognitiveProcess: ["이해", "분석"],
        knowledgeDimension: ["개념적 지식"],
        prerequisiteKnowledge: [
            "AI의 기본 개념",
            "생성형 AI 서비스의 종류"
        ],
        estimatedTime: "3분",
        tags: [
            "Prompt Engineering",
            "Large Language Models",
            "AI",
            "Intermediate"
        ],
        programmingConcepts: ["AI 서비스 선택"],
        skillsPracticed: ["사용 목적에 따른 AI 서비스 구분"],
        standardsAlignment: ["AI 서비스 분류 기준"],
        learningObjectives: [
            "생성형 AI 서비스의 다양한 사용 목적을 이해한다.",
            "목적에 맞는 AI 서비스를 선택할 수 있다."
        ],
        commonMistakes: [
            "AI 서비스의 다양한 기능을 구분하지 못함",
            "적절한 사용 목적에 맞지 않는 서비스를 선택함"
        ],
        hints: {
            beginner: [
                "AI 서비스는 특정 목적을 위해 최적화되어 있습니다.",
                "각 서비스의 주요 기능을 이해하면 목적에 맞는 선택이 가능합니다.",
                "목적과 기능을 일치시키는 것이 중요합니다."
            ]
        },
        relatedConcepts: ["AI 서비스 분류", "AI 기능 이해"],
        learningStyle: ["개념적 학습"],
        questionType: "conceptual",
        answerFormat: "Single Choice",
        choices: [
            { id: "A", text: "대화형 검색엔진 : Perplexity 등" },
            { id: "B", text: "이미지 생성 : Dall-e 등" },
            { id: "C", text: "코드 에디터 : Cursor 등" },
            { id: "D", text: "목적에 상관없이 ChatGPT만 쓰면 된다." },
            { id: "E", text: "회의 녹음 요약 : 클로바노트 등" }
        ],
        correctAnswer: "D",
        solution: "정답은 D입니다. 각 생성형 AI 서비스는 특정한 사용 목적을 위해 설계되었습니다. '목표에 상관없이 아무거나 사용해도 상관없다'는 옳지 않은 접근입니다. 사용 목적에 맞는 AI 서비스를 선택하여 효율적인 활용이 필요합니다.",
        examples: [],
        accessibilityFeatures: ["특별한 요구 사항 없음"],
        technologyRequirements: ["없음"],
        estimatedDifficultyParameters: {
            discriminationIndex: 0.4,
            guessingParameter: 0.25
        },
        statisticalData: {
            attempts: 0,
            successRate: 0,
            suggestedProblem: null
        },
        progressData: {
            isCompleted: false
        },
        author: "SlopeXcelerity",
        dateCreated: "2024-10-17",
        lastUpdated: "2024-10-17",
        versionNumber: 1.0
    },

    // prompt-engineering_005 // Concept - GPT 모델을 활용한 생성형 AI 문제 해결
    {
      problemId: "prompt-engineering_005",
      type: "concept",
      title: "앞으로 GPT 모델을 활용한 생성형 AI 문제 해결",
      image: null,
      description: `이제 본격적으로 프롬프트를 사용하기 위한 방법을 하나씩 배워보겠습니다.
      ChatGPT를 함께 사용하면서 공부하시는걸 권장드립니다.`,
      subject: "Prompt Engineering",
      topic: "Prompt Basics",
      subTopic: "Problem Solving with AI",
      difficulty: 2,
      tags: ["Prompt Engineering", "Generative AI", "GPT Models", "Problem Solving"],
      relatedConcepts: ["AI 응용", "자연어 처리", "문제 해결 전략"],
      author: "SlopeXcelerity",
      dateCreated: "2024-10-17",
      lastUpdated: "2024-10-17",
      versionNumber: 1.0
    },
    
    // id: "basic-information-retrieval",
    // title: "프롬프트 사용 1단계 : 정보 검색하기",
    // topics: ["Keyword Usage", "Question Formulation", "Specifying Information Type"]
    //////////////////////////////

    

  ];
