import React, { useState } from 'react';
import TileContainer from './tileComponents/TileContainer';
import Tile from './tileComponents/Tile';
import ContentsModal from '../modal/ContentsModal'; // 모달 컴포넌트

const InfoContents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);

  // 타일 데이터
  const tilesData = [
    {
      title1: '🧑🏻‍💻',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>첫 시작은 </span><br/>컴퓨터 과학
        </span>
      ),
      description: 'SlopeXcelerty의 기초공학교육.',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: 'SW교육의 중요성',
        modalDetails: `첫 시작을 컴퓨터 과학으로 시작하는 이유는 SW교육의 중요성이 나날이 높아지고 있기 때문입니다.
                      컴퓨터 과학은 전공과 상관없이 모든 과에서 배우게 된 과목이며,
                      CS50이라는 강의는 하버드에서 가장 인기있는 강의 1위에 선정되기도 했습니다.
                      개발자에 대한 수요도 끝없이 높아지고 있지만,
                      수학에 대한 기피와 공학계열에 대한 도전이 줄어들면서 양질의 개발자 육성에도 빨간불이 켜졌습니다.
                      무작정 부트캠프에 참가하기 전에, 컴퓨터 과학의 개념을 배우면서 제대로 시작해보세요.`,
        modalExtraInfo: '공부 시작하기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '🩺',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>SlopeX AI.</span><br/>
          <span style={{ color: '#000' }}>그리고 LMS.</span>
        </span>
      ),
      description: '학습데이터 분석으로 맞춤형 교육.',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: '공부도 효율적으로',
        modalDetails: `사용자의 학습경험을 바탕으로 맞춤형 교육을 제공합니다.
                      학습 커리큘럼도 01CS의 코치가 사용자의 학습경험과 목표를 바탕으로 직접 설계하여,
                      스스로 학습할 수 있는 방법을 제시힙니다.
                      그리고 인공지능 기술로 개발된 01CS의 적응형 학습과 챗봇으로
                      스스로 학습하면서 인공지능의 도움을 받을 수 있습니다.
                      사용자의 학습 데이터를 수집하여 치밀한 분석을 통해 학습성과를 향상시킬 수 있습니다.
                      이렇게 강력한 기능을 가지고 있는 LMS는 사용자의 학습을 최대한으로 끌어올립니다.`,
        modalExtraInfo: 'LMS 사용해보기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '🏃🏻‍♂️‍➡️',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>강력한</span><br/>
          <span style={{ color: '#000' }}>동기부여</span>
        </span>
      ),
      description: '목표까지 달려갈 수 있도록.',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: '스스로 학습할 수 있는 01CS',
        modalDetails: `우리의 목표는 스스로 기초공학을 공부하고, 더 높은 곳으로 올라갈 수 있는 발판을 만들어가는 것입니다.
                      그래서 기본적으로 스스로 학습할 수 있는 환경을 제공하는게 목표입니다.
                      다양한 장치를 만들어서 강력한 동기부여를 제공하고, 목표까지 달려갈 수 있도록 합니다.
                      누구나, 어디서든, 언제든지 공학을 공부하는 세상을 만들어가려고 합니다.
                      공학은 우리가 살아가는 세상에서 반드시 필요하기 때문에.`,
        modalExtraInfo: '목표 설정하기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '⚙️',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>다양한</span><br/>
          <span style={{ color: '#000' }}>공학교육 콘텐츠</span>
        </span>
      ),
      description: '수학, 물리, 화학, 반도체, 기계 등.',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: '공학교육에 필요한 다른분야의 공부도',
        modalDetails: `사실 공학을 공부하는데 빼놓을 수 없는 과목은 바로 수학입니다. 영어도 필요하죠.
                      그리고 우리는 공부할때 보통 이유를 잘 생각하지 않습니다.
                      왜 내가 이걸 배워야 하는지, 어디에 사용되는건지 학교나 학원에서는 잘 알려주지 않습니다.
                      너무 아쉽지만 대학교도 그런것 같습니다. 그래서 어디선가 이런 내용을 알려주면 좋겠다고 생각했습니다.
                      수학에서 인수분해는 대체 왜하는지, 미분은 왜 배우는지 누군가 알려주면 너무 좋을텐데 말이죠.
                      이런 교육을 위해서 SlopeXcelerity가 존재합니다.
                      개념을 튼튼하게. '왜?'라는 질문을 끝없이 던지면서 할 수 있는 공부를 위해.`,
        modalExtraInfo: '교육 콘텐츠 구경하기 〉',
        // modalLink: '#'
      },
    },
    {
      title1: '👬',
      title2: (
        <span>
          <span style={{ color: '#D00070' }}>러닝메이트</span>와 함께<br/>
          <span style={{ color: '#000' }}>끝까지 완주하기</span>
        </span>
      ),
      description: 'SlopeX의 코치가 함께합니다.',
      img: '', // 이미지 삭제
      width: 'md:w-[300px]',
      height: 'h-[290px]',
      mobileWidth: 'w-[300px]',
      mobileHeight: 'h-[290px]',
      title1Margin: 'mt-8 mb-16',
      title2Margin: 'mb-2',
      customStyles: {
        container: {
          backgroundColor: '#ffffff',
        },
        title1: {
          fontSize: '100px',
          color: '#333',
        },
        title2: {
          fontSize: '25px',
          color: '#000',
        },
      },
      modalContent: {
        modalTitle: '혼자 공부하기 힘들다면',
        modalDetails: `SlopeXcelerity에는 전문 코치가 있습니다.
                      스스로 공부하기가 여전히 어렵다면 우리가 런닝메이트가 되어드립니다.
                      코치의 관리하에 스스로 학습하는 방법을 배워갈 수 있습니다.`,
        modalExtraInfo: '러닝메이트 구하러가기 〉',
        // modalLink: '#'
      },
    },
  ];

  const openModal = (tile) => {
    setSelectedTile(tile); // 타일 데이터를 저장
    setIsModalOpen(true);  // 모달 열기
  };

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  // 타일들을 렌더링
  const tiles = tilesData.map((tile, index) => (
    <Tile
      key={index}
      title1={tile.title1}
      title2={tile.title2}
      description={tile.description}
      img={tile.img}
      width={tile.width}
      height={tile.height}
      mobileWidth={tile.mobileWidth}
      mobileHeight={tile.mobileHeight}
      title1Margin={tile.title1Margin}
      title2Margin={tile.title2Margin}
      customStyles={tile.customStyles}
      onClick={() => openModal(tile)} // 타일 클릭 시 모달 열기
    />
  ));

  return (
    <div className="relative w-full py-8">
      <div className="max-w-[2000px] mx-auto px-[3%] md:px-[5%]">
        <h2 className="text-3xl font-bold mb-4 text-[#969696]">
          <span className="text-[#000]">학습자 맞춤형</span><br />기초공학교육
        </h2>
      </div>

      {/* 타일 컨테이너 */}
      <TileContainer tiles={tiles} containerId="info-tile-scroll" />

      {/* 모달 컴포넌트 */}
      {selectedTile && (
        <ContentsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          imgSrc={selectedTile.img}
          title1={selectedTile.title1}
          title2={selectedTile.title2}
          description={selectedTile.description}
          modalContent={selectedTile.modalContent}
        />
      )}
    </div>
  );
};

export default InfoContents;