import React from 'react';
import { Bar } from 'react-chartjs-2';

const CumulativeStudyTimeChart = ({ monthlyStudyTime, cumulativeStudyTime }) => {
  const chartData = {
    labels: ['이번 달', '누적'],
    datasets: [
      {
        label: '학습 시간 (시간)',
        data: [monthlyStudyTime, cumulativeStudyTime],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 159, 64, 0.6)'],
      },
    ],
  };

  return <Bar data={chartData} />;
};

export default CumulativeStudyTimeChart;