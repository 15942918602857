// 완료된 강의 목록을 표시하는 곳.
// 타일 형태로 표시되어, 강의 이름/완료날짜/Sloper 평가/완료 여부 표시
// 완료된 강의가 없을 경우, "완료된 강의가 없습니다. 이런 강의는 어떠신가요?" 메시지 표시와 추천 강의 목록(타일 형태) 표시
import React from 'react';
import { useLms } from '../../../../context/LmsContext';

const LmsCompleteClass = () => {
  const { data } = useLms();
  const { completeClasses } = data;

  if (!completeClasses || completeClasses.length === 0) {
    return (
      <div className="flex flex-wrap justify-center p-4">
        <h1 className='font-extrabold'>완료한 강의</h1>
        <p>완료된 강의가 없습니다. 이런 강의는 어떠신가요?</p>
        {/* 추천 강의 타일 컴포넌트 추가 */}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap justify-center p-4">
      <h1 className='font-extrabold'>완료한 강의</h1>
      {completeClasses.map((course) => (
        <div key={course.id} className="bg-white p-4 m-2 rounded-lg shadow">
          <h2 className="text-lg font-semibold">{course.title}</h2>
          <p>완료 날짜: {course.completedDate}</p>
          <p>Sloper 평가: {course.sloperFeedback}</p>
          <p>완료 여부: {course.isCompleted ? '완료' : '진행 중'}</p>
          {/* 필요 시 추가 기능 구현 */}
        </div>
      ))}
    </div>
  );
};

export default LmsCompleteClass;