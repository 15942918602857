import React from 'react';
import { useNavigate } from 'react-router-dom';

const SignupStep3 = ({ onClose }) => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    onClose(); // 모달 닫기
    navigate('/lms/memory-test'); // 로그인 페이지로 이동
  };

  return (
    <div className="space-y-8 p-4 text-center">
      <p className="text-lg text-gray-700">
        01CS에 가입해주셔서 감사합니다!<br/><br/>
        이제 SlopeX AI와 LMS로<br/>학습을 시작할 수 있습니다.
        <br/><br/>
        먼저 사용자 경험에 맞춘<br/>커리큘럼을 추천해드리겠습니다.
        <br/>
        SlopeX AI와 전문 코치의 도움을 받아<br/>학습 목표를 설정해보세요.
      </p>

      <button
        className="w-full px-6 py-3 bg-[#D00070] text-white text-lg font-medium rounded-lg transition-colors hover:bg-[#c20066]"
        onClick={handleLoginRedirect}
      >
        기억력 테스트 체험하기
      </button>

      <p className="text-sm text-gray-600">
        스스로 강의를 찾아보고 학습하고 싶으시면,<br/>LMS 강의찾기로 페이지로 이동시켜드리겠습니다.
      </p>
    </div>
  );
};

export default SignupStep3;