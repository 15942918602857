import React from 'react';

const ClassPage = () => {
  const materials = [
    {
      day: '기초 파이썬 프로그래밍',
      link: 'https://quietjean.notion.site/AI-78e74f05e4bd4666a72886e3f056e612?pvs=25',
      type: 'python'
    },
    {
      day: 'pip 설치방법(라이브러리 관리)',
      link: 'https://quietjean.notion.site/python-pip-3a35aadfd0c64ccba43a88b2666ba4f4',
      type: 'python'
    },
    {
      day: 'Numpy 라이브러리(1)',
      link: 'https://colab.research.google.com/drive/1eFj3G-KQH6hXhSWpq0hBIrMdD4CBhlHh?hl=ko',
      type: 'python'
    },
    {
      day: 'Numpy 라이브러리(2)',
      link: 'https://colab.research.google.com/drive/1gZrzleGcz4nE2_mgjFC9bjfLBoE1Wrvc?hl=ko',
      type: 'python'
    },
    {
      day: 'Pandas 라이브러리',
      link: '/assets/pandas.pdf',
      type: 'python'
    },
    {
      day: 'Matplotlib 라이브러리(1)',
      link: 'https://colab.research.google.com/drive/1VT2fAfVpk606MAHpOJizkg1jOd4qsQfR?hl=ko',
      type: 'python'
    },
    {
      day: 'Matplotlib 라이브러리(2)',
      link: 'https://colab.research.google.com/drive/1DnqUDNU4xgqGZOj_jxzzpPG0e5W6ag7A?hl=ko',
      type: 'python'
    },
    {
      day: 'openAI - API key 발급 방법',
      link: 'https://quietjean.notion.site/openAI-API-key-884a5a30231d49a68d1274edd67100cc',
      type: 'python'
    },
    {
      day: 'openAI - Image Generation',
      link: 'https://colab.research.google.com/drive/1Z4LHZCf9KjjMc68utmpw0c9T8WTkzrqX?hl=ko#scrollTo=kwIPgP2qFL7l',
      type: 'python'
    },
    {
      day: 'openAI - Text to Speech',
      link: 'https://colab.research.google.com/drive/1GD_c6PEFdVvC4_xjExHZzP9TlGmqfkgK?hl=ko#scrollTo=-R79w8_9GtkL',
      type: 'python'
    },
    {
      day: 'openAI - Speech to Text',
      link: 'https://colab.research.google.com/drive/1R-QHuGdJ7Q7LQnPAnOA2UPetuxaotIE1?hl=ko#scrollTo=iB53-B5Ylkeo',
      type: 'python'
    },
    {
      day: '모바일 UI/UX(1) - (5)',
      link: 'https://brunch.co.kr/@chulhochoiucj0/8',
      type: 'reference'
    },
    {
      day: 'GPT를 활용한 문법+알고리즘 공부법',
      link: 'https://quietjean.notion.site/GPT-93529f291bbe4fd181f1190009cedf8a',
      type: 'reference'
    },
    {
      day: 'Figma - UI/UX 작업도구',
      link: 'https://www.figma.com/',
      type: 'reference'
    },
    {
      day: 'Flow Chart 도구',
      link: 'https://app.diagrams.net/',
      type: 'reference'
    },
    {
      day: 'ERC20 기반 스마트 계약 만들기',
      link: 'https://quietjean.notion.site/ECR-20-47d42dc9368b4889ae95e82aca175b4e?pvs=74',
      type: 'reference'
    },
    {
      day: 'EXCEL VBA 코드 : 계산기',
      link: 'https://quietjean.notion.site/excel-37df3618a71b4f688fa40f9d531a8eaa',
      type: 'reference'
    },
    {
      day: '1일차 수업자료',
      link: 'https://quietjean.notion.site/1-598bc6ead0b947629f4ab38ee1b82dd1?pvs=74',
      type: 'course'
    },
    {
      day: '2일차 수업자료',
      link: 'https://quietjean.notion.site/2-0d77f3bdacba4ebea25a2cd8f71dff73?pvs=25',
      type: 'course'
    },
    {
      day: '3일차 수업자료',
      link: 'https://quietjean.notion.site/AI-3-4-84893156685f40efa8b7e11727e3a000',
      type: 'course'
    },
    {
      day: '4일차 수업자료 - 프롬프트 작성법',
      link: '/assets/prompt26.pdf',
      type: 'course'
    },
    {
      day: '5일차 수업자료 - VSCode, Firebase',
      link: 'https://quietjean.notion.site/AI-5-59e2106abd0a4bb5ba74cb345acf8589',
      type: 'course'
    },
    {
      day: '6일차 수업자료 - 챗봇과 자연어 처리',
      link: 'https://quietjean.notion.site/AI-6-277dd5185d284168a1e6bc262bd23fc7',
      type: 'course'
    },
    {
      day: '7일차 수업자료 - GPT+프로젝트(안심번호 QR)',
      link: 'https://quietjean.notion.site/QR-e7721cabd11e4a8b94879d47c10314a2',
      type: 'course'
    },
    {
      day: '8일차 수업자료 - Fine-tuning',
      link: 'https://quietjean.notion.site/openAI-API-Fine-tuning-0b22d03e705b4431a0401136f99031a8?pvs=74',
      type: 'course'
    },
    {
      day: '9일차 수업자료 - 프로젝트',
      link: 'https://quietjean.notion.site/e5231cd1bf5b4fb2bd5b216c9ac746a4',
      type: 'course'
    },
    {
      day: 'AI실무 생산성 높이기',
      link: 'https://quietjean.notion.site/AI-ed91da7e0cab4e6a85622da124690ffd?pvs=25',
      type: 'ai'
    },
    {
      day: 'Excel + GPT',
      link: 'https://quietjean.notion.site/GPT-4a86929741b94bd6948860abbd26ebbc',
      type: 'ai'
    },
    {
      day: '이메일 자동화 및 fine-tuning',
      link: 'https://quietjean.notion.site/fine-tuning-136dba9e37d680dc90fdef583a5f9cd6?pvs=4',
      type: 'ai'
    }
  ];

  const pythonMaterials = materials.filter(material => material.type === 'python');
  const courseMaterials = materials.filter(material => material.type === 'course');
  const referenceMaterials = materials.filter(material => material.type === 'reference');
  const aiMaterials = materials.filter(material => material.type === 'ai');

  return (
    <div className="container mx-auto py-36 px-[5%]">
      <div>
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
          컴퓨터과학. <span className="text-[#D00070]">CS01</span>
        </h1>
        <h1 className="text-lg font-bold mb-4">교육자료는 10월 10일까지 제공해드립니다.</h1>
        <br />
        <h1 className="text-2xl font-bold mb-4">파이썬 공부</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {pythonMaterials.map((material, index) => (
            <a
              key={index}
              href={material.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-4 border rounded shadow hover:bg-gray-100"
            >
              <div className="text-center">
                <h2 className="text-xl font-bold">{material.day}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-bold mb-4">수업자료</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {courseMaterials.map((material, index) => (
            <a
              key={index}
              href={material.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-4 border rounded shadow hover:bg-gray-100"
            >
              <div className="text-center">
                <h2 className="text-xl font-bold">{material.day}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-bold mb-4">참고자료</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {referenceMaterials.map((material, index) => (
            <a
              key={index}
              href={material.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-4 border rounded shadow hover:bg-gray-100"
            >
              <div className="text-center">
                <h2 className="text-xl font-bold">{material.day}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-bold mb-4">AI실무</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {aiMaterials.map((material, index) => (
            <a
              key={index}
              href={material.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-4 border rounded shadow hover:bg-gray-100"
            >
              <div className="text-center">
                <h2 className="text-xl font-bold">{material.day}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClassPage;